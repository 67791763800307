import React from "react";
import { useState, useEffect } from "react";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import Flights from "./Flights";
import Bus from "./Bus";
import Train from "./Train";

const InterCityTransfer = (props) => {
  const {
    selectedCountryData,
    selectedHotel,
    setSelectedHotel,
    DomesticFlightArrivalRegionbyDay,
    setDomesticFlightArrivalRegionbyDay,
    isIntercitytransferbyDay,
    setIsIntercitytransferbyDay,
    TypeofIntercityTransferbyDay,
    setTypeofIntercityTransferbyDay,
    DomesticFlightArrivalCitybyDay,
    setDomesticFlightArrivalbyDay,
    DomesticFlightSourcebyDay,
    setDomesticFlightSourcebyDay,
    selectedTrainData,
    selectedBusData,
  } = useContext(UserContext);
  
  const travelTypes = [ "Train", "Bus"];
  const cities = [
    "Ho Chi Minh",
    "Da Lat",
    "Phu Quoc",
    "Ha Noi",
    "Sa Pa",
    "Ninh Binh",
    "Da Lat",
    "Nha Trang",
    "Da Nang",
    "Hoi An",
    "Hue",
    "Nha Trang",
    "Dong Hoi",
  ];
  
  // useEffect(() => {
  //   if(selectedTrainData.length > 0){
  //     let temp = [...TypeofIntercityTransferbyDay];
  //     temp[props.day] = "Train";
  //     setTypeofIntercityTransferbyDay(temp);
  //   } 
  // }

  // , [selectedTrainData])

  return (
    <div>
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <select
          className="  p-2   rounded-md focus:outline-1   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-full"  
          style={{ boxShadow: '1px 1px 3px 0px black' }}
          value={DomesticFlightSourcebyDay[props.day] || selectedTrainData.find((train) => train.day === props.day)?.source || selectedBusData.find((bus) => bus.day === props.day)?.source || ""}
          onChange={(e) => {
            let temp = [...DomesticFlightSourcebyDay];
            temp[props.day] = e.target.value;
            setDomesticFlightSourcebyDay(temp);
          }}
        >
          <option>Source City</option>
          {cities.map((item, ind) => (
            <option key={ind} value={item}>
              {item}
            </option>
          ))}
        </select>
        {/* <select
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 mx-2"
          value={DomesticFlightArrivalRegionbyDay[props.day] || ""}
          onChange={(e) => {
            let temp = [...DomesticFlightArrivalRegionbyDay];
            temp[props.day] = e.target.value;
            setDomesticFlightArrivalRegionbyDay(temp);
          }}
        >
          <option value="">Select Region</option>
          {selectedCountryData.regions &&
            Object.keys(selectedCountryData.regions).map((region, index) => (
              <option key={region} value={region}>
                {region}
              </option>
            ))}
        </select> */}
        <select
          className="  p-2   rounded-md focus:outline-1   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-full"  
          style={{ boxShadow: '1px 1px 3px 0px black' }}
          value={DomesticFlightArrivalCitybyDay[props.day] || selectedTrainData.find((train) => train.day === props.day)?.arrival || selectedBusData.find((bus) => bus.day === props.day)?.arrival || ""}
          onChange={(e) => {
            let temp = [...DomesticFlightArrivalCitybyDay];
            temp[props.day] = e.target.value;
            setDomesticFlightArrivalbyDay(temp);
          }}
        >
          <option>Arrival City</option>
          {cities.map((item, ind) => (
            <option key={ind} value={item}>
              {item}
            </option>
          ))}
        </select>
        <select
          className="  p-2   rounded-md focus:outline-1   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-full"  
          style={{ boxShadow: '1px 1px 3px 0px black' }}
          value={TypeofIntercityTransferbyDay[props.day] || (selectedTrainData.find((train) => train.day === props.day)? "Train" : selectedBusData.find((bus) => bus.day === props.day)? "Bus" : "")}
          onChange={(e) => {
            let temp = [...TypeofIntercityTransferbyDay];
            temp[props.day] = e.target.value;
            setTypeofIntercityTransferbyDay(temp);

          }}
        >
          <option>Type of Travel</option>
          {travelTypes.map((item, ind) => (
            <option key={ind} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      {/* {TypeofIntercityTransferbyDay[props.day] === "Flight" && (
        <div>
          <Flights
            day={props.day}
            sourceCity={DomesticFlightSourcebyDay[props.day]}
            arrivalCity={DomesticFlightArrivalCitybyDay[props.day]}
          />
        </div>
      )} */}

      {(TypeofIntercityTransferbyDay[props.day] === "Train" || selectedTrainData.find((train) => train.day === props.day))
       && (
        <div>
          <Train
            day={props.day}
            sourceCity={DomesticFlightSourcebyDay[props.day]}
            arrivalCity={DomesticFlightArrivalCitybyDay[props.day]}
          />
        </div>
      )}

      {(TypeofIntercityTransferbyDay[props.day] === "Bus" || selectedBusData.find((bus) => bus.day === props.day))
       && (
        <div>
          <Bus
            day={props.day}
            sourceCity={DomesticFlightSourcebyDay[props.day]}
            arrivalCity={DomesticFlightArrivalCitybyDay[props.day]}
          />
        </div>
      )}
    </div>
  );
};

export default InterCityTransfer;
