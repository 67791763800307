import React from 'react';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import ChatIcon from '@mui/icons-material/Chat';
import { LiaGiftsSolid } from "react-icons/lia";
import { RiCustomerService2Line } from "react-icons/ri";
import { FaRupeeSign } from "react-icons/fa";


const BenefitsSection = () => {
  const benefits = [
    { 
      title: "500 Amazon Voucher and multi-brand vouchers", 
      icon: () => <CardGiftcardIcon className="text-gray-500 text-3xl" /> 
    },
    { 
      title: "Travaura Goodies", 
      icon: () => <LiaGiftsSolid className="text-gray-500 text-3xl" /> 
    },
    { 
      title: "Travaura Cashback up to INR 5,000", 
      icon: () => <FaRupeeSign className="text-gray-500 text-3xl px-2" /> 
    },
    { 
      title: "Best On Ground Assistance", 
      icon: () => <SupportAgentIcon className="text-gray-500 text-3xl" /> 
    },
    { 
      title: "Best Customer Support", 
      icon: () => <RiCustomerService2Line className="text-gray-500 text-3xl " /> 
    },
    { 
      title: "Smooth Travel Experience", 
      icon: () => <TravelExploreIcon className="text-gray-500 text-3xl" /> 
    },
    { 
      title: "100% Free Cancellation up to 10 days*", 
      icon: () => <CancelPresentationIcon className="text-gray-500 text-3xl" /> 
    },
    { 
      title: "Automated Communication", 
      icon: () => <ChatIcon className="text-gray-500 text-3xl" /> 
    },
  ];

  const BenefitItem = ({ title, icon: Icon }) => {
    return (
      <div className="bg-white rounded-lg shadow-md p-2 pb-4 flex flex-col items-center justify-between text-center h-full transition-transform duration-300 hover:-rotate-3 hover:shadow-2xl">
        <div className=" flex items-center justify-center bg-white  rounded-full mb-6 scale-150 my-4 py-4">
          <Icon />
        </div>
        <div className="text-gray-800 text-sm items-start md:text-lg mb-2 h-full flex md:items my-2 justify-center font-medium">
          {title}
        </div>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {benefits.map((benefit, index) => (
        <BenefitItem key={index} title={benefit.title} icon={benefit.icon} />
      ))}
    </div>
  );
}

export default BenefitsSection;
