import React from "react";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { FaPlane } from "react-icons/fa";

const DomesticFlightCard = (props) => {
  const handleDeleteDomesticFlight = () => {
    fetch(`https://travaurabackend.azurewebsites.net/deleteDomesticFlightManual/${props.id}/${props.ind}`, {
        method: "DELETE",
    })
    .then((res) => {
        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
    })
    .then((data) => {
        console.log("data: ", data);
       
        props.setDeleteCounter(prevCount => prevCount + 1);
    })
    .catch((error) => {
        console.log("Error deleting international flight: ", error);
    });
};

  let formattedNewDate = "Invalid Date";


  if (props.dateOfTravel) {
    const dateOfTravel = new Date(props.dateOfTravel);
    if (!isNaN(dateOfTravel)) {
      const newDate = new Date(dateOfTravel);
      newDate.setDate(newDate.getDate() + props.item.day - 1);
      if (!isNaN(newDate)) {
        formattedNewDate = newDate.toISOString().split("T")[0];
      }
    }
  }

  
  return (
    <div>
  {/* <div
    key={props.ind}
    className="bg-gray-700 p-3 md:p-3 rounded-lg flex flex-col md:flex-row justify-between my-4"
  >
    <div>
      <div className="text-sm md:text-base font-medium">
        {props.item.sourceCity} to {props.item.arrivalCity}
      </div>
      <div className="text-sm md:text-base">{props.item.carrier}</div>
    </div>
    <div>
      <div className="text-sm md:text-base">Day: {props.item.day}</div>
      <div className="text-sm md:text-base">Date: {formattedNewDate}</div>
      <div className="text-sm md:text-base">Departure Time: {props.item.departureTime}</div>
      <div className="text-sm md:text-base">Arrival Time: {props.item.arrivalTime}</div>
      <div className="text-sm md:text-base">Stops: {props.item.noOfStops}</div>
      <div className="text-sm md:text-base">Baggage: {props.item.baggage}</div>
    </div>
    <RiDeleteBin2Fill className="text-2xl text-red-500 cursor-pointer print-proof" onClick={handleDeleteDomesticFlight} />
  </div> */}
  <div className="bg-secondary p-4 rounded-lg">
      
      <div className="bg-[#121212] p-4 rounded-lg max-w-full mx-auto my-8">
        <button className="bg-gradient-to-tr from-[#424242] to-[#769895] text-[#ece7e7] px-16 py-1 rounded relative bottom-7 left-10">
          Flight {props.ind + 1+ props.num}
        </button>
        <div className="flex items-center justify-between mb-4">
          <button className="bg-blue-400 text-white  rounded"></button>
          <div className="flex items-center mb-5 mr-3">
            <span className="text-white text-xl">{props.item.carrier}</span>
          </div>
        </div>
        <div className="flex items-center justify-between text-white  px-8">
          <div className="relative bottom-8 text-gray-300">
            <div className="text-xl font-medium">
              {props.item.sourceCity}
            </div>
            {/* <div className="text-sm">
              {extractCityAndCode(props.item.departureCity).cityName}
            </div> */}
            <div className="text-sm">{props.item.departureTime}</div>
          </div>
          <div className="flex-1 mx-8">
            <div className="border-t border-gray-600 flex justify-center ">
              <FaPlane className="text-gray-500 text-2xl relative bottom-3" />
            </div>
            <div className="text-center text-sm mt-2 text-gray-300">
              <div>{props.item.noOfStops} Stop(s)</div>
              <div>Baggage - {props.item.baggage}</div>
              <div>
                {props.item.day
                  ? ("Day- " + props.item.day)
                  : ""}
              </div>
            </div>
          </div>
          <div className="relative bottom-8 text-gray-300">
            <div className="text-xl font-medium">
              {props.item.arrivalCity}
            </div>
            {/* <div className="text-sm">
              {extractCityAndCode(props.item.arrivalCity).cityName}
            </div> */}
            <div className="text-sm">{props.item.arrivalTime}</div>
          </div>
        </div>
       
      </div>
      <RiDeleteBin2Fill className="text-2xl text-red-500 cursor-pointer print-proof" onClick={handleDeleteDomesticFlight} />
    </div>
</div>

  );
};

export default DomesticFlightCard;
