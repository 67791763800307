import React from 'react'

const TermsandConditions = (props) => {
    return (
        <div className=" p-8 text-gray-500 mx-10 px-15 mt-12  rounded-lg">
        
            <h1 className="text-3xl font-bold mb-6 text-gray-300">Terms and Conditions</h1>

            {/* Section 1 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">1. Threats and Defamation</h2>
                <p className='text-sm'>1.1. <strong>Social Media and Defamation</strong>: In the event of any threats, defamatory actions, or damaging statements made by the Traveler through social media, online reviews, or other means, we reserve the right to take necessary legal actions against the Traveler to protect our reputation and business.</p>
            </div>

            {/* Section 2 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">2. Missed Shared Activities</h2>
                <p className='text-sm'>2.1. <strong>Shared Activities</strong>: Travelers are responsible for punctuality and adherence to shared activities (SIC tours). Personal transfers requested due to late arrival or missed activities are at the Traveler's expense and not the responsibility of Travaura.</p>
            </div>

                {/* Section 3 */}
                <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">3. Itinerary Modifications</h2>
                <p className='text-sm'>3.1. <strong>Itinerary Changes</strong>: We may need to modify the itinerary due to unforeseen circumstances. Travelers are expected to accept these modifications. Any disputes regarding itinerary changes should be requested in writing to bookings@travaura.com.</p>
            </div>

            {/* Section 4 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">4. Timely Payments</h2>
                <p className='text-sm'>4.1. <strong>Payment Deadline</strong>: Payments must be made on time to avoid any disruptions during the trip. Failure to release payments as per the communicated schedule may result in the cancellation of your booking.</p>
            </div>

            {/* Section 5 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">5. Flight Price Changes</h2>
                <p className='text-sm'>5.1. <strong>Dynamic Flight Prices</strong>: Flight prices are subject to change in real-time booking. Any increase in flight prices after booking will not be borne by Travaura.</p>
            </div>

            {/* Section 6 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">6. Visa Services</h2>
                <p className='text-sm'>6.1. <strong>Visa Services</strong>: Travaura provides visa services as an additional service. We ensure correct filing but do not bear responsibility for visa rejections due to poor photo quality, unclear passports, invalid details, or misinformation provided by the Traveler. Related losses will not be borne by Travaura.</p>
            </div>

            {/* Section 7 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">7. On-Ground Support and Customer Service</h2>
                <p className='text-sm'>7.1. <strong>On-Ground Support</strong>: On-ground support is available 24/7 during the trip only. Our customer service department operates from 09:00 to 20:00, except Sundays. 24/7 chat support will be provided by the local team of the destination, not solely by Travaura. Travelers are advised not to contact their sales executive at random times.</p>
            </div>

            {/* Section 8 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">8. Early Check-In and Late Check-Out</h2>
                <p>8.1. <strong>Early Check-In and Late Check-Out</strong>: Early check-in and late check-out are not included unless purchased separately. Timings are subject to the standards of the respective hotels and countries.</p>
            </div>

            {/* Section 9 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">9. Inclusion Modification</h2>
                <p className='text-sm'>9.1. <strong>Inclusion Modification</strong>: Once a booking is made, modifications or deductions to inclusions are not allowed. Add-ons are still possible.</p>
            </div>

            {/* Section 10 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">10. Missed Tours</h2>
                <p className='text-sm'>10.1. <strong>Missed Tours</strong>: If a tour is missed due to timing issues, any associated services (transportation, guide, meals, entry tickets) will not be covered by Travaura.</p>
            </div>

            {/* Section 11 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">11. Extra Payments to Vendors</h2>
                <p className='text-sm'>11.1. <strong>Extra Vendor Payments</strong>: Payments made directly to vendors without prior consent from Travaura will not be reimbursed.</p>
            </div>

            {/* Section 12 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">12. Group Bookings</h2>
                <p className='text-sm'>12.1. <strong>Group Booking Flexibility</strong>: For group bookings, the itinerary may be adjusted to accommodate all travelers. The pattern and structure of timings are subject to change due to the dynamic nature of the industry.</p>
            </div>

            {/* Section 13 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">13. Hotel Availability</h2>
                <p className='text-sm'>13.1. <strong>Hotel Availability</strong>: Hotels listed in the itinerary are subject to change in case of unavailability.</p>
            </div>

            {/* Section 14 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">14. Cancellation and Rescheduling Cover</h2>
                <p className='text-sm'>14.1. <strong>Cancellation and Rescheduling Cover</strong>: A cancellation and rescheduling cover is available as an add-on for INR 1,600. This cover allows for trip cancellation or rescheduling within 6 days of travel, applicable to the land package only. Fare rules of booked flights apply.</p>
            </div>

            {/* Section 15 */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">15. Cancellation Policy</h2>
                <p className='text-sm'>15.1. <strong>Cancellation by Traveler</strong>: If you wish to cancel your trip, notify us in writing at bookings@travaura.com. Every cancellation is prone to cancellation amount which are on the basis of follows.</p>
                <p className='text-sm'>15.2. <strong>Cancellation by Travaura</strong>: Travaura reserves the right to cancel a tour due to unforeseen circumstances or insufficient participants. In such cases, we offer an alternative tour or a full refund.</p>
                <p className='text-sm'>15.3. <strong>No-Shows</strong>: If you fail to join the tour on the specified departure date without prior notice, no refunds will be provided.</p>
                <p className='text-sm'>15.3. <strong>Flights’ Cancellation</strong>: Fare rules of respective booked flights is taken into account when cancelling the flights either Domestic or International.</p>
            </div>

            {/* Privacy Policy */}
            <div className="mb-6">
                <h2 className="text-md font-semibold mb-2">Privacy Policy</h2>
                <p className='text-sm'>Travaura may collect and use personal information as described in our Privacy Policy. By booking a trip with us, you consent to the collection and use of your personal information in accordance with our Privacy Policy.</p>
            </div>

        </div>
       
    
    )
}

export default TermsandConditions

        

     

// **1. Threats and Defamation**
// 1.1. **Social Media and Defamation**: In the event of any threats, defamatory actions, or damaging statements made by the Traveler through social media, online reviews, or other means, we reserve the right to take necessary legal actions against the Traveler to protect our reputation and business.
// **2. Missed Shared Activities**
// 2.1. **Shared Activities**: Travelers are responsible for punctuality and adherence to shared activities (SIC tours). Personal transfers requested due to late arrival or missed activities are at the Traveler's expense and not the responsibility of Travaura.
// **3. Itinerary Modifications**
// 3.1. **Itinerary Changes**: We may need to modify the itinerary due to unforeseen circumstances. Travelers are expected to accept these modifications. Any disputes regarding itinerary changes should be requested in writing to bookings@travaura.com.
// **4. Timely Payments**
// 4.1. **Payment Deadline**: Payments must be made on time to avoid any disruptions during the trip. Failure to release payments as per the communicated schedule may result in the cancellation of your booking.
// **5. Flight Price Changes**
// 5.1. **Dynamic Flight Prices**: Flight prices are subject to change in real-time booking. Any increase in flight prices after booking will not be borne by Travaura.
// **6. Visa Services**
// 6.1. **Visa Services**: Travaura provides visa services as an additional service. We ensure correct filing but do not bear responsibility for visa rejections due to poor photo quality, unclear passports, invalid details, or misinformation provided by the Traveler. Related losses will not be borne by Travaura.
// **7. On-Ground Support and Customer Service**
// 7.1. **On-Ground Support**: On-ground support is available 24/7 during the trip only. Our customer service department operates from 09:00 to 20:00, except Sundays. 24/7 chat support will be provided by the local team of the destination, not solely by Travaura. Travelers are advised not to contact their sales executive at random times.
// **8. Early Check-In and Late Check-Out**
// 8.1. **Early Check-In and Late Check-Out**: Early check-in and late check-out are not included unless purchased separately. Timings are subject to the standards of the respective hotels and countries.
// **9. Inclusion Modification**
// 9.1. **Inclusion Modification**: Once a booking is made, modifications or deductions to inclusions are not allowed. Add-ons are still possible.
// **10. Missed Tours**
// 10.1. **Missed Tours**: If a tour is missed due to timing issues, any associated services (transportation, guide, meals, entry tickets) will not be covered by Travaura.
// **11. Extra Payments to Vendors**
// 11.1. **Extra Vendor Payments**: Payments made directly to vendors without prior consent from Travaura will not be reimbursed.
// **12. Group Bookings**
// 12.1. **Group Booking Flexibility**: For group bookings, the itinerary may be adjusted to accommodate all travelers. The pattern and structure of timings are subject to change due to the dynamic nature of the industry.
// **13. Hotel Availability**
// 13.1. **Hotel Availability**: Hotels listed in the itinerary are subject to change in case of unavailability.
// **14. Cancellation and Rescheduling Cover**
// 14.1. **Cancellation and Rescheduling Cover**: A cancellation and rescheduling cover is available as an add-on for INR 1,600. This cover allows for trip cancellation or rescheduling within 6 days of travel, applicable to the land package only. Fare rules of booked flights apply.
// **15. Cancellation Policy**
// 15.1. **Cancellation by Traveler**: If you wish to cancel your trip, notify us in writing at bookings@travaura.com. Every cancellation is prone to cancellation amount which are on the basis of follows.
// 15.2. **Cancellation by Travaura**: Travaura reserves the right to cancel a tour due to unforeseen circumstances or insufficient participants. In such cases, we offer an alternative tour or a full refund.
// 15.3. **No-Shows**: If you fail to join the tour on the specified departure date without prior notice, no refunds will be provided.
// 15.3. **Flights’ Cancellation**: Fare rules of respective booked flights is taken into account when cancelling the flights either Domestic or International.
// These TnCs are governed by and construed in accordance with the laws of Indian Jurisdiction. Any disputes shall be subject to the exclusive jurisdiction of the courts in Indian Jurisdiction.
// **Privacy Policy**: Travaura may collect and use personal information as described in our Privacy Policy. By booking a trip with us, you consent to the collection and use of your personal information in accordance with our Privacy Policy.