import React, { useEffect } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

const PackageDetails = ({ setPackageDetails, packageDetails, setCountry }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if(name === 'country') {
        setCountry(value);
    }
    setPackageDetails((prevState) => ({
      ...prevState,
      packageDetails: {
        ...prevState.packageDetails,
        [name]: value,
      },
    }));
  };

  const handleImage = async (e) => {
    e.preventDefault();
    try {
      const file = e.target.files[0]; // Get the selected file
      if (file) {
        await uploadImage(file);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  const uploadImage = async (image) => {
    const data = new FormData();
    data.append("file", image);
    data.append("upload_preset", "travaura_hotel");
    data.append("cloud_name", "dxcq5zluj");
    const response = await fetch("https://api.cloudinary.com/v1_1/dxcq5zluj/image/upload", {
      method: "POST",
      body: data,
    });
    const file = await response.json();
    console.log(file.secure_url);
    setPackageDetails(prevState => ({
      ...prevState,
      packageDetails: {
        ...prevState.packageDetails,
        photoGallery: [...prevState.packageDetails.photoGallery, file.secure_url],
      },
    }));
  };

  useEffect(() => {
    console.log("PackageDetails: ", packageDetails);
  }, [packageDetails]);
  return (
    <div>
      <div className="flex justify-center">
        <label className="block text-white text-2xl font-bold my-5">
          Package Details
        </label>
      </div>
      <div className="bg-secondary w-full max-w-4xl p-3 rounded-xl mx-auto">
        <div className="grid grid-cols-2 gap-4 m-3">
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="packageId"
            >
              Package ID
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="packageId"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.packageId}
              type="text"
              placeholder="Package ID"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="country"
            >
              Country
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="country"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.country}
              type="text"
              placeholder="Country"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="language"
            >
              Language
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="language"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.language}
              type="text"
              placeholder="Language"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="name"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.name}
              type="text"
              placeholder="Name"
            />
          </div>
          <div className="mb-4 flex justify-between">
            <div className="mb-4">
              <label
                className="block text-white text-sm font-bold mb-2"
                htmlFor="days"
              >
                Days
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
                name="days"
                onChange={handleInputChange}
                value={packageDetails.packageDetails.days}
                type="text"
                placeholder="Days"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-white text-sm font-bold mb-2"
                htmlFor="nights"
              >
                Nights
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
                name="nights"
                onChange={handleInputChange}
                value={packageDetails.packageDetails.nights}
                type="text"
                placeholder="Nights"
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="placesCovered"
            >
              Places Covered
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="placesCovered"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.placesCovered}
              type="text"
              placeholder="Places Covered"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="citiesCovered"
            >
              Cities Covered
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="citiesCovered"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.citiesCovered}
              type="text"
              placeholder="Cities Covered"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="seasonality"
            >
              Seasonality
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="seasonality"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.seasonality}
              type="text"
              placeholder="Seasonality"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="difficultyLevel"
            >
              Difficulty Level
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="difficultyLevel"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.difficultyLevel}
              type="text"
              placeholder="Difficulty Level"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="theme"
            >
              Theme
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="theme"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.theme}
              type="text"
              placeholder="Theme"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="maxGroupSize"
            >
              Max Group Size
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="maxGroupSize"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.maxGroupSize}
              type="text"
              placeholder="Max Group Size"
            />
          </div>
          <div className="flex justify-around items-center">
            <div className="flex ">
              <label
                className="block text-white text-sm font-bold mb-2 mx-4"
                htmlFor="customizability"
              >
                Customizability
              </label>
              <input
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="customizability"
                type="checkbox"
                onChange={() =>
                  setPackageDetails((prevState) => ({
                    ...prevState,
                    packageDetails: {
                      ...prevState.packageDetails,
                      customizability:
                        !prevState.packageDetails.customizability,
                    },
                  }))
                }
                checked={packageDetails.packageDetails.customizability} // Ensure checked attribute evaluates to a boolean
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="tags"
            >
              Tags
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="tags"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.tags}
              type="text"
              placeholder="Tags"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="packageTypes"
            >
              Package Types
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="packageTypes"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.packageTypes}
              type="text"
              placeholder="Package Types"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="inclusions"
            >
              Inclusions
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="inclusions"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.inclusions}
              type="text"
              placeholder="Inclusions"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="exclusions"
            >
              Exclusions
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="exclusions"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.exclusions}
              type="text"
              placeholder="Exclusions"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="optionalActivities"
            >
              Optional Activities
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="optionalActivities"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.optionalActivities}
              type="text"
              placeholder="Optional Activities"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="cancellationPolicy"
            >
              Cancellation Policy
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="cancellationPolicy"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.cancellationPolicy}
              type="text"
              placeholder="Cancellation Policy"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="mealPlanDetails"
            >
              Meal Plan Details
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="mealPlanDetails"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.mealPlanDetails}
              type="text"
              placeholder="Meal Plan Details"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="healthVaccinationRequirements"
            >
              Health Vaccination Requirements
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="healthVaccinationRequirements"
              onChange={handleInputChange}
              value={
                packageDetails.packageDetails.healthVaccinationRequirements
              }
              type="text"
              placeholder="Health Vaccination Requirements"
            />
          </div>
          <div className="flex justify-around items-center">
            <div className="flex ">
              <label
                className="block text-white text-sm font-bold mb-2 mx-4"
                htmlFor="visaAssistance"
              >
                Visa Assistance
              </label>
              <input
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="visaAssistance"
                type="checkbox"
                onChange={() =>
                  setPackageDetails((prevState) => ({
                    ...prevState,
                    packageDetails: {
                      ...prevState.packageDetails,
                      visaAssistance: !prevState.packageDetails.visaAssistance,
                    },
                  }))
                }
                checked={packageDetails.packageDetails.visaAssistance} // Ensure checked attribute evaluates to a boolean
              />
            </div>
          </div>

          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="culturalEtiquetteTips"
            >
              Cultural Etiquette Tips
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="culturalEtiquetteTips"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.culturalEtiquetteTips}
              type="text"
              placeholder="Cultural Etiquette Tips"
            />
          </div>
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">
              Testimonials & Ratings
            </label>

            {/* Average Rating Input */}
            <div className="mb-2">
              <label
                className="block text-white text-sm font-bold mb-2"
                htmlFor="averageRating"
              >
                Average Rating
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="averageRating"
                onChange={(e) =>
                  setPackageDetails((prevState) => ({
                    ...prevState,
                    packageDetails: {
                      ...prevState.packageDetails,
                      testimonialsRatings: {
                        ...prevState.packageDetails.testimonialsRatings,
                        averageRating: e.target.value, // Assuming you want to store this as a string; convert to number if necessary
                      },
                    },
                  }))
                }
                value={
                  packageDetails.packageDetails.testimonialsRatings
                    .averageRating
                }
                type="number"
                placeholder="Average Rating"
                step="0.1" // Allow decimal values for ratings
              />
            </div>

            {/* Dynamic Testimonials Inputs */}
            {packageDetails.packageDetails.testimonialsRatings.testimonials.map(
              (testimonial, index) => (
                <div key={index} className="mb-2">
                  <label
                    className="block text-white text-sm font-bold mb-2"
                    htmlFor={`testimonial-${index}`}
                  >
                    Testimonial #{index + 1}
                  </label>
                  <div className="flex justify-between">
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-3"
                      name={`testimonial-${index}`}
                      onChange={(e) =>
                        setPackageDetails((prevState) => {
                          const updatedTestimonials = [
                            ...prevState.packageDetails.testimonialsRatings
                              .testimonials,
                          ];
                          updatedTestimonials[index] = e.target.value;
                          return {
                            ...prevState,
                            packageDetails: {
                              ...prevState.packageDetails,
                              testimonialsRatings: {
                                ...prevState.packageDetails.testimonialsRatings,
                                testimonials: updatedTestimonials,
                              },
                            },
                          };
                        })
                      }
                      value={testimonial}
                      type="text"
                      placeholder={`Testimonial #${index + 1}`}
                    />
                    <button // Button to remove a testimonial input
                      className="bg-red-500 px-2 py-1 rounded-lg text-white font-bold focus:outline-none focus:shadow-outline"
                      onClick={() =>
                        setPackageDetails((prevState) => {
                          const updatedTestimonials = [
                            ...prevState.packageDetails.testimonialsRatings
                              .testimonials,
                          ];
                          updatedTestimonials.splice(index, 1); // Remove the testimonial at the current index
                          return {
                            ...prevState,
                            packageDetails: {
                              ...prevState.packageDetails,
                              testimonialsRatings: {
                                ...prevState.packageDetails.testimonialsRatings,
                                testimonials: updatedTestimonials,
                              },
                            },
                          };
                        })
                      }
                    >
                      Remove
                    </button>
                  </div>
                </div>
              )
            )}

            {/* Button to Add New Testimonial Input */}
            <button
              className="bg-blue-500 px-2 py-1 rounded-lg text-white font-bold focus:outline-none focus:shadow-outline mt-2"
              onClick={() =>
                setPackageDetails((prevState) => ({
                  ...prevState,
                  packageDetails: {
                    ...prevState.packageDetails,
                    testimonialsRatings: {
                      ...prevState.packageDetails.testimonialsRatings,
                      testimonials: [
                        ...prevState.packageDetails.testimonialsRatings
                          .testimonials,
                        "",
                      ], // Add an empty string for a new testimonial input
                    },
                  },
                }))
              }
            >
              Add Testimonial
            </button>
          </div>
          <div className="mb-4">
  <label className="block text-white text-sm font-bold mb-2" htmlFor="photoGallery">
    Photo Gallery
  </label>
  <input
    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    name="photoGallery"
    onChange={handleImage}
    type="file"
    placeholder="Photo Gallery"
  />
 <div className="flex my-2">
  {packageDetails.packageDetails.photoGallery.length > 0 &&
    packageDetails.packageDetails.photoGallery.map((item, ind) => (
      <div key={ind} className="relative mx-2">
        <img src={item} alt={`Gallery item ${ind + 1}`} className="w-32 h-32" />
        {/* <button
          className="bg-red-500 px-2 py-1 rounded-lg text-white font-bold focus:outline-none focus:shadow-outline absolute top-0 right-0"
          onClick={() =>
            setPackageDetails((prevState) => {
              const updatedGallery = [...prevState.packageDetails.photoGallery];
              updatedGallery.splice(ind, 1);
              return {
                ...prevState,
                packageDetails: {
                  ...prevState.packageDetails,
                  photoGallery: updatedGallery,
                },
              };
            })
          }
        >
          x
        </button> */}
        <RiDeleteBin6Line
          className="text-red-500 absolute bottom-0 right-0 cursor-pointer"
          onClick={() =>
            setPackageDetails((prevState) => {
              const updatedGallery = [...prevState.packageDetails.photoGallery];
              updatedGallery.splice(ind, 1);
              return {
                ...prevState,
                packageDetails: {
                  ...prevState.packageDetails,
                  photoGallery: updatedGallery,
                },
              };
            })
          }
        />
      </div>
    ))}
</div>

</div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="videoOverview"
            >
              Video Overview
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="videoOverview"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.videoOverview}
              type="text"
              placeholder="Video Overview"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="interactiveMap"
            >
              Interactive Map
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:
                    shadow-outline"
              name="interactiveMap"
              onChange={handleInputChange}
              value={packageDetails.packageDetails.interactiveMap}
              type="text"
              placeholder="Interactive Map"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageDetails;

// const [packageDetails, setPackageDetails] =  useState({
//     packageDetails: {
//       packageId: '',
//       country: '',
//       creationDate: '',
//       lastUpdated: '',
//       language: '',
//       name: '',
//       days: 0,
//       nights: 0,
//       placesCovered: [],
//       citiesCovered: [],
//       seasonality: '',
//       difficultyLevel: '',
//       theme: '',
//       maxGroupSize: 0,
//       customizability: false,
//       tags: [],
//       packageTypes: [],
//       inclusions: [],
//       exclusions: [],
//       optionalActivities: [],
//       cancellationPolicy: '',
//       mealPlanDetails: '',
//       healthVaccinationRequirements: '',
//       visaAssistance: false,
//       culturalEtiquetteTips: '',
//       testimonialsRatings: {
//         testimonials: [],
//         averageRating: 0
//       },
//       photoGallery: [],
//       videoOverview: '',
//       interactiveMap: '',
//     },
//     accommodations: {
//       hotelCategory: '',
//       roomType: '',
//       amenities: [],
//       locationType: '',
//     },
//     additionalFeatures: {
//       nightsInEachCity: [{
//         city: '',
//         nights: 0

//       }],
//       itineraryDetails: [],
//       transportationDetails: '',
//       guideServices: {
//         available: false,
//         languages: []
//       },
//       travelInsurance: '',
//       paymentOptions: [],
//       healthSafetyMeasures: '',
//       sustainabilityPractices: '',
//       emergencySupport: '',
//       localPartners: []
//     }
//   });
