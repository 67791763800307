/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import { useParams } from "react-router-dom";
import React from "react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import ActivityCard from "./ActivityCard";
import Logo from "../Logo.png";
import image from "./image.png";
import DomesticFlightCard from "./DomesticFlightCard";
import InternationalFlightsCard from "./InternationalFlightCard";
import BenefitsSection from "./BenefitsSection";
import TravellersGallerySection from "./TravellersGallerySection";
import k from "../assets/images/k.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import formatDate from "../contexts/dateConverter";
import TermsandConditions from "./TermsandConditions";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { FaExchangeAlt } from "react-icons/fa";
import FlightCard from "../FlightCard";
import InternationalFlightCard from "./InternationalFlightCard";
import FlightsCard from "./FlightCARD";
import HikingIcon from "@mui/icons-material/Hiking";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import HorizontalSectionIndicator from "./HorizontalSectionIndicator";
import PaymentMethodsSection from "./PaymentMethodsSection";
import LogoAnimation from "./logoAnimation";
import travellerImage from "../assets/images/travellerImage.png";
import group from "../assets/images/Group.png";
import upi from "../assets/images/upi-icon.png";
import axios from "axios";
import BookingFlightsCard from "./BookingFlightsCard";
import CambodiaActivityCard from "./CambodiaActivityCard";
import { end } from "@cloudinary/url-gen/qualifiers/textAlignment";
import LabelAnimation from "../Animations/Text_Entry";
import { MdEdit } from "react-icons/md";
import { BsFillFloppyFill } from "react-icons/bs";
import {useRef} from "react";

const LinkPage = () => {
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);

  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const loadImages = (images) => {
    return Promise.all(
      images.map((img) => {
        if (img.complete && img.naturalHeight !== 0) return Promise.resolve();
        return new Promise((resolve) => {
          img.onload = () => resolve();
          img.onerror = () => resolve(); // even if there is an error, continue
        });
      })
    );
  };

  const handleAddInternationalFlight = () => {
    navigate(
      `/resultPage/${
        country === "Vietnam/Cambodia" ? "Vietnam" : country
      }/${id}/AddInternationalFlights`
    );
  };

  const handleAddDomesticFlight = () => {
    navigate(
      `/resultPage/${
        country === "Vietnam/Cambodia" ? "Vietnam" : country
      }/${id}/AddDomesticFlights`
    );
  };

  const generatePDF = async () => {
    setIsGeneratingPdf(true);
    setTimeout(async () => {
      const element = document.getElementById("contentToPrint");

      if (document.fonts) {
        await document.fonts.ready;
      }

      // Check if the element exists
      if (!element) {
        console.error("Element to print not found!");
        return;
      }

      // const pdfButton = document.getElementById("generatePdfButton");
      // const pppCheck = document.getElementById("hide");
      const hide = document.getElementsByClassName("print-proof");
      for (var i = 0; i < hide.length; i++) {
        hide[i].style.visibility = "hidden";
      }

      try {
        const canvas = await html2canvas(element, {
          scale: window.devicePixelRatio, // Use the device's pixel ratio to get full resolution
          useCORS: true,
        });

        const imgData = canvas.toDataURL("image/jpeg");

        // Calculate the number of pages
        const pageHeightInPixels = canvas.height;
        const pageWidthInPixels = canvas.width;

        // Assuming a ratio for A4 size page in portrait mode (210mm x 297mm)
        const pageWidthInMM = 300;
        const pageHeightInMM =
          (pageHeightInPixels * pageWidthInMM) / pageWidthInPixels;

        const pdf = new jsPDF("p", "mm", [pageWidthInMM, pageHeightInMM]);

        pdf.addImage(imgData, "JPEG", 0, 0, pageWidthInMM, pageHeightInMM);
        pdf.save(`${travellerDetails.name} trip-details.pdf`);
      } catch (error) {
        console.error("Error generating PDF", error);
      }

      for (var i = 0; i < hide.length; i++) {
        hide[i].style.visibility = "visible";
      }
      setIsGeneratingPdf(false); // After generating the PDF
    }, 0);
  };

  //   const generatePDF = async () => {
  //     const pageUrl = window.location.href;

  //     try {
  //         const response = await fetch('http://localhost:5000/generate-pdf', {
  //             method: 'POST',
  //             headers: {
  //               'Content-Type': 'application/json',
  //             },
  //             body: JSON.stringify({ url: pageUrl })
  //         });

  //         // Check if the HTTP response is successful
  //         if (!response.ok) {
  //             throw new Error(`HTTP error! status: ${response.status}`);
  //         }

  //         const data = await response.json();

  //         if (data.pdfUrl) {
  //             // Trigger download
  //             window.open(data.pdfUrl, '_blank');
  //         } else {
  //             console.error("PDF URL not received in the response");
  //         }
  //     } catch (error) {
  //         console.error("Error downloading PDF on frontend: ", error);
  //     }
  // };

  const [hotelPrice, setHotelPrice] = useState(0);
  const [toggletnc, setToggletnc] = useState(false);
  const [totalActivityBreakfast, setTotalActivityBreakfast] = useState(0);
  const [totalActivityLunch, setTotalActivityLunch] = useState(0);
  const [totalActivityDinner, setTotalActivityDinner] = useState(0);

  const [selectedCambodiaHotels, setSelectedCambodiaHotels] = useState([]);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    });
  };
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [travellerDetails, setTravellerDetails] = useState({});
  const [itenary, setItenary] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState([]);
  const [selectedFlightData, setSelectedFlightData] = useState([]);
  const [daywiseTransfers, setDaywiseTransfers] = useState([]);
  const [internationalFlights, setInternationalFlights] = useState([]);
  const [activityPrice, setActivityPrice] = useState(0);
  const [addonPrice, setAddonPrice] = useState(0);
  const [internationalFlightsPrice, setInternationalFlightsPrice] = useState(0);
  const [selectedFlightPrice, setSelectedFlightPrice] = useState(0);
  const [transferPrice, setTransferPrice] = useState(0);
  const [nights, setNights] = useState(0);
  const [country, setCountry] = useState("");
  const [adults, setAdults] = useState(0);
  const [child, setChild] = useState(0);
  const [infants, setInfants] = useState(0);
  const [visa, setVisa] = useState("");
  const [peakSeasonMarkup, setPeakSeasonMarkup] = useState(0);
  const [validTillDate, setValidTillDate] = useState("");
  const [EarlyCheckIn, setEarlyCheckIn] = useState(false);
  const [LateCheckOut, setLateCheckOut] = useState(false);
  const [TATAAIGInsurance, setTATAAIGInsurance] = useState(false);
  const [Cancellation, setCancellation] = useState(false);
  const [addonBreakfast, setAddonBreakfast] = useState(0);
  const [addonLunch, setAddonLunch] = useState(0);
  const [addonDinner, setAddonDinner] = useState(0);
  const [selectedAddons, setSelectedAddons] = useState({});
  const [duration, setDuration] = useState(0);

  const [perPersonPrice, setPerPersonPrice] = useState(false);
  const [selectedBuses, setSelectedBuses] = useState([]);
  const [selectedTrains, setSelectedTrains] = useState([]);
  const [selectedBusesPrice, setSelectedBusesPrice] = useState(0);
  const [selectedTrainsPrice, setSelectedTrainsPrice] = useState(0);

  const [selectedBaliICTransfers, setSelectedBaliICTransfers] = useState([]);
  const [selectedBaliICTransfersPrice, setSelectedBaliICTransfersPrice] =
    useState(0);
  const [deleteCounter, setDeleteCounter] = useState(0);
  const [internationalFlightOffer, setInternationalFlightOffer] = useState([]);
  const [internationalFlightsTokens, setInternationalFlightsTokens] = useState(
    []
  );
  const [domesticFlightsTokens, setDomesticFlightsTokens] = useState([]);
  const [BookingInternationalFlightsData, setBookingInternationalFlightsData] =
    useState([]);
  const [BookingDomesticFlightsData, setBookingDomesticFlightsData] = useState(
    []
  );
  const [selectedCambodiaPackage, setSelectedCambodiaPackage] = useState([]);
  const [selectedCambodiaPackagePrice, setSelectedCambodiaPackagePrice] =
    useState(0);
  const [whenCambodia, setWhenCambodia] = useState("");
  const [CambodiaAccomodationType, setCambodiaAccomodationType] = useState("");
  const [packageDetails, setPackageDetails] = useState({});
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          id.startsWith("TRAV")
            ? `https://travaurabackend.azurewebsites.net/api/user/${id}`
            : `https://travaurabackend.azurewebsites.net/api/package/${id}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setUserData(data);
        console.log("userData: ", data);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        // Handle the error appropriately
      }
    };
    fetchUserData();

    console.log("userData: ", userData);
  }, [deleteCounter]);

  const handleDeleteBookingInternationalFlight = (token) => {
    fetch(
      `https://travaurabackend.azurewebsites.net/deleteBookingInternationalFlight/${id}/${token}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // console.log("data: ", data);
        setDeleteCounter((prevCount) => prevCount + 1);
      })
      .catch((error) => {
        console.log("Error deleting international flight: ", error);
      });
  };

  const handleDeleteBookingDomesticFlight = (token) => {
    fetch(
      `https://travaurabackend.azurewebsites.net/deleteBookingDomesticFlight/${id}/${token}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // console.log("data: ", data);
        setDeleteCounter((prevCount) => prevCount + 1);
      })
      .catch((error) => {
        console.log("Error deleting international flight: ", error);
      });
  };

  // app.delete('/deleteInternationalFlight/:id/:ind', async (req, res) => {
  //   const { id, ind } = req.params; // 'ind' is the index of the element to be removed

  //   try {
  //       // First, set the element at the specified index to undefined
  //       let updateQuery = {};
  //       updateQuery[`selectedInternationalFlights.${ind}`] = undefined;
  //       await user.findByIdAndUpdate(id, {
  //           $unset: updateQuery
  //       });

  //       // Then, remove any undefined elements from the array
  //       const updatedDocument = await user.findByIdAndUpdate(id, {
  //           $pull: { selectedInternationalFlights: null }
  //       }, { new: true });

  //       res.json(updatedDocument);
  //   } catch (error) {
  //       res.status(500).send(error);
  //   }
  // });

  useEffect(() => {
    if (userData.packageDetails) {
      setPackageDetails(userData.packageDetails);
      console.log("packageDetails: ", packageDetails);
    }
    if (userData.travellerDetails) {
      setTravellerDetails(
        userData.travellerDetails ? userData.travellerDetails : {}
      );
      setAdults(
        userData.travellerDetails.adults ? userData.travellerDetails.adults : 0
      );
      setChild(
        userData.travellerDetails.child ? userData.travellerDetails.child : 0
      );
      setInfants(
        userData.travellerDetails.infants
          ? userData.travellerDetails.infants
          : 0
      );
      setDuration(
        userData.travellerDetails.duration
          ? userData.travellerDetails.duration
          : 0
      );
      setNights(
        userData.travellerDetails.nights ? userData.travellerDetails.nights : 0
      );
    }
    if(userData.packageDetails){
      if(userData.packageDetails.packageDetails.days){
        setDuration(userData.packageDetails.packageDetails.days);
      }
      if(userData.packageDetails.packageDetails.nights){
        setNights(userData.packageDetails.packageDetails.nights);
      }
      if(userData.packageDetails.packageDetails.country){
        setCountry(userData.packageDetails.packageDetails.country);
      }
    }

    if (userData.selectedActivities) {
      setItenary(userData.selectedActivities);
      console.log("itenaryAtLink: ", itenary);
      //   handleActivityPrice();
    }
    if (userData.country) {
      setCountry(userData.country);
    }
    if (userData.selectedHotels) {
      setSelectedHotel(userData.selectedHotels);
    }
    if (userData.selectedDomesticFlights) {
      setSelectedFlightData(userData.selectedDomesticFlights);
      //   handleDomesticFlightPrice();
    }
    if (userData.selectedTransfers) {
      setDaywiseTransfers(userData.selectedTransfers);
      //   handleTransferPrice();
    }
    if (userData.selectedInternationalFlights) {
      setInternationalFlights(userData.selectedInternationalFlights);
      //   handleInternationalFlightPrice();
    }
    if (userData.selectedInternationalFlightOffers) {
      setInternationalFlightOffer(userData.selectedInternationalFlightOffers);
    }
    if (userData.selectedAddons) {
      setSelectedAddons(userData.selectedAddons);
      setEarlyCheckIn(userData.selectedAddons[0].earlyCheckIn);
      setLateCheckOut(userData.selectedAddons[0].lateCheckOut);
      setAddonBreakfast(
        userData.selectedAddons[0].addonBreakfast
          ? userData.selectedAddons[0].addonBreakfast
          : 0
      );
      setAddonLunch(
        userData.selectedAddons[0].addonLunch
          ? userData.selectedAddons[0].addonLunch
          : 0
      );
      setAddonDinner(
        userData.selectedAddons[0].addonDinner
          ? userData.selectedAddons[0].addonDinner
          : 0
      );
      setTATAAIGInsurance(userData.selectedAddons[0].TATAAIGInsurance);
      setCancellation(userData.selectedAddons[0].Cancellation);
      setVisa(userData.selectedAddons[0].visa);
      setPeakSeasonMarkup(userData.selectedAddons[0].peakSeasonMarkup);
      setValidTillDate(userData.selectedAddons[0].validTillDate);
      //   handleAddonPrice();
    }
    if (userData.selectedBuses) {
      setSelectedBuses(userData.selectedBuses);
    }
    if (userData.selectedTrains) {
      setSelectedTrains(userData.selectedTrains);
    }
    if (userData.selectedBaliICTransfers) {
      setSelectedBaliICTransfers(userData.selectedBaliICTransfers);
    }
    if (userData.BookingSelectedDomesticFlights) {
      setDomesticFlightsTokens(userData.BookingSelectedDomesticFlights);
    }
    if (userData.BookingSelectedInternationalFlights) {
      setInternationalFlightsTokens(
        userData.BookingSelectedInternationalFlights
      );
    }
    if (userData.selectedCambodiaPackage) {
      setSelectedCambodiaPackage(userData.selectedCambodiaPackage);
    }
    if (userData.CambodiaWhen) {
      setWhenCambodia(userData.CambodiaWhen);
    }
    if (userData.selectedCambodiaHotels) {
      setSelectedCambodiaHotels(userData.selectedCambodiaHotels);
    }
    if (userData.CambodiaAccomodationType) {
      setCambodiaAccomodationType(userData.CambodiaAccomodationType);
    }
    // if(userData.selectedFlights){
    //   setSelectedFlights(userData.selectedFlights);
    // }
  }, [
    userData,
    itenary,
    selectedHotel,
    internationalFlights,
    selectedCambodiaHotels,
  ]);

  const rapidapi_key = process.env.REACT_APP_RAPID_API_KEY;
  const rapidapi_host = process.env.REACT_APP_RAPID_API_HOST;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const requests = domesticFlightsTokens.map((flightObj) => {
        return axios.get(
          "https://booking-com15.p.rapidapi.com/api/v1/flights/getFlightDetails",
          {
            params: { token: flightObj.token, currency_code: "INR" },
            headers: {
              "X-RapidAPI-Key": rapidapi_key,
              "X-RapidAPI-Host": rapidapi_host,
            },
          }
        );
      });

      try {
        const responses = await Promise.all(requests);
        const dataWithBaggage = responses.map((response, index) => {
          const flightData = response.data;
          const numberOfBaggages =
            domesticFlightsTokens[index].numberOfBaggages;
          return { ...flightData, numberOfBaggages };
        });

        setBookingDomesticFlightsData(dataWithBaggage);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching flight details:", error);
      }
    };

    if (domesticFlightsTokens.length > 0) {
      fetchData();
    }
  }, [domesticFlightsTokens]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const requests = internationalFlightsTokens.map((flightObj) => {
        return axios.get(
          "https://booking-com15.p.rapidapi.com/api/v1/flights/getFlightDetails",
          {
            params: { token: flightObj.token, currency_code: "INR" },
            headers: {
              "X-RapidAPI-Key": rapidapi_key,
              "X-RapidAPI-Host": rapidapi_host,
            },
          }
        );
      });

      try {
        const responses = await Promise.all(requests);
        const dataWithBaggage = responses.map((response, index) => {
          const flightData = response.data;
          const numberOfBaggages =
            internationalFlightsTokens[index].numberOfBaggages;
          return { ...flightData, numberOfBaggages };
        });

        if (dataWithBaggage) {
          setBookingInternationalFlightsData(dataWithBaggage);
          setLoading(false);
        }
        // setBookingInternationalFlightsData(dataWithBaggage);
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching flight details:", error);
      }
    };

    if (internationalFlightsTokens.length > 0) {
      fetchData();
    }
  }, [internationalFlightsTokens]);

  // useEffect(() => {
  //   console.log("selectedAddons: ", selectedAddons);
  //   console.log("BookingSelectedDomesticFlights: ", BookingDomesticFlightsData);
  //   console.log(
  //     "BookingSelectedInternationalFlights: ",
  //     BookingInternationalFlightsData
  //   );
  // }, [
  //   selectedAddons,
  //   BookingDomesticFlightsData,
  //   BookingInternationalFlightsData,
  // ]);
  useEffect(() => {
    let tempactivityprice = 0;
    itenary.map((item, ind) => {
      tempactivityprice +=
        item.Activities.PriceinRupees * (parseInt(adults) + parseInt(child));
    });
    setActivityPrice(tempactivityprice);

    // console.log("activityPrice: ", activityPrice);
  }, [itenary]);

  useEffect(() => {
    let tempBusPrice = 0;
    selectedBuses.map((item, ind) => {
      tempBusPrice += item.price * (parseInt(adults) + parseInt(child));
    });

    setSelectedBusesPrice(tempBusPrice);
    // console.log("selectedBusesPrice: ", selectedBusesPrice);
  }, [selectedBuses]);

  useEffect(() => {
    let tempTrainPrice = 0;
    selectedTrains.map((item, ind) => {
      tempTrainPrice += item.price * (parseInt(adults) + parseInt(child));
    });

    setSelectedTrainsPrice(tempTrainPrice);
    // console.log("selectedTrainsPrice: ", selectedTrainsPrice);
  }, [selectedTrains]);

  useEffect(() => {
    let tempBaliICTransfersPrice = 0;
    selectedBaliICTransfers.map((item, ind) => {
      tempBaliICTransfersPrice += item.priceInr * item.numberOfTransfers;
    });

    setSelectedBaliICTransfersPrice(tempBaliICTransfersPrice);
    // console.log("selectedBaliICTransfersPrice: ", selectedBaliICTransfersPrice);
  }, [selectedBaliICTransfers]);

  useEffect(() => {
    let tempBreakfast = 0;
    let tempLunch = 0;
    let tempDinner = 0;
    if (itenary.length === 0) {
      setTotalActivityBreakfast(0);
      setTotalActivityLunch(0);
      setTotalActivityDinner(0);

      return;
    } else {
      itenary.forEach((item) => {
        if (item.Activities.Breakfast === "Yes") {
          tempBreakfast += 1;
        }
        if (item.Activities.Lunch === "Yes") {
          tempLunch += 1;
        }
        if (item.Activities.Dinner === "Yes") {
          tempDinner += 1;
        }
      });
      setTotalActivityBreakfast(tempBreakfast);
      setTotalActivityLunch(tempLunch);
      setTotalActivityDinner(tempDinner);
    }
  }, [itenary]);

  useEffect(() => {
    let breakfastprice = 0;
    let lunchprice = 0;
    let dinnerprice = 0;
    let earlycheckinprice = 0;
    let latecheckoutprice = 0;
    let insuranceprice = 0;
    let cancellationprice = 0;
    let visaprice = 0;

    if (addonBreakfast) {
      breakfastprice =
        addonBreakfast * 700 * (parseInt(adults) + parseInt(child));
    }
    if (addonLunch) {
      lunchprice = addonLunch * 700 * (parseInt(adults) + parseInt(child));
    }
    if (addonDinner) {
      dinnerprice = addonDinner * 700 * (parseInt(adults) + parseInt(child));
    }
    if (EarlyCheckIn) {
      earlycheckinprice = 1600;
    }
    if (LateCheckOut) {
      latecheckoutprice = 1600;
    }
    if (TATAAIGInsurance) {
      insuranceprice =
        (parseInt(adults) + parseInt(child) + parseInt(infants)) * 800;
      // console.log(insuranceprice);
    }
    if (Cancellation) {
      cancellationprice = 1600;
    }
    if (visa && visa === "Included") {
      visaprice =
        (parseInt(adults) + parseInt(child) + parseInt(infants)) * 2800;
      // console.log(parseInt(adults) + parseInt(child) + parseInt(infants));
      // console.log("Visa price: ", visaprice);
    }

    setAddonPrice(
      visaprice +
        breakfastprice +
        lunchprice +
        dinnerprice +
        earlycheckinprice +
        latecheckoutprice +
        insuranceprice +
        cancellationprice
    );
    // console.log("addonPrice: ", addonPrice);
  }, [
    visa,
    addonBreakfast,
    addonLunch,
    addonDinner,
    EarlyCheckIn,
    LateCheckOut,
    TATAAIGInsurance,
    Cancellation,
  ]);

  useEffect(() => {
    let tempCambodiaPackagePrice = 0;

    if (selectedCambodiaPackage && selectedCambodiaPackage.length > 0) {
      const hotelInfo =
        selectedCambodiaPackage[0].Hotels?.[CambodiaAccomodationType]?.[0];
      if (hotelInfo && hotelInfo.PriceINR) {
        const adultPrice = parseInt(adults) * hotelInfo.PriceINR;
        const childPrice = 0.75 * hotelInfo.PriceINR * parseInt(child);
        tempCambodiaPackagePrice = adultPrice + childPrice;
      }
    }

    setSelectedCambodiaPackagePrice(tempCambodiaPackagePrice);
    // console.log("selectedCambodiaPackagePrice: ", tempCambodiaPackagePrice);
  }, [selectedCambodiaPackage, CambodiaAccomodationType, adults, child]);

  useEffect(() => {
    let tempDomesticFlightPrice = 0;
    {
      selectedFlightData &&
        selectedFlightData.map((item, ind) => {
          tempDomesticFlightPrice +=
            item.flightPrice * (parseInt(adults) + parseInt(child));
        });
    }
    {
      BookingDomesticFlightsData &&
        BookingDomesticFlightsData.map((item, ind) => {
          const totalRounded = item.data?.priceBreakdown?.totalRounded?.units;
          if (totalRounded !== undefined) {
            tempDomesticFlightPrice +=
              item.data.priceBreakdown.totalRounded.units +
              (3 / 100) * item.data.priceBreakdown.totalRounded.units +
              item.numberOfBaggages *
                (item.data.extraProducts.filter(
                  (item) => item.type === "checkedInBaggage"
                ).length > 0
                  ? item.data.extraProducts.filter(
                      (item) => item.type === "checkedInBaggage"
                    )[0].priceBreakdown.baseFare.units
                  : 0) +
              (5 / 100) *
                (item.numberOfBaggages *
                  (item.data.extraProducts.filter(
                    (item) => item.type === "checkedInBaggage"
                  ).length > 0
                    ? item.data.extraProducts.filter(
                        (item) => item.type === "checkedInBaggage"
                      )[0].priceBreakdown.baseFare.units
                    : 0));
          }
        });
    }

    setSelectedFlightPrice(tempDomesticFlightPrice);
    // console.log("selectedFlightPrice: ", selectedFlightPrice);
  }, [BookingDomesticFlightsData, selectedFlightData, domesticFlightsTokens]);

  useEffect(() => {
    let tempInternationalFlightPrice = 0;
    {
      internationalFlights &&
        internationalFlights.map((item, ind) => {
          tempInternationalFlightPrice +=
            item.price * (parseInt(adults) + parseInt(child));
        });
    }
    {
      BookingInternationalFlightsData &&
        BookingInternationalFlightsData.map((item, ind) => {
          const totalRounded = item.data?.priceBreakdown?.totalRounded?.units;
          if (totalRounded !== undefined) {
            tempInternationalFlightPrice +=
              item.data.priceBreakdown.totalRounded.units +
              (3 / 100) * item.data.priceBreakdown.totalRounded.units +
              item.numberOfBaggages *
                (item.data.extraProducts.filter(
                  (item) => item.type === "checkedInBaggage"
                ).length > 0
                  ? item.data.extraProducts.filter(
                      (item) => item.type === "checkedInBaggage"
                    )[0].priceBreakdown.baseFare.units
                  : 0) +
              (5 / 100) *
                (item.numberOfBaggages *
                  (item.data.extraProducts.filter(
                    (item) => item.type === "checkedInBaggage"
                  ).length > 0
                    ? item.data.extraProducts.filter(
                        (item) => item.type === "checkedInBaggage"
                      )[0].priceBreakdown.baseFare.units
                    : 0));
          }
        });
    }
    setInternationalFlightsPrice(tempInternationalFlightPrice);
    // console.log("internationalFlightsPrice: ", internationalFlightsPrice);
  }, [
    BookingInternationalFlightsData,
    internationalFlights,
    internationalFlightsTokens,
  ]);

  //   const handleInternationalFlightPrice = () => {
  //     let tempflightprice = 0;
  //     internationalFlights.map((item, ind) => {
  //       tempflightprice += item.price * (parseInt(adults) + parseInt(child));
  //     });
  //     setInternationalFlightsPrice(tempflightprice);
  //     console.log("internationalFlightsPrice: ", internationalFlightsPrice);
  //   };

  //   const handleTransferPrice = () => {
  //     let tempTransferPrice = 0;
  //     daywiseTransfers.map((item, ind) => {
  //       tempTransferPrice += item.transfer.price * item.noOfTransfer;
  //     });

  //     setTransferPrice(tempTransferPrice);
  //     console.log("transferPrice: ", transferPrice);
  //   };
  useEffect(() => {
    let tempTransferPrice = 0;
    daywiseTransfers.map((item, ind) => {
      tempTransferPrice += item.transfer.price * item.noOfTransfer;
    });
    setTransferPrice(tempTransferPrice);
    // console.log("transferPrice: ", transferPrice);
  }, [daywiseTransfers]);

  useEffect(() => {
    let tempBreakfast = 0;
    let tempLunch = 0;
    let tempDinner = 0;
    if (itenary.length === 0) {
      setTotalActivityBreakfast(0);
      setTotalActivityLunch(0);
      setTotalActivityDinner(0);
      return;
    } else {
      itenary.forEach((item) => {
        if (item.Activities.Breakfast === "Yes") {
          tempBreakfast += 1;
        }
        if (item.Activities.Lunch === "Yes") {
          tempLunch += 1;
        }
        if (item.Activities.Dinner === "Yes") {
          tempDinner += 1;
        }
      });
      setTotalActivityBreakfast(tempBreakfast);
      setTotalActivityLunch(tempLunch);
      setTotalActivityDinner(tempDinner);
    }
  }, []);

  useEffect(() => {
    console.log("activityPrice: ", activityPrice);
    console.log("hotelPrice: ", hotelPrice);
    console.log("addonPrice: ", addonPrice);
    console.log("transferPrice: ", transferPrice);
    console.log("selectedBusesPrice: ", selectedBusesPrice);
    console.log("selectedTrainsPrice: ", selectedTrainsPrice);
    console.log("internationalFlightsPrice: ", internationalFlightsPrice);
    console.log("selectedFlightPrice: ", selectedFlightPrice);
    console.log("peakSeasonMarkup: ", peakSeasonMarkup);
    console.log("selectedCambodiaPackage: ", selectedCambodiaPackage);
  }, [
    activityPrice,
    hotelPrice,
    addonPrice,
    transferPrice,
    selectedBusesPrice,
    selectedTrainsPrice,
    internationalFlightsPrice,
    selectedFlightPrice,
    peakSeasonMarkup,
    selectedCambodiaPackage,
  ]);

  const landPackage =
    parseInt(activityPrice) +
    parseInt(hotelPrice) +
    parseInt(addonPrice) +
    parseInt(transferPrice) +
    parseInt(selectedBusesPrice) +
    parseInt(selectedTrainsPrice) +
    parseInt(selectedBaliICTransfersPrice) +
    parseInt(selectedCambodiaPackagePrice);

  const landPackage18 =
    landPackage +
    (landPackage * 23) / 100 +
    (landPackage * parseInt(peakSeasonMarkup)) / 100;
  const oto = (landPackage * 5) / 100;
  const ldp =
    landPackage +
    (9 / 100) * landPackage +
    (landPackage * parseInt(peakSeasonMarkup)) / 100;
  const ldpgst = (5 / 100) * ldp;
  const ldpcostAfterGST = ldp + ldpgst;
  const ldptcs = (5 / 100) * ldpcostAfterGST;
  const ldptotalCost =
    ldpcostAfterGST +
    ldptcs +
    internationalFlightsPrice +
    selectedFlightPrice -
    oto;
  const gst = (5 / 100) * landPackage18;
  const costAfterGST = landPackage18 + gst;
  const tcs = (5 / 100) * costAfterGST;
  const totalCost =
    costAfterGST + tcs + internationalFlightsPrice + selectedFlightPrice;

  const ppp =
    totalCost / (parseInt(adults) + parseInt(child) + parseInt(infants));

  const groupedByDay = itenary.reduce((acc, item) => {
    const day = item.day;

    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);

    return acc;
  }, {});

  // useEffect(() => {
  //   console.log("groupedByDay: ", groupedByDay);
  // }, [itenary]);

  const groupHotelByName = selectedHotel.reduce((acc, item) => {
    const hotelName = item.selectedHotel.Name;

    if (!acc[hotelName]) {
      acc[hotelName] = [];
    }

    // Add an object containing the day and other relevant details
    acc[hotelName].push({
      day: item.day,
      region: item.region,
      city: item.city,
      numberOfRooms: item.numberOfRooms,
      numberofSupplements: item.numberofSupplements,
      // Add other relevant details you want to include
    });

    return acc;
  }, {});

  // useEffect(() => {
  //   console.log("groupHotelByName: ", groupHotelByName);
  //   console.log("hotel keys: ", Object.keys(groupHotelByName));
  // }, [selectedHotel]);

  const groupFlightByDay = selectedFlightData.reduce((acc, item) => {
    const day = item.day;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {});
  // useEffect(() => {
  //   console.log("groupFlightByDay: ", groupFlightByDay);
  // }, [selectedFlightData]);

  const TravellersData = [
    {
      image:
        "https://s3.eu-west-1.amazonaws.com/media.mcsuk.org/images/RS34129_jelly2_scotland-lpr.width-765.jpg",
      username: "ayushj91",
    },
    {
      image:
        "https://s3.eu-west-1.amazonaws.com/media.mcsuk.org/images/RS34129_jelly2_scotland-lpr.width-765.jpg",
      username: "ketan.saluja",
    },
    {
      image:
        "https://s3.eu-west-1.amazonaws.com/media.mcsuk.org/images/RS34129_jelly2_scotland-lpr.width-765.jpg",
      username: "muskaan_19",
    },
    {
      image:
        "https://s3.eu-west-1.amazonaws.com/media.mcsuk.org/images/RS34129_jelly2_scotland-lpr.width-765.jpg",
      username: "samya.k8",
    },
    {
      image:
        "https://s3.eu-west-1.amazonaws.com/media.mcsuk.org/images/RS34129_jelly2_scotland-lpr.width-765.jpg",
      username: "username",
    },

    // ... add other marine life data
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let tempPrice = 0;

    if (selectedHotel.length === 0) {
      setHotelPrice(0);
      return;
    }

    selectedHotel.forEach((item) => {
      const numberOfRooms = parseInt(item.numberOfRooms, 10);
      const roomPrice = parseInt(item.selectedHotel.CPRoomPrice, 10);
      const numberofSupplements = parseInt(item.numberofSupplements, 10);

      // Debugging
      // console.log(
      //   "numberOfRooms:",
      //   numberOfRooms,
      //   "roomPrice:",
      //   roomPrice,
      //   "numberofSupplements:",
      //   numberofSupplements
      // );

      // Check if parsed values are valid numbers
      if (
        !isNaN(numberOfRooms) &&
        !isNaN(roomPrice) &&
        !isNaN(numberofSupplements)
      ) {
        tempPrice +=
          numberOfRooms * roomPrice +
          (numberofSupplements * roomPrice * 45) / 100;
      } else {
        console.log("Invalid data for item:", item);
      }
    });

    setHotelPrice(tempPrice);
  }, [selectedHotel]);

  const cambodiaPackageDays =
    selectedCambodiaPackage && selectedCambodiaPackage.length > 0
      ? selectedCambodiaPackage[0].Activities.length
      : 0;

  // Calculate the starting and ending day based on the CambodiaWhen condition
  const startDay =
    whenCambodia === "Before Vietnam" ? cambodiaPackageDays + 1 : 1;
  const endDay =
    whenCambodia === "After Vietnam"
      ? duration - cambodiaPackageDays
      : duration;

  // Create an array of day numbers to be rendered
  const daysToRender = Array.from(
    { length: endDay - startDay + 1 },
    (_, i) => startDay + i
  );

  // useEffect(() => {
  //   console.log("daysToRender: ", daysToRender);
  //   console.log("camodiaPackageDays: ", cambodiaPackageDays);
  //   console.log("startDay: ", startDay);
  //   console.log("endDay: ", endDay);
  //   console.log("whenCambodia: ", whenCambodia);
  // }
  // , [daysToRender, cambodiaPackageDays, startDay, endDay, whenCambodia]);

  const [isEditMode, setIsEditMode] = useState(false);

  // State to track form changes
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues(travellerDetails);
  }, [travellerDetails]);

  useEffect(() => {
    console.log("formValues: ", formValues);
    console.log("travellerDetails: ", travellerDetails);
  }, [formValues, travellerDetails]);

  

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Handle saving the details
  const handleSave = async () => {
    if (formValues === travellerDetails) {
      setIsEditMode(false);
      return;
    }
    window.confirm("Are you sure you want to update the Traveller details?");

    setIsEditMode(false);
    await updateTravellerDetails(formValues); // Function to call the API to update the details
    
  };



  useEffect(() => {
    // Only proceed if adults or child values have changed meaningfully
    
      // Calculate the new values for rooms and supplements based on adults and child
      const totalPeople = parseInt(formValues.adults, 10) + parseInt(formValues.child, 10);
      const calculatedRooms = Math.floor(totalPeople / 2);
      const calculatedSupplements = totalPeople % 2;

      // Update selectedHotel with the new values
      const updatedSelectedHotels = selectedHotel.map(hotel => ({
        ...hotel,
        numberOfRooms: calculatedRooms,
        numberofSupplements: calculatedSupplements,
      }));

      setSelectedHotel(updatedSelectedHotels);

      // Update the refs with the new values
      
    
  }, [formValues.adults, formValues.child]);

  useEffect(() => {
    console.log("selectedHotelweikjfs: ", selectedHotel);
    
    console.log("formValues.adults: ", formValues.adults);
  }, [selectedHotel]);
      

  // Function to call the API to update the details
  const updateSelectedHotels = async (selectedHotel) => {
    try {
      const response = await fetch(
        `https://travaurabackend.azurewebsites.net/updateSelectedHotels/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ selectedHotels: selectedHotel }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("data: ", data);
      setDeleteCounter((prevCount) => prevCount + 1);
    } catch (error) {
      console.error("Failed to update selected hotels:", error);
      // Handle the error appropriately
    }
  };

  const updateTravellerDetails = async (formValues) => {
    try {
      const response = await fetch(
        `https://travaurabackend.azurewebsites.net/updateTravellerDetails/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ travellerDetails: formValues }),
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("data: ", data);
      setDeleteCounter((prevCount) => prevCount + 1);
    } catch (error) {
      console.error("Failed to update traveller details:", error);
      // Handle the error appropriately
    }
  };
  return (
    <div id="contentToPrint">
      <body class="bg-[#121212] text-white">
        <header class=" py-6 bg-gradient-to-br from-[#424242] to-transparent  mb-5  ">
          <div className=" flex justify-between md:mx-36 mx-3 px-3 md:px-0">
            <div className="   ">
              {/* <LogoAnimation /> */}
              <motion.img
                src={image}
                alt=""
                className="h-16 w-24 "
                initial={{ scale: 1, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  stiffness: 260,
                  damping: 10,
                  duration: 1,
                }}
              />
            </div>
            <motion.header
              className=" text-sm md:text-xl mt-5   font-medium text-white font-montserrat "
              initial={{ scale: 1, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{
                stiffness: 260,

                damping: 10,
                duration: 2,
              }}
            >
              {" "}
              Experience Enhanced
            </motion.header>
          </div>
        </header>

        {/* <img src={group} alt="" className="h-auto w-full relative top-0 z-10 object-cover "/> */}
        <div className="min-w-screen ">
          {/* <HorizontalSectionIndicator numberOfSections={7} currentSection={3}/> */}
        </div>
        <div class="container mx-auto px-4">
          <main>
            <div className="flex justify-between">
              <h1 class="text-2xl md:text-5xl font-bold mb-6 md:ml-6">
                {/* {travellerDetails.name
                  ? travellerDetails.name.split(" ")[0]
                  : ""}
                's trip to {country} */}
                {travellerDetails.name ? (
                  <LabelAnimation
                    text={
                      travellerDetails.name.split(" ")[0] +
                      "'s trip to " +
                      country
                    }
                  />
                ) : (
                  ""
                )}
              </h1>

              <div className="flex justify-center items-center my-4">
                <button
                  onClick={generatePDF}
                  // id="generatePdfButton"

                  className="bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121]  px-4 py-2 rounded-lg print-proof"
                >
                  Download PDF
                </button>
              </div>
            </div>
            <div class="xl:flex xl:justify-between">
              <div class="xl:w-3/4 w-full  space-y-6 ">
                {id.startsWith("TRAV") && (
                <div class="bg-[#212121] p-4 md:p-6 rounded-lg md:mx-6">
                  <div class="flex justify-between items-center">
                    <h2 class="text-xl font-semibold mb-4">Trip ID: {id}</h2>
                    <div>
                      {isEditMode ? (
                        <div className="flex justify-center ">
                          <BsFillFloppyFill
                            onClick={handleSave}
                            className="text-3xl cursor-pointer text-green-400 print-proof"
                            name="save Traveller details"
                          />
                        </div>
                      ) : (
                        <div className="flex justify-center items-center my-4">
                          <MdEdit
                            onClick={() => setIsEditMode(true)}
                            className="text-3xl cursor-pointer text-green-400 print-proof"
                            name="Edit Traveller details"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div class="flex flex-col md:flex-row gap-4 justify-between">
                    <div className="grid grid-cols-2 gap-1 md:gap-2 w-full ">
                      {/* <div className="text-sm md:text-md font-semibold">
                        Name:
                      </div>
                      <div className="text-sm md:text-md">
                        {travellerDetails.name}
                      </div> */}
                      {isEditMode ? (
                        <>
                          <label
                            htmlFor="name"
                            className="text-sm md:text-md font-semibold"
                          >
                            Name:
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={formValues.name}
                            onChange={handleChange}
                            className="text-sm md:text-md bg-gray-600 rounded-lg"
                          />
                        </>
                      ) : (
                        <>
                          <div className="text-sm md:text-md font-semibold">
                            Name:
                          </div>
                          <div className="text-sm md:text-md">
                            {travellerDetails.name}
                          </div>
                        </>
                      )}

                      {/* <div className="text-sm md:text-md font-semibold">
                        Destination:
                      </div>
                      <div className="text-sm md:text-md">{country}</div> */}

                      <div className="text-sm md:text-md font-semibold">
                        Destination:
                      </div>
                      <div className="text-sm md:text-md">{country}</div>

                      {/* <div className="font-semibold text-sm md:text-md">
                        Trip Type:
                      </div>
                      <div className="text-sm md:text-md">
                        {travellerDetails.tripType}
                      </div>

                      <div className="font-semibold text-sm md:text-md">
                        Duration:
                      </div>
                      <div className="text-sm md:text-md">
                        {travellerDetails.duration}D/
                        {nights !== 0 ? nights : travellerDetails.duration - 1}N
                      </div>

                      <div className="font-semibold text-sm md:text-md">
                        Accommodation Type:
                      </div>
                      <div className="text-sm md:text-md">
                        {travellerDetails.accommodationType}
                      </div>
                    </div> */}
                      {isEditMode ? (
                        <>
                          <label
                            htmlFor="tripType"
                            className="text-sm md:text-md font-semibold"
                          >
                            Trip Type:
                          </label>
                          <input
                            type="text"
                            name="tripType"
                            id="tripType"
                            value={formValues.tripType}
                            onChange={handleChange}
                            className="text-sm md:text-md bg-gray-600 rounded-lg"
                          />
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-sm md:text-md">
                            Trip Type:
                          </div>
                          <div className="text-sm md:text-md">
                            {travellerDetails.tripType}
                          </div>
                        </>
                      )}

                      {isEditMode ? (
                        <>
                          <label
                            htmlFor="duration"
                            className="font-semibold text-sm md:text-md"
                          >
                            Duration:
                          </label>
                          <input
                            type="text"
                            name="duration"
                            id="duration"
                            value={formValues.duration}
                            onChange={handleChange}
                            className="text-sm md:text-md bg-gray-600 rounded-lg"
                          />
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-sm md:text-md">
                            Duration:
                          </div>
                          <div className="text-sm md:text-md">
                            {travellerDetails.duration}D/
                            {nights !== 0
                              ? nights
                              : travellerDetails.duration - 1}
                            N
                          </div>
                        </>
                      )}

                      {isEditMode ? (
                        <>
                          <label
                            htmlFor="accommodationType"
                            className="font-semibold text-sm md:text-md"
                          >
                            Accommodation Type:
                          </label>
                          <input
                            type="text"
                            name="accommodationType"
                            id="accommodationType"
                            value={formValues.accommodationType}
                            onChange={handleChange}
                            className="text-sm md:text-md bg-gray-600 rounded-lg"
                          />
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-sm md:text-md">
                            Accommodation Type:
                          </div>
                          <div className="text-sm md:text-md">
                            {travellerDetails.accommodationType}
                          </div>
                        </>
                      )}
                    </div>

                    <div className=" hidden md:block md:border-gray-500 md:border-t-0 md:border-l-2 md:my-0 mx-0"></div>

                    {/* <div className="grid grid-cols-2 gap-1 md:gap-2 w-full">
                      <div className="font-semibold text-sm md:text-md">
                        Date of Travel:
                      </div>
                      <div className="text-sm md:text-md">
                        {travellerDetails.dateOfTravel
                          ? formatDate(travellerDetails.dateOfTravel)
                          : ""}
                      </div>

                      <div className="font-semibold text-sm md:text-md">
                        Traveller Count:
                      </div>
                      <div className="text-[11px]">
                        {adults} Adult(s), {child} Kid(s), {infants} infant(s)
                      </div>

                      <div className="font-semibold text-sm md:text-md">
                        International Flights:
                      </div>
                      <div className="text-sm md:text-md">
                        {travellerDetails.internationalFlights}
                      </div>

                      <div className="font-semibold text-sm md:text-md">
                        Domestic Flights:
                      </div>
                      <div className="text-sm md:text-md">
                        {travellerDetails.domesticFlights}
                      </div>

                      <div className="font-semibold text-sm md:text-md">
                        Visa:
                      </div>
                      <div className="text-sm md:text-md">
                        {travellerDetails.visa}
                      </div>
                    </div> */}
                    <div className="grid grid-cols-2 gap-1 md:gap-2 w-full">
                      {isEditMode ? (
                        <>
                          <label
                            htmlFor="dateOfTravel"
                            className="font-semibold text-sm md:text-md"
                          >
                            Date of Travel:
                          </label>
                          <input
                            type="date"
                            name="dateOfTravel"
                            id="dateOfTravel"
                            value={formValues.dateOfTravel}
                            onChange={handleChange}
                            className="text-sm md:text-md bg-gray-600 rounded-lg"
                          />
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-sm md:text-md">
                            Date of Travel:
                          </div>
                          <div className="text-sm md:text-md">
                            {travellerDetails.dateOfTravel
                              ? formatDate(travellerDetails.dateOfTravel)
                              : ""}
                          </div>
                        </>
                      )}

                      {isEditMode ? (
                        <>
                          <label
                            htmlFor="adults"
                            className="font-semibold text-sm md:text-md"
                          >
                            Traveller Count:
                          </label>
                          <div className=" my-1">
                            <input
                              type="text"
                              name="adults"
                              id="adults"
                              value={formValues.adults}
                              onChange={handleChange}
                              className="text-sm md:text-md bg-gray-600 rounded-lg mb-1"
                              title="Adults"
                            />
                            <input
                              type="text"
                              name="child"
                              id="child"
                              value={formValues.child}
                              onChange={handleChange}
                              className="text-sm md:text-md bg-gray-600 rounded-lg mb-1"
                              title="Children"
                            />
                            <input
                              type="text"
                              name="infants"
                              id="infants"
                              value={formValues.infants}
                              onChange={handleChange}
                              className="text-sm md:text-md bg-gray-600 rounded-lg"
                              title="Infants"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-sm md:text-md">
                            Traveller Count:
                          </div>
                          <div className="text-[11px]">
                            {adults} Adult(s), {child} Kid(s), {infants}{" "}
                            infant(s)
                          </div>
                        </>
                      )}

                      {isEditMode ? (
                        <>
                          <label
                            htmlFor="internationalFlights"
                            className="font-semibold text-sm md:text-md"
                          >
                            International Flights:
                          </label>
                          <input
                            type="text"
                            name="internationalFlights"
                            id="internationalFlights"
                            value={formValues.internationalFlights}
                            onChange={handleChange}
                            className="text-sm md:text-md bg-gray-600 rounded-lg"
                          />
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-sm md:text-md">
                            International Flights:
                          </div>
                          <div className="text-sm md:text-md">
                            {travellerDetails.internationalFlights}
                          </div>
                        </>
                      )}

                      {isEditMode ? (
                        <>
                          <label
                            htmlFor="domesticFlights"
                            className="font-semibold text-sm md:text-md"
                          >
                            Domestic Flights:
                          </label>
                          <input
                            type="text"
                            name="domesticFlights"
                            id="domesticFlights"
                            value={formValues.domesticFlights}
                            onChange={handleChange}
                            className="text-sm md:text-md bg-gray-600 rounded-lg"
                          />
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-sm md:text-md">
                            Domestic Flights:
                          </div>
                          <div className="text-sm md:text-md">
                            {travellerDetails.domesticFlights}
                          </div>
                        </>
                      )}

                      {isEditMode ? (
                        <>
                          <label
                            htmlFor="visa"
                            className="font-semibold text-sm md:text-md"
                          >
                            Visa:
                          </label>
                          <input
                            type="text"
                            name="visa"
                            id="visa"
                            value={formValues.visa}
                            onChange={handleChange}
                            className="text-sm md:text-md bg-gray-600 rounded-lg"
                          />
                        </>
                      ) : (
                        <>
                          <div className="font-semibold text-sm md:text-md">
                            Visa:
                          </div>
                          <div className="text-sm md:text-md">
                            {travellerDetails.visa}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                )}
                <hr class="border-gray-700 " />
                {/* <!-- Itinerary Section --> */}
                <div class=" md:p-6 rounded-lg ">
                  <h2 class="text-4xl font-semibold mb-6 flex items-center">
                    Itinerary
                  </h2>
                  {country && (
                    <button
                      className="bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121] px-4 py-2 rounded-lg print-proof"
                      onClick={() => {
                        navigate(
                          `/iteneryBuilder/${id}/${
                            country === "Vietnam/Cambodia" ? "Vietnam" : country
                          }/${travellerDetails.duration}`
                        );
                      }}
                    >
                      Go to Itinerary Builder
                    </button>
                  )}
                  {selectedCambodiaPackage &&
                    selectedCambodiaPackage.length > 0 &&
                    whenCambodia === "Before Vietnam" &&
                    selectedCambodiaPackage[0].Activities.map((item, ind) => (
                      <CambodiaActivityCard
                        key={`cambodia-before-${ind}`} // unique key prop
                        item={item}
                        Day={ind + 1}
                        hotel={selectedCambodiaHotels}
                        id={id}
                        Category={CambodiaAccomodationType}
                        city={selectedCambodiaPackage[0].Activities[ind].Stay}
                        setDeleteCounter={setDeleteCounter}
                      />
                    ))}

                  {daysToRender.map((dayNumber, ind) => (
                    <ActivityCard
                      key={`activity-${dayNumber}`} // unique key prop
                      index={ind}
                      day={dayNumber}
                      itenary={itenary}
                      selectedHotel={selectedHotel}
                      daywiseTransfers={daywiseTransfers}
                      id={id}
                      adults={adults}
                      child={child}
                      country={country}
                      setDeleteCounter={setDeleteCounter}
                    />
                  ))}

                  {selectedCambodiaPackage &&
                    selectedCambodiaPackage.length > 0 &&
                    whenCambodia === "After Vietnam" &&
                    selectedCambodiaPackage[0].Activities.map((item, ind) => (
                      <CambodiaActivityCard
                        key={`cambodia-after-${ind}`} // unique key prop
                        item={item}
                        Day={endDay + ind + 1}
                        hotel={selectedCambodiaHotels}
                        id={id}
                        Category={CambodiaAccomodationType}
                        city={selectedCambodiaPackage[0].Activities[ind].Stay}
                        setDeleteCounter={setDeleteCounter}
                      />
                    ))}
                </div>
                <hr class="border-gray-700 " />
                {/* <!-- Inclusions Section --> */}
                <h2 className="text-4xl font-semibold mb-4 flex  justify-start">
                  <h1 className=" md:ml-5">Inclusions</h1>
                </h2>
                <div className="bg-[#212121] text-white p-6 rounded-lg md:mx-6">
                  <div className="mb-4">
                    {/* Accommodation and Meals Inclusion */}
                    <div>
                      <h3 className="font-semibold mb-2 text-center md:text-left mt-3 flex">
                        <LocalDiningIcon className="h-6 w-6 inline-block mr-3 text-[#E2B53E]" />
                        <p className="text-md md:text-xl">
                          Accommodation and Meals Inclusion
                        </p>
                      </h3>
                      <div className="">
                        <ul className="list-disc">
                          {Object.keys(groupHotelByName).map(
                            (hotelName, index) => (
                              <div
                                className=" text-sm md:text-md relative md:left-16 left-10 text-gray-300"
                                key={index}
                              >
                                <li className="" key={index}>
                                  {hotelName}
                                </li>
                              </div>
                            )
                          )}
                        </ul>
                        <ul className="list-disc relative md:left-16 left-10">
                          {/* Dynamic content here */}
                          <li className="text-sm md:text-md text-gray-300">
                            Delicious and Quality Meals -{" "}
                            {parseInt(totalActivityBreakfast) +
                              parseInt(addonBreakfast) +
                              parseInt(selectedHotel.length)}{" "}
                            Breakfasts-
                            {parseInt(totalActivityLunch) +
                              parseInt(addonLunch)}{" "}
                            Lunches-
                            {parseInt(totalActivityDinner) +
                              parseInt(addonDinner)}{" "}
                            Dinners Per Person.
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* Activity Inclusion */}
                    <div>
                      <h3 className="font-semibold mb-2 text-center md:text-left mt-5 flex">
                        <HikingIcon className="h-6 w-6 inline-block mr-3 text-[#E2B53E]" />
                        <p className="text-md md:text-xl">Activity Inclusion</p>
                      </h3>
                      {itenary.map((item, ind) => (
                        <div className="relative md:left-16 left-10" key={ind}>
                          <ul className="list-disc ">
                            {/* Dynamic content here */}
                            <li className=" max-w-full pr-8 text-sm md:text-md text-gray-300">
                              {item.Activities.Headingofevent}
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>

                    {/* Flight and Transfers Inclusion */}
                    <div>
                      <h3 className="font-semibold mb-2 text-center md:text-left mt-5 flex">
                        <ConnectingAirportsIcon className="h-6 w-6 inline-block mr-3 text-[#E2B53E]" />
                        <p className="text-md md:text-xl">
                          Flight and Transfers Inclusion
                        </p>
                      </h3>
                      <ul className="list-disc  ">
                        {/* Dynamic content here */}
                        {/* {internationalFlightOffer.length > 0 && (
                          <label className="font-semibold ">
                            International Flights
                          </label>
                        )} */}
                        <div className="relative md:left-12 left-7 text-sm md:text-md text-gray-300">
                          {/* {internationalFlightOffer[0].itineraries.map((item, ind) => (
                            <li>
                              {item.duration} flight booked from{" "}
                              {item.departureCity} to {item.arrivalCity} on{" "}
                              {item.journeyDate
                                ? formatDate(item.journeyDate)
                                : ""}
                            </li>
                          ))} */}
                          ** Flights As Shown In Flights Section **
                        </div>
                        <div className="">
                          <ul className="list-disc text-sm md:text-md md:left-16 left-10 relative text-gray-300">
                            {selectedBaliICTransfers.map((item, ind) => (
                              <li key={ind}>
                                {item.seater} seater vehicle booked from{" "}
                                {item.source} to {item.arrival}
                              </li>
                            ))}
                          </ul>

                          <ul className="list-disc relative md:left-16 left-10 text-sm md:text-md text-gray-300">
                            {daywiseTransfers.map((item, ind) => (
                              <li key={ind}>
                                {item.noOfTransfer} {item.transfer.vehicleType}
                                (s) booked in {item.transfer.city}
                              </li>
                            ))}
                          </ul>

                          <ul className="list-disc relative md:left-16 left-10 text-sm md:text-md text-gray-300">
                            {selectedBuses.map((item, ind) => (
                              <li key={ind}>
                                Bus booked from {item.source} to {item.arrival}
                              </li>
                            ))}
                          </ul>

                          <ul className="list-disc relative md:left-16 left-10 text-sm md:text-md text-gray-300">
                            {selectedTrains.map((item, ind) => (
                              <li key={ind}>
                                Train booked from {item.source} to{" "}
                                {item.arrival}{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </ul>
                    </div>

                    <h3 className="font-semibold mb-2 text-center md:text-left mt-5 text-md md:text-xl flex">
                      <ControlPointDuplicateIcon className="h-6 w-6 inline-block mr-3 text-[#E2B53E]" />
                      <p> Addons</p>
                    </h3>
                    <ul className="list-disc relative md:left-16 left-10 text-sm md:text-md text-gray-300">
                      {EarlyCheckIn && <li>Early Check In included on Day 1</li>}
                      {LateCheckOut && <li>Late Check Out included on Last Day</li>}
                      {TATAAIGInsurance && (
                        <li>
                          TATA AIG Insurance included for{" "}
                          {parseInt(adults) +
                            parseInt(child) +
                            parseInt(infants)}{" "}
                          People
                        </li>
                      )}
                      {Cancellation && <li>Cancellation included</li>}
                      {visa === "Included" && <li>Visa included</li>}
                    </ul>
                  </div>
                </div>
                <div className="flex justify-around">
                  <button
                    className="bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121] p-4 rounded-lg mt-4 print-proof text-sm md:text-md"
                    onClick={handleAddInternationalFlight}
                  >
                    Add International Flight
                  </button>

                  <button
                    className="bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121] p-4 rounded-lg mt-4 print-proof text-sm md:text-md"
                    onClick={handleAddDomesticFlight}
                  >
                    Add Domestic Flight
                  </button>
                </div>
                <hr class="border-gray-700 " />
                {/* <!-- Flight Details Section --> */}
                <h2
                  className={`text-4xl font-semibold mb-4 ml-7 ${
                    isGeneratingPdf ? "extra-pdf-spacing" : ""
                  }`}
                >
                  Flight<span></span> Details
                </h2>
                {(selectedFlightData.length > 0 ||
                  internationalFlights.length > 0 ||
                  BookingInternationalFlightsData.length > 0 ||
                  BookingDomesticFlightsData.length > 0) && (
                  <div class="bg-[#212121] p-6 rounded-lg mx-6">
                    {/* <!-- International Flights --> */}
                    <div>
                      {(BookingInternationalFlightsData.length > 0 ||
                        internationalFlights.length > 0) && (
                        <h3 class="font-semibold mb-2">
                          International Flights
                        </h3>
                      )}
                      <div class="  gap-4 mb-4">
                        {BookingDomesticFlightsData &&
                          BookingInternationalFlightsData.length > 0 &&
                          BookingInternationalFlightsData.map((item, ind) => (
                            //  <BookingFlightsCard flight={item.data} key={ind} flights={item}/>
                            <div>
                              <BookingFlightsCard
                                flight={item.data}
                                key={ind}
                                flights={item}
                              />
                              <RiDeleteBin2Fill
                                className="text-2xl text-red-500 cursor-pointer print-proof"
                                onClick={() =>
                                  handleDeleteBookingInternationalFlight(
                                    item.data.token
                                  )
                                }
                              />
                            </div>
                          ))}
                        {internationalFlights.length > 0 &&
                          internationalFlights.map((item, ind) => (
                            <FlightsCard
                              item={item}
                              key={item.id}
                              id={id}
                              setDeleteCounter={setDeleteCounter}
                              ind={ind}
                              num={
                                internationalFlightOffer.filter(
                                  (item) => item.isInternationalFlight === true
                                ).length
                              }
                            />
                          ))}
                      </div>
                      <hr class="border-gray-700 my-4" />
                    </div>

                    {/* <!-- Domestic Flights --> */}
                    <div>
                      {(BookingDomesticFlightsData.length > 0 ||
                        selectedFlightData.length > 0) && (
                        <h3 class="font-semibold mb-2">Domestic Flights</h3>
                      )}
                      <div class="  gap-4 mb-4">
                        {BookingDomesticFlightsData &&
                          BookingDomesticFlightsData.length > 0 &&
                          BookingDomesticFlightsData.map((item, ind) => (
                            <div>
                              <BookingFlightsCard
                                flight={item.data}
                                key={ind}
                                flights={item}
                              />
                              <RiDeleteBin2Fill
                                className="text-2xl text-red-500 cursor-pointer print-proof"
                                onClick={() =>
                                  handleDeleteBookingDomesticFlight(
                                    item.data.token
                                  )
                                }
                              />
                            </div>
                          ))}

                        {selectedFlightData.length > 0 &&
                          selectedFlightData.map((item, ind) => (
                            <DomesticFlightCard
                              item={item}
                              key={item.id}
                              id={id}
                              setDeleteCounter={setDeleteCounter}
                              ind={ind}
                              num={
                                internationalFlightOffer.filter(
                                  (item) => item.isInternationalFlight === false
                                ).length
                              }
                            />
                          ))}
                      </div>
                      <hr class="border-gray-700 " />
                    </div>
                  </div>
                )}
                <div className="py-1"></div>
                <hr class="border-gray-700" />
                <div className="flex">
                  <label
                    className={`text-4xl my-3 font-medium mb-2 ${
                      isGeneratingPdf ? "extra-pdf-spacing" : ""
                    }`}
                  >
                    Travaura<span></span> Benefits
                  </label>
                </div>

                <BenefitsSection />

                <hr class="border-gray-700" />

                <div className="flex">
                  <label
                    className={`text-4xl my-3 font-medium mb-2 ${
                      isGeneratingPdf ? "extra-pdf-spacing" : ""
                    }`}
                  >
                    Payment<span></span> Methods
                  </label>
                </div>

                <PaymentMethodsSection />
                <div className="flex">
                  <label
                    className={`text-4xl my-3 font-medium mb-2 ${
                      isGeneratingPdf ? "extra-pdf-spacing" : ""
                    }`}
                  >
                    Traveller's<span></span> Gallery
                  </label>
                </div>

                <div>
                  {/* <TravellersGallerySection items={TravellersData} /> */}
                  <img
                    src={travellerImage}
                    alt=""
                    className="h-auto w-full object-cover"
                  />
                </div>

                <hr class="border-gray-700" />

                <div>
                  <label className="block text-gray-400 text-xl md:text-2xl font-medium mb-2">
                    Travaura is your trusted Travel Partner
                  </label>
                  <div className="flex flex-col md:flex-row items-center">
                    <img
                      src="https://media4.giphy.com/media/5NPhdqmyRxn8I/giphy.webp?cid=acd21440zcnhcext9mruka5sftfahscptg5hfslu75mzi9f6&ep=v1_gifs_search&rid=giphy.webp&ct=g"
                      alt=""
                      className="h-20 w-20 my-3 mx-auto md:mx-0"
                    />
                    <p className="p-4 text-xl md:text-2xl text-center md:text-left">
                      4.9 Star Review <br /> on Google.
                    </p>
                  </div>
                  {/* <div className="flex flex-wrap justify-center md:justify-start">
                    {[1, 2, 3].map(() => (
                      <div className="bg-gray-700 h-28 w-52 rounded-lg m-4 flex">
                        <img
                          src={k}
                          alt=""
                          className="h-10 w-10 my-3 rounded-full mx-3"
                        />
                        <div>
                          <div className="text-white text-lg font-semibold pt-3">
                            Ketan Saluja
                          </div>
                          <div className="text-white text-sm">⭐⭐⭐⭐⭐</div>
                        </div>
                      </div>
                    ))}
                  </div> */}
                </div>

                <hr class="border-gray-700" />
                {/* <label class="block text-gray-400 text-2xl font-medium mb-2">
                  Terms And Conditions
                </label> */}
              </div>

              {/* <!-- Pricing Details Sidebar --> */}
              {loading && (
                <div className="fixed z-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
                  <div className="text-white text-xl font-semibold text-center mt-4">
                    {" "}
                    Flights are Loading!!{" "}
                  </div>
                </div>
              )}
{id.startsWith("TRAV") && (
              <div className="mx-4">
                <h2 class="text-3xl font-bold mb-4">Pricing Details</h2>
                <div class="w-full my-3 h-fit bg-slate-200 text-gray-700 p-6 rounded-lg  ">
                  <div class="space-y-2 mb-4">
                    <div class="flex justify-between">
                      <div>International Flights</div>
                      <div>
                        {" "}
                        {!perPersonPrice
                          ? formatCurrency(Math.ceil(internationalFlightsPrice))
                          : formatCurrency(
                              Math.ceil(
                                internationalFlightsPrice /
                                  (parseInt(adults) + parseInt(child))
                              )
                            )}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div>Domestic Flights</div>
                      <div>
                        {" "}
                        {!perPersonPrice
                          ? formatCurrency(Math.ceil(selectedFlightPrice))
                          : formatCurrency(
                              Math.ceil(
                                selectedFlightPrice /
                                  (parseInt(adults) + parseInt(child))
                              )
                            )}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div>Land Package</div>
                      <div>
                        {" "}
                        {!perPersonPrice
                          ? formatCurrency(Math.ceil(landPackage18))
                          : formatCurrency(
                              Math.ceil(
                                landPackage18 /
                                  (parseInt(adults) + parseInt(child))
                              )
                            )}
                      </div>
                    </div>

                    <div class="flex justify-between">
                      <div>5% GST</div>
                      <div>
                        {" "}
                        {!perPersonPrice
                          ? formatCurrency(Math.ceil(gst))
                          : formatCurrency(
                              Math.ceil(
                                gst / (parseInt(adults) + parseInt(child))
                              )
                            )}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div>TCS</div>
                      <div>
                        {" "}
                        {!perPersonPrice
                          ? formatCurrency(Math.ceil(tcs))
                          : formatCurrency(
                              Math.ceil(
                                tcs / (parseInt(adults) + parseInt(child))
                              )
                            )}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div>Total Amount</div>
                      <div>
                        {" "}
                        {!perPersonPrice
                          ? formatCurrency(Math.ceil(totalCost))
                          : formatCurrency(
                              Math.ceil(
                                totalCost / (parseInt(adults) + parseInt(child))
                              )
                            )}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div>One Time Payment Off</div>
                      <div>
                        -{" "}
                        {!perPersonPrice
                          ? formatCurrency(Math.ceil(oto))
                          : formatCurrency(
                              Math.ceil(
                                oto / (parseInt(adults) + parseInt(child))
                              )
                            )}
                      </div>
                    </div>

                    <hr class="border-gray-700" />
                    <div class="flex justify-between font-semibold">
                      <div>
                        <div>Total Effective Price</div>
                        {perPersonPrice && <div>(Per Person)</div>}
                      </div>
                      <div>
                        {!perPersonPrice
                          ? formatCurrency(Math.ceil(totalCost - oto))
                          : formatCurrency(
                              Math.ceil(
                                (totalCost - oto) /
                                  (parseInt(adults) + parseInt(child))
                              )
                            )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="w-full  h-fit bg-red-500 text-white p-6 rounded-lg ml-4 ">
                  <div class="flex justify-between">
                    <div>Last Discounted Price</div>
                    {!perPersonPrice? <div className="text-xl font-bold">
                      {" "}
                      {formatCurrency(Math.ceil(ldptotalCost))}
                    </div> : <div className="text-xl font-bold">
                      {" "}
                      {formatCurrency(Math.ceil(ppp))} Per Person
                    </div>}
                  </div>
                  <div className="flex justify-center">
                    {validTillDate && (
                      <label class="block  text-xl mb-2 mt-4 font-thin">
                        Offer Valid Till {formatDate(validTillDate)}
                      </label>
                    )}
                  </div>
                 
                </div> */}

                <div>
                  <h1 className="text-3xl font-bold my-9 ">Payment Plans</h1>
                  <div className="bg-yellow-500 my-5 p-4 px-6 mx-3  rounded-lg text-gray-800">
                    <h1 className="text-xl font-medium">
                      One Time Payment Discount
                    </h1>
                    <p className="text-sm mr-2 mt-3 ">
                      Get OTO Off by making the pament in one go
                    </p>
                  </div>
                  <div className="bg-[#212121] my-5 p-4 rounded-lg px-6 mx-3">
                    <h1 className="text-xl font-medium">Easy Peasy</h1>
                    <p className="text-sm mr-2 text-gray-500 mt-3">
                      Pay in 3 Easy Parts.
                    </p>
                  </div>
                  <div className="bg-[#212121]  my-5 p-4 rounded-lg px-6 mx-3">
                    <h1 className="text-xl font-medium">EMIs</h1>
                    <p className="text-sm mr-2 text-gray-500 mt-3">
                      Get EMIs on Debit/Credit Cards and personal finance.
                    </p>
                  </div>
                  <div className="w-full my-3 h-fit  text-gray-400 p-6 rounded-lg  print-proof ">
                    <input
                      type="checkbox"
                      onChange={() => setPerPersonPrice(!perPersonPrice)}
                      className="my-4"
                    />{" "}
                    <label className="text-lg ">Show Per Person Price</label>
                  </div>
                </div>
              </div>
)}
            </div>

          </main>
        </div>
      </body>

      <TermsandConditions setToggletnc={setToggletnc} toggletnc={toggletnc} />

      <hr class="border-gray-700" />
    </div>
  );
};

export default LinkPage;
