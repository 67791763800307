import React from "react";
import { FaPlane } from "react-icons/fa";
import { useState, useEffect } from "react";

import { render } from "@testing-library/react";

const BookingFlightsCard = ({ flight, flights }) => {
  const [clicked, setClicked] = React.useState({});
  useEffect(() => {
    console.log("flightTOSHow: ", flight);
    }, [flight]);
  function formatTimeToHoursAndMinutes(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    return `${hours}H ${minutes.toString().padStart(2, "0")}`;
  }

  const calculateWaitTime = (currentLegArrivalTime, nextLegDepartureTime) => {
    const arrival = new Date(currentLegArrivalTime);
    const departure = new Date(nextLegDepartureTime);
    const diff = departure - arrival;
    const diffInMinutes = Math.floor(diff / 60000);
    return formatTimeToHoursAndMinutes(diffInMinutes * 60);
  };

  const formatDate = (dateTimeStr) => {
    const dateObj = new Date(dateTimeStr);

    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("en-US", { month: "short" }); // 'Jan'
    const year = dateObj.getFullYear();

    // Function to get the day suffix
    const getDaySuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${getDaySuffix(day)} ${month} ${year}`;
  };

  const formatTime = (dateTimeStr) => {
    const dateObj = new Date(dateTimeStr);
    return dateObj.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  };

  // Combine date and time formatting
  const formatDateTime = (dateTimeStr) => {
    return `${formatDate(dateTimeStr)}, ${formatTime(dateTimeStr)}`;
  };
  return (
    <div className=" p-4 rounded-lg shadow-md m-6 bg-secondary ">
      {/* <h2 className="text-lg font-bold text-gray-200">Flight Number: {flight.key}</h2> */}

      <div className="bg-[#121212] p-4 rounded-lg max-w-full mx-auto my-8">
        {flight && flight.segments && flight.segments.map((segment, idx) => (
          <div
            key={idx}
            className="mb-4 hover:border hover:rounded-xl hover:border-gray-500 cursor-pointer p-4"
            onClick={() => {
              clicked === segment ? setClicked([]) : setClicked(segment);
            }}
          >
            <div className="flex items-center justify-between mb-4">
              <button className="bg-blue-400 text-white  rounded"></button>
              <div className="flex items-center mb-5 mr-3">
                <span className="text-white text-xl flex">
                  {segment &&
                    segment.legs &&
                    (() => {
                      // Create a Set to store unique carrier names
                      const uniqueCarrierNames = new Set();

                      // Filter out duplicates and store unique carrier names in the Set
                      segment.legs.forEach((leg) => {
                        const carrierName = leg.carriersData[0].name;
                        uniqueCarrierNames.add(carrierName);
                      });

                      // Convert the Set to an array of unique carrier names
                      const uniqueNamesArray = Array.from(uniqueCarrierNames);

                      // Map over the unique names array and render them
                      return uniqueNamesArray.map((carrierName, idx) => (
                        <div key={idx} className="text-gray-300 text-sm mx-1">
                          <img
                            src={segment.legs[idx].carriersData[0].logo}
                            alt={segment.legs[0].carriersData[0].name}
                            className="w-8 h-8 inline-block mx-1"
                          />
                          {carrierName}
                          {idx === uniqueNamesArray.length - 1 ? "" : ", "}
                        </div>
                      ));
                    })()}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between text-white  px-8 ">
              <div className="relative bottom-11 text-gray-300">
                <div className="text-xl font-medium">
                  {segment.departureAirport.code}
                </div>
                <div className="text-sm">
                  {segment.departureAirport.cityName}
                </div>
                <div className="text-sm">
                  {formatDateTime(segment.departureTime)}
                </div>
              </div>
              <div className="flex-1 mx-8 relative top-5 ">
                <div
                  className={`border-t border-gray-600 flex justify-center   ${
                    clicked === segment ? " border-blue-600" : ""
                  }`}
                >
                  <FaPlane
                    className={` text-2xl relative bottom-3 ${
                      clicked === segment ? " text-blue-600" : "text-gray-500"
                    }`}
                  />
                </div>

                <div className="flex justify-center">
                  {segment.legs.length > 1 ? (
                    <div className="text-gray-300 text-xl relative bottom-3 items-center">
                      {segment.legs.length - 1} stops
                    </div>
                  ) : (
                    <div className="text-gray-500 text-xl relative bottom-3">
                      Nonstop
                    </div>
                  )}
                </div>
                <div className="flex justify-center">
                  {formatTimeToHoursAndMinutes(segment.totalTime)}
                </div>
                <div className="flex justify-center">
                  <div className="text-gray-500">
                    <div>
                      Cabin Baggage:{" "}
                      {segment.travellerCabinLuggage &&
                      segment.travellerCabinLuggage[0] &&
                      segment.travellerCabinLuggage[0].luggageAllowance &&
                      typeof segment.travellerCabinLuggage[0].luggageAllowance
                        .maxWeightPerPiece === "number"
                        ? `${Math.floor(
                            segment.travellerCabinLuggage[0].luggageAllowance
                              .maxWeightPerPiece / 2.205
                          )} Kgs `
                        : "Included"}
                    </div>
                    <div>
                    {(segment.travellerCheckedLuggage &&
                      segment.travellerCheckedLuggage[0] &&
                      typeof segment.travellerCheckedLuggage[0]
                        .luggageAllowance !== "undefined") || (flights.numberOfBaggages && flights.numberOfBaggages > 0) ? (
                        <div>
                            Checked Baggage:{" "}
                            </div>
                        ) : (
                            ""
                        )}
                      {segment.travellerCheckedLuggage &&
                      segment.travellerCheckedLuggage[0] &&
                      typeof segment.travellerCheckedLuggage[0]
                        .luggageAllowance !== "undefined" &&
                      ((segment.travellerCheckedLuggage[0].luggageAllowance
                        .ruleType === "PIECE_BASED" &&
                        typeof segment.travellerCheckedLuggage[0]
                          .luggageAllowance.maxWeightPerPiece === "number") ||
                        typeof segment.travellerCheckedLuggage[0]
                          .luggageAllowance.maxTotalWeight === "number")
                        ? segment.travellerCheckedLuggage[0].luggageAllowance
                            .ruleType === "PIECE_BASED"
                          ? `${Math.floor(
                              segment.travellerCheckedLuggage[0]
                                .luggageAllowance.maxWeightPerPiece / 2.205
                            )} Kgs `
                          : `${Math.floor(
                              segment.travellerCheckedLuggage[0]
                                .luggageAllowance.maxTotalWeight / 2.205
                            )} Kgs `
                        : ""}
                        <p className="">{flights.numberOfBaggages && flights.numberOfBaggages > 0 ? `${flights.numberOfBaggages} Addon Baggages` : ""}</p>

                    </div>
                  </div>
                </div>
                {/* {flight.includedProducts.areAllSegmentsIdentical ? (
                    <div className="flex justify-center">
                        <div className="text-gray-500">
                            <div>
                                Cabin Baggage:{" "}
                                {Math.ceil(flight.includedProducts.segments[0].filter((item) => item.luggageType === "HAND").length > 0 ? flight.includedProducts.segments[0].filter((item) => item.luggageType === "HAND")[0].maxWeightPerPiece/2.205 : 0)} Kgs
                                </div>
                            <div>
                                Checked Baggage:{" "}
                                {Math.ceil(flight.includedProducts.segments[0].filter((item) => item.luggageType === "CHECKED").length > 0 ? flight.includedProducts.segments[0].filter((item) => item.luggageType === "CHECKED")[0].maxWeightPerPiece/2.205 : 0)} Kgs

                                </div>
                                </div>

                    </div>
                ) : (
                    <div className="flex justify-center"></div>)} */}
                <div className="text-center text-sm mt-2 text-gray-300">
                  <div>
                    {/* {segment.departure.at
                      // ? formatDuration(segment.duration)
                      : ""} */}
                  </div>
                </div>
              </div>
              <div className="relative bottom-11 text-gray-300">
                <div className="text-xl font-medium">
                  {segment.arrivalAirport.code}
                </div>
                <div className="text-sm">{segment.arrivalAirport.cityName}</div>
                <div className="text-sm">
                  {formatDateTime(segment.arrivalTime)}{" "}
                </div>
              </div>
            </div>
            {clicked === segment && segment.legs.length > 1 ? (
              segment.legs.map((leg, idx) => (
                <React.Fragment key={idx}>
                  <div className="scale-75 bg-secondary rounded-xl p-3 align-middle">
                    <div className="flex items-center justify-between mb-4 ">
                      <button className="bg-blue-400 text-white  rounded"></button>
                      <div className="flex items-center mb-5 mr-3">
                        <span className="text-gray-300 text-xl ">
                          <img
                            src={leg.carriersData[0].logo}
                            alt={leg.carriersData[0].name}
                            className="w-8 h-8 inline-block mx-1 bg-gray-900 p-1"
                          />
                          {leg.carriersData[0].name}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between text-gray-300  px-8">
                      <div className="relative bottom-8 ">
                        <div className="text-xl font-medium">
                          {leg.departureAirport.code}
                        </div>
                        <div className="text-sm">
                          {leg.departureAirport.cityName}
                        </div>
                        <div className="text-md">
                          {formatDateTime(leg.departureTime)}
                        </div>
                      </div>
                      <div className="flex-1 mx-8">
                        <div className="border-t border-gray-600 flex justify-center ">
                          <FaPlane className="text-gray-300 text-2xl relative bottom-3" />
                        </div>

                        <div className="flex justify-center">
                          <p className="text-gray-300 ">
                            {formatTimeToHoursAndMinutes(leg.totalTime)}
                          </p>
                        </div>
                        <div className="flex justify-center">
                          <p className="text-gray-300">
                            {leg.flightInfo.carrierInfo.marketingCarrier}
                            {leg.flightInfo.flightNumber}
                          </p>
                        </div>
                        <div className="flex justify-center">
                          <p className="text-gray-300">{leg.cabinClass}</p>
                        </div>
                        
                        <div className="text-center text-sm mt-2 text-gray-300">
                          <div>
                            {/* {segment.departure.at
                      // ? formatDuration(segment.duration)
                      : ""} */}
                          </div>
                        </div>
                      </div>
                      <div className="relative bottom-8 text-gray-300">
                        <div className="text-xl font-medium">
                          {leg.arrivalAirport.code}
                        </div>
                        <div className="text-sm">
                          {leg.arrivalAirport.cityName}
                        </div>
                        <div className="text-md">
                          {formatDateTime(leg.arrivalTime)}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  {idx < segment.legs.length - 1 && (
                    <div className="text-gray-300 text-center p-2 flex justify-center">
                      <div className=" w-fit px-3 rounded-full py-1">
                        Wait Time:{" "}
                        {calculateWaitTime(
                          leg.arrivalTime,
                          segment.legs[idx + 1].departureTime
                        )}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))
            ) : (
              <div></div>
            )}
          </div>
        ))}
      </div>

      {/* <div className="mt-4">
        <p className="font-semibold text-gray-200">
          Total Time for This Itinerary (including wait time):{" "}
          {getTotalTimeWithWait(flight.itineraries[0])}
        </p>
      </div> */}

      {/* <div className="mt-4">
        <p className="font-bold text-3xl text-gray-200">
          Price:
          {flight.priceBreakdown.totalRounded.units
            ? Number(flight.priceBreakdown.totalRounded.units).toLocaleString(
                "en-IN",
                {
                  style: "currency",
                  currency: flight.priceBreakdown.totalRounded.currencyCode,
                }
              )
            : "N/A"}
        </p>
      </div> */}
      {/* {renderButtons()} */}
    </div>
  );
};

export default BookingFlightsCard;