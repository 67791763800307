import React from "react";
import { useState, useEffect } from "react";
import { useRef } from "react";
import { AuthContext } from "./contexts/AuthProvider";
import { useContext } from "react";
import { Pagination } from "@mui/material";
import ReactSlider from "react-slider";
import { useNavigate } from "react-router-dom";
import EditActivityPopup from "./AdminEditActivity"

const AdminActivitiyManagement = () => {
    const [ActivityData, setActivityData] = useState([]);
    const [filteredActivityData, setFilteredActivityData] = useState([]);
    const [country, setCountry] = useState("");
    const [searchPrompt, setSearchPrompt] = useState("");
    const isDataFetched = useRef(false);
    const { currentUser } = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const [priceRange, setPriceRange] = useState([]);
    const [filterCities, setFilterCities] = useState([]);
    const [filterRegions, setFilterRegions] = useState([]);
    const [filterDurations, setFilterDurations] = useState([]);
    const [filterCity, setFilterCity] = useState("");
    const [filterRegion, setFilterRegion] = useState("");
    const [filterDuration, setFilterDuration] = useState("");
    const [maxPrice, setMaxPrice] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [sortBy, setSortBy] = useState("");
    const navigate = useNavigate();

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);
  const [deleteCounter, setDeleteCounter] = useState(0);
  const [editedData, setEditedData] = useState({});
  
  

  const handleEditClick = (Activity) => {
    setSelectedActivity(Activity);
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSelectedActivity(null);
  };

  const handleUpdateActivity = async (updatedActivity) => {
    const isConfirmed =  window.confirm(`Are you sure you want to update this hote with these changes: ${JSON.stringify(editedData)}`);
    if (!isConfirmed) return;
    
    setIsPopupOpen(false);

    // Update the hotel data in your state
    const updatedActivities = ActivityData.map((item) => {
        if(item.Activities.find((Activity) => Activity._id === updatedActivity._id)) {
            return {
                ...item,
                hotels: item.Activities.map((Activity) => {
                    if(Activity._id === updatedActivity._id) {
                        return updatedActivity;
                    }
                    return Activity;
                })
            }
        }
        return item;
    });
    setActivityData(updatedActivities);

    // Make an API call to update the hotel in the database

    try {
      const response = await fetch(
        `https://travaurabackend.azurewebsites.net/UpdateActivities/${selectedActivity._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedActivity),
        }
      );

      if (!response.ok) throw new Error("Failed to update hotel");
      else {
        alert("Activity updated successfully");
        setDeleteCounter(deleteCounter + 1);
        setSelectedActivity(null);
        setEditedData({});
        }

      // Optionally, fetch the updated list of hotels from the server
    } catch (error) {
      console.error("Error updating hotel:", error);
    }
  };
  
    const [, forceUpdate] = useState();
  
    // Call this function to force a re-render
    const triggerReRender = () => {
      forceUpdate({});
    };

    
  
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentActivities =
      filteredActivityData &&
      filteredActivityData.slice(indexOfFirstItem, indexOfLastItem);
  
    // Update the page count
    const pageCount = Math.ceil(
      filteredActivityData && filteredActivityData.length / itemsPerPage
    );
  
    // Handle change page
    const handleChangePage = (event, value) => {
      setCurrentPage(value);
    };
  
   
    useEffect(() => {
      let tempFilterCitiesOptions = [];
      let tempFilterRegionsOptions = [];
      let tempFilterDurationOptions = [];
  /*
      filteredActivityData.map((item) => {
        if (item.City) {
          tempFilterCitiesOptions.push(item.City);
        }
        if (item.PartofCountry) {
          tempFilterRegionsOptions.push(item.PartofCountry);
        }
        if (item.Durationofevent) {
          tempFilterDurationOptions.push(item.Durationofevent);
        }
      });

  */

      ActivityData.map((item) => {
        item.Activities.map((activity) => {
            if(activity.City) tempFilterCitiesOptions.push(activity.City);
            if(activity.PartofCountry) tempFilterRegionsOptions.push(activity.PartofCountry);
            if(activity.Durationofevent) tempFilterDurationOptions.push(activity.Durationofevent);
        });
        });
      tempFilterCitiesOptions = [...new Set(tempFilterCitiesOptions)];
      tempFilterRegionsOptions = [...new Set(tempFilterRegionsOptions)];
      tempFilterDurationOptions = [...new Set(tempFilterDurationOptions)];
  
      setFilterCities(tempFilterCitiesOptions);
      setFilterRegions(tempFilterRegionsOptions);
      setFilterDurations(tempFilterDurationOptions);
    }, [filteredActivityData]);
  
    useEffect(() => {
      
        Promise.all([
          fetch("https://travaurabackend.azurewebsites.net/api/data")
            .then((response) => response.json())
            .then((data) => ({ country: "Vietnam", Activities: data })),
            fetch("https://travaurabackend.azurewebsites.net/api/BaliData")
            .then((response) => response.json())
            .then((data) => ({ country: "Bali", Activities: data })),
            fetch("https://travaurabackend.azurewebsites.net/api/ThailandActivitiesSchema")
            .then((response) => response.json())
            .then((data) => ({ country: "Thailand", Activities: data })),
        ])
          .then((results) => {
            // Update hotelData with all the results at once
            setActivityData(results);
  
            
          })
          .catch((error) => {
            console.error("Error fetching hotel data:", error);
          });
      
    }, [currentUser, deleteCounter]);

  useEffect(() => {
    console.log("ActivityData: ", ActivityData);
    console.log("filteredActivityData: ", filteredActivityData);
    }, [ActivityData, filteredActivityData]);
  
    useEffect(() => {
      let temp = [];
      ActivityData.map((item) => {
        item.Activities.map((activity) => {
            if(activity.PriceinRupees) temp.push(activity);
        });
      });
      if (country !== "") {
        temp = [];
        ActivityData.filter((item) => {
          if (item.country === country) {
            item.Activities.map((activity) => {
                if(activity.PriceinRupees) temp.push(activity);
            });
          }
        });
      }
      if (searchPrompt !== "") {
        temp = temp.filter((item) => {
          if (item.Headingofevent) {
            return item.Headingofevent.toLowerCase().includes(searchPrompt.toLowerCase());
          }
        });
      }
      if ((priceRange[0] || priceRange[1]) && priceRange[0] <= priceRange[1]) {
        temp = temp.filter((item) => {
          if ((item.PriceinRupees)) {
            return (
                item.PriceinRupees >= priceRange[0] &&
                item.PriceinRupees <= priceRange[1]
            );
          }
        });
      }
      if (filterCity !== "") {
        temp = temp.filter((item) => {
          if (item.City) {
            return item.City === filterCity;
          }
        });
      }
      if (filterRegion !== "") {
        temp = temp.filter((item) => {
          if (item.PartofCountry) {
            return item.PartofCountry === filterRegion;
          }
        });
      }
      if (filterDuration !== "") {
        temp = temp.filter((item) => {
          if (item.Durationofevent) {
            return item.Durationofevent === filterDuration;
          }
        });
      }
      if (sortBy === "PriceLTH") {
        temp.sort((a, b) => {
          return a.PriceinRupees - b.PriceinRupees;
        });
      }
      if (sortBy === "PriceHTL") {
          temp.sort((a, b) => {
              return b.PriceinRupees - a.PriceinRupees;
          }
          );
      }
  
        
        setFilteredActivityData(temp);
    }, [
      country,
        ActivityData,
      searchPrompt,
      priceRange,
      filterCity,
      filterRegion,
      filterDuration,
      sortBy,
      ]);
  
    // useEffect(() => {
    //   console.log("hotelData: ", hotelData);
    //   console.log("filteredHotelData: ", filteredHotelData);
    //   console.log("priceRange: ", priceRange);
    //   console.log("minPrice: ", minPrice);
    //   console.log("maxPrice: ", maxPrice);
    // }, [hotelData, filteredHotelData, priceRange, minPrice, maxPrice]);
  
    return (
      <div className="">
        <div className="flex justify-between ">
          <div className="w-1/5  bg-black mr-3 ">
            <div className="  h-full bg-[#2B2B2B] p-3">
              <div className="h-16 text-white text-3xl font-bold ">
                Activity Management
              </div>
              <div className="text-white text-2xl font-medium mb-3">
                Filter Activities
              </div>
              <div className="flex flex-col justify-start my-2">
                <label className="text-white text-sm font-medium">Country:</label>
  
                <select
                  className="bg-black text-white text-sm font-medium px-2 py-1 rounded-md mx-2"
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="">Select Country</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Bali">Bali</option>
                  <option value="Thailand">Thailand</option>
                </select>
              </div>
              <div className="flex flex-col justify-start my-2">
                <label className="text-white text-sm font-medium">City:</label>
                <select
                  className="bg-black text-white text-sm font-medium px-2 py-1 rounded-md mx-2"
                  onChange={(e) => setFilterCity(e.target.value)}
                >
                  <option value="">Select City</option>
                  {filterCities.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col justify-start my-2">
                <label className="text-white text-sm font-medium">Region:</label>
                <select
                  className="bg-black text-white text-sm font-medium px-2 py-1 rounded-md mx-2"
                  onChange={(e) => setFilterRegion(e.target.value)}
                >
                  <option value="">Select Region</option>
                  {filterRegions.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col justify-start my-2">
                <label className="text-white text-sm font-medium">
                Duration of Event:
                </label>
                <select
                  className="bg-black text-white text-sm font-medium px-2 py-1 rounded-md mx-2"
                  onChange={(e) => setFilterDuration(e.target.value)}
                  value={filterDuration}
                >
                  <option value="">Select Duration</option>
                  { filterDurations
                  .map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
              </div>
  
              {/* <div className="flex flex-col justify-start my-2">
                <label className="text-white text-sm font-medium">
                  Min Price:
                </label>
                <input
                  type="number"
                  className=" rounded-md  bg-black text-white"
                  placeholder="₹ 999"
                  value={priceRange[0]}
                  onChange={(e) => setPriceRange([e.target.value, priceRange[1]])}
                />
              </div>
              <div className="flex flex-col justify-start">
                <label className="text-white text-sm font-medium">
                  Max Price:
                </label>
                <input
                  type="number"
                  className=" rounded-md  bg-black text-white"
                  placeholder="₹ 99999"
                  value={priceRange[1]}
                  onChange={(e) => setPriceRange([priceRange[0], e.target.value])}
                />
              </div> */}
              <div className="flex flex-col justify-start my-2">
                <label className="text-white text-sm font-medium">
                  Price Range:
                </label>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  defaultValue={[0, 50000]}
                  ariaLabel={["Lower thumb", "Upper thumb"]}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => (
                    <div {...props}>
                      <div className="thumb-value">{state.valueNow}</div>
                    </div>
                  )}
                  pearling={true} // Ensure thumbs do not cross
                  minDistance={3000} // Minimum distance between thumbs
                  min={0} // Minimum value of the slider
                  max={50000} // Maximum value of the slider
                  value={priceRange} // Controlled value
                  onChange={(e) => setPriceRange(e)}
                />
              </div>
              <div className="flex flex-col justify-start my-6">
                  <label className="text-white text-sm font-medium">
                      Sort By:
                  </label>
                  <select
                      className="bg-black text-white text-sm font-medium px-2 py-1 rounded-md mx-2"
                      onChange={(e) => setSortBy(e.target.value)}
                  >
                      <option value="">Select Sort By</option>
                      <option value="PriceLTH">Price Low to High</option>
                      <option value="PriceHTL">Price High to Low</option>
  
                      
                  </select>
                  </div>
            </div>
          </div>
          <div className="w-4/5  bg-black">
          <EditActivityPopup
            ActivityData={selectedActivity}
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            onUpdate={handleUpdateActivity}
            updateEditedData={setEditedData}
            edit={editedData}
          />
            <div className="flex justify-center items-center h-16 text-white text-2xl font-bold">
              <input
                type="text"
                placeholder="Search Activities"
                value={searchPrompt}
                onChange={(e) => setSearchPrompt(e.target.value)}
                className="w-1/2 h-10 rounded-md px-2 bg-[#2B2B2B]"
              />
              {/* <button className="bg-blue-500 text-white px-4 py-2 rounded-md mx-2" onClick={() => navigate("/hotelform")}>
                Add Hotel
              </button> */}
            </div>
  
            <div className="bg-[#2B2B2B] mx-5 p-3 rounded-xl">
              <div className="grid grid-cols-7 gap-1 mt-5 text-white font-medium my-3 ">
                <div className="col-span-1 text-center text-md font-bold">
                  S. No.
                </div>
                <div className="col-span-1 text-center text-md font-bold">
                  Name of Activity
                </div>
                <div className="col-span-1 text-center text-md font-bold">
                  Duration
                </div>
                <div className="col-span-1 text-center text-md font-bold">
                  Region
                </div>
                <div className="col-span-1 text-center text-md font-bold">
                  City
                </div>
                <div className="col-span-1 text-center text-md font-bold">
                  Price
                </div>
                <div className="col-span-1 text-center text-md font-bold">
                  Edit
                </div>
                <div className="col-span-7">
                  <hr className="mx-5 h-0.5 border-0 bg-gray-200" />
                </div>
                {currentActivities &&
                  currentActivities.map((item, index) => (
                    <React.Fragment key={item._id}>
                      {" "}
                      {/* Use React.Fragment with a key for each item */}
                      <div className="col-span-1 text-center text-gray-300 text-sm relative top-2">
                        {currentPage === 1
                          ? index + 1
                          : (currentPage - 1) * itemsPerPage + index + 1}
                      </div>
                      <div className="col-span-1 text-center text-sm relative top-2">
                        {item.Headingofevent ? item.Headingofevent : "-"}
                      </div>
                      <div className="col-span-1 text-center text-sm relative top-2">
                        {item.Durationofevent ? item.Durationofevent : "-"}
                      </div>
                      <div className="col-span-1 text-center text-gray-300 text-sm relative top-2">
                        {item.PartofCountry ? item.PartofCountry : "-"}
                      </div>
                      <div className="col-span-1 text-center text-gray-300 text-sm relative top-2">
                        {item.City ? item.City : "-"}
                      </div>
                      <div className="col-span-1 text-center text-gray-300 text-sm relative top-2">
                        {item.PriceinRupees
                          ? `₹${new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                            })
                              .format(item.PriceinRupees)
                              .slice(1)}`
                          : "-"}
                      </div>
                      <div className="col-span-1 text-center">
                        <button className="bg-blue-500 text-white p-1 rounded-lg h-[30px] w-full text-sm my-1" onClick={() => handleEditClick(item)}>
                          Edit
                        </button>
                      </div>
                      <div className="col-span-7">
                        <hr className="mx-5 h-0.5 border-0 bg-gray-500" />
                      </div>
                    </React.Fragment>
                  ))}
              </div>
              <div className="flex justify-center items-center">
                <Pagination
                  count={pageCount}
                  color="primary" // or any other color prop that suits your design
                  onChange={handleChangePage}
                  className="whiteTextPagination" // Applying the custom CSS class
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AdminActivitiyManagement
