import React from "react";
import { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import DaywiseItenerary from "./DaywiseItenerary";

import { useNavigate } from "react-router-dom";
import Logo from "./Logo.png";
import { motion } from "framer-motion";
import Navbar from "./Navbar";
import LabelAnimation from "./Animations/Text_Entry";

const IteneraryBuilder = () => {
  const { id, duration, country} = useParams();
  const [userData, setUserData] = useState({});

  const ref = useRef();
  // BookingSelectedDomesticFlights: selectedDomesticFlights || {},
  // BookingSelectedInternationalFlights: selectedInternationalFlights || {},
  // selectedDomesticFlights: selectedFlightData || {},
  //     selectedInternationalFlights: internationalFlights || {},
  useEffect(() => {
    if(id){
      sessionStorage.setItem("customId", id);
    }
  }, [id]);

  const {
    selectedCambodiaHotels, setSelectedCambodiaHotels,
    selectedDomesticFlights,
    setSelectedDomesticFlights,
    selectedInternationalFlights,
    setSelectedInternationalFlights,
    selectedFlightData,
    setSelectedFlightData,
    internationalFlights,
    setInternationalFlights,
    // country,
    CambodiaAccomodationType,
    setCambodiaAccomodationType,
    setCambodiaWhen,
    CambodiaWhen,
    selectedCambodiaPackage,
    setSelectedCambodiaPackage,
    travellerDetails,
    setTravellerDetails,
    numberOfPeople,
    setNumberOfPeople,
    ActivityData,
    setActivityData,
    globalCityStructure,
    setGlobalCityStructure,
    clickedButtons,
    setClickedButtons,
    setCountry,
    numberOfDays,
    setNumberOfDays,
    selectedCountryData,
    setSelectedCountryData,
    addonPrice,
    setAddonPrice,
    adults,
    setAdults,
    child,
    setChild,
    infants,
    setInfants,
    itenary,
    setItenary,
    activityPrice,
    setActivityPrice,
    addonDinner,
    setAddonDinner,
    addonLunch,
    setAddonLunch,
    addonBreakfast,
    setAddonBreakfast,
    EarlyCheckIn,
    setEarlyCheckIn,
    LateCheckOut,
    setLateCheckOut,
    TATAAIGInsurance,
    setTATAAIGInsurance,
    Cancellation,
    setCancellation,
    visa,
    setVisa,
    peakSeasonMarkup,
    setPeakSeasonMarkup,
    validTillDate,
    setValidTillDate,
    selectedHotel,
    setSelectedHotel,
    setTransferData,
    setDaywiseTransfers,
    setInterCityTransferData,
    setSelectedBaliIntercityTransfers,
    setCheckedBaliIntercityTransfers,
    setSelectedTrainData,
    setSelectedBusData,
    selectedAddons, setSelectedAddons,
    daywiseTransfers,
    selectedBaliIntercityTransfers,
    selectedTrainData,
    selectedBusData,
    totalNumberofThingsSelected, setTotalNumberofThingsSelected
    

    
  } = useContext(UserContext);
  const [day, setDay] = useState(1);
  const navigate = useNavigate();
  const dayRefs = useRef([]);
  const [hotelData, setHotelData] = useState([]);

  

  const [activeDay, setActiveDay] = useState(0);
  const cambodiaPackageDays =
    selectedCambodiaPackage && selectedCambodiaPackage.length > 0
      ? selectedCambodiaPackage[0].Activities.length
      : 0;

  // Calculate the starting and ending day based on the CambodiaWhen condition
  const startDay =
    CambodiaWhen === "Before Vietnam" ? cambodiaPackageDays + 1 : 1;
  const endDay =
    CambodiaWhen === "After Vietnam"
      ? (duration || numberOfDays) - cambodiaPackageDays
      : (duration || numberOfDays);

  // Create an array of day numbers to be rendered
  const daysToRender = Array.from(
    { length: endDay - startDay + 1 },
    (_, i) => startDay + i
  );

  // if (daysToRender.length <= 0) {
  //   alert("Please select a valid number of days");
  //   navigate("/");
  // }
  

  dayRefs.current = Array(daysToRender)
    .fill()
    .map((_, i) => dayRefs.current[i] || React.createRef());

  const scrollToDay = (index) => {
    dayRefs.current[index].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleGetItenerary = () => {
    let tempactivityprice = 0;
    itenary.map((item, ind) => {
      tempactivityprice =
        tempactivityprice +
        item.Activities.PriceinRupees * (parseInt(adults) + parseInt(child));
    });
    setActivityPrice(tempactivityprice);

    console.log("activityPrice: ", activityPrice);
    navigate(`/FlightSearch/${id}/${country}/${duration}`);
  };

  // /FlightSearch/:id/:country/:duration

  useEffect(() => {
    console.log("addonPrice: ", addonPrice);
  }, [addonPrice]);

  useEffect(() => {
    const fetchUserData = async () => {
      
      try {
        const response = await fetch(
          (id.startsWith("PACKAGE") ? `https://travaurabackend.azurewebsites.net/api/package/${id}` :
          `https://travaurabackend.azurewebsites.net/api/user/${id}`
        )
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const data = await response.json();
        
        setUserData(data);
        setItenary(data.selectedActivities || []);
          setTravellerDetails(data.travellerDetails || {});
        setNumberOfDays(data.travellerDetails.duration || 0);
        setCountry(data.travellerDetails.country || "");
        setSelectedHotel(data.selectedHotels || []);
        setSelectedAddons(data.selectedAddons[0] || {});
        setDaywiseTransfers(data.selectedTransfers || []);
        setAdults(data.travellerDetails.adults || 0);
        setChild(data.travellerDetails.child || 0);
        setInfants(data.travellerDetails.infants || 0);
        setSelectedBaliIntercityTransfers(data.selectedBaliICTransfers || []);
        setCheckedBaliIntercityTransfers(data.selectedBaliICTransfers || []);
        setSelectedTrainData(data.selectedTrains || []);
        setSelectedBusData(data.selectedBuses || []);
        setCountry(data.travellerDetails.country || "");
        setCambodiaWhen(data.CambodiaWhen || "");
        setSelectedCambodiaPackage(data.selectedCambodiaPackage || []);
        setInternationalFlights(data.selectedInternationalFlights || {});
        setSelectedFlightData(data.selectedDomesticFlights || {});
        setSelectedInternationalFlights(data.BookingSelectedInternationalFlights || {});
        setSelectedDomesticFlights(data.BookingSelectedDomesticFlights || {});
        setCambodiaAccomodationType(data.CambodiaAccomodationType || "");
       setSelectedCambodiaHotels(data.selectedCambodiaHotels || []);
        
        console.log("userData: ", data);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        // Handle the error appropriately
      }
    };
    fetchUserData();

    console.log("userData: ", userData);
  }, []);

  

  useEffect(() => {
    let totalNumberofThingsSelected = 0;
    totalNumberofThingsSelected += itenary ? itenary.length : 0;
    totalNumberofThingsSelected += selectedHotel ? selectedHotel.length : 0;
    totalNumberofThingsSelected += daywiseTransfers ? daywiseTransfers.length : 0;
    totalNumberofThingsSelected += selectedBaliIntercityTransfers ? selectedBaliIntercityTransfers.length : 0;
    totalNumberofThingsSelected += selectedTrainData ? selectedTrainData.length : 0;
    totalNumberofThingsSelected += selectedBusData ? selectedBusData.length : 0;
    totalNumberofThingsSelected += selectedInternationalFlights ? selectedInternationalFlights.length : 0;
    totalNumberofThingsSelected += selectedDomesticFlights ? selectedDomesticFlights.length : 0;
    totalNumberofThingsSelected += selectedFlightData ? selectedFlightData.length : 0;
   
    setTotalNumberofThingsSelected(totalNumberofThingsSelected);
    console.log("totalNumberofThingsSelected: ", totalNumberofThingsSelected);
    console.log("itenary: ", itenary && itenary.length);
    console.log("selectedHotel: ", selectedHotel && selectedHotel.length);
    console.log("daywiseTransfers: ", daywiseTransfers && daywiseTransfers.length);
    console.log("selectedBaliIntercityTransfers: ", selectedBaliIntercityTransfers && selectedBaliIntercityTransfers.length);
    console.log("selectedTrainData: ", selectedTrainData && selectedTrainData.length);
    console.log("selectedBusData: ", selectedBusData && selectedBusData.length);
    console.log("selectedInternationalFlights: ", selectedInternationalFlights && selectedInternationalFlights.length);
    console.log("selectedDomesticFlights: ", selectedDomesticFlights.length);
    console.log("selectedFlightData: ", selectedFlightData.length);
    console.log("selectedAddons: ", Object.keys(selectedAddons).length);

  }, [itenary, selectedHotel, daywiseTransfers, selectedBaliIntercityTransfers, selectedTrainData, selectedBusData, selectedInternationalFlights, selectedDomesticFlights, selectedFlightData, selectedAddons]);

  
 

  useEffect(() => {
    if (country === "Vietnam" || country === "Vietnam/Cambodia") {
      fetch("https://travaurabackend.azurewebsites.net/api/data")
        .then((response) => response.json())
        .then((data) => setActivityData(data));
    } else if (country === "Bali") {
      fetch("https://travaurabackend.azurewebsites.net/api/BaliData")
        .then((response) => response.json())
        .then((data) => setActivityData(data));
    }
    else if (country === "Thailand") {
      fetch("https://travaurabackend.azurewebsites.net/api/ThailandActivitiesSchema")
        .then((response) => response.json())
        .then((data) => setActivityData(data));
    }
  }, [country]);

  useEffect(() => {
    if(country === "Vietnam" || country === "Vietnam/Cambodia"){
    fetch("https://travaurabackend.azurewebsites.net/api/transfer")
      .then((response) => response.json())
      .then((data) => {
        setTransferData(data);
        console.log("transferData: ", data);
      });
    }
    else if(country === "Bali"){
      fetch("https://travaurabackend.azurewebsites.net/api/BaliTransfer")
      .then((response) => response.json())
      .then((data) => {
        setTransferData(data);
        console.log("transferData: ", data);
      }
      );
    }
      else if(country === "Thailand"){
        fetch("https://travaurabackend.azurewebsites.net/api/ThailandAirportTransfersSchema")
        .then((response) => response.json())
        .then((data) => {
          setTransferData(data);
          console.log("transferData: ", data);
        }
        );

    }
  }, [country]);

  useEffect(() => {
    if(country==="Bali"){
    fetch(
      "https://travaurabackend.azurewebsites.net/api/baliIntercityTransfersSchema"
    )
      .then((response) => response.json())
      .then((data) => setInterCityTransferData(data));
    }
    else if(country==="Thailand"){
      fetch("https://travaurabackend.azurewebsites.net/api/ThailandIntercityTransfersSchema")
      .then((response) => response.json())
      .then((data) => setInterCityTransferData(data));
    }
  }, [country]);


useEffect(() => {
  setClickedButtons(itenary.map((item) => {return {itemId: item.Activities._id, day: item.day}}));
  console.log("clickedButtons: ", clickedButtons);

}
, [itenary])

useEffect(() => {
  if(country==="Vietnam" || country==="Vietnam/Cambodia"){
    fetch("https://travaurabackend.azurewebsites.net/api/hotel")
    .then((response) => response.json())
    .then((data) => setHotelData(data));
  }
  else if(country==="Bali"){
    fetch("https://travaurabackend.azurewebsites.net/api/BaliHotels")
    .then((response) => response.json())
    .then((data) => setHotelData(data));
    
  }

  else if(country==="Thailand"){
    fetch("https://travaurabackend.azurewebsites.net/api/ThailandHotelsSchema")
    .then((response) => response.json())
    .then((data) => setHotelData(data));
  }
}, [country]);

  useEffect(() => {
    let breakfastprice = 0;
    let lunchprice = 0;
    let dinnerprice = 0;
    let earlycheckinprice = 0;
    let latecheckoutprice = 0;
    let insuranceprice = 0;
    let cancellationprice = 0;
    let visaprice = 0;

    if (addonBreakfast) {
      breakfastprice = addonBreakfast * 700 * parseInt(numberOfPeople);
    }
    if (addonLunch) {
      lunchprice = addonLunch * 700 * parseInt(numberOfPeople);
    }
    if (addonDinner) {
      dinnerprice = addonDinner * 700 * parseInt(numberOfPeople);
    }
    if (EarlyCheckIn) {
      earlycheckinprice = 1600;
    }
    if (LateCheckOut) {
      latecheckoutprice = 1600;
    }
    if (TATAAIGInsurance) {
      insuranceprice =
        (parseInt(adults) + parseInt(child) + parseInt(infants)) * 800;
      console.log(insuranceprice);
    }
    if (Cancellation) {
      cancellationprice = 1600;
    }
    if (visa === "Included") {
      visaprice =
        (parseInt(adults) + parseInt(child) + parseInt(infants)) * 2800;
      console.log(parseInt(adults) + parseInt(child) + parseInt(infants));
      console.log("Visa price: ", visaprice);
    }

    setAddonPrice(
      visaprice +
        breakfastprice +
        lunchprice +
        dinnerprice +
        earlycheckinprice +
        latecheckoutprice +
        insuranceprice +
        cancellationprice
    );
  }, [
    visa,
    addonBreakfast,
    addonLunch,
    addonDinner,
    EarlyCheckIn,
    LateCheckOut,
    TATAAIGInsurance,
    Cancellation,
    numberOfPeople,
    infants,
  ]);

  useEffect(() => {
    setAddonBreakfast(selectedAddons && selectedAddons.addonBreakfast && selectedAddons.addonBreakfast);
  setAddonLunch(selectedAddons && selectedAddons.addonLunch && selectedAddons.addonLunch);
    setAddonDinner(selectedAddons && selectedAddons.addonDinner && selectedAddons.addonDinner);
    setEarlyCheckIn(selectedAddons && selectedAddons.earlyCheckIn && selectedAddons.earlyCheckIn);
    setLateCheckOut(selectedAddons && selectedAddons.lateCheckOut && selectedAddons.lateCheckOut);
    setTATAAIGInsurance(selectedAddons && selectedAddons.TATAAIGInsurance && selectedAddons.TATAAIGInsurance);
    setCancellation(selectedAddons && selectedAddons.Cancellation && selectedAddons.Cancellation);
    setVisa(selectedAddons && selectedAddons.visa && selectedAddons.visa);
    setPeakSeasonMarkup(selectedAddons && selectedAddons.peakSeasonMarkup && selectedAddons.peakSeasonMarkup);
    setValidTillDate(selectedAddons && selectedAddons.validTillDate && selectedAddons.validTillDate);
    console.log("selectedAddons: ", selectedAddons);  
    console.log("addonBreakfast: ", addonBreakfast);
  }
  , [selectedAddons, userData]);

  return (
    <div class="bg-primary">
      <Navbar />
      <body class="bg-primary text-white font-sans">
        <div class="container mx-auto p-4 ">
          {/* <div className="flex justify-center items-center my-5">
            <img src={Logo} alt="" className="h-24 w-24 mb-3 " />

            <header className="text-2xl font-bold text-white font-caveat">
              {" "}
              Build the Perfect Getaway
            </header>
          </div> */}
          

          <div className="flex justify-center items-center my-5">
          <label className="text-[#5E827E] font-medium font-montserrat text-6xl my-8">
          <LabelAnimation text={` ${travellerDetails.name !== undefined ? travellerDetails.name : "User"}'s Itinerary in progress...`} />
            </label>
            </div>
          <div class="mb-6">
           
            <div className="flex space-x-2 mb-2 z-50 sticky top-4">
              {daysToRender.map((dayNumber, ind) => (
                <div
                  key={ind}
                  className={`hover:scale-105 px-4 py-2 rounded cursor-pointer ${
                    activeDay === ind
                      ? "bg-blue-500 text-white"
                      : "bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121]"
                  }`}
                  onClick={() => {
                    setActiveDay(ind);
                  }}
                >
                  Day {dayNumber}
                </div>
              ))}
            </div>

            <div className="space-x-4 my-2" ref={ref}>
              {daysToRender.map((dayNumber, ind) => (
                <div key={ind} ref={dayRefs.current[ind]}>
                  {activeDay === ind && (
                    <DaywiseItenerary
                      day={dayNumber}
                      selectedCountryData={selectedCountryData}
                      globalCityStructure={globalCityStructure}
                      hotelData= {hotelData}
                      
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bg-[#212121] p-8 rounded-md flex justify-center mx-10">
          <div className="">
            <label className="block text-gray-400 text-2xl font-medium mb-2">
              Added Inclusions
            </label>

            <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mb-4">
              <label className="bg-secondary flex items-center space-x-2 mb-2 mx-4 rounded-lg p-4">
                <input
                  type="checkbox"
                  checked={EarlyCheckIn}
                  onChange={() => setEarlyCheckIn(!EarlyCheckIn)}
                  className="text-blue-400 rounded"
                />
                <span className="text-gray-400 text-sm font-medium">
                  Early Check In
                </span>
              </label>

              <label className="bg-secondary flex items-center space-x-2 mb-2 mx-4 rounded-lg p-4">
                <input
                  type="checkbox"
                  checked={LateCheckOut}
                  onChange={() => setLateCheckOut(!LateCheckOut)}
                  className="text-blue-400 rounded"
                />
                <span className="text-gray-400 text-sm font-medium">
                  Late Check Out
                </span>
              </label>

              <label className="bg-secondary flex justify-between items-center space-x-2 mb-2 mx-4 rounded-lg p-4">
                <span className="text-gray-400 text-sm font-medium">
                  Breakfast
                </span>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  min={0}
                  value={addonBreakfast}
                  onChange={(e) => setAddonBreakfast(e.target.value)}
                  className="  rounded-md focus:outline-1   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-1/2"  
        style={{ boxShadow: '1px 1px 3px 0px black' }}
                />
              </label>

              <label className="bg-secondary flex justify-between items-center space-x-2 mb-2 mx-4 rounded-lg p-4">
                <span className="text-gray-400 text-sm font-medium">Lunch</span>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  min={0}
                  value={addonLunch}
                  onChange={(e) => setAddonLunch(e.target.value)}
                  className="   rounded-md focus:outline-1   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-1/2"  
        style={{ boxShadow: '1px 1px 3px 0px black' }} 
                />
              </label>

              <label className="bg-secondary flex justify-between items-center space-x-2 mb-2 mx-4 rounded-lg p-4">
                <span className="text-gray-400 text-sm font-medium">
                  Dinner
                </span>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  min={0}
                  value={addonDinner}
                  onChange={(e) => setAddonDinner(e.target.value)}
                  className="   rounded-md focus:outline-1   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-1/2"  
                  style={{ boxShadow: '1px 1px 3px 0px black' }} 
                />
              </label>

              <label className="bg-secondary flex items-center space-x-2 mb-2 mx-4 rounded-lg p-4">
                <input
                  type="checkbox"
                  checked={TATAAIGInsurance}
                  onChange={() => setTATAAIGInsurance(!TATAAIGInsurance)}
                  className="text-blue-400 rounded"
                />
                <span className="text-gray-400 text-sm font-medium">
                  TATA AIG Insurance
                </span>
              </label>

              <label className="bg-secondary flex items-center space-x-2 mb-2 mx-4 rounded-lg p-4">
                <input
                  type="checkbox"
                  checked={Cancellation}
                  onChange={() => setCancellation(!Cancellation)}
                  className="text-blue-400 rounded"
                />
                <span className="text-gray-400 text-sm font-medium">
                  Cancellation and Rescheduling Cover
                </span>
              </label>

              <select
                value={visa}
                onChange={(e) => {
                  setVisa(e.target.value);
                }}
                className="  mb-4   rounded-md focus:outline-1   bg-secondary placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-full"  
        style={{ boxShadow: '1px 1px 3px 0px black' }}
              >
                <option value="">Select Visa</option>
                <option value="Included">Included</option>
                <option value="Excluded">Excluded</option>
                <option value="Visa on Arrival">Visa on Arrival</option>
                <option value="Visa Free Destination">
                  Visa Free Destination
                </option>
                <option value="Not Required in this Itinerary">
                  Not Required in this Itinerary
                </option>
              </select>
              <label className="bg-secondary flex items-center space-x-2 mb-2 mx-4 rounded-lg p-4">
                <span className="text-gray-400 text-sm font-medium">
                  Peak Season Markup in %
                </span>
                <input
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  min={0}
                  value={peakSeasonMarkup}
                  onChange={(e) => setPeakSeasonMarkup(e.target.value)}
                  className="  p-2   rounded-md focus:outline-1   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white  w-1/2"  
        style={{ boxShadow: '1px 1px 3px 0px black' }}
                />
              </label>

              <label className="bg-secondary flex items-center space-x-2 mb-2 mx-4 rounded-lg p-4">
                <span className="text-gray-400 text-sm font-medium">
                  Offer Valid Till
                </span>
                <input
                  type="date"
                  value={validTillDate}
                  onChange={(e) => setValidTillDate(e.target.value)}
                  className="  p-2   rounded-md focus:outline-1   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-full"  
        style={{ boxShadow: '1px 1px 3px 0px black' }}
                />
              </label>
            </div>
          </div>
        </div>
      </body>
      <div className="flex justify-around my-5 ">
        <button
          onClick={handleGetItenerary}
          className="py-2 px-4 bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121] rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex justify-between"
        >
          Select Flights
        </button>
{/*         
        <button
          className="py-2 px-4 bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121]  rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex justify-between "
          onClick={() => {country==="Vietnam/Cambodia" ? navigate(`/flightsSearchResults/${id}/Vietnam/${duration}`) :
            navigate(`/flightsSearchResults/${id}/${country}/${duration}`)}}
        >
          Get Itinerary Directly
        </button> */}
        <button className="py-2 px-4 bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121]  rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex justify-between"
          onClick={() => navigate(`/AirportTransfers/${id}/${country}/${duration}`)} >
          Select Airport Transfers
        </button>
      </div>
    </div>
  );
};

export default IteneraryBuilder;
