import React, { useEffect } from 'react'
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CambodiaActivityCard = ({item, Day, hotel, city, id, Category, setDeleteCounter}) => {
    const [hotelToDisplay, setHotelToDisplay] = useState({});
    const [expandedStates, setExpandedStates] = useState([false]);
    const handleExpandClick = () => {
      setExpandedStates(!expandedStates);
    }
    
        const navigate = useNavigate();

        useEffect(() => {
            console.log("Day: ", Day);
            console.log("City: ", city);
            console.log("id: ", id);
            console.log("Category: ", Category);
            console.log("hotelToDisplay: ", hotelToDisplay)
        }, [hotelToDisplay])

const handleHotelNavigation = () => {
    // /addCambodiaHotels/:city/:id/:day/:Category

    navigate(`/addCambodiaHotels/${city}/${id}/${Day}/${Category}`);
}

    useEffect(() => {
        if(hotel){
            hotel.find((item) => {
                if(item.day === Day){
                    setHotelToDisplay(item)
                }
            }
            )
        }
    }
    , [hotel])

    const handleRemoveHotel = () => {

        axios.delete(`https://travaurabackend.azurewebsites.net/${id}/${Day}`)
        .then((res) => {
            console.log("res: ", res);
            setDeleteCounter(prevCount => prevCount + 1);
            setHotelToDisplay({});
            
        }
        )
        .catch((err) => {
            console.log("Error deleting Cambodia hotel: ", err);
        })
    }

  return (
    <div className="my-3 rounded-lg   text-white ">
      <div className="font-semibold  text-START md:text-left flex justify-between md:w-4/6 mb-4">
        <p>Day {Day}</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-11 gap-4">
        {/* Left section - Activities */}
        <div className="md:col-span-8 space-y-2 bg-gray-800 p-4 rounded-lg">
          <div className="flex justify-between">
            <p className="text-gray-400 font-bold"> Activities</p>
            <div className="flex justify-end">
              {/* <ChangeCircleOutlinedIcon
                className="text-2xl text-gray-400 mx-3  cursor-pointer print-proof"
                // onClick={handleNavigation}
              />
              <HighlightOffIcon
                className="text-2xl text-gray-400 cursor-pointer print-proof"
                // onClick={handleRemoveActivity}
              /> */}
            </div>
          </div>
          {item.Title ? (
              <div
                // key={ind}
                className="  md:flex flex-col items-center  md:items-start "
              >
                <div className="flex flex-row  items-center w-full">
                  <img
                    src={
                      item.FeaturedImage.split(", ")[0] ||
                      ""
                    }
                    alt=""
                    className="w-32 h-32  rounded-lg mb-5 mt-2 md:my-0 "
                  />
                  <div className="flex flex-col items-start text-start md:text-start pl-4">
                    <div className="font-bold text-sm md:text-xl">
                      {item.Title}
                    </div>
                    <div className="text-xs text-gray-400">
                      {item.Stay}
                    </div>
                    <button
                      className="text-blue-500 text-xs py-2 rounded-md mt-2 print-proof w-fit"
                      onClick={() => handleExpandClick()}
                    >
                      {expandedStates ? "Show Less" : "Read Description"}
                    </button>
                  </div>
                </div>
                <div>
                  {expandedStates && (
                    <div className="bg-gray-700 p-4 rounded-md mt-6 ">
                      
                      {/* <p className="text-lg text-gray-400">Description: </p> */}
                      {item.LongDescription.split("\n").map(
                        (line, index) => (
                          <span
                            className="text-gray-300 text-xs md:text-md"
                            key={index}
                          >
                            {line}
                            <br />
                          </span>
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            
          ) : (
            <div className="md:flex flex-col items-center  md:items-start">
              <div className="flex flex-row  items-center w-full">
                <img
                  src="https://res.cloudinary.com/dxcq5zluj/image/upload/v1705046847/ixxwg9jvwgdkzmkrgwhy.jpg"
                  alt=""
                  className="w-32 h-32 rounded-lg my-5 md:my-0"
                />
                {/* <div className="text-left mr-4 text-white ">
                No Activity Selected for the Day.
              </div> */}
                <div className="flex flex-col items-start text-start md:text-start pl-4">
                  <div className="font-bold text-sm md:text-xl">
                    No Activity Selected for the Day.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="md:col-span-3 flex flex-col justify-start space-y-4 bg-gray-800 md:px-2 rounded-lg p-4">
          <div className="flex justify-between ">
            <p className="text-gray-400 font-bold">Accommodation</p>
            <div className="font-bold text-center flex justify-between px-3">
              <ChangeCircleOutlinedIcon
                className="text-2xl text-gray-400 mx-3 md:mx-1 cursor-pointer print-proof"
                onClick={handleHotelNavigation}
              />
              <HighlightOffIcon
                className="text-2xl text-gray-400 cursor-pointer print-proof"
                onClick={handleRemoveHotel}
              />
            </div>
          </div>

          {hotelToDisplay && (
            
              <div className="text-left ">
                <div>{hotelToDisplay && hotelToDisplay.hotel && hotelToDisplay.hotel.Name}</div>
                {/* <div>{hotelToDisplay && hotelToDisplay.hotel && hotelToDisplay.hotel.RoomType}</div> */}
                <div className="text-gray-400 text-sm">
                    {hotelToDisplay && hotelToDisplay.hotel && hotelToDisplay.hotel.Category}
                </div>
              </div>
          )}
           
        </div>
      </div>
    </div>
  )
}

export default CambodiaActivityCard
