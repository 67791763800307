import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";

const ManageFlightVouchers = () => {
  const navigate = useNavigate();
  const [flightVouchers, setFlightVouchers] = useState([]);
  const [filteredFlightVouchers, setFilteredFlightVouchers] = useState([]);
  const [viewPDF, setViewPDF] = useState(false);
  const [activePDFLink, setActivePDFLink] = useState("");
  const [searchPrompt, setSearchPrompt] = useState("");

  useEffect(() => {
    try {
      fetch("https://travaurabackend.azurewebsites.net/api/getFlightVoucher")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setFlightVouchers(data);
          console.log("flightVouchers: ", data);
        })
        .catch((error) => {
          console.error("Failed to fetch flight vouchers:", error);
        });
    } catch (error) {
      console.error("Failed to fetch flight vouchers:", error);
    }
  }, []);

  useEffect(() => {
    if(searchPrompt){
      if(flightVouchers.length > 0){
        setFilteredFlightVouchers(flightVouchers.filter((voucher) => {
          return (voucher.name && voucher.name.toLowerCase().includes(searchPrompt.toLowerCase())) || (voucher.bookingID && voucher.bookingID.toLowerCase().includes(searchPrompt.toLowerCase())) || (voucher.agentEmail && voucher.agentEmail.toLowerCase().includes(searchPrompt.toLowerCase()));
          
        }));

    }
  }
  else{
    setFilteredFlightVouchers(flightVouchers);
  }
  }, [searchPrompt, flightVouchers]);
  return (
    <div>
      <Navbar />
      {viewPDF && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={() => setViewPDF(false)}
        >
          <div
            className="bg-white p-4"
            onClick={(e) => e.stopPropagation()}
            style={{ width: "80%", maxHeight: "90vh", overflow: "auto" }}
          >
            <button
              className="bg-yellow-500 p-2 m-2"
              onClick={() => setViewPDF(false)}
            >
              Close
            </button>
            <iframe
              className="w-full"
              src={activePDFLink}
              title="Voucher"
              style={{ height: "80vh" }} // Adjust the height based on viewport height
            ></iframe>
          </div>
        </div>
      )}

      <div className="container mx-auto text-white">
        <h1 className="text-3xl font-bold text-center my-4">
          Manage Flight Vouchers
        </h1>
        <div className="flex justify-between">
          <div className="flex justify-center">
            <button
              className="bg-yellow-500 p-2 m-2"
              onClick={() => {
                navigate("/Upload Flight Voucher");
              }}
            >
              Add New Voucher
            </button>
          </div>

          <input
            type="text"
            className=" p-2 my-4 placeholder:text-gray-300 text-white bg-secondary rounded-xl"
            placeholder="Search"
            onChange={(e) => setSearchPrompt(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-1">Name</div>
          <div className="col-span-1">Booking ID</div>
          <div class="col-span-1">Uploaded By</div>

          <div className="col-span-1">Voucher</div>

          <div class="col-span-4">
            <hr class="mx-5" />
          </div>
          {filteredFlightVouchers.map((voucher) => {
            return (
              <React.Fragment key={voucher._id}>
                <div className="col-span-1">{voucher.name}</div>
                <div className="col-span-1">{voucher.bookingID}</div>
                <div className="col-span-1">{voucher.agentEmail}</div>

                <div className="col-span-1">
                  <button
                    className="bg-yellow-500 p-2 rounded-xl"
                    onClick={() => {
                      setViewPDF(true);
                      setActivePDFLink(voucher.VoucherLink);
                    }}
                  >
                    View
                  </button>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ManageFlightVouchers;
