import React, { useEffect } from "react";
import { useState } from "react";
import LogoutButton from "./Google Auth/logout";
import { useContext } from "react";
import { AuthContext } from "./contexts/AuthProvider";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AdminAccess from "./AdminAccess";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { TiDelete } from "react-icons/ti";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { MdSupportAgent } from "react-icons/md";
import { IoFilterSharp } from "react-icons/io5";
import { GrPowerReset } from "react-icons/gr";
import Example from "./BarGraph";
import logo from "./assets/images/Pro_logo.png";
import { ScrambledAnimation } from "react-scrambled-text";
import ToggleButton from "./Hamburger Menu/ToggleButton";
import Sidebar from "./Hamburger Menu/Sidebar";
import Navbar from "./Navbar";
import LabelAnimation from "./Animations/Text_Entry";

const AgentItinerariesSection = () => {
  const Navigate = useNavigate();
  const [agentUID, setAgentUID] = useState("");
  const { logout, currentUser } = useContext(AuthContext);
  const [totalTravellers, setTotalTravellers] = useState(0);
  const [searchPrompt, setSearchPrompt] = useState("");
  const [filteredItineraries, setFilteredItineraries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [isAdmin, setIsAdmin] = useState(false);
  const [totalItineraries, setTotalItineraries] = useState(0);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [filterfromDate, setFilterfromDate] = useState("");
  const [filtertoDate, setFiltertoDate] = useState("");
  const [filterCreatedfromDate, setFilterCreatedfromDate] = useState("");
  const [filterCreatedtoDate, setFilterCreatedtoDate] = useState("");
  const [allItineraries, setAllItineraries] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [filterTodayWeekMonth, setFilterTodayWeekMonth] = useState("");
  const [filterCountry, setFilterCountry] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [ItinerariesFrequencyWeekWise, setItinerariesFrequencyWeekWise] =
    useState([]);
  const [ItinerariesFrequencyMonthWise, setItinerariesFrequencyMonthWise] =
    useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const SuperAdmins = [
    "ketansaluja@travaura.com",
    "muskaanarya@travaura.com",
    "ayushjha@travaura.com",
    "duchoang221296@gmail.com"
  ];
  const Admins = ["hrithiksindhwani@travaura.com"];

  useEffect(() => {
    if (currentUser && currentUser.email) {
      setIsSuperAdmin(SuperAdmins.includes(currentUser.email));
    }
    if (currentUser && currentUser.email) {
      setIsAdmin(Admins.includes(currentUser.email));
    }
  }, [currentUser]);

  const countNumberOfTravellers = (item) => {
    let totalTravellers = 0;
    if (item.adults) {
      totalTravellers += item.adults;
    }
    if (item.children) {
      totalTravellers += item.children;
    }
    if (item.infants) {
      totalTravellers += item.infants;
    }
    return totalTravellers;
  };

  useEffect(() => {
    //remove customId from session storage

    sessionStorage.removeItem("customId");
  }, []);

  const [itineraries, setItineraries] = useState([]);
  const formatDate = (date) => {
    const d = new Date(date);
    const month = d.toLocaleString("default", { month: "long" });
    const day = d.getDate();
    const year = d.getFullYear();
    return `${day} ${month} ${year}`;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams({
          agentUID: agentUID,
          email: currentUser.email,
          isAdmin: isSuperAdmin,
        }).toString();

        const response = await fetch(
          `https://travaurabackend.azurewebsites.net/itineraries?${queryParams}`
        );
        if (response.ok) {
          const data = await response.json();
          setItineraries(data);
        } else {
          throw new Error("Network response was not ok.");
        }
      } catch (error) {
        console.error("Error during fetch:", error.message);
      }
    };

    if (agentUID && currentUser && currentUser.email && isAdmin !== undefined) {
      fetchData();
    }
  }, [agentUID, isAdmin, currentUser]);

  useEffect(() => {
    console.log("itineraries: ", itineraries);
    console.log("selectedEmail: ", selectedEmail);
    console.log("filterDayWeekMonth: ", filterTodayWeekMonth);
  }, [itineraries, selectedEmail, filterTodayWeekMonth]);

  useEffect(() => {
    let tempItineraries = [];
    itineraries.forEach((item) => {
      if (item.itineraries)
        tempItineraries = [...tempItineraries, ...item.itineraries];
    });
    setAllItineraries(tempItineraries);
  }, [itineraries]);

  useEffect(() => {
    const agentUID = localStorage.getItem("userUID") || "";
    if (agentUID === "") {
      alert("Agent ID not found!! Please login again");

      setTimeout(() => {
        (async () => {
          await logout();
        })();
      }, 3000);
      return;
    }

    setAgentUID(agentUID);
  }, []);

  const [, forceUpdate] = useState();

  // Call this function to force a re-render
  const triggerReRender = () => {
    forceUpdate({});
  };

  // Call triggerReRender after sorting

  useEffect(() => {
    let tempItineraries = !isSuperAdmin ? itineraries : [];
    if (isSuperAdmin) {
      if (!selectedEmail || selectedEmail.length === 0) {
        tempItineraries = allItineraries;
      } else {
        // tempItineraries = itineraries.find(
        //   (item) => item._id === selectedEmail
        // )?.itineraries;
        itineraries
          .filter((item) => selectedEmail.includes(item._id))
          .map((item) => {
            tempItineraries = [...tempItineraries, ...item.itineraries];
          });
        console.log("tempItineraries: ", tempItineraries);
        console.log("allItineraries: ", allItineraries);
      }
    }

    if (searchPrompt) {
      tempItineraries = tempItineraries.filter(
        (item) =>
          (item.name &&
            item.name.toLowerCase().includes(searchPrompt.toLowerCase())) ||
          (item._id &&
            item._id.toLowerCase().includes(searchPrompt.toLowerCase()))
      );
    }
    if (filterfromDate && filtertoDate) {
      tempItineraries = tempItineraries.filter(
        (item) =>
          item.TravelDate &&
          new Date(item.TravelDate) >= filterfromDate &&
          new Date(item.TravelDate) <= filtertoDate
      );
    }
    if (filterCreatedfromDate && filterCreatedtoDate) {
      tempItineraries = tempItineraries.filter(
        (item) =>
          item.createdAt &&
          new Date(item.createdAt) >= filterCreatedfromDate &&
          new Date(item.createdAt) <= filterCreatedtoDate
      );
    }
    if (sortBy === "createdAt") {
      tempItineraries.sort((a, b) => {
        if (!a.createdAt || !b.createdAt) return 0;
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    } else if (sortBy === "TravelDate") {
      tempItineraries.sort((a, b) => {
        if (!a.TravelDate || !b.TravelDate) return 0;
        return new Date(a.TravelDate) - new Date(b.TravelDate);
      });
    } else if (sortBy === "TravellerCount") {
      tempItineraries.sort((a, b) => {
        return countNumberOfTravellers(b) - countNumberOfTravellers(a);
      });
    }
    if (filterTodayWeekMonth === "today") {
      tempItineraries = tempItineraries.filter(
        (item) =>
          item.createdAt &&
          new Date(item.createdAt).toDateString() === new Date().toDateString()
      );
    }
    if (filterTodayWeekMonth === "week") {
      const weekAgo = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
      tempItineraries = tempItineraries.filter(
        (item) =>
          item.createdAt &&
          new Date(item.createdAt) >= weekAgo &&
          new Date(item.createdAt) <= new Date() // Ensure the itinerary was created within the last week
      );
    }
    if (filterTodayWeekMonth === "month") {
      const monthAgo = new Date(
        new Date().getTime() - 30 * 24 * 60 * 60 * 1000
      );
      tempItineraries = tempItineraries.filter(
        (item) =>
          item.createdAt &&
          new Date(item.createdAt) >= monthAgo &&
          new Date(item.createdAt) <= new Date() // Ensure the itinerary was created within the last month
      );
    }

    if (filterCountry) {
      tempItineraries = tempItineraries.filter(
        (item) =>
          item.country &&
          item.country.toLowerCase().includes(filterCountry.toLowerCase())
      );
    }
    setFilteredItineraries(tempItineraries);
    console.log("filteredItineraries: ", filteredItineraries);
    triggerReRender();
  }, [
    sortBy,
    searchPrompt,
    itineraries,
    isSuperAdmin,
    selectedEmail,
    filterfromDate,
    filtertoDate,
    filterCreatedfromDate,
    filterCreatedtoDate,
    allItineraries,
    filterTodayWeekMonth,
    filterCountry,
  ]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItineraries =
    filteredItineraries &&
    filteredItineraries.slice(indexOfFirstItem, indexOfLastItem);

  // Update the page count
  const pageCount = Math.ceil(
    filteredItineraries && filteredItineraries.length / itemsPerPage
  );

  // Handle change page
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    let tempTotal = 0;

    if (isSuperAdmin) {
      itineraries.forEach((item) => {
        // Using optional chaining to avoid trying to read 'length' of undefined
        tempTotal += item.itineraries?.length || 0;
      });
    } else {
      // Ensure 'itineraries' itself is defined before accessing its 'length'
      tempTotal += itineraries?.length || 0;
    }

    setTotalItineraries(tempTotal);
  }, [itineraries, isSuperAdmin]); // Including 'isAdmin' in dependencies if its change should also trigger the effect

  useEffect(() => {
    console.log("sortBy: ", sortBy);
  }, [sortBy]);

  useEffect(() => {
    // Helper function to get abbreviated day name (3 letters)
    const getDayName = (date) => {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return days[date.getDay()];
    };

    // Initialize the last week array with abbreviated day names and counts
    let lastWeek = new Array(7)
      .fill(null)
      .map((_, index) => {
        // Use a new date instance to avoid mutating the original date
        let day = new Date();
        day.setDate(day.getDate() - index);
        return { dayName: getDayName(day), count: 0 };
      })
      .reverse();

    const today = new Date();
    // Reset today to the start of the day for accurate comparison
    today.setHours(0, 0, 0, 0);

    const lastWeekStartDate = new Date();
    lastWeekStartDate.setDate(today.getDate() - 6);
    // Reset start date to the start of the day for accurate comparison
    lastWeekStartDate.setHours(0, 0, 0, 0);

    filteredItineraries.forEach((item) => {
      if (item.createdAt) {
        const createdAtDate = new Date(item.createdAt);
        // Ensure createdAtDate is set to the start of the day for accurate comparison
        createdAtDate.setHours(0, 0, 0, 0);

        if (createdAtDate >= lastWeekStartDate && createdAtDate <= today) {
          const diffInDays = Math.floor(
            (createdAtDate - lastWeekStartDate) / (1000 * 60 * 60 * 24)
          );
          lastWeek[diffInDays].count++;
        }
      }
    });

    console.log(
      "Itinerary counts by abbreviated day for the last week: ",
      lastWeek
    );
    setItinerariesFrequencyWeekWise(lastWeek);
  }, [filteredItineraries]);

  useEffect(() => {
    // Helper function to get month name and year (e.g., January 2023)
    const getMonthYearKey = (date) => {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      return `${month}`;
    };

    // Generate keys for the last 12 months with full month names
    const generateLast12MonthsKeys = () => {
      const keys = [];
      const today = new Date();
      for (let i = 11; i >= 0; i--) {
        const d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        keys.push(getMonthYearKey(d));
      }
      return keys;
    };

    const last12MonthsKeys = generateLast12MonthsKeys();
    const last12MonthsData = last12MonthsKeys.map((key) => ({
      dayName: key,
      count: 0,
    }));

    filteredItineraries.forEach((item) => {
      if (item.createdAt) {
        const createdAtKey = getMonthYearKey(new Date(item.createdAt));
        const dataIndex = last12MonthsKeys.indexOf(createdAtKey);
        if (dataIndex !== -1) {
          last12MonthsData[dataIndex].count++;
        }
      }
    });

    console.log(
      "Itinerary counts by month for the last 12 months: ",
      last12MonthsData
    );
    setItinerariesFrequencyMonthWise(last12MonthsData);
  }, [filteredItineraries]);

  return (
    <div>
      <Navbar />
      <div className="flex w-full justify-center ">
        <label className="text-[#5E827E] font-medium font-montserrat text-6xl my-8">
          <LabelAnimation text="Your own workspace." />
        </label>
      </div>
      <div className="p-4">
        {/* <div className="flex justify-between">
            <Sidebar />
          <div className="flex">
            <img
              src={logo}
              alt="Travaura Logo"
              className="h-12 flex-shrink-0 pl-7"
            />
            <div>
              <p className="text-white font-LeagueSpartan font-bold ml-2 mt-2 text-4xl">
                {" "}
                PRO ITINERARY
              </p>
              <p className="text-[16px] ml-2 font-LeagueSpartan text-white">
                Crafted by Travaura
              </p>
            </div>
          </div>
          <button
            className="bg-blue-500 text-white p-2 rounded-lg  h-fit w-fit"
            onClick={logout}
          >
            Logout
          </button>
        </div> */}

        {/* <div className=" bg-gray-700 p-6 rounded-xl my-5">
          <div className="flex justify-between min-w-full">
            <p className="text-white text-2xl font-medium">Overview</p>
            
          </div>
          <div className="flex justify-around">
            <div className="bg-gray-700 p-4 rounded-lg ">
              <p className="text-white text-2xl font-medium">
                Total Itineraries: {totalItineraries}
              </p>
              <p className="text-white text-2xl font-medium"></p>
            </div>
            
            <div className="flex items-center justify-cente">
              <button
                className="bg-blue-500 text-white p-2 rounded-lg  h-fit w-fit"
                onClick={() => Navigate("/")}
              >
                Create New Itinerary
              </button>
            </div>
          </div>
        </div> */}
        {/* <div className="flex min-w-full justify-around"> */}
        {/* {isAdmin || isSuperAdmin ? (
            <div className="bg-secondary w-1/2 m-3  rounded-xl text-white  p-5 font-sans flex flex-col items-center">
              
              <button
                className="bg-blue-500 text-white p-2 rounded-lg m-4 h-fit w-fit"
                onClick={() => Navigate("/HotelManagement")}
              >
                Hotel Management
              </button>
              <button
                className="bg-blue-500 text-white p-2 rounded-lg  h-fit w-fit"
                onClick={() => Navigate("/ActivityManagement")}
              >
                Activity Management
              </button>
              <button 
              className="bg-blue-500 text-white p-2 rounded-lg m-4  h-fit w-fit"
                onClick={() => Navigate("/CreatePackage")}
                >
                    Create A Package
                </button>
            </div>
          ) : (
            <div className="bg-secondary w-1/2 m-3 text-5xl font-caveat  rounded-xl text-white  pt-5  flex flex-col items-center">
              
               <ScrambledAnimation
                texts={["Instant Itineraries.", "Infinite Memories."]}
                speed={100}
                pauseDuration={2000}
                start={true}
                style={{ color: "white", maxWidth: "80%" }}
              />
            </div>
          )} */}

        {/* </div> */}
        <div className="flex items-center justify-start mb-6 w-full">
          <div className="flex items-center justify-start  w-1/3 mr-3">
            <Example
              ItinerariesFrequency={ItinerariesFrequencyWeekWise}
              freq="week"
            />
          </div>
          <div className="flex items-center justify-start w-2/3 ">
            <Example
              ItinerariesFrequency={ItinerariesFrequencyMonthWise}
              freq="month"
            />
          </div>
        </div>
        <div className="flex justify-between">
          {
            <div className=" rounded-xl  w-1/3 mr-3">
              <div className="flex justify-start bg-gradient-to-tr from-[#AADAD6] to-[#769895] p-3 rounded-t-xl font-montserrat font-semibold">
                <IoFilterSharp className="text-black text-2xl mr-4 pt-1   " />
                <p className="text-black text-xl   rounded-t-xl ">Filters</p>
              </div>
              <div className="bg-secondary rounded-b-xl ">
                <div className="flex items-start justify-between ">
                  <div className="p-6 lg:flex justify-between">
                    <div class="flex items-center ">
                      <input
                        id="default-radio-1"
                        type="radio"
                        name="default-radio"
                        checked={filterTodayWeekMonth === "today"}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        value="today"
                        onChange={(e) =>
                          setFilterTodayWeekMonth(e.target.value)
                        }
                      />
                      <label
                        for="default-radio-1"
                        class="ms-1 text-md  font-medium text-gray-300"
                      >
                        Today
                      </label>
                    </div>
                    <div class="flex items-center lg:mx-7">
                      <input
                        id="default-radio-2"
                        type="radio"
                        name="default-radio"
                        checked={filterTodayWeekMonth === "week"}
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        value="week"
                        onChange={(e) =>
                          setFilterTodayWeekMonth(e.target.value)
                        }
                      />
                      <label
                        for="default-radio-2"
                        class="ms-1 text-md font-medium text-gray-300"
                      >
                        This Week
                      </label>
                    </div>
                    <div class="flex items-center">
                      <input
                        id="default-radio-3"
                        type="radio"
                        checked={filterTodayWeekMonth === "month"}
                        name="default-radio"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        value="month"
                        onChange={(e) =>
                          setFilterTodayWeekMonth(e.target.value)
                        }
                      />
                      <label
                        for="default-radio-3"
                        class="ms-1 text-md font-medium text-gray-300"
                      >
                        This Month
                      </label>
                    </div>
                  </div>
                  <GrPowerReset
                    className="text-white text-3xl  mr-4  pt-2 mt-2 rounded-xl p-1 "
                    onClick={() => setFilterTodayWeekMonth("")}
                    name="reset"
                  />
                </div>
                <hr className="mx-5 h-0.5 border-0 bg-gray-500" />

                {isSuperAdmin && (
                  <div className=" ">
                    <div className="flex justify-between">
                      <div className="p-3 lg:flex">
                        <label className="text-white text-md font-medium m-3">
                          Agent:
                        </label>

                        <select
                          className="  p-2  max-w-[200px] rounded-md focus:outline-none   bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  border-none focus:ring-0 "
                          style={{ boxShadow: "1px 1px 3px 0px black" }}
                          onChange={(e) =>
                            setSelectedEmail([...selectedEmail, e.target.value])
                          }
                        >
                          <option
                            className=" hover:bg-blue-300 cursor-pointer  text-white text-sm font-medium "
                            value=""
                          >
                            Select Agent
                          </option>
                          {itineraries.map((item) => (
                            <option
                              className={` hover:bg-blue-300 cursor-pointer ${
                                item._id === selectedEmail ? "bg-blue-300" : ""
                              }  text-white text-sm font-medium `}
                              value={item._id}
                            >
                              {item._id}
                            </option>
                          ))}
                        </select>
                      </div>
                      <GrPowerReset
                        className="text-white text-3xl  mr-4 pt-2 mt-2 rounded-xl p-1 "
                        onClick={() => setSelectedEmail([])}
                        name="reset"
                      />
                    </div>
                    <hr className="mx-5 h-0.5 border-0 bg-gray-500" />
                    <div className="flex flex-col  mt-2 ">
                      {selectedEmail &&
                        selectedEmail.length > 0 &&
                        selectedEmail.map((item) => (
                          <div className="flex justify-between">
                            <p className="text-white bg-secondary p-1 rounded-xl text-sm font-medium m-1 ml-5 flex">
                              <p className="mx-2">{item}</p>
                              <TiDelete
                                className="text-red-500 cursor-pointer text-xl"
                                onClick={() =>
                                  setSelectedEmail(
                                    selectedEmail.filter(
                                      (email) => email !== item
                                    )
                                  )
                                }
                              />
                            </p>
                            {/* <button
                      className="bg-red-500 text-white p-2 rounded-lg  h-fit w-fit"
                      onClick={() => setSelectedEmail(selectedEmail.filter((email) => email !== item))}
                    >
                      Remove
                    </button> */}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <hr className="mx-5 h-0.5 border-0 bg-quaternery" />

                <div className="flex justify-between w-full">
                  <div className="p-3">
                    <label className="text-white text-md font-medium m-3">
                      Travel Date
                    </label>
                    <div className="lg:flex items-center ml-3 mt-2 w-2/3">
                      <div className="">
                        <DatePicker
                          selected={filterfromDate}
                          onChange={(date) => setFilterfromDate(date)}
                          selectsStart
                          startDate={filterfromDate}
                          endDate={filtertoDate}
                          className="  p-2    rounded-md focus:outline-none   bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  border-none focus:ring-0 max-w-[100px]"
                          style={{ boxShadow: "1px 1px 3px 0px black" }}
                          placeholderText="Select date start"
                        />
                      </div>
                      <span className="mx-4 text-gray-500">to</span>
                      <div className="relative">
                        <DatePicker
                          selected={filtertoDate}
                          onChange={(date) => setFiltertoDate(date)}
                          selectsEnd
                          startDate={filterfromDate}
                          endDate={filtertoDate}
                          minDate={filterfromDate}
                          className="  p-2   rounded-md focus:outline-none   bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  border-none focus:ring-0 max-w-[100px]"
                          style={{ boxShadow: "1px 1px 3px 0px black" }}
                          placeholderText="Select date end"
                        />
                      </div>
                    </div>
                  </div>
                  <GrPowerReset
                    className="text-white text-3xl  mr-4 pt-2 mt-2 rounded-xl p-1 "
                    onClick={() => {
                      setFilterfromDate("");
                      setFiltertoDate("");
                    }}
                    name="reset"
                  />
                </div>
                <hr className="mx-5 h-0.5 border-0 bg-gray-500" />
                <div className="flex justify-between w-full">
                  <div className="p-3 w-full ">
                    <label className="text-white text-md font-medium m-3">
                      Created Date
                    </label>
                    <div className="lg:flex items-center ml-3 mt-2">
                      <div className="relative">
                        <DatePicker
                          selected={filterCreatedfromDate}
                          onChange={(date) => setFilterCreatedfromDate(date)}
                          selectsStart
                          startDate={filterCreatedfromDate}
                          endDate={filterCreatedtoDate}
                          className="  p-2   rounded-md focus:outline-none   bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  border-none focus:ring-0 max-w-[100px]"
                          style={{ boxShadow: "1px 1px 3px 0px black" }}
                          placeholderText="Select date start"
                        />
                      </div>
                      <span className="mx-4 text-gray-500">to</span>
                      <div className="relative">
                        <DatePicker
                          selected={filterCreatedtoDate}
                          onChange={(date) => setFilterCreatedtoDate(date)}
                          selectsEnd
                          startDate={filterCreatedfromDate}
                          endDate={filterCreatedtoDate}
                          minDate={filterCreatedfromDate}
                          className="  p-2   rounded-md focus:outline-none   bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  border-none focus:ring-0 max-w-[100px] "
                          style={{ boxShadow: "1px 1px 3px 0px black" }}
                          placeholderText="Select date end"
                        />
                      </div>
                    </div>
                  </div>
                  <GrPowerReset
                    className="text-white text-3xl  mr-4 pt-2 mt-2 rounded-xl p-1 "
                    onClick={() => {
                      setFilterCreatedfromDate("");
                      setFilterCreatedtoDate("");
                    }}
                    name="reset"
                  />
                </div>
                <hr className="mx-5 h-0.5 border-0 bg-gray-500" />
                <div className="flex justify-between">
                  <div className="p-3">
                    <label className="text-white text-md font-medium m-3">
                      Destination:
                    </label>
                    <select
                      className="  p-2   rounded-md focus:outline-none   bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  border-none focus:ring-0 pl-6"
                      style={{ boxShadow: "1px 1px 3px 0px black" }}
                      onChange={(e) => setFilterCountry(e.target.value)}
                    >
                      <option
                        className="flex justify-between hover:bg-blue-300 cursor-pointer my-1 text-white text-xl font-medium m-3"
                        value=""
                      >
                        Select
                      </option>
                      <option value="Vietnam">Vietnam</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Bali">Bali</option>
                      <option value="Vietnam/Cambodia">Vietnam/Cambodia</option>
                    </select>
                  </div>
                  <GrPowerReset
                    className="text-white text-3xl  mr-4 pt-2 mt-2  rounded-xl p-1 "
                    onClick={() => setFilterCountry("")}
                    name="reset"
                  />
                </div>
                {/* <hr className="mx-5" /> */}
              </div>
            </div>
          }

          <div className="bg-secondary p-4 rounded-xl w-2/3">
            <div className="flex justify-between">
              <div className=" p-4  ">
                <p className="text-white text-2xl font-medium">
                  Total Itineraries: {filteredItineraries.length}
                </p>
                <p className="text-white text-2xl font-medium"></p>
              </div>
              <div className="flex justify-between">
                <div className="p-3">
                  <label className="text-white text-md font-medium m-3">
                    Sort By:
                  </label>
                  <select
                    className="  p-2   rounded-md focus:outline-none   bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  border-none focus:ring-0 pl-6"
                    style={{ boxShadow: "1px 1px 3px 0px black" }}
                    onChange={(e) => setSortBy(e.target.value)}
                  >
                    <option
                      className="flex justify-between hover:bg-blue-300 cursor-pointer my-1 text-white text-xl font-medium m-3"
                      value=""
                    >
                      Select
                    </option>
                    <option
                      className="flex justify-between hover:bg-blue-300 cursor-pointer my-1 text-white text-xl font-medium m-3"
                      value="createdAt"
                    >
                      Created At
                    </option>
                    <option
                      className="flex justify-between hover:bg-blue-300 cursor-pointer my-1 text-white text-xl font-medium m-3"
                      value="TravelDate"
                    >
                      Travel Date
                    </option>
                    <option
                      className="flex justify-between hover:bg-blue-300 cursor-pointer my-1 text-white text-xl font-medium m-3"
                      value="TravellerCount"
                    >
                      Traveller Count
                    </option>
                  </select>
                </div>
                {/* <GrPowerReset className="text-white text-3xl  mr-4 pt-2  rounded-xl p-1 " onClick={() => setSortBy("")} name="reset" /> */}
              </div>
              <div className="flex justify-between">
                <div className="relative  mx-3 rounded-xl p-3 scale-90">
                  <input
                    type="text"
                    placeholder="Search Name or ID"
                    className="  p-2   rounded-md focus:outline-none   bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  border-none focus:ring-0 pl-6"
                    style={{ boxShadow: "1px 1px 3px 0px black" }}
                    onChange={(e) => setSearchPrompt(e.target.value)}
                  />
                  <FaMagnifyingGlass className="absolute right-6 top-1/2 transform -translate-y-1/2 text-gray-400 text-xl" />
                </div>
              </div>
            </div>
            {/* {!isAdmin && (
              <div>
                <div class="grid grid-cols-8 gap-3 mt-5 text-white font-medium my-3">
                  <div class="col-span-1 text-center text-xl">S. No.</div>
                  <div class="col-span-1 text-center text-xl">
                    Name of Traveller
                  </div>
                  <div class="col-span-1 text-center text-xl">Trip ID</div>
                  <div class="col-span-1 text-center text-xl">Destination</div>
                  <div class="col-span-1 text-center text-xl">Created At</div>
                  <div class="col-span-1 text-center text-xl">Travel Date</div>
                  <div class="col-span-1 text-center text-xl">
                    Traveller Count
                  </div>
                  <div class="col-span-1 text-center text-xl">View</div>
                  <div class="col-span-8">
                    <hr class="mx-5" />
                  </div>

                  {currentItineraries &&
                    currentItineraries.map((item, index) => (
                      <React.Fragment key={item._id}>
                        {" "}
                       
                        <div class="col-span-1 text-center">
                          {currentPage === 1
                            ? index + 1
                            : (currentPage - 1) * itemsPerPage + index + 1}
                        </div>
                        <div class="col-span-1 text-center">
                          {item.name ? item.name : "-"}
                        </div>
                        <div class="col-span-1 text-center">{item._id}</div>
                        <div class="col-span-1 text-center">
                          {item.country ? item.country : "-"}
                        </div>
                        <div class="col-span-1 text-center">
                          {item.createdAt ? formatDate(item.createdAt) : "-"}
                        </div>
                        <div class="col-span-1 text-center">
                          {item.TravelDate ? formatDate(item.TravelDate) : "-"}
                        </div>
                        <div class="col-span-1 text-center">
                          {countNumberOfTravellers(item)}
                        </div>
                        <div class="col-span-1 text-center">
                          <button
                            class="bg-blue-500 text-white p-2 rounded-lg h-fit w-fit"
                            onClick={() =>
                              window.open(
                                `https://www.travaura.in/resultPage/${item._id}`
                              )
                            }
                          >
                            View
                          </button>
                        </div>
                        <div class="col-span-8">
                          <hr class="mx-5" />
                        </div>{" "}
                        
                      </React.Fragment>
                    ))}
                </div>

                <Pagination
                  count={pageCount}
                  page={currentPage}
                  onChange={handleChangePage}
                  color="primary"
                />
              </div>
            )} */}
            {<AdminAccess itineraries={filteredItineraries} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentItinerariesSection;
