import React, { useEffect } from "react";
import { useState } from "react";
import { Pagination } from "@mui/material";

const AdminAccess = ({ itineraries }) => {
  const [filteredItineraries, setFilteredItineraries] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  useEffect(() => {
    setFilteredItineraries(itineraries);
    console.log("itineraries: ", itineraries);
  }, [itineraries]);

  const countNumberOfTravellers = (item) => {
    let totalTravellers = 0;
    if (item.adults) {
      totalTravellers += item.adults;
    }
    if (item.children) {
      totalTravellers += item.children;
    }
    if (item.infants) {
      totalTravellers += item.infants;
    }
    return totalTravellers;
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItineraries =
    filteredItineraries &&
    filteredItineraries.slice(indexOfFirstItem, indexOfLastItem);

  // Update the page count
  const pageCount = Math.ceil(
    filteredItineraries && filteredItineraries.length / itemsPerPage
  );

  // Handle change page
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const month = d.toLocaleString("default", { month: "long" });
    const day = d.getDate();
    const year = d.getFullYear();
    return `${day} ${month} ${year}`;
  };
  return (
    <div>
      <div class="grid grid-cols-8 gap-1 mt-5 text-white font-medium my-3">
        <div class="col-span-1 text-center text-md font-bold">S. No.</div>
        <div class="col-span-1 text-center text-md font-bold">
          Name of Traveller
        </div>
        <div class="col-span-1 text-center text-md font-bold">Trip ID</div>
        <div class="col-span-1 text-center text-md font-bold ">Destination</div>
        <div class="col-span-1 text-center text-md font-bold">Created At</div>
        <div class="col-span-1 text-center text-md font-bold">Travel Date</div>
        <div class="col-span-1 text-center text-md font-bold">
          Traveller Count
        </div>
        <div class="col-span-1 text-center text-md font-bold">View</div>
        <div class="col-span-8">
          <hr class="mx-5" />
        </div>

        {currentItineraries &&
          currentItineraries.map((item, index) => (
            <React.Fragment key={item._id}>
              {" "}
              {/* Use React.Fragment with a key for each item */}
              <div class="col-span-1 text-center text-gray-300 text-sm relative top-2">
                {currentPage === 1
                  ? index + 1
                  : (currentPage - 1) * itemsPerPage + index + 1}
              </div>
              <div class="col-span-1 text-center text-sm relative top-2">
                {item.name ? item.name : "-"}
              </div>
              <div class="col-span-1 text-center text-gray-300 text-xs text-[10px] relative top-2">
                {item._id}
              </div>
              <div class="col-span-1 text-center text-gray-300 text-sm relative top-2">
                {item.country ? item.country : "-"}
              </div>
              <div class="col-span-1 text-center text-gray-300 text-sm text-[12px] relative top-2">
                {item.createdAt ? formatDate(item.createdAt) : "-"}
              </div>
              <div class="col-span-1 text-center text-gray-300 text-[12px] relative top-2">
                {item.TravelDate ? formatDate(item.TravelDate) : "-"}
              </div>
              <div class="col-span-1 text-center text-gray-300 text-sm relative top-2">
                {countNumberOfTravellers(item)}
              </div>
              <div class="col-span-1 text-center">
                <button
                  class="bg-quaternary text-white p-1 rounded-lg h-[30px] w-full text-sm my-1"
                  onClick={() =>
                    window.open(
                      `https://www.proitinerary.com/resultPage/${item._id}`
                    )
                  }
                >
                  View
                </button>
              </div>
              <div class="col-span-8">
                <hr className="mx-5 h-[1px] border-0 bg-gray-500" />
              </div>{" "}
              {/* Ensure the <hr> spans all columns */}
            </React.Fragment>
          ))}
      </div>
      <Pagination
        count={pageCount}
        page={currentPage}
        onChange={handleChangePage}
        color="primary"
        className="whiteTextPagination"
      />
    </div>
  );
};

export default AdminAccess;
