import React, { useContext, useEffect } from "react";
import UserContext from "./contexts/UserContext";

const Bus = (props) => {
  const {
    selectedBusData,
    setSelectedBusData,
    selectedBusPriceByDay,
    setSelectedBusPriceByDay,
  } = useContext(UserContext);

  const handleBusSelect = () => {
    const existingBusIndex = selectedBusData.findIndex(bus => bus.day === props.day);

    if (existingBusIndex !== -1) {
      // Bus for this day is already selected, remove it
      const updatedBusData = [...selectedBusData];
      updatedBusData.splice(existingBusIndex, 1);
      setSelectedBusData(updatedBusData);
    } else {
      // Add new bus data
      const newBusData = {
        day: props.day,
        source: props.sourceCity,
        arrival: props.arrivalCity,
        price: selectedBusPriceByDay[props.day]
      };
      setSelectedBusData([...selectedBusData, newBusData]);
    }
  };
  useEffect(() => {
    console.log("selectedBusData: ", selectedBusData);
  }, [selectedBusData]);

  return (
    <div>
      <label className="block text-gray-400 text-sm font-medium mb-2">Bus</label>
      <input 
        type='number' 
        placeholder='Bus Price'  
        className="  p-2   rounded-md focus:outline-1 mb-3   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-full"  
        style={{ boxShadow: '1px 1px 3px 0px black' }}
        onChange={(e) => {
          let temp = [...selectedBusPriceByDay]; 
          temp[props.day] = e.target.value; 
          setSelectedBusPriceByDay(temp);
        }} 
        value={selectedBusPriceByDay[props.day] || selectedBusData.find(bus => bus.day === props.day)?.price || ""}
      />
      <button 
        className={`text-xs px-4 py-2 rounded-md flex ${selectedBusData.some(bus => bus.day === props.day) ? 'bg-red-600' : 'bg-gradient-to-tr from-[#625d5d] to-[#769895] text-[#ece7e7]'} `} 
        onClick={handleBusSelect}
      >
        {selectedBusData.some(bus => bus.day === props.day) ? 'Remove Transfer' : 'Add Transfer'}
      </button>     
    </div>
  );
};

export default Bus;
