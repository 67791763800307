import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { AuthContext } from "./contexts/AuthProvider";
import UserContext from "./contexts/UserContext";
import logo from "./assets/images/Pro_logo.png";
import Sidebar from "./Hamburger Menu/Sidebar";

const Navbar = () => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const { currentUser, logout } = useContext(AuthContext);
  const { totalNumberofThingsSelected, setTotalNumberofThingsSelected } =
    useContext(UserContext);
  return (
    <div>
      <div className="flex justify-between p-4 bg-gradient-to-br from-[#424242] to-transparent  ">
        <Sidebar />
        <div className="flex items-center ">
          {" "}
          {/* Add items-center to vertically align items */}
          <img
            src={logo}
            alt="Travaura Logo"
            className="h-16 mx-2 flex-shrink-0 pl-7" // Adjust padding if necessary
          />
          <div>
            <p className="text-white font-LeagueSpartan font-bold ml-2 text-4xl">
              PRO ITINERARY
            </p>
            <p className="text-[16px] ml-2 font-LeagueSpartan text-white">
              Crafted by Travaura
            </p>
          </div>
        </div>

        {/* <LogoutButton /> */}
        <div className="flex items-center">
          {currentUser && currentUser.photoURL ? (
            <img
              src={currentUser.photoURL}
              alt="user"
              className="h-12 rounded-full cursor-pointer mr-6"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "path_to_default_image.jpg";
              }} // Fallback to a default image on error
              onClick={() => setUserMenuOpen(!userMenuOpen)}
            />
          ) : (
            <div
              className="h-12 w-12 rounded-full bg-gray-400 cursor-pointer mr-6 flex items-center justify-center"
              onClick={() => setUserMenuOpen(!userMenuOpen)}
            >
              <p className="text-white font-LeagueSpartan font-bold text-md">
                T
              </p>
            </div>
          )}

          <p className="text-white font-LeagueSpartan font-bold text-md px-1 bg-red-400 rounded-full relative right-10 bottom-5">
            {totalNumberofThingsSelected}
          </p>
        </div>
        {userMenuOpen && (
          <div className="absolute top-16 right-0 bg-[#424242] p-4 mt-2 rounded-lg shadow-lg">
            <div className="flex-cols justify-center">
              <p className="text-white ml-2 mb-2">{currentUser.displayName}</p>
              <hr className="w-full mb-2" />

              <p className="text-white ml-2 mb-2"> {currentUser.email}</p>
              <button
                className=" text-white p-2 rounded-lg  h-fit w-fit mx-3"
                onClick={logout}
              >
                Logout
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
