import React from "react";
import { useEffect, useState } from "react";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import ActivityCard from "./ActivityCard";
import Logo from "../Logo.png";
import DomesticFlightCard from "./DomesticFlightCard";
import InternationalFlightsCard from "./InternationalFlightCard";
import BenefitsSection from "./BenefitsSection";
import TravellersGallerySection from "./TravellersGallerySection";
import k from "../assets/images/k.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import formatDate from "../contexts/dateConverter";
import TermsandConditions from "./TermsandConditions";
import { NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const ResultPage = () => {
  const [perPersonPrice, setPerPersonPrice] = useState(false);
  const navigate = useNavigate();
  const loadImages = (images) => {
    return Promise.all(
      images.map((img) => {
        if (img.complete && img.naturalHeight !== 0) return Promise.resolve();
        return new Promise((resolve) => {
          img.onload = () => resolve();
          img.onerror = () => resolve(); // even if there is an error, continue
        });
      })
    );
  };
  

  const generatePDF = async () => {
    const element = document.getElementById('contentToPrint'); // Ensure this is the correct ID
  
    // Check if the element exists
    if (!element) {
      console.error('Element to print not found!');
      return;
    }
  
    const pdfButton = document.getElementById("generatePdfButton");
    if (pdfButton) {
      pdfButton.style.visibility = "hidden";
    }
  
    try {
      const canvas = await html2canvas(element, { 
        scale: window.devicePixelRatio, // Use the device's pixel ratio to get full resolution
        useCORS: true
      });
  
      const imgData = canvas.toDataURL('image/jpeg');
  
      // Calculate the number of pages
      const pageHeightInPixels = canvas.height;
      const pageWidthInPixels = canvas.width;
  
      // Assuming a ratio for A4 size page in portrait mode (210mm x 297mm)
      const pageWidthInMM = 210;
      const pageHeightInMM = (pageHeightInPixels * pageWidthInMM) / pageWidthInPixels;
  
      const pdf = new jsPDF('p', 'mm', [pageWidthInMM, pageHeightInMM]);
      pdf.addImage(imgData, 'JPEG', 0, 0, pageWidthInMM, pageHeightInMM);
      pdf.save('trip-details.pdf');
    } catch (error) {
      console.error('Error generating PDF', error);
    }
  
    if (pdfButton) {
      pdfButton.style.visibility = "visible";
    }
  };
  
  
  

  const {
    adults,
    setAdults,
    child,
    setChild,
    infants,
    setInfants,
    selectedFlightPrice,
    transferPrice,
    setTransferPrice,
    selectedHotel,
    activityPrice,
    addonPrice,
    nights,
    setNights,
    country,
    travellerDetails,
    setTravellerDetails,
    selectedFlightData,
    setSelectedFlightData,
    numberOfDays,
    internationalFlightsPrice,
    internationalFlights,

    addonDinner,
    setAddonDinner,
    addonLunch,
    setAddonLunch,
    addonBreakfast,
    setAddonBreakfast,
    itenary,
    setItenary,
    setInternationalFlightsPrice,
    daywiseTransfers,
    setDaywiseTransfers,
    EarlyCheckIn,
    setEarlyCheckIn,
    LateCheckOut,
    setLateCheckOut,
    TATAAIGInsurance,
    setTATAAIGInsurance,
    Cancellation,
    setCancellation,
    visa,
    setVisa,
    peakSeasonMarkup, setPeakSeasonMarkup,
    validTillDate, setValidTillDate
  } = useContext(UserContext);
  const duration = parseInt(travellerDetails.duration) || 0;
  const [hotelPrice, setHotelPrice] = useState(0);
  const [toggletnc, setToggletnc] = useState(false);
  const [totalActivityBreakfast, setTotalActivityBreakfast] = useState(0);
  const [totalActivityLunch, setTotalActivityLunch] = useState(0);
  const [totalActivityDinner, setTotalActivityDinner] = useState(0);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    });
  };

  const generateCustomId = (prefix = 'id') => {
    return `${prefix}-${Math.random().toString(36).substr(2, 9)}`;
  };
  
  const customId = generateCustomId('TRAV'); 
  
  useEffect(() => {
    console.log("customId: ", customId);
  }, [customId]);

 

  const handlePost = () => {
    const data = {
      id: customId,
      travellerDetails: travellerDetails || [],
      country: country || "",
      selectedActivities: itenary || {},
      selectedHotels: selectedHotel || {},
      selectedTransfers: daywiseTransfers || {},
      selectedDomesticFlights: selectedFlightData || {},
      selectedInternationalFlights: internationalFlights || {},
      selectedAddons: {
        earlyCheckIn: EarlyCheckIn || false,
        lateCheckOut: LateCheckOut || false,
        addonBreakfast: addonBreakfast || 0,
        addonLunch: addonLunch || 0,
        addonDinner: addonDinner || 0,
        TATAAIGInsurance: TATAAIGInsurance || false ,
        Cancellation: Cancellation || false,
        visa: visa || "",
        peakSeasonMarkup: peakSeasonMarkup || 0,
        validTillDate: validTillDate || ""
      },
    };
    console.log(data);
    fetch("https://travaurabackend.azurewebsites.net/api/user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
navigate(`/resultPage/${customId}`);
  };



  useEffect(() => {
    let tempBreakfast = 0;
    let tempLunch = 0;
    let tempDinner = 0;
    if (itenary.length === 0) {
      setTotalActivityBreakfast(0);
      setTotalActivityLunch(0);
      setTotalActivityDinner(0);
      return;
    } else {
      itenary.forEach((item) => {
        if (item.Activities.Breakfast === "Yes") {
          tempBreakfast += 1;
        }
        if (item.Activities.Lunch === "Yes") {
          tempLunch += 1;
        }
        if (item.Activities.Dinner === "Yes") {
          tempDinner += 1;
        }
      });
      setTotalActivityBreakfast(tempBreakfast);
      setTotalActivityLunch(tempLunch);
      setTotalActivityDinner(tempDinner);
    }
  }, []);

  const landPackage =
    parseInt(activityPrice) +
    parseInt(hotelPrice) +
    parseInt(addonPrice) +
    parseInt(transferPrice);

  const landPackage18 = landPackage + (landPackage * 18) / 100 + (landPackage* parseInt(peakSeasonMarkup))/100;
  const oto = (landPackage * 5) / 100;
  const ldp = landPackage + (9 / 100) * landPackage + (landPackage* parseInt(peakSeasonMarkup))/100;
  const ldpgst = (5 / 100) * ldp;
  const ldpcostAfterGST = ldp + ldpgst;
  const ldptcs = (5 / 100) * ldpcostAfterGST;
  const ldptotalCost =
    ldpcostAfterGST + ldptcs + internationalFlightsPrice + selectedFlightPrice;
  const gst = (5 / 100) * landPackage18;
  const costAfterGST = landPackage18 + gst;
  const tcs = (5 / 100) * costAfterGST;
  const totalCost =
    costAfterGST + tcs + internationalFlightsPrice + selectedFlightPrice - oto;
    const ppp= totalCost/(parseInt(adults) + parseInt(child)+ parseInt(infants));

  const groupedByDay = itenary.reduce((acc, item) => {
    const day = item.day;

    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);

    return acc;
  }, {});

  useEffect(() => {
    console.log("groupedByDay: ", groupedByDay);
  }, [itenary]);

  const groupHotelByName = selectedHotel.reduce((acc, item) => {
    const hotelName = item.selectedHotel.Name;

    if (!acc[hotelName]) {
      acc[hotelName] = [];
    }

    // Add an object containing the day and other relevant details
    acc[hotelName].push({
      day: item.day,
      region: item.region,
      city: item.city,
      numberOfRooms: item.numberOfRooms,
      numberofSupplements: item.numberofSupplements,
      // Add other relevant details you want to include
    });

    return acc;
  }, {});

  useEffect(() => {
    console.log("groupHotelByName: ", groupHotelByName);
  }, [selectedHotel]);

  const groupFlightByDay = selectedFlightData.reduce((acc, item) => {
    const day = item.day;
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(item);
    return acc;
  }, {});
  useEffect(() => {
    console.log("groupFlightByDay: ", groupFlightByDay);
  }, [selectedFlightData]);

  const TravellersData = [
    {
      image:
        "https://s3.eu-west-1.amazonaws.com/media.mcsuk.org/images/RS34129_jelly2_scotland-lpr.width-765.jpg",
      username: "ayushj91",
    },
    {
      image:
        "https://s3.eu-west-1.amazonaws.com/media.mcsuk.org/images/RS34129_jelly2_scotland-lpr.width-765.jpg",
      username: "ketan.saluja",
    },
    {
      image:
        "https://s3.eu-west-1.amazonaws.com/media.mcsuk.org/images/RS34129_jelly2_scotland-lpr.width-765.jpg",
      username: "muskaan_19",
    },
    {
      image:
        "https://s3.eu-west-1.amazonaws.com/media.mcsuk.org/images/RS34129_jelly2_scotland-lpr.width-765.jpg",
      username: "samya.k8",
    },
    {
      image:
        "https://s3.eu-west-1.amazonaws.com/media.mcsuk.org/images/RS34129_jelly2_scotland-lpr.width-765.jpg",
      username: "username",
    },

    // ... add other marine life data
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    let tempPrice = 0;

    if (selectedHotel.length === 0) {
      setHotelPrice(0);
      return;
    }

    selectedHotel.forEach((item) => {
      const numberOfRooms = parseInt(item.numberOfRooms, 10);
      const roomPrice = parseInt(item.selectedHotel.CPRoomPrice, 10);
      const numberofSupplements = parseInt(item.numberofSupplements, 10);

      // Debugging
      console.log(
        "numberOfRooms:",
        numberOfRooms,
        "roomPrice:",
        roomPrice,
        "numberofSupplements:",
        numberofSupplements
      );

      // Check if parsed values are valid numbers
      if (
        !isNaN(numberOfRooms) &&
        !isNaN(roomPrice) &&
        !isNaN(numberofSupplements)
      ) {
        tempPrice +=
          numberOfRooms * roomPrice +
          (numberofSupplements * roomPrice * 45) / 100;
      } else {
        console.log("Invalid data for item:", item);
      }
    });

    setHotelPrice(tempPrice);
  }, [selectedHotel]);

  // Add another useEffect to log the updated hotelPrice
  useEffect(() => {
    console.log("Updated hotelPrice: ", hotelPrice);
    console.log("Updated transferPrice: ", transferPrice);
    console.log("Updated activityPrice: ", activityPrice);
    console.log("Updated addonPrice: ", addonPrice);
    console.log("internationalFlightsPrice: ", internationalFlightsPrice);
    console.log("selectedFlightPrice: ", selectedFlightPrice);
    console.log("internationalFlights: ", internationalFlights);
  }, [
    hotelPrice,
    transferPrice,
    activityPrice,
    addonPrice,
    internationalFlightsPrice,
    selectedFlightPrice,
    internationalFlights,
  ]);

  useEffect(() => {
    console.log(numberOfDays);
    console.log(duration);
    console.log(hotelPrice);
  }, [numberOfDays, travellerDetails, selectedHotel]);
  return (
    <div id="contentToPrint">
      <body class="bg-gray-900 text-white">
        <div class="container mx-auto px-4">
          <header class="flex justify-between items-center py-6">
            <div className="flex justify-start items-start">
              <img src={Logo} alt="" className="h-24 w-24 my-3" />
            </div>
          </header>

          <main>
            <h1 class="text-3xl font-bold mb-6">
              {travellerDetails.name
                ? travellerDetails.name.split(" ")[0]
                : " "}
              's trip to {country}
            </h1>
            <div class="xl:flex xl:justify-between">
              <div class="w-3/4 space-y-6">
                <div class="bg-gray-800 p-4 md:p-6 rounded-lg">
                  <h2 class="text-xl font-semibold mb-4">Trip ID: tripId</h2>
                  <div class="flex flex-col md:flex-row gap-4 justify-between">
                    <div className="grid grid-cols-2 gap-4 w-full">
                      <div className="font-semibold">Name:</div>
                      <div>{travellerDetails.name}</div>

                      <div className="font-semibold">Destination:</div>
                      <div>{country}</div>

                      <div className="font-semibold">Trip Type:</div>
                      <div>{travellerDetails.tripType}</div>

                      <div className="font-semibold">Duration:</div>
                      <div>
                        {travellerDetails.duration}D/{nights}N
                      </div>

                      <div className="font-semibold">Accommodation Type:</div>
                      <div>{travellerDetails.accommodationType}</div>
                    </div>

                    <div className="border-t-2 border-gray-500 md:border-t-0 md:border-l-2 my-4 md:my-0 mx-4 md:mx-0"></div>

                    <div className="grid grid-cols-2 gap-4 w-full">
                      <div className="font-semibold">Date of Travel:</div>
                      <div>{travellerDetails.dateOfTravel? formatDate(travellerDetails.dateOfTravel):""}</div>

                      <div className="font-semibold">Traveller Count:</div>
                      <div>
                        {adults} Adult(s), {child} Kid(s), {infants} infant(s)
                      </div>

                      <div className="font-semibold">
                        International Flights:
                      </div>
                      <div>{travellerDetails.internationalFlights}</div>

                      <div className="font-semibold">Domestic Flights:</div>
                      <div>{travellerDetails.domesticFlights}</div>

                      <div className="font-semibold">Visa:</div>
                      <div>{travellerDetails.visa}</div>
                    </div>
                  </div>
                </div>

                {/* <!-- Itinerary Section --> */}
                <div class=" p-6 rounded-lg">
                  <h2 class="text-xl font-semibold mb-4 flex items-center">
                    <i class="fas fa-suitcase-rolling mr-2"></i> Itinerary
                  </h2>
                  {/* <!-- Day 1 --> */}
                  {Array.from(Array(parseInt(duration)).keys()).map(
                    (item, ind) => (
                      <ActivityCard ss="result" id={customId} day={item + 1} itenary={itenary} selectedHotel={selectedHotel} daywiseTransfers={daywiseTransfers} />
                    )
                  )}
                </div>

                {/* <!-- Inclusions Section --> */}
                <div className="bg-gray-800 text-white p-6 rounded-lg">
                  <h2 className="text-xl font-semibold mb-4  ">
                    <i className="fas fa-concierge-bell mr-2"></i> Inclusions
                  </h2>
                  <div className="mb-4">
                    {/* Accommodation and Meals Inclusion */}
                    <div>
                      <h3 className="font-semibold mb-2 text-center md:text-left mt-3">
                        Accommodation and Meals Inclusion
                      </h3>
                      <ul className="list-disc pl-5">
                        {/* Dynamic content here */}
                        <li>
                          Delicious and Quality Meals -{" "}
                          {parseInt(totalActivityBreakfast) +
                            parseInt(addonBreakfast) +
                            parseInt(selectedHotel.length)}{" "}
                          Breakfasts-
                          {parseInt(totalActivityLunch) +
                            parseInt(addonLunch)}{" "}
                          Lunches-
                          {parseInt(totalActivityDinner) +
                            parseInt(addonDinner)}{" "}
                          Dinners Per Person.
                        </li>

                        <li>
                          Accommodation in{" "}
                          <div className="pl-3">
                            {Object.keys(groupHotelByName).map(
                              (hotelName, index) => {
                                const hotelBookings =
                                  groupHotelByName[hotelName];

                                // Find the earliest and latest days for the hotel bookings
                                const days = hotelBookings.map(
                                  (booking) => booking.day
                                );
                                const minDay = Math.min(...days);
                                const maxDay = Math.max(...days);

                                return (
                                  <div key={index}>
                                    {minDay === maxDay ? (
                                      <p>
                                        {`${hotelName} Hotel (booked for day ${minDay}`}
                                        )
                                      </p>
                                    ) : (
                                      <p>{`${hotelName} Hotel booked from day ${minDay} to day ${maxDay}`}</p>
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </li>
                      </ul>
                    </div>

                    {/* Activity Inclusion */}
                    <div>
                      <h3 className="font-semibold mb-2 text-center md:text-left mt-3">
                        Activity Inclusion
                      </h3>
                      {itenary.map((item, ind) => (
                        <div>
                          <ul className="list-disc pl-5">
                            {/* Dynamic content here */}
                            <li>
                              {item.Activities.Headingofevent} on day {item.day}
                            </li>
                          </ul>
                        </div>
                      ))}
                    </div>

                    {/* Flight and Transfers Inclusion */}
                    <div>
                      <h3 className="font-semibold mb-2 text-center md:text-left mt-3">
                        Flight and Transfers Inclusion
                      </h3>
                      <ul className="list-disc pl-5 ">
                        {/* Dynamic content here */}
                        <label className="font-semibold ">
                          International Flights
                        </label>
                        <div className="pl-7 ">
                          {internationalFlights.map((item, ind) => (
                            <li>
                              {item.carrier} flight booked from{" "}
                              {item.sourceCity} to {item.arrivalCity} on{" "}
                              {item.journeyDate? formatDate(item.journeyDate):""}
                            </li>
                          ))}
                        </div>
                        <label className="font-semibold ">
                          Domestic Flights
                        </label>
                        <ul className="list-disc pl-7">
                          {selectedFlightData.map((item, ind) => (
                            <li>
                              {item.carrier} flight booked from{" "}
                              {item.sourceCity} to {item.arrivalCity} on day{" "}
                              {item.day}
                            </li>
                          ))}
                        </ul>
                        <label className="font-semibold ">Transfers</label>
                        <ul className="list-disc pl-7">
                          {daywiseTransfers.map((item, ind) => (
                            <li >
                              {item.noOfTransfer} {item.transfer.vehicleType}(s) booked in{" "}
                              {item.transfer.city} on day {item.day}
                            </li>
                          ))}
                        </ul>
                      </ul>
                    </div>
                    <h3 className="font-semibold mb-2 text-center md:text-left mt-3">
                      Addons 
                    </h3>
                    <ul className="list-disc pl-7">
                    {EarlyCheckIn && (
                        <li >Early Check In included</li>
                    )}
                    {LateCheckOut && (
                        <li >Late Check Out included</li>
                    )}
                    {TATAAIGInsurance && (
                        <li >TATA AIG Insurance included for {parseInt(adults) + parseInt(child)+ parseInt(infants)} People</li>
                    )}
                    {Cancellation && (
                        <li >Cancellation included</li>
                    )}
                    {visa==="Included" && (
                        <li >Visa included</li>
                    )}
                    </ul>
                    
                  </div>
                </div>

                {/* <!-- Flight Details Section --> */}
                <div class="bg-gray-800 p-6 rounded-lg">
                  <h2 class="text-xl font-semibold mb-4 flex items-center">
                    <i class="fas fa-plane-departure mr-2"></i> Flight Details
                  </h2>
                  {/* <!-- International Flights --> */}
                  <div>
                    <h3 class="font-semibold mb-2">International Flights</h3>
                    <div class="  gap-4 mb-4">
                      {internationalFlights.map((item, ind) => (
                        <div>
                          <InternationalFlightsCard item={item} ind={ind} />
                        </div>
                      ))}
                    </div>
                    <hr class="border-gray-700" />
                  </div>

                  {/* <!-- Domestic Flights --> */}
                  <div>
                    <h3 class="font-semibold mb-2">Domestic Flights</h3>
                    <div class="  gap-4 mb-4">
                      {selectedFlightData.map((item, ind) => (
                        <div>
                          <DomesticFlightCard
                            item={item}
                            ind={ind}
                            dateOfTravel={travellerDetails.dateOfTravel}
                          />
                        </div>
                      ))}
                    </div>
                    <hr class="border-gray-700" />
                  </div>
                </div>

                <hr class="border-gray-700" />
                <div className="flex justify-center">
                  <label class="block text-gray-400 text-5xl font-medium mb-2">
                    Travaura Benefits
                  </label>
                </div>

                <BenefitsSection />

                <hr class="border-gray-700" />
                {/* <div className="flex justify-center">
                  <label class="block text-gray-400 text-5xl font-medium mb-2">
                    Traveller's Gallery
                  </label>
                </div>
                <div>
                  <TravellersGallerySection items={TravellersData} />
                </div>

                <hr class="border-gray-700" />

                <div>
                  <label className="block text-gray-400 text-xl md:text-2xl font-medium mb-2">
                    Travaura is your trusted Travel Partner
                  </label>
                  <div className="flex flex-col md:flex-row items-center">
                    <img
                      src="https://media4.giphy.com/media/5NPhdqmyRxn8I/giphy.webp?cid=acd21440zcnhcext9mruka5sftfahscptg5hfslu75mzi9f6&ep=v1_gifs_search&rid=giphy.webp&ct=g"
                      alt=""
                      className="h-20 w-20 my-3 mx-auto md:mx-0"
                    />
                    <p className="p-4 text-xl md:text-2xl text-center md:text-left">
                      4.9 Star Review <br /> on Google.
                    </p>
                  </div>
                  <div className="flex flex-wrap justify-center md:justify-start">
                    {[1, 2, 3].map(() => (
                      <div className="bg-gray-700 h-28 w-52 rounded-lg m-4 flex">
                        <img
                          src={k}
                          alt=""
                          className="h-10 w-10 my-3 rounded-full mx-3"
                        />
                        <div>
                          <div className="text-white text-lg font-semibold pt-3">
                            Ketan Saluja
                          </div>
                          <div className="text-white text-sm">⭐⭐⭐⭐⭐</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <hr class="border-gray-700" /> */}
                {/* <label class="block text-gray-400 text-2xl font-medium mb-2">
                  Terms And Conditions
                </label> */}
                <p
                  className="text-lg cursor-pointer"
                  onClick={() => setToggletnc(!toggletnc)}
                >
                  <span className="text-red-600">Click here</span> to Read our
                  terms and conditions
                </p>
                {toggletnc && (
                  <TermsandConditions
                    setToggletnc={setToggletnc}
                    toggletnc={toggletnc}
                  />
                )}

                <hr class="border-gray-700" />
              </div>

              {/* <!-- Pricing Details Sidebar --> */}
              <div>
                <div class="w-full my-3 h-fit bg-slate-200 text-gray-700 p-6 rounded-lg ml-4 ">
                  <h2 class="text-xl font-semibold mb-4">Pricing Details</h2>
                  <div class="space-y-2 mb-4">
                    <div class="flex justify-between">
                      <div>International Flights</div>
                      <div>
                        {" "}
                        {formatCurrency(Math.ceil(internationalFlightsPrice))}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div>Domestic Flights</div>
                      <div>
                        {" "}
                        {formatCurrency(Math.ceil(selectedFlightPrice))}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div>Land Package</div>
                      <div> {formatCurrency(Math.ceil(landPackage18))}</div>
                    </div>

                    <div class="flex justify-between">
                      <div>5% GST</div>
                      <div> {formatCurrency(Math.ceil(gst))}</div>
                    </div>
                    <div class="flex justify-between">
                      <div>TCS</div>
                      <div> {formatCurrency(Math.ceil(tcs))}</div>
                    </div>
                    <div class="flex justify-between">
                      <div>Total Amount</div>
                      <div>
                        {" "}
                        {formatCurrency(Math.ceil(costAfterGST + tcs))}
                      </div>
                    </div>
                    <div class="flex justify-between">
                      <div>One Time Payment Off</div>
                      <div>- {formatCurrency(Math.ceil(oto))}</div>
                    </div>

                    <hr class="border-gray-700" />
                    <div class="flex justify-between font-semibold">
                      <div>Total Effective Price</div>
                      <div>{formatCurrency(Math.ceil(totalCost))}</div>
                    </div>
                    
                  </div>
                </div>
                <div class="w-full  h-fit bg-red-500 text-white p-6 rounded-lg ml-4 ">
                  <div class="flex justify-between">
                    <div>Last Discounted Price</div>
                    <div className="text-xl font-bold">
                      {" "}
                      {formatCurrency(Math.ceil(ldptotalCost))}
                    </div>
                  </div>
                  <div className="flex justify-center">
                  {validTillDate && <label class="block  text-xl mb-2 mt-4 font-thin">
                    Offer Valid Till { formatDate(validTillDate)}
                    </label>
}
                    </div>
                </div>
                
              </div>
            </div>
          </main>
        </div>
      </body>
      <div className="flex justify-center items-center my-4">
        <button
          onClick={generatePDF}
          id="generatePdfButton"
          className="bg-red-600 text-white px-4 py-2 rounded-lg"
        >
          Download PDF
        </button>

        <button className="bg-blue-600 text-white px-4 py-2 rounded-lg ml-4" onClick={handlePost}>
          Post to DB
        </button>
        
      </div>
    </div>
  );
};

export default ResultPage;
