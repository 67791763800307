import React from "react";
import { motion } from "framer-motion";
function LabelAnimation({ text }) {
    
  
    return (
      <div className="App">
        {text.split(" ").map((el, i) => (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.75,
              delay: i/5,
            }}
            key={i}
          >
            {el}{" "}
          </motion.span>
        ))}
      </div>
    );
  }

  export default LabelAnimation;