import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const DragNDropTesting = () => {
    const [file, setFile] = useState(null);
    const [csvData, setCsvData] = useState(null);

    const {getRootProps, getInputProps} = useDropzone({
        accept: '.csv',
        onDrop: acceptedFiles => {
            setFile(acceptedFiles[0]);

            // Read the contents of the CSV file
            const reader = new FileReader();
            reader.onload = (event) => {
                setCsvData(event.target.result);
            };
            reader.readAsText(acceptedFiles[0]);
        }
    });

    // let tenantId = req.query.tenantId;

    const handleSubmit = async () => {
        // Perform upload action here
        console.log("File to upload:", file);
        try {
            const formData = new FormData();
            formData.append('file', file);
            const queryParams = new URLSearchParams({ tenantId: 'dummyTenant145' });
            const url = `http://localhost:6969/activities?${queryParams.toString()}`;
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to upload file');
            }
            console.log('File uploaded successfully');
        } catch (error) {
            console.error("Error uploading file: ", error);
        }
    };
    

    const openCsvDataViewer = () => {
        // Open a new window to display the CSV data
        const newWindow = window.open();
        newWindow.document.write(`<pre>${csvData}</pre>`);
    };

    return (
        <div className='text-white'>
            <h1>Drag and Drop Testing</h1>
            <div {...getRootProps()} style={dropzoneStyle}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <div>
                {file && (
                    <div>
                        <h3>File to Upload:</h3>
                        <p>{file.name} - {file.size} bytes</p>
                        <button onClick={openCsvDataViewer}>View CSV Data</button>
                    </div>
                )}
            </div>
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
};

const dropzoneStyle = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '20px'
};

export default DragNDropTesting;
