import React, { useEffect } from 'react';

const AccomodationDetails = ({ setPackageDetails, packageDetails }) => {
  useEffect(() => {
    console.log('AccomodationDetails: ', packageDetails);
  }, [packageDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPackageDetails(prevState => ({
      ...prevState,
      accommodations: {
        ...prevState.accommodations,
        [name]: value,
      },
    }));
  };

  return (
    <div className=' '>
        <div className='flex justify-center'>
          <label className="block text-white text-2xl font-bold my-5">
            Accomodation Details
          </label>
        </div>
      <div className='bg-secondary w-full max-w-4xl p-3 rounded-xl mx-auto'>
        
        <div className="grid grid-cols-2 gap-4 m-3">
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2" htmlFor="hotelCategory">
              Hotel Category
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name='hotelCategory' onChange={handleInputChange} value={packageDetails.accommodations.hotelCategory} type="text" placeholder="Hotel Category" />
          </div>
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2" htmlFor="roomType">
              Room Type
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name='roomType' onChange={handleInputChange} value={packageDetails.accommodations.roomType} type="text" placeholder="Room Type" />
          </div>
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2" htmlFor="amenities">
              Amenities
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name='amenities' onChange={handleInputChange} value={packageDetails.accommodations.amenities} type="text" placeholder="Amenities" />
          </div>
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2" htmlFor="locationType">
              Location Type
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name='locationType' onChange={handleInputChange} value={packageDetails.accommodations.locationType} type="text" placeholder="Location Type" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccomodationDetails;


// const [packageDetails, setPackageDetails] =  useState({
//     packageDetails: {
//       packageId: '',
//       country: [],
//       creationDate: '',
//       lastUpdated: '',
//       language: [],
//       name: '',
//       days: 0,
//       nights: 0,
//       placesCovered: [],
//       citiesCovered: [],
//       seasonality: '',
//       difficultyLevel: '',
//       theme: '',
//       maxGroupSize: 0,
//       customizability: false,
//       tags: [],
//       packageTypes: [],
//       inclusions: [],
//       exclusions: [],
//       optionalActivities: [],
//       cancellationPolicy: '',
//       mealPlanDetails: '',
//       healthVaccinationRequirements: '',
//       visaAssistance: false,
//       culturalEtiquetteTips: '',
//       testimonialsRatings: {
//         testimonials: [],
//         averageRating: 0
//       },
//       photoGallery: [],
//       videoOverview: '',
//       interactiveMap: '',
//     },
//     accommodations: {
//       hotelCategory: '',
//       roomType: '',
//       amenities: [],
//       locationType: '',
//     },
//     additionalFeatures: {
//       nightsInEachCity: {},
//       itineraryDetails: [],
//       transportationDetails: '',
//       guideServices: {
//         available: false,
//         languages: []
//       },
//       travelInsurance: '',
//       paymentOptions: [],
//       healthSafetyMeasures: '',
//       sustainabilityPractices: '',
//       emergencySupport: '',
//       localPartners: []
//     }
//   });