import React, { useEffect, useState } from "react";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import { useNavigate } from "react-router-dom";
import Hotel from "./Hotel";
import { CircularProgress } from "@mui/material";
function Transfer(props) {
  // const city = props.city;
  // const SelectedCityName = city ? Object.keys(city)[0] : '';
  // const [transferData, setTransferData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    TransferCities,
    setTransferCities,
    transfer,
    setTransfer,
    daywiseTransfers,
    setDaywiseTransfers,
    numberOfPeople,
    transferPrice,
    setTransferPrice,
    clickedTransferIds,
    setClickedTransferIds,
    noOfTransfers,
    setNoOfTransfers,
    country,
    selectedPackage,
    adults, child
    
  } = useContext(UserContext);
  const [selectedDaywiseTransfer, setSelectedDaywiseTransfer] = useState([]);
  // const [clickedTransferIds, setClickedTransferIds] = useState([]);
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/transport");
  };

  // useEffect(() => {
  //   setIsLoading(true);
  //   fetch("https://travaura-tech-api.azurewebsites.net/api/transfer")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setTransferData(data);
  //       setIsLoading(false);
  //     });
  // }, [props.isAirportTransfer]);

  useEffect(() => {
    if (daywiseTransfers.length === 0) {
      setTransferPrice(0);
      return;
    } else {
      let tempTransferPrice = 0;
      daywiseTransfers.forEach((item) => {
        tempTransferPrice += item.transfer.price * item.noOfTransfer;
      });
      setTransferPrice(tempTransferPrice);
      console.log("tempTransferPrice: ", tempTransferPrice);
    }
  }, [daywiseTransfers]);

  useEffect(() => {
    
    // console.log(SelectedCityName);
    console.log("selectedDaywiseTransfer: ", selectedDaywiseTransfer);
    console.log("daywiseTransfers: ", daywiseTransfers);
    console.log("filterData: ", filterData);
    console.log("numberOfPeople: ", numberOfPeople);
    console.log("transfer: ", transfer);
  }, [
    
    selectedDaywiseTransfer,
    daywiseTransfers,
    filterData,
    numberOfPeople,
    transfer,
    noOfTransfers
  ]);

  useEffect(() => {
    console.log("selectedData: ", selectedData);
    console.log("transfer Data: ", transfer);
    let tempPrice = 0;
    selectedData.forEach((id) => {
      const data = props.transferData.find((item) =>
        item.components.find((component) => component.id === id)
      );
      const component = data.components.find(
        (component) => component.id === id
      );
    });
  }, [selectedData, transfer]);

  useEffect(() => {
  let tempData = [...props.transferData];

  // Get the city for the specific day and ensure it's a string
  const cityForDay = TransferCities[props.day - 1];
  const cityString = cityForDay ? cityForDay.toLowerCase() : "";

  if (cityString !== "") {
    // Filter data based on the city
    tempData = tempData.filter((item) => {
      const city = item.city ? item.city.toLowerCase() : "";
      return city.replace(/\s+/g, '').includes(cityString.replace(/\s+/g, ''));
    });
  }

  // Sort the filtered data by 'seater' or 'Seater'
  tempData.sort((a, b) => {
    const seaterA = a.seater || a.Seater || 0; // Fallback to 0 if neither property exists
    const seaterB = b.seater || b.Seater || 0; // Fallback to 0 if neither property exists
    return seaterA - seaterB;
  });

  if(selectedPackage){
    tempData = tempData.filter((item) => {
      return item.city === selectedPackage.selectedAirportTransferCities[props.day - 1];
    });
  }
   
  

  setFilterData(tempData);
}, [props.transferData, TransferCities[props.day - 1]]);




  const handleTransferButtonClick = (item) => {
    const transferId = item._id;
    const currentDay = props.day;

    // Check if the transfer button is already clicked for the same day
    const isAlreadySelected = daywiseTransfers.some(
      (transfer) =>
        transfer.transfer._id === transferId && transfer.day === currentDay 
    );
    

    if (isAlreadySelected) {
      // Remove the item from daywiseTransfers
      const updatedDaywiseTransfers = daywiseTransfers.filter(
        (transfer) =>
          !(transfer.transfer._id === transferId && transfer.day === currentDay)
      );
      setDaywiseTransfers(updatedDaywiseTransfers);

      // Remove from clickedTransferIds
      const updatedClickedTransferIds = clickedTransferIds.filter(
        (id, day) => id !== transferId && day !== currentDay
      );

      setNoOfTransfers((prevCounts) => {
        const updatedCounts = { ...prevCounts };
        if (!updatedCounts[currentDay]) {
          updatedCounts[currentDay] = {};
        }
        updatedCounts[currentDay][transferId] = 0;
        return updatedCounts;
      });
      setClickedTransferIds(updatedClickedTransferIds);
    } else {
      // Remove any other transfer previously selected for the same day
      const otherTransfers = daywiseTransfers.filter(
        (transfer) => transfer.day !== currentDay
      );

      // Add the new selected transfer for the current day
      setDaywiseTransfers([
        ...otherTransfers,
        {
          day: currentDay,
          
          transfer: item,
          noOfTransfer: noOfTransfers[currentDay]?.[transferId] || 2,
        },
      ]);
      setNoOfTransfers((prevCounts) => {
        const updatedCounts = { ...prevCounts };
        if (!updatedCounts[currentDay]) {
          updatedCounts[currentDay] = {};
        }
        updatedCounts[currentDay][transferId] = Math.max( parseInt(noOfTransfers[currentDay]?.[transferId] || 0) , 2);
        return updatedCounts;
      });
      setClickedTransferIds([...clickedTransferIds, transferId, currentDay]);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="my-5">
      <input
        type="text"
        placeholder="Enter City"
        className="  p-2   rounded-md focus:outline-1   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-full"  
        style={{ boxShadow: '1px 1px 3px 0px black' }}
        value={TransferCities[props.day - 1] || ""}
        onChange={(e) => {
          let tempTransferCities = [...TransferCities];
          tempTransferCities[props.day - 1] = e.target.value;
          setTransferCities(tempTransferCities);
          console.log(tempTransferCities);
        }}
      />
      <div className="flex overflow-y-hidden w-full">
        {filterData.map((item) => (
          <div key={item._id} className="mx-4 my-4 flex-shrink-0">
            <div class=" p-4 rounded-md flex-grow"  className="bg-secondary p-4 rounded-md "
      style={{ boxShadow: '1px 1px 3px 0px black' }} >
              <div className="flex ">
                <div class="mb-2 p-2 ">
                  <div class="text-xl font-medium">{item.vehicleType}</div>
                  <div class="text-md text-gray-400 max-w-xs">
                    {item.seater}
                  </div>
                  <div class="text-md text-gray-400 max-w-xs">{item.Seater && item.Seater}</div>
                  <div class="text-xs text-gray-400">{item.city}</div>
                  <div class="text-xs text-gray-400">{item.arrivalDropOffAirport && (item.arrivalDropOffAirport)}</div>
                  <div class="text-lg text-gray-200">
                    ₹{item.price ? item.price.toLocaleString("en-IN") : 0}
                  </div>
                  {/* increment decrement button */}
                  <input
                    type="number"
                    className="text-gray-800 rounded-lg px-1 w-10 my-2 bg-gray-400"
                    min="0"
                    max="10"
                    value={daywiseTransfers.some(
                      (transfer) =>
                        transfer.transfer._id === item._id &&
                        transfer.day === props.day
                    )
                      ? daywiseTransfers.find(
                        (transfer) =>
                          transfer.transfer._id === item._id &&
                          transfer.day === props.day
                      ).noOfTransfer
                      : 0}
                    onChange={(e) => {
                      const value = e.target.value;
                      setNoOfTransfers((prevCounts) => {
                        const updatedCounts = { ...prevCounts };
                        daywiseTransfers.forEach((transfer) => {
                          if (
                            transfer.transfer._id === item._id &&
                            transfer.day === props.day
                          ) {
                            transfer.noOfTransfer = parseInt(value);
                          }
                        }
                        );
                        if (!updatedCounts[props.day]) {
                          updatedCounts[props.day] = {};
                        }
                        
                        updatedCounts[props.day][item._id] = parseInt(value);
                        return updatedCounts;

                        
                      });
                    }}
                  />
                </div>
                {/* <img
                  src="https://blog.akbartravels.com/wp-content/uploads/2022/10/Shutterstock_2199842613-1.jpg"
                  alt=""
                  className="w-32 h-32"
                /> */}
              </div>
              <button
                class={`${
                  daywiseTransfers.some(
                    (transfer) =>
                      transfer.transfer._id === item._id &&
                      transfer.day === props.day
                  )
                    ? "bg-green-600"
                    : "bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121]"
                } hover:scale-105   text-xl px-2  rounded-md flex`}
                onClick={() => handleTransferButtonClick(item)}
              >
                {daywiseTransfers.some(
                  (transfer) =>
                    transfer.transfer._id === item._id &&
                    transfer.day === props.day
                )
                  ? <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                  : "+"}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Transfer;
