import React from 'react';

const HorizontalSectionIndicator = ({ numberOfSections, currentSection }) => {
    const boxWidth= 100/numberOfSections;
  return (
    <div className="relative flex w-full justify-between px-4 py-2">
      {/* Horizontal line */}
      <div className="absolute w-full bg-gray-300 h-0.5 top-5" />

      {/* Boxes representing sections */}
      {Array.from({ length: numberOfSections }, (_, index) => (
        <div key={index} className={`relative flex flex-col items-center z-10 `}>
          {/* Circle node */}
          <div
            className={` h-8 rounded-full flex items-center justify-center p-3 w-${boxWidth} ${
              index === currentSection ? 'bg-blue-500' : 'bg-gray-400'
            } text-white shadow-md`}
          >
            jedksfnleoskdlfmcs
          </div>

          {/* Label */}
          <div className="mt-2 text-sm text-gray-700">
            {`Section ${index + 1}`}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HorizontalSectionIndicator;
