import React, { useState, useEffect } from "react";
import { auth, provider } from "./config.js";
import { signInWithPopup, updateProfile } from "firebase/auth";
import { signInWithEmailAndPassword } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import google from "../assets/images/google_logo.png";
import logo from "../Logo.png";
import proItinerary from "../assets/images/2.png";
import proLogo from "../assets/images/Pro_logo.png";
import { motion } from "framer-motion";

import TravauraLanding from "../assets/images/TravauraLanding.png";
const Signin = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSIgnUp, setIsSignUp] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const userEmail = result.user.email;
        if (userEmail.endsWith("@travaura.com")) {
          setUser(result.user);
          // It's safe to store non-sensitive information like email or UID
          localStorage.setItem("userEmail", userEmail);
          localStorage.setItem("userUID", result.user.uid);
          navigate("/");
        } else {
          alert("Access is only allowed from a @travaura.com email.");
          auth.signOut();
        }
      })
      .catch((error) => {
        console.error("Error during sign in:", error.message);
        alert(error.message);
      });
  };

  const handleSignInWithEmail = () => {
    if (!email.endsWith("@travaura.com")) {
      alert("Access is only allowed from a @travaura.com email.");
      return;
    }
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        setUser(result.user);
        // It's safe to store non-sensitive information like email or UID
        localStorage.setItem("userEmail", result.user.email);
        localStorage.setItem("userUID", result.user.uid);
        navigate("/");
      })
      .catch((error) => {
        console.error("Error during sign in:", error.message);
        alert(error.message);
      });
  };

  const handleSignUp = () => {
    if (!email.endsWith("@travaura.com")) {
      alert("Access is only allowed from a @travaura.com email.");
      return;
    }
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }
    createUserWithEmailAndPassword(auth, email, password, name).then(
      (result) => {
        //   setUser(result.user);
        //   // It's safe to store non-sensitive information like email or UID
        //   localStorage.setItem("userEmail", result.user.email);
        //   localStorage.setItem("userUID", result.user.uid);
        //   navigate("/");
        // })
        // .catch((error) => {
        //   console.error("Error during sign up:", error.message);
        //   alert(error.message);
        // });
        updateProfile(auth.currentUser, {
          displayName: name,
          phoneNumber: phoneNumber,
        })
          .then(() => {
            setUser(auth.currentUser);
            // It's safe to store non-sensitive information like email or UID
            localStorage.setItem("userEmail", auth.currentUser.email);
            localStorage.setItem("userUID", auth.currentUser.uid);
            navigate("/");
          })
          .catch((error) => {
            console.error("Error during sign up:", error.message);
            alert(error.message);
          });
      }
    );
  };

  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    if (userEmail) {
      setUser({ email: userEmail }); // Re-create the minimal user object
    }
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center  bg-gray-200 h-screen  ">
      {/* <img src={proItinerary} alt="Travaura logo" className="w-1/2 h-screen " /> */}
      <div className="bg-[#1b1c3b] w-1/2 h-screen flex items-center justify-center">
        <motion.img
          src={proLogo}
          alt="Travaura logo"
          className="  h-32"
          initial={{ scale: 1, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            stiffness: 260,
            damping: 10,
            duration: 1,
            type: "bounce",
          }}
        />
        <div className="border-r-2 border-gray-400 h-32 mx-8"></div>
        <motion.div initial={{ scale: 1, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            stiffness: 260,
            damping: 10,
            duration: 1,
            type: "bounce",
          }}>
          <div className="text-white text-6xl font-bold font-LeagueSpartan">
            PRO ITINERARY
          </div>
          <div className="text-white text-xl  font-LeagueSpartan">Crafted by Travaura.</div>
        </motion.div>
      </div>

      {/* <motion.img
                src={image}
                alt=""
                className="h-16 w-24 "
                initial={{ scale: 1, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{
                  stiffness: 260,
                  damping: 10,
                  duration: 1,
                }}
              /> */}
      <div className="py-12 px-8 max-w-sm mx-auto bg-white rounded-xl   space-y-2">
        {/* <div className="flex  items-center justify-center my-2 pb-5">
          <img
            src={google}
            alt="Google logo"
            className="w-24 mx-auto scale-125"
          />

          <img
            src={logo}
            alt="Travaura logo"
            className="w-24 mx-auto scale-105"
          />
        </div> */}
        {!isSIgnUp && (
          <>
            <div className="flex  items-center justify-center mb-3 ">
              <label className="text-[#696B7A] text-3xl font-bold font-montserrat">
                Log in
              </label>
            </div>
            <input
              type="text"
              placeholder="Email"
              className="border border-gray-300 px-3 py-2 rounded-md w-full"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              className="border border-gray-300 px-3 py-2 rounded-md w-full"
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              className="bg-blue-500 p-3 rounded-md w-full text-white"
              onClick={handleSignInWithEmail}
            >
              Sign In
            </button>
            <p className="text-center text-gray-500 text-xs">
              Don't have an account?{" "}
              <span
                className="text-blue-500 cursor-pointer"
                onClick={() => setIsSignUp(true)}
              >
                Sign Up
              </span>
            </p>
          </>
        )}
        {isSIgnUp && (
          <>
            <div className="flex  items-center justify-center mb-3 ">
              <label className="text-[#696B7A] text-3xl font-bold font-montserrat">
                Sign up
              </label>
            </div>
            <input
              type="text"
              placeholder="Name"
              className="border border-gray-300 px-3 py-2 rounded-md w-full"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="number"
              placeholder="Phone Number"
              className="border border-gray-300 px-3 py-2 rounded-md w-full"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              className="border border-gray-300 px-3 py-2 rounded-md w-full"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              className="border border-gray-300 px-3 py-2 rounded-md w-full"
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              onCopy={(e) => e.preventDefault()}
              onPaste={(e) => e.preventDefault()}
              className="border border-gray-300 px-3 py-2 rounded-md w-full"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
            <button
              className="bg-blue-500 p-3 rounded-md w-full text-white"
              onClick={handleSignUp}
            >
              Sign Up
            </button>
            <p className="text-center text-gray-500 text-xs">
              Already have an account?{" "}
              <span
                className="text-blue-500 cursor-pointer"
                onClick={() => setIsSignUp(false)}
              >
                Sign In
              </span>
            </p>
          </>
        )}

        <hr className="my-2" />

        <p className="text-center text-gray-500 text-xs">
          Also sign in with{" "}
          <img
            src={google}
            alt="Google logo"
            className="w-6 inline-block"
            onClick={handleSignIn}
            data-cy="google-signin"
          />
        </p>
      </div>
    </div>
  );
};

export default Signin;
