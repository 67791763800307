import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddCambodiaHotels = () => {
  const { id, day, Category, city } = useParams();
  const navigate = useNavigate();
  const [CambodiaHotelsData, setCambodiaHotelsData] = useState([]);

  const [selectedCambodiaHotels, setSelectedCambodiaHotels] = useState({});
  const [loading, setLoading] = useState(false);

  const handleUpdateCambodiaHotel = () => {
    console.log("selectedCambodiaHotelsToPost: ", selectedCambodiaHotels);
    setLoading(true);
    fetch(`https://travaurabackend.azurewebsites.net/addCambodiaHotels/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ selectedCambodiaHotels }), // Ensure proper structure
    })
    .then((res) => {
        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
    })
    .then((data) => {
        console.log("data: ", data);
        setLoading(false);
        navigate(-1);
    })
    .catch((error) => {
        console.log("Error updating Cambodia hotel: ", error);
    });
};

  useEffect(() => {
    fetch(
      "https://travaurabackend.azurewebsites.net/api/cambodiaHotelsSchema"
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // console.log("data: ", data);
        const temp = data.filter((item) => item.City === city);
        const temp2 = temp[0].Categories.filter(
          (item) => item.Category === Category
        );
        setCambodiaHotelsData(temp2);
      })
      .catch((error) => {
        console.log("Error fetching Cambodia Hotels: ", error);
      });
  }, []);

  useEffect(() => {
    console.log("CambodiaHotelsData: ", CambodiaHotelsData);
    console.log("selectedCambodiaHotels: ", selectedCambodiaHotels);
  }, [CambodiaHotelsData, selectedCambodiaHotels]);

 
  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-6 text-gray-300">
        Cambodia Hotels
      </h2>

      <div className="grid grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-6">
        {CambodiaHotelsData &&
          CambodiaHotelsData[0] &&
          CambodiaHotelsData[0].Hotels.map((hotel, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg overflow-hidden text-gray-300"
            >
              {hotel.Images ? (
                <img
                  src={hotel.Images.split(", ")[0]}
                  alt={`Image of ${hotel.Name}`}
                  className="w-full h-32 sm:h-48 object-cover"
                />
              ) : (
                <img
                  src="https://static.toiimg.com/photo/101545435.cms"
                  alt={`Image of ${hotel.Name}`}
                  className="w-full h-32 sm:h-48 object-cover"
                />
              )}
              <div className="p-4">
                <h3 className="text-lg font-semibold">{hotel.Name}</h3>
                {/* <button className="text-blue-600">View</button> */}
                <div className="flex items-center mt-2">
                  <i className="fas fa-star text-yellow-400"></i>

                  <button
                    className={`${
                      selectedCambodiaHotels &&
                      selectedCambodiaHotels.hotel &&
                      selectedCambodiaHotels.hotel.Name === hotel.Name &&
                      selectedCambodiaHotels.hotel.Category ===
                        hotel.Category &&
                      selectedCambodiaHotels.hotel.RoomType === hotel.RoomType
                        ? "bg-red-500"
                        : "bg-blue-600"
                    } text-white rounded-full py-2 px-4 ml-auto`}
                    onClick={async () => {
                      if (
                        selectedCambodiaHotels &&
                        selectedCambodiaHotels.hotel &&
                        selectedCambodiaHotels.hotel.Name === hotel.Name &&
                        selectedCambodiaHotels.hotel.Category ===
                          hotel.Category &&
                        selectedCambodiaHotels.hotel.RoomType === hotel.RoomType
                      ) {
                        setSelectedCambodiaHotels({}); // Deselect the hotel
                      } else {
                        setSelectedCambodiaHotels({ day, hotel }); // Select the hotel

                        // Slow smooth scroll to bottom of screen
                        await new Promise((resolve) =>
                          setTimeout(resolve, 500)
                        );
                        window.scrollTo({
                          top: document.body.scrollHeight,
                          behavior: "smooth",
                        });
                      }
                    }}
                  >
                    {selectedCambodiaHotels &&
                    selectedCambodiaHotels.hotel &&
                    selectedCambodiaHotels.hotel.Name === hotel.Name &&
                    selectedCambodiaHotels.hotel.Category === hotel.Category &&
                    selectedCambodiaHotels.hotel.RoomType === hotel.RoomType
                      ? "Remove"
                      : "Add"}
                  </button>
                </div>
              </div>
            </div>
          ))}
      </div>
      {loading && (
                  <div className="fixed z-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
                  </div>
                )}
      <button
        className="bg-blue-600 text-white rounded-full py-2 px-4 ml-auto mt-4"
        onClick={() => {
          handleUpdateCambodiaHotel();
          
        }}
      >
        Go back
      </button>
    </div>
  );
};

export default AddCambodiaHotels;
