import React, { useState, useEffect } from "react";
import getCityNameByIataCode from "../GetCityNames";
import { FaPlane } from "react-icons/fa";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import { RiDeleteBin2Fill } from "react-icons/ri";

const InternationalFlightCard = ({ flight, dictionaries, id, ind, setDeleteCounter }) => {
  const [departureCity, setDepartureCity] = useState("");
  const [arrivalCity, setArrivalCity] = useState("");
  const [segmentCities, setSegmentCities] = useState({});
  const {selectedInternationalFlightOffers, setSelectedInternationalFlightOffers}= useContext(UserContext)
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const isSelectedFlight = selectedInternationalFlightOffers.some(selectedFlight => selectedFlight.id === flight.id);
    setIsSelected(isSelectedFlight);
  }, [selectedInternationalFlightOffers, flight.id]);

  // Handle Add Flight
  const handleAddFlight = () => {
    setSelectedInternationalFlightOffers([...selectedInternationalFlightOffers, { ...flight, dictionaries }]);
    setIsSelected(true);
  };

  // Handle Deselect Flight
  const handleDeselectFlight = () => {
    const filteredFlights = selectedInternationalFlightOffers.filter(selectedFlight => selectedFlight.id !== flight.id);
    setSelectedInternationalFlightOffers(filteredFlights);
    setIsSelected(false);
  };
  useEffect(() => {
    console.log("selectedInternationalFlightOffers: ", selectedInternationalFlightOffers);
  }, [selectedInternationalFlightOffers]);

  const handleDeleteInternationalFlight = () => {
    fetch(
      `https://travaurabackend.azurewebsites.net/deleteInternationalFlight/${id}/${ind}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log("data: ", data);
        // Update your component's state or parent component's state here
        // For example, using a setState function
        setDeleteCounter((prevCount) => prevCount + 1);
      })
      .catch((error) => {
        console.log("Error deleting international flight: ", error);
      });
  };

  useEffect(() => {
    const fetchCityNames = async () => {
      const cityNames = {};

      for (const itinerary of flight.itineraries) {
        for (const segment of itinerary.segments) {
          const departureCode = segment.departure.iataCode;
          const arrivalCode = segment.arrival.iataCode;

          if (!cityNames[departureCode]) {
            cityNames[departureCode] = await getCityNameByIataCode(departureCode) || departureCode;
          }
          if (!cityNames[arrivalCode]) {
            cityNames[arrivalCode] = await getCityNameByIataCode(arrivalCode) || arrivalCode;
          }
        }
      }

      setSegmentCities(cityNames);
    };

    fetchCityNames();
  }, [flight.itineraries]);

  const getBaggageWeight = (flight) => {
    const travelerPricing = flight.travelerPricings[0];
    const fareDetailsBySegment = travelerPricing ? travelerPricing.fareDetailsBySegment[0] : null;
    const includedCheckedBags = fareDetailsBySegment ? fareDetailsBySegment.includedCheckedBags : null;
  
    return includedCheckedBags ? `Baggage: ${includedCheckedBags.weight} KG` : '';
  };

  // Function to format date and time
  const formatDate = (dateTimeStr) => {
    const dateObj = new Date(dateTimeStr);
  
    const day = dateObj.getUTCDate();
    const month = dateObj.toLocaleString('en-US', { month: 'short', timeZone: 'UTC' });
    const year = dateObj.getUTCFullYear();
  
    const getDaySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
      }
    };
  
    return `${day}${getDaySuffix(day)} ${month} ${year}`;
  };
  
  const formatTime = (dateTimeStr) => {
    const dateObj = new Date(dateTimeStr);
    return dateObj.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'UTC'
    });
  };
  
  const formatDateTime = (dateTimeStr) => {
    return `${formatDate(dateTimeStr)}, ${formatTime(dateTimeStr)}`;
  };
  
  // Function to get aircraft name
  const getAircraftName = (code) => dictionaries.aircraft[code];

  // Function to get city name from IATA code
  const getCityName = (iataCode) => {
    const location = dictionaries.locations[iataCode];
    return location
      ? `${location.cityCode}, ${location.countryCode}`
      : iataCode;
  };
  useEffect(() => {
    const fetchCityNames = async () => {
      const departureCode =
        flight.itineraries[0].segments[0].departure.iataCode;
      const arrivalCode = flight.itineraries[0].segments[0].arrival.iataCode;

      const fetchedDepartureCity = await getCityNameByIataCode(departureCode);
      const fetchedArrivalCity = await getCityNameByIataCode(arrivalCode);

      setDepartureCity(fetchedDepartureCity || departureCode);
      setArrivalCity(fetchedArrivalCity || arrivalCode);
    };

    fetchCityNames();
  }, [flight.itineraries]);
  // Function to get airline name
  const getAirlineName = (carrierCode) =>
    dictionaries.carriers[carrierCode] || carrierCode;

  // Function to format baggage information
  


  // Function to convert ISO 8601 duration to a readable format
  const formatDuration = (isoDuration) => {
    const match = isoDuration.match(/PT(\d+H)?(\d+M)?/);
    const hours = match[1] ? `${parseInt(match[1])} hours ` : "";
    const minutes = match[2] ? `${parseInt(match[2])} minutes` : "";
    return hours + minutes;
  };
  const durationToMinutes = (isoDuration) => {
    const match = isoDuration.match(/PT(\d+H)?(\d+M)?/);
    const hours = match[1] ? parseInt(match[1]) * 60 : 0;
    const minutes = match[2] ? parseInt(match[2]) : 0;
    return hours + minutes;
  };

  const parseDateTime = (dateTimeStr) => new Date(dateTimeStr);

  // Function to calculate total time including wait time in a particular itinerary
  const getTotalTimeWithWait = (itinerary) => {
    const segments = itinerary.segments;
    if (segments.length === 0) return "0 hours 0 minutes";

    let totalMinutes = 0;
    for (let i = 0; i < segments.length; i++) {
      const arrivalTime =
        i === 0
          ? parseDateTime(segments[i].departure.at)
          : parseDateTime(segments[i - 1].arrival.at);
      const departureTime = parseDateTime(segments[i].departure.at);

      // Calculate flight duration
      totalMinutes += durationToMinutes(segments[i].duration);

      // Calculate wait time between flights
      if (i > 0) {
        const waitTime = (departureTime - arrivalTime) / 60000; // Convert milliseconds to minutes
        totalMinutes += waitTime;
      }
    }

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} hours ${minutes} minutes`;
  };

  const getNumberOfStops = (segments) => {
    return segments.length > 1
      ? `${segments.length - 1} stops`
      : "Direct flight";
  };

  return (
    <div className=" p-4 rounded-lg shadow-md bg-secondary mb-5 ">
      
      {flight.itineraries.map((itinerary, index) => (
        <div key={index}>
          
          <div className="bg-[#121212] p-4 rounded-lg max-w-full mx-auto my-8">
            <button className="bg-blue-500 text-white px-16 py-1 rounded relative bottom-7 left-10">
              Flight {index + 1}
            </button>
            { itinerary.segments.map((segment, idx) => (
                <div key={idx} className="mb-4">

            <div className="flex items-center justify-between mb-4">
              <button className="bg-blue-400 text-white  rounded"></button>
              <div className="flex items-center mb-5 mr-3">
                <span className="text-white text-sm md:text-xl">
                  {getAirlineName(segment.carrierCode)}
                </span>
              </div>
            </div>
            <div className="md:flex items-center justify-between text-white  px-8">
              <div className="relative bottom-8 text-gray-300">
                <div className="text-xl font-medium">{segment.departure.iataCode}</div>
                <div className="text-sm">
                {segmentCities[segment.departure.iataCode]}
                </div>
                <div className="text-sm">
                  {formatDateTime(segment.departure.at)}{" "}
                </div>
              </div>
              <div className="md:flex-1 flex-2 mx-8">
                <div className="border-t border-gray-600 flex justify-center rotate-90 md:rotate-0 ">
                  <FaPlane className="text-gray-500 text-2xl relative bottom-3  " />
                </div>
                <div className="text-center text-sm md:mt-4  text-gray-300">
                  {/* <div>{getNumberOfStops(itinerary.segments)} </div> */}
                  <div className="text-sm md:text-md">{getBaggageWeight(flight)}</div>
                  <div className="text-sm md:text-md">
                    {segment.departure.at
                      ? formatDuration(segment.duration)

                      : ""}
                  </div>
                </div>
                
              </div>
              <div className="relative bottom-8 text-gray-300">
                  <div className="text-xl font-medium">{segment.arrival.iataCode}</div>
                  <div className="text-md">
                  {segmentCities[segment.arrival.iataCode]}
                  </div>
                  <div className="text-sm">
                    {formatDateTime(segment.arrival.at)}{" "}
                  </div>
                </div>
              {/* <RiDeleteBin2Fill className="text-2xl text-red-500 cursor-pointer print-proof" onClick={handleDeleteInternationalFlight} /> */}
            
            </div>
          </div>
            ))}
            </div>
            
        </div>
        ))}
      {/* <div className="mt-4">
        <p className="font-semibold text-gray-200">
          Total Time for This Itinerary (including wait time):{" "}
          {getTotalTimeWithWait(flight.itineraries[0])}
        </p>
      </div>

      <div className="mt-4">
        <p className="font-bold text-gray-200 text-3xl">
          Price: {flight.price.total}{" "}
          {dictionaries.currencies[flight.price.currency]}
        </p>
      </div> */}

      <RiDeleteBin2Fill className="text-2xl text-red-500 cursor-pointer print-proof" onClick={handleDeleteInternationalFlight} />
       

    </div>
  );
};

export default InternationalFlightCard;
