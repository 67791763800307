import React, { useEffect, useState } from "react";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import { useNavigate } from "react-router-dom";

import LabelAnimation from "./Animations/Text_Entry";

import { CircularProgress } from "@mui/material";
import TravellerDetailsSkeleton from "./loaderSkeleton";
import { AuthContext } from "./contexts/AuthProvider";
import LogoutButton from "./Google Auth/logout";
import Signin from "./Google Auth/signin";
import logo from "./assets/images/Pro_logo.png";
import { motion } from "framer-motion";
import { IoMdRefresh } from "react-icons/io";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Navbar from "./Navbar";
import Sidebar from "./Hamburger Menu/Sidebar";



function TravellerDetails() {
  const [travauraID, setTravauraID] = useState("TRAV-");
  const { currentUser } = useContext(AuthContext);
  const [name, setName] = useState("");
const {logout} = useContext(AuthContext);
  const [tripType, setTripType] = useState("");
  const [duration, setDuration] = useState(0);
  const {
    CambodiaAccomodationType,
    setCambodiaAccomodationType,
    CambodiaWhen,
    setCambodiaWhen,
    selectedCombodiaID,
    setSelectedCombodiaID,
    CambodiaPackagesData,
    setCambodiaPackagesData,
    selectedCambodiaPackage,
    setSelectedCambodiaPackage,
    selectedHotel,
    setSelectedHotel,
    adults,
    setAdults,
    child,
    setChild,
    infants,
    setInfants,
    nights,
    setNights,
    numberOfPeople,
    transferData,
    setTransferData,
    selectedPackage
  } = useContext(UserContext);
  const [accommodationType, setAccommodationType] = useState("");
  const [dateOfTravel, setDateOfTravel] = useState("");
  const [travellerCount, setTravellerCount] = useState(0);
  const [internationalFlights, setInternationalFlights] = useState("");
  const [domesticFlights, setDomesticFlights] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(10);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const {
    globalCityStructure,
    setGlobalCityStructure,
    setSelectedCountryData,
    selectedCountryData,
    travellerDetails,
    setTravellerDetails,
    setNumberOfDays,
    setNumberOfPeople,
    country,
    setCountry,
    addonPrice,
    setAddonPrice,
    setActivityData,
    setHotelData,
  } = useContext(UserContext);

  const generateCustomId = (prefix = "TRAV") => {
    return `${prefix}-${Math.random().toString(36).substr(2, 9)}`;
  };

  // Check and set customId
  const getOrCreateCustomId = () => {
    let id = sessionStorage.getItem("customId");
    if (!id) {
      id = generateCustomId();
      sessionStorage.setItem("customId", id);
    }
    return id;
  };

  const [customId, setCustomId] = useState(getOrCreateCustomId());

const checkIfIDExists = () => {
  if(sessionStorage.getItem("customId")){
    fetch(`https://travaurabackend.azurewebsites.net/api/check/${sessionStorage.getItem("customId")}`)
    .then((response) => response.json())
    .then((data) => {
      if(data.message === "ID exists"){
        const confirm = window.confirm("An itinerary with this ID already exists. Do you want to create a new one?");
        if (confirm) {
          sessionStorage.removeItem("customId");
          setCustomId(generateCustomId());
          window.location.reload();
        } else {
          setCustomId(sessionStorage.getItem("customId"));
        }
      }
    });
  }
}

  useEffect(() => {
    checkIfIDExists();
  }, []);


  // if(country==="Bali"){
  //   tempData = tempData.filter((item) => (item.city === "Canggu") || (item.city === "Jimbaran") || (item.city === "Seminyak") || (item.city === "Kuta") || (item.city === "Ubud") || (item.city === "Tegallalang ") || (item.city === "Legion"));
  // }
  //  if(country==="Vietnam"){
  //   tempData = tempData.filter((item) => (item.city !== "Canggu") || (item.city !== "Jimbaran") || (item.city !== "Seminyak") || (item.city !== "Kuta") || (item.city !== "Ubud") || (item.city !== "Tegallalang ") || (item.city !== "Legion"));
  // }
  useEffect(() => {
    if (selectedHotel && (adults || child)) {
      const totalPeople = parseInt(adults, 10) + parseInt(child, 10);
      const updatedSelectedHotels = selectedHotel.map((item) => {
        const calculatedRooms = Math.floor(totalPeople / 2) 
        const calculatedSupplements = totalPeople % 2; // 1 if odd (extra person), 0 if even
  
        // Check if updates are necessary to avoid infinite loop
        if (item.numberOfRooms !== calculatedRooms || item.numberofSupplements !== calculatedSupplements) {
          return {
            ...item,
            numberOfRooms: calculatedRooms,
            numberofSupplements: calculatedSupplements,
          };
        }
  
        // Return the item unchanged if no updates are needed
        return item;
      });
  
      // Check if any hotel data was actually updated to prevent unnecessary state updates
      const isDataUpdated = updatedSelectedHotels.some((item, index) => (
        item.numberOfRooms !== selectedHotel[index]?.numberOfRooms ||
        item.numberofSupplements !== selectedHotel[index]?.numberofSupplements
      ));
  
      if (isDataUpdated) {
        setSelectedHotel(updatedSelectedHotels);
      }
    }
  }, [selectedHotel, adults, child]);
  
  useEffect(() => {
    console.log("selectedHotel: ", selectedHotel);
  }, [selectedHotel]);
  

  useEffect(() => {
    if (country === "Vietnam") {
      fetch("https://travaurabackend.azurewebsites.net/api/transfer")
        .then((response) => response.json())
        .then((data) => {
          setTransferData(data);
          console.log("transferData: ", data);
        });
    } else if (country === "Bali") {
      fetch("https://travaurabackend.azurewebsites.net/api/BaliTransfer")
        .then((response) => response.json())
        .then((data) => {
          setTransferData(data);
          console.log("transferData: ", data);
        });
    } else if (country === "Thailand") {
      fetch(
        "https://travaurabackend.azurewebsites.net/api/ThailandAirportTransfersSchema"
      )
        .then((response) => response.json())
        .then((data) => {
          setTransferData(data);
          console.log("transferData: ", data);
        });
    }
  }, [country]);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://travaurabackend.azurewebsites.net/api/globalCityStructure")
      .then((response) => response.json())
      .then((data) => {
        setGlobalCityStructure(data);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (country === "Vietnam" || country === "Vietnam/Cambodia") {
      fetch("https://travaurabackend.azurewebsites.net/api/data")
        .then((response) => response.json())
        .then((data) => setActivityData(data));
    } else if (country === "Bali") {
      fetch("https://travaurabackend.azurewebsites.net/api/BaliData")
        .then((response) => response.json())
        .then((data) => setActivityData(data));
    } else if (country === "Thailand") {
      fetch(
        "https://travaurabackend.azurewebsites.net/api/ThailandActivitiesSchema"
      )
        .then((response) => response.json())
        .then((data) => setActivityData(data));
    }
  }, [country]);

  useEffect(() => {
    if (country === "Vietnam/Cambodia") {
      fetch(
        "https://travaurabackend.azurewebsites.net/api/cambodiaPackageSchema"
      )
        .then((response) => response.json())
        .then((data) => setCambodiaPackagesData(data));
    }
  }, [country]);

  useEffect(() => {
    console.log("selectedCambodiaPackage: ", selectedCambodiaPackage);
  }, [selectedCambodiaPackage]);

  useEffect(() => {
    globalCityStructure
      .filter((item) => item.name === country)
      .map((item) => {
        setSelectedCountryData(item);
      });
  }, [country]);

  const navigate = useNavigate();

  useEffect(() => {
    if(selectedPackage){
      setDuration(selectedPackage.packageDetails.packageDetails.days);
      setTravellerDetails({
        ...travellerDetails,
        duration: selectedPackage.packageDetails.packageDetails.days,
        country: selectedPackage.country,
      });
      setCountry(selectedPackage.country);
    }
  }, [selectedPackage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (country === "Vietnam/Cambodia") {
      if (CambodiaAccomodationType && CambodiaWhen && selectedCombodiaID) {
        navigate(`/iteneryBuilder/${customId}/Vietnam/${duration}`);
      } else {
        alert(
          `Please select ${
            !selectedCombodiaID
              ? "Cambodia Package"
              : !CambodiaWhen
              ? "Cambodia When"
              : !CambodiaAccomodationType
              ? "Cambodia Accomodation Type"
              : ""
          }`
        );
      }
    } else {
      if(!country || duration === 0  || numberOfPeople === 0){
        alert(`Please select ${
          !country
            ? "Country"
            : !duration
            ? "Duration"
            
            : !numberOfPeople
            ? "Number of People"
            : ""
        }`);
      } else {
      navigate(
        `/iteneryBuilder/${customId}/${country}/${travellerDetails.duration}`
      )
      }
    }
  };

  useEffect(() => {
    console.log(travellerDetails);
    console.log("selectedCountryData: ", selectedCountryData);
  }, [travellerDetails, selectedCountryData]);

  useEffect(() => {
    let tempNumberOfPeople = 0;
    if (adults) {
      tempNumberOfPeople += parseInt(adults);
    }
    if (child) {
      tempNumberOfPeople += parseInt(child);
    }
    setNumberOfPeople(tempNumberOfPeople);
  }, [adults, child]);

  useEffect(() => {
    console.log("numberOfPeople: ", numberOfPeople);
  }, [numberOfPeople]);
  useEffect(() => {
    localStorage.setItem("travellerDetails", JSON.stringify(travellerDetails));
  }, [travellerDetails]);

  // Read from local storage on initial load
  useEffect(() => {
    const savedDetails = localStorage.getItem("travellerDetails");
    // console.log("Loaded from storage:", savedDetails);
    if (savedDetails) {
      setTravellerDetails(JSON.parse(savedDetails));
    }
  }, []);

  useEffect(() => {
    const tempCambodiaPackage = CambodiaPackagesData.filter(
      (item) =>
        item._id === selectedCombodiaID ||
        item.PackageName === selectedCombodiaID
    );
    setSelectedCambodiaPackage(tempCambodiaPackage);
  }, [selectedCombodiaID]);

  if (isLoading) {
    return <TravellerDetailsSkeleton />;
  }
  return (
    <div>
      {currentUser ? (
        <div>
          {/* <div className="flex justify-between items-center">
          <div className="flex justify-start items-center">
            <img src={Logo} alt="" className="h-24 w-24 my-3" />

            <header className="text-2xl font-bold text-white font-caveat">
              {" "}
              Build the Perfect Getaway
            </header>
          </div>
          <LogoutButton />
          </div> */}
          <div className="flex justify-between p-4 bg-gradient-to-br from-[#424242] to-transparent  ">
            <Sidebar />
            <div className="flex items-center ">
              {" "}
              {/* Add items-center to vertically align items */}
              <img
                src={logo}
                alt="Travaura Logo"
                className="h-16 mx-2 flex-shrink-0 pl-7"
                onClick={() => {
                  sessionStorage.removeItem("customId");
                  setCustomId(generateCustomId());
                 window.location.reload();

                }
              }
              />
              <div>
                <p className="text-white font-LeagueSpartan font-bold ml-2 text-4xl">
                  PRO ITINERARY
                </p>
                <p className="text-[16px] ml-2 font-LeagueSpartan text-white">
                  Crafted by Travaura
                </p>
              </div>
            </div>

            {/* <LogoutButton /> */}
            {currentUser.photoURL ? (
            <img src={currentUser.photoURL } alt="user" className="h-12 rounded-full cursor-pointer mr-6" onClick={() => setUserMenuOpen(!userMenuOpen)} />
            ) : (
              <div className="h-12 w-12 rounded-full bg-gray-400 cursor-pointer mr-6" onClick={() => setUserMenuOpen(!userMenuOpen)}></div>
            )
          }
            {userMenuOpen && (
              <div className="absolute top-16 right-0 bg-[#424242] p-4 mt-2 rounded-lg shadow-lg">
               
                  <div className="flex-cols justify-center">
                  <p className="text-white ml-2 mb-2">{currentUser.displayName}</p>
                  <hr className="w-full mb-2" />
                  
               
                  <p className="text-white ml-2 mb-2"> {currentUser.email}</p>
                  <button className=" text-white p-2 rounded-lg  h-fit w-fit mx-3" onClick={logout}>Logout</button>
                  </div>
                  </div>
                  
            )}
          </div>
          {/* <p>{localStorage.getItem("userEmail")}</p> */}
          <div className="flex justify-around items-center mt-4">
            {/* <button
              className="bg-blue-500 text-white p-2 rounded-lg  h-fit w-fit mx-3"
              onClick={() => navigate("/Agent")}
            >
              Go to Dashboard
              
            </button>
            <div className="flex items-center ">
              <input
                type="text"
                className="mx-3 rounded-lg p-4"
                value={travauraID}
                placeholder="Enter Travaura ID"
                onChange={(e) => setTravauraID(e.target.value)}
              />
              <button
                onClick={() => {
                  navigate(`resultPage/${travauraID}`);
                }}
                className="bg-blue-400 rounded-lg p-4"
              >
                Get Itinerary
              </button>
            </div> */}
            <label className="text-[#5E827E] font-medium font-montserrat text-6xl my-8">
              <LabelAnimation text= "Create Itineraries like a PRO." />
            </label>
          </div>

          <form
            onSubmit={handleSubmit}
            className="max-w-5xl mx-auto  bg-secondary p-6 m-4 rounded-xl shadow-md shadow-black shadow-135deg" 
          >
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col mb-4">
                <label className="mb-2 font-semibold text-white">Name:</label>
                <input
                  type="text"
                  value={travellerDetails.name}
                  placeholder="Enter Name"
                  onChange={(e) => {
                    setName(e.target.value);
                    setTravellerDetails({
                      ...travellerDetails,
                      name: e.target.value,
                    });
                  }}
                  className="  p-2   rounded-md  bg-[#121212] placeholder:text-[#E8E8E8]  shadow-inner shadow-black focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 text-white " 
                  style={{ boxShadow: '1px 1px 3px 0px black' }}
                  />

                
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-2 font-semibold text-white">
                  Destination:
                </label>
                <select
                  value={travellerDetails.country}
                  onChange={(e) => {
                    setCountry(e.target.value);
                    setTravellerDetails({
                      ...travellerDetails,
                      country: e.target.value,
                    });
                  }}
                  className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                  style={{ boxShadow: '1px 1px 3px 0px black' }}
                >
                  <option value="">Select Destination</option>
                  <option value="Vietnam">Vietnam</option>
                  <option value="Vietnam/Cambodia">Vietnam/Cambodia</option>
                  <option value="Bali">Bali</option>
                  <option value="Thailand">Thailand</option>
                </select>
              </div>

              {country === "Vietnam/Cambodia" && (
                <div className="flex flex-col mb-4">
                  <label className="mb-2 font-semibold text-white">
                    Cambodia Package:
                  </label>
                  <select
                     className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                     style={{ boxShadow: '1px 1px 3px 0px black' }}
                    value={selectedCombodiaID}
                    onChange={(e) => {
                      setSelectedCombodiaID(e.target.value);
                    }}
                  >
                    <option value="">Select Cambodia Package</option>
                    {CambodiaPackagesData && CambodiaPackagesData.length > 0 ? (
                      CambodiaPackagesData.map((item, index) => (
                        <option
                          key={index}
                          value={item._id || item.PackageName}
                        >
                          {item.PackageName}
                        </option>
                      ))
                    ) : (
                      <option disabled>Loading packages...</option>
                    )}
                  </select>
                </div>
              )}

              {country === "Vietnam/Cambodia" &&
                selectedCambodiaPackage &&
                selectedCambodiaPackage.length > 0 && (
                  <div className="flex flex-col mb-4">
                    <label className="mb-2 font-semibold text-white">
                      Cambodia When:
                    </label>
                    <select
                        className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                        style={{ boxShadow: '1px 1px 3px 0px black' }}
                      value={CambodiaWhen}
                      onChange={(e) => {
                        setCambodiaWhen(e.target.value);
                      }}
                    >
                      <option value="">Select Before/After</option>
                      <option value="Before Vietnam">Before Vietnam</option>
                      <option value="After Vietnam">After Vietnam</option>
                    </select>
                  </div>
                )}

              {country === "Vietnam/Cambodia" &&
                selectedCambodiaPackage &&
                selectedCambodiaPackage.length > 0 && (
                  <div className="flex flex-col mb-4">
                    <label className="mb-2 font-semibold text-white">
                      Cambodia Accomodation Type:
                    </label>
                    <select
                        className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                        style={{ boxShadow: '1px 1px 3px 0px black' }}
                      value={CambodiaAccomodationType}
                      onChange={(e) => {
                        setCambodiaAccomodationType(e.target.value);
                      }}
                    >
                      <option value="">Select Accomodation Type</option>
                      <option value="3Star">3 Star</option>
                      <option value="4Star">4 Star</option>
                      <option value="5Star">5 Star</option>
                    </select>
                  </div>
                )}

              <div className="flex flex-col mb-4">
                <label className="mb-2 font-semibold text-white">
                  Trip Type:
                </label>
                <select
                  value={travellerDetails.tripType}
                  onChange={(e) => {
                    setTripType(e.target.value);
                    setTravellerDetails({
                      ...travellerDetails,
                      tripType: e.target.value,
                    });
                  }}
                  className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                  style={{ boxShadow: '1px 1px 3px 0px black' }}
                >
                  <option value="">Select Trip Type</option>
                  <option value="Solo Travel">Solo Travel</option>
                  <option value="Couple Travel">Couple Travel</option>
                  <option value="Honeymoon">Honeymoon</option>
                  <option value="Family Trip">Family Trip</option>
                  <option value="Cousins Trip">Cousins Trip</option>
                  <option value="Bachelors">Bachelors</option>
                  <option value="Group Trip">Group Trip</option>
                  <option value="Private Trip">Private Trip</option>
                </select>
              </div>
              {/* Trip Type Options - Solo Travel , Couple Travel, Honeymoon, Family Trip, Cousins Trip, Bachelors, Group Trip, Private Trip */}

              <div className="flex flex-col mb-4">
                <label className="mb-2 font-semibold text-white">
                  Accommodation Type:
                </label>
                <select
                  value={travellerDetails.accommodationType}
                  onChange={(e) => {
                    setAccommodationType(e.target.value);
                    setTravellerDetails({
                      ...travellerDetails,
                      accommodationType: e.target.value,
                    });
                  }}
                  className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                  style={{ boxShadow: '1px 1px 3px 0px black' }}
                >
                  <option value="">Select Accommodation Type</option>
                  <option value="Mixed Accommodation">
                    Mixed Accommodation
                  </option>
                  <option value="Budgeted Hotel">Budgeted Hotel</option>
                  <option value="3 Star Hotel">3 Star Hotel</option>
                  <option value="4 Star Hotel">4 Star Hotel</option>
                  <option value="5 Star Hotel">5 Star Hotel</option>
                  <option value="Homestay">Homestay</option>
                  <option value="Villa">Villa</option>
                  <option value="Resort">Resort</option>
                  <option value="Camps">Camps</option>
                </select>
              </div>
              {/* Accommodation Type Options: Mixed Accommodation, Budgeted Hotel, 3 Star Hotel, 4 Star Hotel, 5 Star Hotel, Homestay, Villa, Resort, Camps */}

              <div className="flex flex-col mb-4 ">
                <label className="mb-2 font-semibold text-white">
                  Date of Travel:
                </label>

                {/* <input
                  type="date"
                  value={travellerDetails.dateOfTravel}
                  onChange={(e) => {
                    setDateOfTravel(e.target.value);
                    setTravellerDetails({
                      ...travellerDetails,
                      dateOfTravel: e.target.value,
                    });
                  }}
                  className="  p-2   rounded-md focus:outline-none   bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  border-none focus:ring-0 pl-6 text-white"  
                  style={{ boxShadow: '1px 1px 3px 0px black' }}
                /> */}
                <DatePicker
  selected={travellerDetails.dateOfTravel ? new Date(travellerDetails.dateOfTravel) : ""}
  onChange={(date) => {
    setDateOfTravel(date);
    setTravellerDetails({
      ...travellerDetails,
      dateOfTravel: date,
    });
  }}
  minDate={new Date()}
  placeholderText="Select Date"
  className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
  style={{ boxShadow: '1px 1px 3px 0px black' }}
/>

              </div>

              <div className="flex flex-col mb-4">
                <label className=" mb-2 font-semibold text-white">
                  Traveller Count:
                </label>
                <div className=" grid-cols-6 grid gap-3">
                  <div className="flex justify-end items-center">
                    <label className="mb-2 font-semibold text-white">
                      Adults:
                    </label>
                  </div>
                  <input
                    type="number"
                    value={travellerDetails.adults}
                    min={1}
                    defaultValue={1}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setAdults(e.target.value.trim());
                      setTravellerDetails({
                        ...travellerDetails,
                        adults: parseInt(e.target.value) || 1,
                      });
                    }}
                    className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                    style={{ boxShadow: '1px 1px 3px 0px black' }}
                  />

                  <div className="flex justify-end items-center">
                    <label className="mb-2 font-semibold text-white">
                      Kids:
                    </label>
                  </div>
                  <input
                    type="number"
                    value={travellerDetails.child}
                    min={0}
                    defaultValue={0}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setChild(e.target.value.trim());
                      setTravellerDetails({
                        ...travellerDetails,
                        child: parseInt(e.target.value) || 0,
                      });
                    }}
                    className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                    style={{ boxShadow: '1px 1px 3px 0px black' }}
                  />

                  <div className="flex justify-end items-center">
                    <label className="mb-2 font-semibold text-white">
                      Infants:
                    </label>
                  </div>

                  <input
                    type="number"
                    value={travellerDetails.infants}
                    min={0}
                    defaultValue={0}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setInfants(e.target.value.trim());
                      setTravellerDetails({
                        ...travellerDetails,
                        infants: e.target.value,
                      });
                    }}
                    className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                    style={{ boxShadow: '1px 1px 3px 0px black' }}
                  />
                </div>
              </div>

              {/* Traveller count to be distributed among “Adult(s)”, “Kid(s)”, “Infant(s)” */}

              <div className="flex flex-col mb-4">
                <label className="mb-2 font-semibold text-white">
                  International Flights:
                </label>
                <select
                  value={travellerDetails.internationalFlights}
                  onChange={(e) => {
                    setInternationalFlights(e.target.value);
                    setTravellerDetails({
                      ...travellerDetails,
                      internationalFlights: e.target.value,
                    });
                  }}
                  className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                  style={{ boxShadow: '1px 1px 3px 0px black' }}
                >
                  <option value="">Select International Flights</option>
                  <option value="Included">Included</option>
                  <option value="Excluded">Excluded</option>
                  <option value="Not Required in this Itinerary">
                    Not Required in this Itinerary
                  </option>
                </select>
              </div>

              {/* International Flights Option, Included, Excluded, Not Required in this Itinerary */}

              <div className="flex flex-col mb-4">
                <label className="mb-2 font-semibold text-white">
                  Domestic Flights:
                </label>
                <select
                  value={travellerDetails.domesticFlights}
                  onChange={(e) => {
                    setDomesticFlights(e.target.value);
                    setTravellerDetails({
                      ...travellerDetails,
                      domesticFlights: e.target.value,
                    });
                  }}
                  className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                  style={{ boxShadow: '1px 1px 3px 0px black' }}
                >
                  <option value="">Select Domestic Flights</option>
                  <option value="Included">Included</option>
                  <option value="Excluded">Excluded</option>
                  <option value="Not Required in this Itinerary">
                    Not Required in this Itinerary
                  </option>
                </select>
              </div>

              <div className="flex flex-col mb-4">
                <label className="mb-2 font-semibold text-white">Visa:</label>
                <select
                  value={travellerDetails.visa}
                  onChange={(e) => {
                    setTravellerDetails({
                      ...travellerDetails,
                      visa: e.target.value,
                    });
                  }}
                  className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                  style={{ boxShadow: '1px 1px 3px 0px black' }}
                >
                  <option value="">Select Visa</option>
                  <option value="Included">Included</option>
                  <option value="Excluded">Excluded</option>
                  <option value="Visa on Arrival">Visa on Arrival</option>
                  <option value="Visa Free Destination">
                    Visa Free Destination
                  </option>
                  <option value="Not Required in this Itinerary">
                    Not Required in this Itinerary
                  </option>
                </select>
              </div>
              {/* Visa options, Included, Excluded, Visa on Arrival, Visa Free Destination, Not Required in this Itinerary */}

              <div className=" mb-4">
                <label className="mb-2 font-semibold text-white">
                  Duration:
                </label>
                <div className="grid grid-cols-6 gap-4 m-3">
                  <div className="flex justify-end items-center">
                    <label className="mr-2  font-semibold text-white">
                      Days:
                    </label>
                  </div>
                  <input
                    type="number"
                    value={travellerDetails.duration}
                    min={
                      selectedCambodiaPackage &&
                      selectedCambodiaPackage[0] &&
                      selectedCambodiaPackage[0].Activities
                        ? selectedCambodiaPackage[0].Activities.length
                        : 0
                    }
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      setDuration(e.target.value.trim());
                      setNumberOfDays(e.target.value.trim());
                      setTravellerDetails({
                        ...travellerDetails,
                        duration: e.target.value.trim(),
                      });
                    }}
                    className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                    style={{ boxShadow: '1px 1px 3px 0px black' }}
                  />
                  <div className="flex justify-end items-center">
                    <label className="mb-2 font-semibold text-white">
                      Nights:
                    </label>
                  </div>
                  <input
                    type="number"
                    value={
                      travellerDetails.nights ||
                      Math.max(travellerDetails.duration - 1, 0)
                    }
                    onWheel={(e) => e.target.blur()}
                    min={0}
                    onChange={(e) => {
                      setNights(e.target.value);
                      setTravellerDetails({
                        ...travellerDetails,
                        nights: e.target.value,
                      });
                    }}
                    className="  p-2   rounded-md    bg-[#121212] placeholder:text-[#E8E8E8] text-[#E8E8E8] shadow-inner shadow-black  focus:outline-1   focus:border-solid border-none focus:ring-1 pl-6 "  
                    style={{ boxShadow: '1px 1px 3px 0px black' }}
                  />
                </div>
              </div>

              <div className="flex flex-col mb-4 col-span-2">
                <button
                  type="submit"
                  className=" py-2 px-4 bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121] font-semibold font-montserrat rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Build Itinerary
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="text-white text-center font-semibold">
          Not Logged in
          <Signin />
        </div>
      )}
    </div>
  );
}

export default TravellerDetails;
