import React from "react";
import { useState, useEffect } from "react";
import UserContext from "./contexts/UserContext";
import { useContext } from "react";

const BaliInterCityTransfers = (props) => {
  const SourcesAndArrivals = {
    Ubud: ["Kuta", "Jimbaran", "Seminyak"],
    Sanur: ["Kuta", "Jimbaran", "Seminyak", "Canggu", "Tanah Lot", "Ubud Area"],
    Seminyak: ["Kuta", "Jimbaran", "Seminyak"],
  };
  const ThailandSourcesAndArrivals = {
    Bangkok: ["Pattaya"],
    Phuket: ["Krabi", "Surat Thani"],
    Krabi: ["Surat Thani"],
    "Koh Samui Sea Port": ["Ko Samui Hotel"],
  };

  const {
    country,
    SelectedBaliIntercityTransfers,
    setSelectedBaliIntercityTransfers,
    BaliIntercitySourceByDay,
    setBaliIntercitySourceByDay,
    BaliIntercityArrivalByDay,
    setBaliIntercityArrivalByDay,
    checkedBaliIntercityTransfers,
    setCheckedBaliIntercityTransfers,
    numberOfIntercityTransfersBali,
    setNumberOfIntercityTransfersBali,
    InterCityTransferData,
    setInterCityTransferData,
  } = useContext(UserContext);
  //   const [selectedSource, setSelectedSource] = useState("");
  //   const [selectedArrival, setSelectedArrival] = useState("");
  // const [InterCityTransferData, setInterCityTransferData] = useState([]);

  useEffect(() => {
    const selectedTransfer = SelectedBaliIntercityTransfers.find(
      (element) => element.day === props.day
    );

    if (selectedTransfer) {
      setCheckedBaliIntercityTransfers((prevCounts) => {
        const updatedCounts = [...prevCounts];
        updatedCounts[props.day - 1] = selectedTransfer;
        return updatedCounts;
      });

      setBaliIntercityArrivalByDay((prevCounts) => {
        const updatedCounts = [...prevCounts];
        updatedCounts[props.day - 1] = selectedTransfer.arrival;
        return updatedCounts;
      });

      setBaliIntercitySourceByDay((prevCounts) => {
        const updatedCounts = [...prevCounts];
        updatedCounts[props.day - 1] = selectedTransfer.source;
        return updatedCounts;
      });

      setNumberOfIntercityTransfersBali((prevCounts) => {
        const updatedCounts = [...prevCounts];
        if (!updatedCounts[props.day - 1]) {
          updatedCounts[props.day - 1] = {};
        }
        updatedCounts[props.day - 1][selectedTransfer._id] =
          selectedTransfer.numberOfTransfers;
        return updatedCounts;
      });
    }
  }, [SelectedBaliIntercityTransfers, props.day]);

  useEffect(() => {
    if (country === "Bali") {
      fetch(
        "https://travaurabackend.azurewebsites.net/api/baliIntercityTransfersSchema"
      )
        .then((response) => response.json())
        .then((data) => setInterCityTransferData(data));
    } else if (country === "Thailand") {
      fetch(
        "https://travaurabackend.azurewebsites.net/api/ThailandIntercityTransfersSchema"
      )
        .then((response) => response.json())
        .then((data) => setInterCityTransferData(data));
    }
  }, []);

  useEffect(() => {
    console.log(
      "SelectedbaliIntercityTransfers: ",
      SelectedBaliIntercityTransfers,
      "baliIntercitySourceByDay: ",
      BaliIntercitySourceByDay
    );
  }, [
    SelectedBaliIntercityTransfers,
    BaliIntercitySourceByDay,
    numberOfIntercityTransfersBali,
  ]);

  const handleSelectedBaliIntercityTransfers = (item) => {
    const currentDay = props.day;
    const currentSource = BaliIntercitySourceByDay[currentDay - 1];
    const currentArrival = BaliIntercityArrivalByDay[currentDay - 1];
    const isAlreadySelected = SelectedBaliIntercityTransfers.find(
      (element) =>
        element.day === currentDay &&
        element.source === currentSource &&
        element.arrival === currentArrival &&
        element.seater === item.seater
    );

    if (isAlreadySelected) {
      const temp = SelectedBaliIntercityTransfers.filter(
        (element) =>
          element.day !== currentDay ||
          element.source !== currentSource ||
          element.arrival !== currentArrival ||
          element.seater !== item.seater
      );
      setSelectedBaliIntercityTransfers(temp);
      const temp2 = checkedBaliIntercityTransfers.filter(
        (element) =>
          element.day !== currentDay ||
          element.source !== currentSource ||
          element.arrival !== currentArrival ||
          element.seater !== item.seater
      );
      setCheckedBaliIntercityTransfers(temp2);
      setNumberOfIntercityTransfersBali((prevCounts) => {
        const updatedCounts = [...prevCounts];
        if (!updatedCounts[currentDay - 1]) {
          updatedCounts[currentDay - 1] = {};
        }
        updatedCounts[currentDay - 1][item._id] = 0;
        return updatedCounts;
      });
    } else {
      const temp = [...SelectedBaliIntercityTransfers];
      temp.push({
        day: currentDay,
        source: currentSource,
        arrival: currentArrival,
        seater: item.seater,
        priceInr: item.priceInr,
        numberOfTransfers:
          parseInt(
            numberOfIntercityTransfersBali[currentDay - 1]?.[item._id]
          ) || 1,
      });

      setSelectedBaliIntercityTransfers(temp);
      const temp2 = [...checkedBaliIntercityTransfers];
      temp2.push({
        day: currentDay,
        source: currentSource,
        arrival: currentArrival,
        seater: item.seater,
        priceInr: item.priceInr,
        numberOfTransfers:
          parseInt(
            numberOfIntercityTransfersBali[currentDay - 1]?.[item._id]
          ) || 1,
      });
      setCheckedBaliIntercityTransfers(temp2);
      setNumberOfIntercityTransfersBali((prevCounts) => {
        const updatedCounts = [...prevCounts];
        if (!updatedCounts[currentDay - 1]) {
          updatedCounts[currentDay - 1] = {};
        }
        updatedCounts[currentDay - 1][item._id] =
          parseInt(
            numberOfIntercityTransfersBali[currentDay - 1]?.[item._id]
          ) || 1;
        return updatedCounts;
      });
    }
  };

  return (
    <div>
      <div className="flex mb-5">
        <select
          class="active:scale-95 bg-gray-700 text-white rounded-md w-full p-2 mx-2"
          value={BaliIntercitySourceByDay[props.day - 1] || ""}
          onChange={(e) => {
            let temp = [...BaliIntercitySourceByDay];
            temp[props.day - 1] = e.target.value;
            setBaliIntercitySourceByDay(temp);
          }}
        >
          <option value="">Select Source</option>
          {country === "Bali" &&
            Object.keys(SourcesAndArrivals).map((source, index) => (
              <option key={index} value={source}>
                {source}
              </option>
            ))}
          {country === "Thailand" &&
            Object.keys(ThailandSourcesAndArrivals).map((source, index) => (
              <option key={index} value={source}>
                {source}
              </option>
            ))}
        </select>

        <select
          class="active:scale-95 bg-gray-700 text-white rounded-md w-full p-2 mx-2"
          value={BaliIntercityArrivalByDay[props.day - 1] || ""}
          onChange={(e) => {
            let temp = [...BaliIntercityArrivalByDay];
            temp[props.day - 1] = e.target.value;
            setBaliIntercityArrivalByDay(temp);
          }}
        >
          <option value="">Select Arrival</option>
          {country === "Bali" &&
            BaliIntercitySourceByDay[props.day - 1] &&
            SourcesAndArrivals[BaliIntercitySourceByDay[props.day - 1]].map(
              (arrival, index) => (
                <option key={index} value={arrival}>
                  {arrival}
                </option>
              )
            )}
          {country === "Thailand" &&
            BaliIntercitySourceByDay[props.day - 1] &&
            ThailandSourcesAndArrivals[
              BaliIntercitySourceByDay[props.day - 1]
            ].map((arrival, index) => (
              <option key={index} value={arrival}>
                {arrival}
              </option>
            ))}
        </select>
      </div>
      <div className="flex mx-3 overflow-x-auto">
        {InterCityTransferData.map((item, index) => (
          <div key={index}>
            {item.source === BaliIntercitySourceByDay[props.day - 1] &&
              item.arrival === BaliIntercityArrivalByDay[props.day - 1] && (
                <div class="bg-gray-700 p-4 rounded-md  my-3 mx-5 ">
                  <div className=" ">
                    <div class="mb-2 p-2 ">
                      <div class="text-xl font-medium">
                        {item.seater} Seater
                      </div>

                      <div class="text-lg text-gray-200">
                        ₹
                        {item.priceInr
                          ? item.priceInr.toLocaleString("en-IN")
                          : 0}
                      </div>
                    </div>
                    <input
                      type="number"
                      class="text-gray-800 rounded-lg px-1 w-10 my-2 bg-gray-400"
                      min="0"
                      max="10"
                      value={
                        numberOfIntercityTransfersBali[props.day - 1]?.[
                          item._id
                        ] ||
                        SelectedBaliIntercityTransfers.find(
                          (element) =>
                            element.day === props.day &&
                            element.source ===
                              BaliIntercitySourceByDay[props.day - 1] &&
                            element.arrival ===
                              BaliIntercityArrivalByDay[props.day - 1] &&
                            element.seater === item.seater
                        )?.numberOfTransfers ||
                        0
                      }
                      onChange={(prevCounts) => {
                        const updatedCounts = [
                          ...numberOfIntercityTransfersBali,
                        ];
                        if (!updatedCounts[props.day - 1]) {
                          updatedCounts[props.day - 1] = {};
                        }
                        updatedCounts[props.day - 1][item._id] =
                          prevCounts.target.value;
                        setNumberOfIntercityTransfersBali(updatedCounts);
                        SelectedBaliIntercityTransfers.find(
                          (element) =>
                            element.day === props.day &&
                            element.source ===
                              BaliIntercitySourceByDay[props.day - 1] &&
                            element.arrival ===
                              BaliIntercityArrivalByDay[props.day - 1] &&
                            element.seater === item.seater
                        ).length > 0 &&
                          (SelectedBaliIntercityTransfers.find(
                            (element) =>
                              element.day === props.day &&
                              element.source ===
                                BaliIntercitySourceByDay[props.day - 1] &&
                              element.arrival ===
                                BaliIntercityArrivalByDay[props.day - 1] &&
                              element.seater === item.seater
                          ).numberOfTransfers = prevCounts.target.value);
                      }}
                    />
                  </div>

                  <button
  className={`bg-blue-700 text-white rounded-md px-2 text-2xl ${
    Array.isArray(checkedBaliIntercityTransfers) && typeof props.day !== 'undefined' &&
    checkedBaliIntercityTransfers.some(
      (element) =>
       element.day && typeof element.day !== 'undefined' &&
        element.day === props.day &&
        typeof element.source !== 'undefined' &&
        element.source === BaliIntercitySourceByDay[props.day - 1] &&
        typeof element.arrival !== 'undefined' &&
        element.arrival === BaliIntercityArrivalByDay[props.day - 1] &&
        element.seater === item.seater
    ) ? "bg-green-500" : ""
  }`}
  onClick={() => handleSelectedBaliIntercityTransfers(item)}
>
  {Array.isArray(checkedBaliIntercityTransfers) && typeof props.day !== 'undefined' &&
  checkedBaliIntercityTransfers.some(
    (element) =>
      element.day && typeof element.day !== 'undefined' &&
      element.day === props.day &&
      typeof element.source !== 'undefined' &&
      element.source === BaliIntercitySourceByDay[props.day - 1] &&
      typeof element.arrival !== 'undefined' &&
      element.arrival === BaliIntercityArrivalByDay[props.day - 1] &&
      element.seater === item.seater
  ) ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 text-white"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M5 13l4 4L19 7"
      />
    </svg>
  ) : (
    "+"
  )}
</button>

                </div>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BaliInterCityTransfers;
