import React from "react";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import { useState, useEffect } from "react";

const InternationalFlightComponent = (props) => {
  const [departureCity, setDepartureCity] = useState("");
  const [arrivalCity, setArrivalCity] = useState("");
  const [carrier, setCarrier] = useState("");
  const [baggage, setBaggage] = useState("");
  const [journeyDate, setJourneyDate] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [price, setPrice] = useState(0);
  const [noOfStops, setNoOfStops] = useState(0);
  const { internationalFlights, setInternationalFlights, country } =
    useContext(UserContext);
  const [clicked, setClicked] = useState(false);
  const citiesAndCodes = [
    "Kochi (COK)",
    "Tiruchirapalli (TRZ)",
    "Thiruvananthapuram (TRV)",
    "Chennai (MAA)",
    "Ahmedabad (AMD)",
    "Mumbai (BOM)",
    "Kolkata (CCU)",
    "Amritsar (ATQ)",
    "Coimbatore (CJB)",
    "New Delhi (DEL)",
    "Visakhapatnam (VTZ)",
    "Bengaluru (BLR)",
    "Hyderabad (HYD)",
    "Bagdogra (IXB)",
    "Kannur (CNN)",
    "Bhubaneswar (BBI)",
    "Guwahati (GAU)",
    "Pune (PNQ)",
    "Gaya (GAY)",
    "Raipur (RPR)",
    "Dibrugarh (DIB)",
    "Patna (PAT)",
    "Jaipur (JAI)",
    "Mopa (GOI)",
    "Varanasi (VNS)",
    "Indore (IDR)",
    "Chandigarh (IXC)",
  ];

  const vietnameseCities = [
    "Ho Chi Minh City (SGN)",
    "Hanoi (HAN)",
    "Da Nang (DAD)",
  ];
  const baliCities = ["Denpasar (DPS)"];

  const carrierNames = [
    "IndoViet Air",
    "Subcontinental Wings",
    "Asian Skyways",
    "Pacific Jetstream",
    "Emerald Airlines",
    "Lotus Airways",
  ];

  const baggageAllowance = ["7 Kgs", "7+20 Kgs", "7+23 Kgs", "7+30 Kgs"];

// setJourneyDate(displayFormat);
const handleFlightChange = (field, value) => {
  // Ensure the internationalFlights array is correctly initialized
  if (!internationalFlights || !Array.isArray(internationalFlights)) {
    console.error('International flights data is not correctly initialized');
    return;
  }

  // Copy the current international flights array
  const updatedFlights = [...internationalFlights];

  // Update the specific field for the flight
  updatedFlights[props.flightId] = {
    ...updatedFlights[props.flightId],
    [field]: value,
  };

  // Update the international flights in the context
  setInternationalFlights(updatedFlights);
};



  useEffect(() => {
    console.log("departureCity: ", departureCity);
    console.log("arrivalCity: ", arrivalCity);
    console.log("internationalFlights: ", internationalFlights);
  }, [internationalFlights, departureCity, arrivalCity]);

  
  return (
    <div class="mb-4 p-5 bg-gray-800 lg:flex lg:flex-wrap lg:justify-between">
  <div class="lg:w-1/2 lg:px-2">
    <label class="block text-gray-400 text-sm font-medium mb-2">
      International Flights {props.key}
    </label>
    <select
      
      class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 my-2"
      value={internationalFlights[props.flightId]?.departureCity || ""}
      onChange={(e) => handleFlightChange('departureCity', e.target.value)}
    >
      <option>Departure City</option>
      {citiesAndCodes.map((item, ind) => (
        <option key={ind} value={item}>
          {item}
        </option>
      ))}
      {country==="Vietnam" && vietnameseCities.map((item, ind) => (
        <option key={ind} value={item}>
          {item}
        </option>
      ))}
      {country==="Bali" && baliCities.map((item, ind) => (
        <option key={ind} value={item}>
          {item}
        </option>
      ))}
    </select>
    <select
      class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 my-2"
      onChange={(e) => handleFlightChange('arrivalCity', e.target.value)}
  className="bg-gray-700 text-white rounded-md w-full p-2 my-2"
    >
      <option>Arrival City</option>
      {country==="Vietnam" && vietnameseCities.map((item, ind) => (
        <option key={ind} value={item}>
          {item}
        </option>
      ))}
      {country==="Bali" && baliCities.map((item, ind) => (
        <option key={ind} value={item}>
          {item}
        </option>
      ))}
      {citiesAndCodes.map((item, ind) => (
        <option key={ind} value={item}>
          {item}
        </option>
      ))}
    </select>
    <input
      type="text"
      placeholder="Enter Carrier"
      class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 my-2"
      value={internationalFlights[props.flightId]?.carrier || ""}
      onChange={(e) => handleFlightChange('carrier', e.target.value)}
    >
      
    </input>
    
    <select
      class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 my-2"
      value={internationalFlights[props.flightId]?.baggage || ""}
      onChange={(e) => handleFlightChange('baggage', e.target.value)}
    >
      <option>Baggage</option>
      {baggageAllowance.map((item, ind) => (
        <option key={ind} value={item}>
          {item}
        </option>
      ))}
    </select>
  </div>
  <div class="lg:w-1/2 lg:px-2">
    <label class="block text-gray-400 text-sm font-medium">
      Journey Date
    </label>
    <input
      type="date"
      placeholder="Journey Date"
      value={internationalFlights[props.flightId]?.journeyDate || ""}
      onChange={(e) => handleFlightChange('journeyDate', e.target.value)}
      class="hover:scale-105 bg-gray-700 text-white rounded-md w-fit p-2 mb-2"
    />
    <label class="block text-gray-400 text-sm font-medium ">
      Departure Time
    </label>
    <input
      type="time"
      placeholder="Departure Time"
      min="00:00"
      max="23:59"
      value={internationalFlights[props.flightId]?.departureTime || ""}
  onChange={(e) => handleFlightChange('departureTime', e.target.value)}
      class="hover:scale-105 bg-gray-700 text-white rounded-md w-fit p-2 mb-2"
    />
    <label class="block text-gray-400 text-sm font-medium">
      Arrival Time
    </label>
    <input
      type="time"
      placeholder="Arrival Time"
      value={internationalFlights[props.flightId]?.arrivalTime || ""}
      onChange={(e) => handleFlightChange('arrivalTime', e.target.value)}
      class="hover:scale-105 bg-gray-700 text-white rounded-md w-fit p-2 mb-2"
    />

    <label class="block text-gray-400 text-sm font-medium">Price</label>
    <input
      type="number"
      placeholder="Price"
      onWheel={(e) => e.target.blur()}
      value={internationalFlights[props.flightId]?.price || 0}
      onChange={(e) => handleFlightChange('price', e.target.value)}
      class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 mb-4"
    />
<label class="block text-gray-400 text-sm font-medium">Stops</label>
    <input type="number" onWheel={(e) => e.target.blur()} placeholder="Stops" min={0} class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 mb-4" value={internationalFlights[props.flightId]?.noOfStops || 0}
  onChange={(e) => handleFlightChange('noOfStops', e.target.value)}/>

  </div>
</div>

  );
};

export default InternationalFlightComponent;
