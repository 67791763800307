import React from "react";
import { useState, useEffect } from "react";
import image from "./image.jpeg";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";



const FLightSearch = () => {
  const { id, country, duration } = useParams();

  const [tripType, setTripType] = useState("roundTrip");
  const [source, setSource] = useState([]);
  const [destination, setDestination] = useState([]);
  const [sourceID, setSourceID] = useState([]);
  const [destinationID, setDestinationID] = useState([]);
  const [cabinClass, setCabinClass] = useState("ECONOMY");
  const [sortBy, setSortBy] = useState("BEST"); // BEST, CHEAPEST, FASTEST
  const navigate = useNavigate();
  const { setFlights } = useContext(UserContext);
  const [date, setDate] = useState([]);
  const [returnDate, setReturnDate] = useState("");
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState("");
  const [nonStop, setNonStop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numberOfFlights, setNumberOfFlights] = useState(1);
  const [sourceCityRecommendations, setSourceCityRecommendations] = useState(
    []
  );
  const [destinationCityRecommendations, setDestinationCityRecommendations] =
    useState([]);
  const [sourceClick, setSourceClick] = useState([]);
  const [destinationClick, setDestinationClick] = useState([]);
  const [sourceIndexToWatch, setSourceIndexToWatch] = useState(null); // Now a state variable
  const [destinationIndexToWatch, setDestinationIndexToWatch] = useState(null); // Now a state variable
  const prevSourceRef = useRef();
  const rapidapi_key = process.env.REACT_APP_RAPID_API_KEY;
  const rapidapi_host = process.env.REACT_APP_RAPID_API_HOST;

  useEffect(() => {
    console.log(sourceCityRecommendations);
    console.log(destinationCityRecommendations);
    console.log(sourceID);
    console.log(destinationID);
  }, [
    sourceCityRecommendations,
    destinationCityRecommendations,
    sourceID,
    destinationID,
  ]);

  // Effect for source input
  useEffect(() => {
    // Ensure indexToWatch is not null and within the range of sources
    if (sourceIndexToWatch !== null && source[sourceIndexToWatch]) {
      const currentSource = source[sourceIndexToWatch];

      if (
        currentSource &&
        currentSource.length >= 3 &&
        currentSource !== prevSourceRef.current
      ) {
        prevSourceRef.current = currentSource; // Update the previous source reference

        // Fetch source city recommendations
        const options = {
          method: "GET",
          url: "https://booking-com15.p.rapidapi.com/api/v1/flights/searchDestination",
          params: { query: currentSource },
          headers: {
            "X-RapidAPI-Key": rapidapi_key,
            "X-RapidAPI-Host": rapidapi_host,
          },
        };

        axios
          .request(options)
          .then((response) => {
            let temp = [...sourceCityRecommendations];
            temp[sourceIndexToWatch] = response.data;
            setSourceCityRecommendations(temp);
            console.log(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
          });
      }
    }
  }, [source, sourceIndexToWatch]);

  // Effect for destination input
  useEffect(() => {
    // Ensure indexToWatch is not null and within the range of destinations
    if (
      destinationIndexToWatch !== null &&
      destination[destinationIndexToWatch]
    ) {
      const currentDestination = destination[destinationIndexToWatch];

      if (
        currentDestination &&
        currentDestination.length >= 3 &&
        currentDestination !== prevSourceRef.current
      ) {
        prevSourceRef.current = currentDestination; // Update the previous destination reference

        // Fetch destination city recommendations

        const options = {
          method: "GET",
          url: "https://booking-com15.p.rapidapi.com/api/v1/flights/searchDestination",
          params: { query: currentDestination },
          headers: {
            "X-RapidAPI-Key": rapidapi_key,
            "X-RapidAPI-Host": rapidapi_host,
          },
        };

        axios
          .request(options)
          .then((response) => {
            let temp = [...destinationCityRecommendations];
            temp[destinationIndexToWatch] = response.data;
            setDestinationCityRecommendations(temp);
            console.log(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data: ", error);
          });
      }
    }
  }, [destination, destinationIndexToWatch]);

  const getFlights = async () => {
    // Check for required parameters
    if (!sourceID || !destinationID || !date) {
      alert("Please provide all required fields: From, To, and Departure Date.");
      return; // Exit the function if required fields are missing
    }
  
    setLoading(true);
  
    // Common parameters
    let params = {
      currency_code: "INR",
      adults: adults || undefined,
      
      sort: sortBy || undefined,
      
      cabinClass: cabinClass || undefined,
    };
  
    if (tripType === "roundTrip" || tripType === "oneWay") {
      params = {
        ...params,
        fromId: sourceID[0],
        toId: destinationID[0],
        departDate: date[0],
      };
      if (returnDate) {
        params.returnDate = returnDate;
      }
      if (children) {
        params.children = children;
      }
      if (nonStop) {
        params.nonStop = nonStop;
      }
    } else if (tripType === "multiCity") {
      if (source.length === destination.length && source.length === date.length) {
        const legs = source.map((src, index) => ({
          fromId: sourceID[index],
          toId: destinationID[index],
          date: date[index]
        }));
      
        params.legs = JSON.stringify(legs);
        if(children) {
          params.children = children;
        }
        
        console.log(params);
      } else {
        console.error("The arrays sources, destinations, and departDates must be of the same length.");
        return; // Exit the function if arrays are not of the same length
      }
    }
  
    const options = {
      method: "GET",
      url: `${ tripType === "roundTrip" || tripType === "oneWay" ? "https://booking-com15.p.rapidapi.com/api/v1/flights/searchFlights" : "https://booking-com15.p.rapidapi.com/api/v1/flights/searchFlightsMultiStops"}`,
      params: params,
      headers: {
        "X-RapidAPI-Key": rapidapi_key,
        "X-RapidAPI-Host": rapidapi_host,
      },
    };
  
    try {
      const response = await axios.request(options);
      
      setFlights(response.data);
      setLoading(false);
      console.log("Flights: ", response.data);
      if (response.data.error && response.data.error.code === "SEARCH_SEARCHFLIGHTS_NO_FLIGHTS_FOUND") {
        // Handle no flights found
        handleNoFlightsFound();
      } else if (response.data.data && response.data.data.length === 0) {
        // Handle empty results but no explicit error message
        handleNoFlightsFound();
      } else {
        // Proceed with navigating to the search results page
        setFlights(response.data);
        navigate(`/flightsSearchResults/${id}/${country}/${duration}`);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false);
    }
  };
  // data.error.code
  const handleNoFlightsFound = () => {
    alert('No flights available for the selected criteria. Please try modifying your search.');
    
  };

  useEffect(() => {
    console.log(tripType);
    console.log(source);
    console.log(destination);
    console.log(date);
    console.log(sourceCityRecommendations);
  }, [tripType, source, destination, date, sourceCityRecommendations]);

  const handleTripTypeChange = (event) => {
    setTripType(event.target.value);
    if (event.target.value === "oneWay") {
      setNumberOfFlights(1);
    } else if (event.target.value === "roundTrip") {
      setNumberOfFlights(1);
    } else if (event.target.value === "multiCity") {
      setNumberOfFlights(2);
    }
  };

  const handleSourceChange = (value, ind) => {
    let temp = [...source];
    temp[ind] = value;
    setSource(temp);
    setSourceIndexToWatch(ind);
  };

  const handleDestinationChange = (value, ind) => {
    let temp = [...destination];
    temp[ind] = value;
    setDestination(temp);
    setDestinationIndexToWatch(ind);
  };
  // Render Destination Recommendations

  const renderDestinationRecommendations = (ind) => {
    return (
      <div
        className={`grid mx-2 mb-4 ${
          numberOfFlights > 2 ? "grid-cols-3" : "grid-cols-2"
        }`}
      >
        <div className="relative px-2 w-full mb-4 md:mb-0">
          <label className="block text-gray-400 mb-1 text-sm relative top-4 z-10 bg-black w-fit left-5 px-1">
            From
          </label>
          <div className="flex items-center bg-black rounded border-gray-500 border-2">
            <i className="fas fa-plane-departure text-gray-400"></i>
            <input
              type="text"
              className="w-full py-2 px-2 rounded bg-black text-gray-400"
              placeholder="Enter Source"
              value={source[ind] || ""}
              onClick={() => {
                let temp = [...sourceClick];
                temp[ind] = true;
                setSourceClick(temp);
              }}
              onChange={(e) => handleSourceChange(e.target.value, ind)}
            />
          </div>
          <div className="recommendation-container">
            {sourceClick[ind] &&
              sourceCityRecommendations[ind] &&
              sourceCityRecommendations[ind].data.map((city, index) => (
                <div
                  key={index}
                  className="recommendation-item"
                  onClick={() => {
                    handleSourceChange(city.cityName? city.cityName : city.name, ind);
                    // Additional logic...
                    let temp = [...sourceID];
                    temp[ind] = city.id;
                    setSourceID(temp);
                    let temp3 = [...sourceClick];
                    temp3[ind] = false;
                    setSourceClick(temp3);

                    // let temp2 = [...sourceCityRecommendations];
                    // temp2[ind] = [];
                    // setSourceCityRecommendations(temp2);
                  }}
                >
                  {city.name}, {city.cityName}
                </div>
              ))}
          </div>
        </div>
        <div className="relative px-2 w-full mb-4 md:mb-0">
          <label className="block text-gray-400 mb-1 text-sm relative top-4 z-10 bg-black w-fit left-5 px-1">
            To
          </label>
          <div className="flex items-center bg-black rounded border-gray-500 border-2">
            <i className="fas fa-plane-departure text-gray-400"></i>
            <input
              type="text"
              className="w-full py-2 px-2 rounded bg-black text-gray-400"
              placeholder="Enter Destination"
              value={destination[ind] || ""}
              onClick={() => {
                let temp = [...destinationClick];
                temp[ind] = true;
                setDestinationClick(temp);
              }}
              onChange={(e) => handleDestinationChange(e.target.value, ind)}
            />
          </div>
          <div className="recommendation-container">
            {destinationClick[ind] &&
              destinationCityRecommendations &&
              destinationCityRecommendations[ind] &&
              destinationCityRecommendations[ind].data.map((city, index) => (
                <div
                  key={index}
                  className="recommendation-item"
                  onClick={() => {
                    handleDestinationChange(city.cityName? city.cityName : city.name, ind);
                    let temp = [...destinationID];
                    temp[ind] = city.id;
                    setDestinationID(temp);
                    let temp3 = [...destinationClick];
                    temp3[ind] = false;
                    setDestinationClick(temp3);
                    // let temp2 = [...destinationCityRecommendations];
                    // temp2[ind] = [];
                    // setDestinationCityRecommendations(temp2);
                  }}
                >
                  {city.name}, {city.countryName}
                </div>
              ))}
          </div>
        </div>

        <div className="px-2 w-full  mb-4 md:mb-0">
          <label className="block text-gray-400 text-sm mb-1 relative top-4 z-3 bg-black w-fit left-5 px-1">
            Depart
          </label>
          <div className="flex items-center bg-black rounded border-gray-500 border-2">
            <i className="fas fa-plane-departure text-gray-400 "></i>
            <input
              type="date"
              className="w-full py-2 px-2 rounded bg-black text-gray-400 custom-date-picker"
              placeholder="DEL"
              min={new Date().toISOString().split("T")[0]}
              value={date[ind] || ""}
              onChange={(e) => {
                let temp = [...date];
                temp[ind] = e.target.value;
                setDate(temp);
              }}
              
            />
          </div>
        </div>
        <div
          className={`px-2 w-full  mb-4 md:mb-0 ${
            tripType === "oneWay" || tripType === "multiCity"
              ? "hidden cursor-not-allowed"
              : ""
          }`}
        >
          <label className="block text-gray-400 mb-1 relative top-4 z-3 bg-black w-fit left-5 px-1 text-sm">
            Return
          </label>
          <div className="flex items-center bg-black rounded border-gray-500 border-2">
            <i className="fas fa-plane-departure text-gray-400 "></i>
            <input
              type="date"
              className={`w-full py-2 px-2 rounded bg-black text-gray-400 custom-date-picker ${
                tripType === "oneWay" ? "opacity-50 cursor-not-allowed" : ""
              }`}
              placeholder="DEL"
              min={date[ind] || new Date().toISOString().split("T")[0]}
              
              
              value={returnDate}
              onChange={(e) => setReturnDate(e.target.value)}
            />
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    renderDestinationRecommendations();
  }, [numberOfFlights, tripType]);

  return (
    <div>
      <Navbar />
      <div className="relative w-screen h-screen rounded-lg shadow-md">
        <img
          src={image}
          alt="Description"
          className="w-full h-full object-cover rounded-lg"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 rounded-lg"></div>
      </div>

      <div className="fixed inset-0 z-100 flex items-center justify-center ">
        <div>
          <h1 className="text-5xl text-white font-semibold mb-auto rounded p-8">
            Travaura Flight Search Engine
          </h1>
          <div className="bg-black p-8 rounded-lg scale-125 mt-10">
            <div className="flex justify-between">
              <div className="flex items-center mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    className="form-radio text-orange-500"
                    name="tripType"
                    value="roundTrip"
                    checked={tripType === "roundTrip"}
                    onChange={handleTripTypeChange}
                  />
                  <span className="ml-2 text-gray-400">Round Trip</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    className="form-radio text-orange-500"
                    name="tripType"
                    value="oneWay"
                    checked={tripType === "oneWay"}
                    onChange={handleTripTypeChange}
                  />
                  <span className="ml-2 text-gray-400">One Way</span>
                </label>
                <label className="inline-flex items-center ml-6">
                  <input
                    type="radio"
                    className="form-radio text-orange-500"
                    name="tripType"
                    value="multiCity"
                    checked={tripType === "multiCity"}
                    onChange={handleTripTypeChange}
                  />
                  <span className="ml-2 text-gray-400">Multi City</span>
                </label>
              </div>
              <div>
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value={nonStop}
                    class="sr-only peer"
                    onChange={(e) => setNonStop(e.target.checked)}
                  />
                  <div class="w-11 h-6 bg-gray-800 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  <span class="ms-3 text-lg font-medium text-gray-400">
                    Non Stop
                  </span>
                </label>
              </div>
            </div>

            <div className="flex">
              <div>
                {numberOfFlights && [...Array(numberOfFlights)].map((_, index) => {
                  return renderDestinationRecommendations(index);
                })}
                <div className="grid grid-cols-2 mx-2 mb-4">
                  {tripType === "multiCity" && (
                    <div className="px-2 w-full  mb-4 md:mb-0">
                      <button
                        className="bg-yellow-500 text-white font-bold rounded-md hover:bg-blue-700 transition-colors p-2 mt-3"
                        onClick={() => {
                          setNumberOfFlights(numberOfFlights + 1);
                          

                        }
                        }
                      >
                        ADD Flight
                      </button>
                      )
                      {tripType === "multiCity" && (
                        <button
                          className="bg-yellow-500 text-white font-bold rounded-md hover:bg-blue-700 transition-colors p-2 mt-3"
                          onClick={() => {
                            setNumberOfFlights(
                              Math.max(1, numberOfFlights - 1)
                            );
                            let temp = [...source];
                            temp.pop();
                            setSource(temp);
                            temp = [...destination];
                            temp.pop();
                            setDestination(temp);
                            temp = [...date];
                            temp.pop();
                            setDate(temp);
                            temp = [...sourceID];
                            temp.pop();
                            setSourceID(temp);
                            temp = [...destinationID];
                            temp.pop();
                            setDestinationID(temp);
                            temp = [...sourceCityRecommendations];
                          }}
                        >
                          REMOVE Flight
                        </button>
                      )}
                    </div>
                  )}
                  {tripType === "multiCity" && <div></div>}

                  <div className="px-2 w-full grid grid-cols-1  mb-4 md:mb-0">
                    <label className="block text-gray-400 text-sm mb-1 relative top-4 z-3 bg-black w-fit left-5 px-1">
                      Cabin Class
                    </label>
                    <div className="flex items-center bg-black rounded border-gray-500 border-2">
                      <i className="fas fa-plane-departure text-gray-400 "></i>
                      <select
                        className="w-full h-full py-2 px-2 rounded bg-black text-gray-400"
                        value={cabinClass}
                        onChange={(e) => setCabinClass(e.target.value)}
                      >
                        <option value="ECONOMY">Economy</option>
                        <option value="PREMIUM_ECONOMY">Premium Economy</option>
                        <option value="BUSINESS">Business</option>
                        <option value="FIRST">First</option>
                      </select>
                    </div>
                  </div>
                  <div className="px-2 w-full  mb-4 md:mb-0">
                    <label className="block text-gray-400 text-sm mb-1 relative top-4 z-3 bg-black w-fit left-5 px-1">
                      Sort By
                    </label>
                    <div className="flex items-center bg-black rounded border-gray-500 border-2">
                      <i className="fas fa-plane-departure text-gray-400 "></i>
                      <select
                        className="w-full h-full py-2 px-2 rounded bg-black text-gray-400"
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                      >
                        <option value="BEST">Best</option>
                        <option value="CHEAPEST">Cheapest</option>
                        <option value="FASTEST">Fastest</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="flex mx-2 mb-4">
                  <div className="px-2 w-20  mb-4 md:mb-0">
                    <label className="block text-gray-400 mb-1 relative top-4 z-3 bg-black w-fit left-1 px-1 text-sm">
                      Adults
                    </label>
                    <div className="flex items-center bg-black rounded border-gray-500 border-2">
                      <i className="fas fa-plane-departure text-gray-400 "></i>
                      <input
                        type="number"
                        className="w-full py-2 px-2 rounded bg-black text-gray-400"
                        placeholder="0"
                        value={adults}
                        onChange={(e) => setAdults(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="px-2 w-4xl  mb-4 md:mb-0">
                    <label className="block text-gray-400 mb-1 relative top-4 z-3 bg-black w-fit left-1 px-1 text-sm">
                      Children
                    </label>
                    <div className="flex items-center bg-black rounded border-gray-500 border-2">
                      <i className="fas fa-plane-departure text-gray-400 "></i>
                      <input
                        type="text"
                        className="w-full py-2 px-2 rounded bg-black text-gray-400 placeholder:text-[7px] placeholder-opacity-50 placeholder-gray-400 "
                        placeholder="Add Ages of Children Below 18 seaparated by comma"
                        value={children}
                        onChange={(e) => setChildren(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="bg-yellow-500 text-white font-bold rounded-md hover:bg-blue-700 transition-colors p-2 px-20 mt-3"
                  onClick={getFlights}
                >
                  SEARCH
                </button>
                {loading && (
                  <div className="fixed z-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-yellow-500"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FLightSearch;
