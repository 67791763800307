import React, { useEffect, useState } from "react";

const EditHotelPopup = ({ hotelData, isOpen, onClose, onUpdate, updateEditedData, edit }) => {
  const [editedData, setEditedData] = useState(hotelData);
  useEffect(() => {
    setEditedData(hotelData);
    console.log("hotelData: ", hotelData);
  }, [hotelData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
    updateEditedData({ ...edit, [name]: value });

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate(editedData);
  };

  if (!isOpen) return null;

  return (
    <div className="popup-backdrop">
      <div className="popup-content">
        <form onSubmit={handleSubmit}>
          {hotelData && (
            <div className="grid grid-cols-2 gap-4">
              {Object.keys(hotelData || {}).map((key) => {
                if (key === "_id") {
                  return null;
                }
                return (
                  <div className="mb-4" key={key}>
                    {" "}
                    {/* Ensure each child in a list has a unique "key" prop. */}
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor={key}
                    >
                      {key}
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      name={key}
                      value={
                        editedData && editedData[key]
                          ? editedData[key]
                          : hotelData[key]
                      }
                      onChange={handleChange}
                      type="text"
                      placeholder={key}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {/* Add more inputs as needed */}
          <div className="mb-4">
            {/* <button type="submit">Submit</button>
          <button onClick={onClose}>Cancel</button> */}
            <button
              className="bg-blue-500 mx-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditHotelPopup;
