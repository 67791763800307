import React from "react";
import { useState, useEffect } from "react";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import FlightCard from "./FlightCard";
import { useNavigate } from "react-router-dom";
import PageSkeleton from "./result/SkeletonPage";
import { useParams } from "react-router-dom";
import Navbar from "./Navbar";


const FlightsSearchResults = () => {
  const {  id, duration} = useParams();
  const navigate = useNavigate();
  const {
    flights,
    selectedInternationalFlightOffers,
    setSelectedInternationalFlightOffers,
    selectedCambodiaPackage,
    CambodiaWhen, setCambodiaWhen,

  } = useContext(UserContext);
  const dictionaries = flights.dictionaries;
  const [loader, setLoader] = useState(false);

  const {
    country,
    CambodiaAccomodationType,
    selectedFlights,
    selectedDomesticFlights,
    selectedInternationalFlights,
    numberofFlights,
    setNumberofFlights,
    setInternationalFlights,
    adults,
    setAdults,
    child,
    setChild,
    infants,
    setInfants,
    selectedFlightPrice,
    transferPrice,
    setTransferPrice,
    selectedHotel,
    activityPrice,
    addonPrice,
    nights,
    setNights,
    
    travellerDetails,
    setTravellerDetails,
    selectedFlightData,
    setSelectedFlightData,
    numberOfDays,
    internationalFlightsPrice,
    internationalFlights,

    addonDinner,
    setAddonDinner,
    addonLunch,
    setAddonLunch,
    addonBreakfast,
    setAddonBreakfast,
    itenary,
    setItenary,
    setInternationalFlightsPrice,
    daywiseTransfers,
    setDaywiseTransfers,
    EarlyCheckIn,
    setEarlyCheckIn,
    LateCheckOut,
    setLateCheckOut,
    TATAAIGInsurance,
    setTATAAIGInsurance,
    Cancellation,
    setCancellation,
    visa,
    setVisa,
    peakSeasonMarkup,
    setPeakSeasonMarkup,
    validTillDate,
    setValidTillDate,
    selectedBusData,
    setSelectedBusData,
    selectedTrainData,
    setSelectedTrainData,
    SelectedBaliIntercityTransfers,
    selectedCambodiaHotels,
    packageDetails,
    selectedCitiesForAirportTransfer
  } = useContext(UserContext);
  
  const [hotelPrice, setHotelPrice] = useState(0);
  const [toggletnc, setToggletnc] = useState(false);
  const [totalActivityBreakfast, setTotalActivityBreakfast] = useState(0);
  const [totalActivityLunch, setTotalActivityLunch] = useState(0);
  const [totalActivityDinner, setTotalActivityDinner] = useState(0);

  const [CambodiaHotelsData, setCambodiaHotelsData] = useState([]);

  useEffect(() => {
  country === "Vietnam/Cambodia" &&
    fetch(
      `https://travaurabackend.azurewebsites.net/api/cambodiaHotelsSchema`, {
        method: "GET",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setCambodiaHotelsData(data);
        console.log("CambodiaHoteldata: ", data);
      })
      .catch((error) => {
        console.log("Error deleting international flight: ", error);
      });
  }, []);

  

  


  const formatCurrency = (value) => {
    return value.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    });
  };

  const generateCustomId = (prefix = "TRAV") => {
    return `${prefix}-${Math.random().toString(36).substr(2, 9)}`;
  };

  // Check and set customId
  const getOrCreateCustomId = () => {
    let id = sessionStorage.getItem("customId");
    if (!id) {
      id = generateCustomId();
      sessionStorage.setItem("customId", id);
    }
    return id;
  };

  const [customId, setCustomId] = useState(getOrCreateCustomId());


  useEffect(() => {
    console.log("customId: ", customId);
    travellerDetails.duration = duration;
  }, [customId]);

  

  const email = localStorage.getItem("userEmail");

  const handlePost = async () => {
    setLoader(true);
    let data= {};
    if(customId.startsWith("TRAV")){
     data = {
      agentEmail: email || "",
      createdAt: new Date().toISOString(),
      agentUID: localStorage.getItem("userUID") || "",
      id: customId,
      travellerDetails: travellerDetails || [],
      country: country || "",
      selectedActivities: itenary || {},
      selectedHotels: selectedHotel || {},
      selectedTransfers: daywiseTransfers || {},
      selectedDomesticFlights: selectedFlightData || {},
      selectedInternationalFlights: internationalFlights || {},
      selectedAddons: {
        earlyCheckIn: EarlyCheckIn || false,
        lateCheckOut: LateCheckOut || false,
        addonBreakfast: addonBreakfast || 0,
        addonLunch: addonLunch || 0,
        addonDinner: addonDinner || 0,
        TATAAIGInsurance: TATAAIGInsurance || false,
        Cancellation: Cancellation || false,
        visa: visa || "",
        peakSeasonMarkup: peakSeasonMarkup || 0,
        validTillDate: validTillDate || "",
      },
      selectedBuses: selectedBusData || {},
      selectedTrains: selectedTrainData || {},
      selectedBaliICTransfers: SelectedBaliIntercityTransfers || {},
      selectedInternationalFlightOffers:selectedInternationalFlightOffers || {},
      BookingSelectedDomesticFlights: selectedDomesticFlights || {},
      BookingSelectedInternationalFlights: selectedInternationalFlights || {},
      selectedCambodiaPackage: selectedCambodiaPackage || {},
      CambodiaWhen: CambodiaWhen || "",
      CambodiaAccomodationType: CambodiaAccomodationType || "",
      selectedCambodiaHotels: selectedCambodiaHotels || {},



    }
    console.log("data to post: ", data);
    try {
      const response = await fetch(
        "https://travaurabackend.azurewebsites.net/api/user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        setLoader(false);
        navigate(`/resultPage/${customId}`);
      } else {
        // Handle response error
        console.error("Error posting data");
      }
    } catch (error) {
      console.error("Error during fetch: ", error);
    }
  }
  else{
    data = {
      agentEmail: email || "",
      createdAt: new Date().toISOString(),
      agentUID: localStorage.getItem("userUID") || "",
      id: customId,
      country: country || "",
      packageDetails: packageDetails || {},
      selectedActivities: itenary || {},
      selectedHotels: selectedHotel || {},
      selectedTransfers: daywiseTransfers || {},
      isPackage: id.startsWith("PACKAGE") ? true : false,
      
      selectedAddons: {
        earlyCheckIn: EarlyCheckIn || false,
        lateCheckOut: LateCheckOut || false,
        addonBreakfast: addonBreakfast || 0,
        addonLunch: addonLunch || 0,
        addonDinner: addonDinner || 0,
        TATAAIGInsurance: TATAAIGInsurance || false,
        Cancellation: Cancellation || false,
        visa: visa || "",
        peakSeasonMarkup: peakSeasonMarkup || 0,
        validTillDate: validTillDate || "",
      },
      selectedBuses: selectedBusData || {},
      selectedTrains: selectedTrainData || {},
      
      selectedCambodiaPackage: selectedCambodiaPackage || {},
      CambodiaWhen: CambodiaWhen || "",
      CambodiaAccomodationType: CambodiaAccomodationType || "",
      selectedCambodiaHotels: selectedCambodiaHotels || {},
      selectedAirportTransferCities: selectedCitiesForAirportTransfer || {},
    }
    console.log("data to post: ", data);
    try {
      const response = await fetch(
        `https://travaurabackend.azurewebsites.net/api/package/${customId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        setLoader(false);
        navigate(`/resultPage/${customId}`);
      } else {
        // Handle response error
        console.error("Error posting data");
      }
    } catch (error) {
      console.error("Error during fetch: ", error);
    }
  }
    

   
  };

  

  if(loader){
    return (
      <PageSkeleton />
    )
  }
  return (
    <div className="bg-black ">
      
<Navbar />
      <div className="text-gray-300 text-3xl font-medium ">Search Results</div>
      <div className="flex justify-between">
        <button className='bg-yellow-500 p-4 m-10 ' onClick={handlePost}>Get Itinerary</button>
        <buttom className='bg-yellow-500 p-4 m-10 ' onClick={()=> navigate(`/FlightSearch/${id}/${country}/${duration}`)}>Add More Flights</buttom>
      </div>

      <div>
        <div className="bg-black ">
          {flights &&
            flights.data && !flights.data.error && 
            flights.data.flightOffers.map((flight) => (
              <FlightCard key={flight.id} flight={flight} baggagePrice={flight.extraProducts && flight.extraProducts.filter((item)=>item.type === "checkedInBaggage").length > 0 ? flight.extraProducts.filter((item)=>item.type === "checkedInBaggage")[0].priceBreakdown.total.units : 0} />
            ))}
        </div>
      </div>
    </div>
  );
};
// data.flightOffers[0].extraProducts[1].priceBreakdown.total.units

export default FlightsSearchResults;
