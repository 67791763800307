import React from "react";
import { useState, useEffect } from "react";
import Activities from "./Activities";
import Hotel from "./Hotel";
import Transfer from "./Transfer";
import InterCityTransfer from "./InterCityTransfer";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import BaliInterCityTransfers from "./BaliInterCityTransfers";
const DaywiseItenerary = (props) => {
  const [region, setRegion] = useState("");

  const [city, setCity] = useState("");

  const [activityPrompt, setActivityPrompt] = useState("");
  const {
    regions,
    setRegions,
    cities,
    setCities,
    travellerDetails,
    selectedCountryData,
    isAirportTransfers,
    setIsAirportTransfers,
    isIntercitytransferbyDay,
    setIsIntercitytransferbyDay,
    duration,
    country,
  } = useContext(UserContext);

  // const [isAirportTransfer, setIsAirportTransfer] = useState(false);
  // const [isInterCityTransfer, setIsInterCityTransfer] = useState(false);
  useEffect(() => {
    console.log("day: ", props.day);
    console.log("region: ", region);
  }, [props.day, region]);
  

  useEffect(() => {
    console.log("cities: ", cities);
  }, [cities]);

  const handleSearchActivity = (e) => {
    setActivityPrompt(e.target.value);
  };

  useEffect(() => {
    console.log("isDaywiseTransfer: ", isIntercitytransferbyDay);
  }, [isIntercitytransferbyDay]);

  return (
    <div class="mb-6">
      <div class="bg-[#212121] p-4 rounded-md">
        <label class="block text-gray-400 text-3xl font-medium mb-2">
          Day {props.day}
        </label>
        <div class="mb-4  flex">
          <input
            type="text"
            className="  p-2   rounded-md focus:outline-1   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-full"  
        style={{ boxShadow: '1px 1px 3px 0px black' }}
            placeholder="Search Activity name, City or Region"
            onChange={handleSearchActivity}
          />
          {/* <select
              class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 mx-2"
              value={regions[props.day] || ""}
              onChange={(e) => {
                let temp = [...regions];
                temp[props.day] = e.target.value;
                setRegions(temp);
              }}
            >
              <option value="">Select Region</option>
              {selectedCountryData.regions &&
                Object.keys(selectedCountryData.regions).map(
                  (region, index) => (
                    <option key={region} value={region}>
                      {region}
                    </option>
                  )
                )}
            </select>
            <select
              class="hover:scale-105 mx-2 bg-gray-700 text-white rounded-md w-full p-2"
              value={cities[props.day] || ""}
              onChange={(e) => {
                let temp = [...cities];
                temp[props.day] = e.target.value;
                setCities(temp);
              }}
            >
              <option value="">Select City</option>
              {regions[props.day] &&
                selectedCountryData.regions[regions[props.day]] &&
                selectedCountryData.regions[regions[props.day]].map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
            </select> */}
        </div>

        <div class="mb-4 my-14">
          <label class="block text-gray-400 text-2xl font-medium mb-2">
            Activities
          </label>
          <div class="grid grid-cols-1 gap-4 mx-2">
            <Activities
              region={regions[props.day]}
              city={cities[props.day]}
              day={props.day}
              activityPrompt={activityPrompt}
            />
          </div>
        </div>

        <div class="mb-4 my-14">
          <label class="block text-gray-400 text-2xl font-medium mb-2">
            Accommodation
          </label>
          <Hotel
            region={regions[props.day]}
            city={cities[props.day]}
            day={props.day}
            hotelData={props.hotelData}
          />
        </div>

        

        <div class="mb-4  my-14">
          <label class="flex items-center space-x-2 mb-2">
            <span class="text-gray-400 text-2xl font-medium">
              Intercity Transfers
            </span>
          </label>
          {country === "Vietnam" || country === "Vietnam/Cambodia"
           ? (
            <InterCityTransfer
              region={regions[props.day]}
              city={cities[props.day]}
              day={props.day}
            />
          ) : (
            <BaliInterCityTransfers day={props.day} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DaywiseItenerary;
