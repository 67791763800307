import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const AddDomesticFlight = () => {
    const { id, country} = useParams();
    const navigate = useNavigate();
    
    const cities = [
        "Ho Chi Minh",
        "Da Lat",
        "Phu Quoc",
        "Ha Noi",
        "Sa Pa",
        "Ninh Binh",
        "Da Lat",
        "Nha Trang",
        "Da Nang",
        "Hoi An",
        "Hue",
        "Nha Trang",
        "Dong Hoi",
      ];

      const thailandCities= ["Bangkok (BKK)", "Phuket (HKT)", "Chiang Mai (CNX)", "Krabi (KBV)", "Don Mueang (DMK)", "Koh Samui (USM)", "Pattaya (UTP)"];
        const baggageAllowance = ["7 Kgs", "7+20 Kgs", "7+23 Kgs", "7+30 Kgs"];

        const [selectedDomesticFlight, setSelectedDomesticFlight] = useState({
            sourceCity: "",
            arrivalCity: "",
            flightPrice: "",
            arrivalTime: "",
            departureTime: "",
            noOfStops: "",
            carrier: "",
            baggage: "",
          });

          useEffect(() => {
            console.log("selectedDomesticFlight: ", selectedDomesticFlight);
            }, [selectedDomesticFlight]);

            const handleAddDomesticFlight = () => {
                try{
                    fetch(`https://travaurabackend.azurewebsites.net/updateDomesticFlight/${id}`, {
                        method: "PUT",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ selectedDomesticFlight: selectedDomesticFlight }),
                      }).then((response) => {
                        if (response.ok) {
                    
                            navigate(-1);
                        } else {
                            // Handle response error
                            console.error('Error posting data');
                        }
                      });

                }
                catch(err){
                    console.error('Error during fetch: ', err);
                }
            }
  return (
    <div>
      <div class="mb-4 p-10">
        <label class="block text-gray-400 text-4xl font-medium mb-5">
         Domestic Flights
        </label>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <input
            type="number"
            onWheel={(e) => e.target.blur()}
            placeholder="Day"
            class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
            value={selectedDomesticFlight.day || ""}
            onChange={(e) => setSelectedDomesticFlight({...selectedDomesticFlight, day: e.target.value})}
            />
            <input
            type="text"
            placeholder="Enter Carrier"
            class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
            value={selectedDomesticFlight.carrier || ""}
            onChange={(e) => setSelectedDomesticFlight({...selectedDomesticFlight, carrier: e.target.value})}
          />
            <select
            class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
            value={selectedDomesticFlight.sourceCity || ""}
            onChange={(e) => setSelectedDomesticFlight({...selectedDomesticFlight, sourceCity: e.target.value})}
            >
            <option>Source Source City</option>
            {country==="Vietnam" && 
            cities.map((item, ind) => (
                <option key={ind} value={item}>
                {item}
                </option>
            ))}
            {country==="Thailand" &&
            thailandCities.map((item, ind) => (
                <option key={ind} value={item}>
                {item}
                </option>
            ))}
            </select>
            <select
            class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 mx-2"
            value={selectedDomesticFlight.arrivalCity || ""}
            onChange={(e) => setSelectedDomesticFlight({...selectedDomesticFlight, arrivalCity: e.target.value})}
            >
            <option value="">Select Arrival City</option>
            {country==="Vietnam" && cities.map((item, ind) => (
                <option key={ind} value={item}>
                {item}
                </option>
            ))}
            {country==="Thailand" && thailandCities.map((item, ind) => (
                <option key={ind} value={item}>
                {item}
                </option>
            ))}

            </select>

         
          
          
          <select
            required
            class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
            value={selectedDomesticFlight.baggage || ""}
            onChange={(e) => setSelectedDomesticFlight({...selectedDomesticFlight, baggage: e.target.value})}
          >
            <option>Baggage</option>
            {baggageAllowance.map((item, ind) => (
              <option key={ind} value={item}>
                {item}
              </option>
            ))}
          </select>
          
          <div>
            <label class="block text-gray-400 text-sm font-medium mb-2">
              Price
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              placeholder="Enter Price"
           value={selectedDomesticFlight.flightPrice || ""}
            onChange={(e) => setSelectedDomesticFlight({...selectedDomesticFlight, flightPrice: e.target.value})}
              class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <div>
            <label class="block text-gray-400 text-sm font-medium mb-2">
              Departure Time
            </label>
            <input
              type="time"
              min="00:00"
              max="23:59"
              title="Enter Arrival Time"
              class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
           value={selectedDomesticFlight.departureTime || ""}
            onChange={(e) => setSelectedDomesticFlight({...selectedDomesticFlight, departureTime: e.target.value})}
            />
          </div>
          <div>
            <label class="block text-gray-400 text-sm font-medium mb-2">
              Arrival Time
            </label>
            <input
              type="time"
              min="00:00"
              max="23:59"
              title="Enter Departure Time"
              class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
             
            value={selectedDomesticFlight.arrivalTime || ""}
            onChange={(e) => setSelectedDomesticFlight({...selectedDomesticFlight, arrivalTime: e.target.value})}
            />
          </div>
          
          <div>
            <label class="block text-gray-400 text-sm font-medium mb-2">
              No. of Stops
            </label>
            <input
              type="number"
              onWheel={(e) => e.target.blur()}
              placeholder="Enter number of Stops"
              class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
                value={selectedDomesticFlight.noOfStops || ""}
            onChange={(e) => setSelectedDomesticFlight({...selectedDomesticFlight, noOfStops: e.target.value})}
            />
          </div>
        </div>
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleAddDomesticFlight}>
            Add Domestic Flight
        </button>
      </div>
    </div>
  )
}

export default AddDomesticFlight