import React from "react";
import upi from "../assets/images/upi-icon.png";
import qr from "../assets/images/qr.png";
const PaymentMethodsSection = () => {
  return (
    <div>
      <div className="bg-[#212121] p-4 rounded-lg my-5">
        <h1 className="text-white text-xl font-bold">
          We accept payment via following methods:
        </h1>
        <ul className="text-white text-md list-disc relative left-6">
          <li>Cash</li>
          <li>Bank Transfer</li>
          <li>UPI</li>
          <li>Debit Card</li>
          <li>Credit Card</li>
          <li>Leading Wallets</li>
          <li>EMIs on Credit/Debit Card</li>
          <li>EMI via Personal Finance</li>
          <li>Travaura Cash</li>
        </ul>
      </div>
      <div className="flex justify-between my-10">
        <img src={upi} alt="upi" className=" h-auto mx-auto my-auto w-1/4" />
        <div className="mx-auto">
          <img src={qr} alt="qr" className="w-1/2" />
          <p className="text-white text-sm md:text-md font-bold my-3 w-full">
            UPI ID: travaura.llp@okhdfcbank
          </p>
        </div>
      </div>
      <div className="bg-[#212121] p-4 rounded-lg">
        <h1 className="text-white text-xl font-bold">Bank Details:</h1>
        <div className="text-white text-md  relative left-3">
          <p>Name - TRAVAURA LLP</p>
          <p>A/c number - 50200054167491</p>
          <p>IFSC - HDFC0000158</p>
          <p>Bank - HDFC Bank</p>
          <p>Account Type - Current</p>
          <p>
            <span className="font-bold">7428285667</span>: For
            Paytm/UPI/GPay/Phonepe via number
          </p>
          <p>
            <span className="font-bold">travaura.llp@okhdfcbank</span>: UPI ID
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodsSection;

// Name - TRAVAURA LLP
// A/c number - 50200054167491
// IFSC - HDFC0000158
// Bank - HDFC Bank
// Account Type - Current
// 7428285667: For Paytm/UPI/GPay/Phonepe via number
// travaura.llp@okhdfcbank: UPI ID
