import logo from "./logo.svg";
import "./App.css";

import UserProvider from "./contexts/UserProvider";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Hotelform from "./Hotelform";
import IteneraryBuilder from "./IteneraryBuilder";
import ResultPage from "./result/ResultPage";
import TravellerDetails from "./TravellerDetails";
import InternationalFlightComponent from "./InternationalFlightComponent";
import InternationalFlights from "./InternationalFlights";
import LinkResult from "./result/LinkResult";
import ActivityChangePage from "./result/ActivityChangePage";
import HotelChangePage from "./result/HotelChangePage";
import SignIn from "./Google Auth/signin";
import AuthProvider from "./contexts/AuthProvider";
import PrivateRoute from "./contexts/PrivateRoute";
import AddInternationalFlight from "./result/AddInternationalFlight";
import AddDomesticFlight from "./result/AddDomesticFlight";
import FLightSearch from "./FlightsSearch";
import Flights from "./Flights";
import FlightsSearchResults from "./FlightsSearchResults";
import AddCambodiaHotels from "./result/AddCambodiaHotels";
import AgentItinerariesSection from "./AgentItinerariesSection";
import AdminActivitiyManagement from "./AdminActivitiyManagement";
import AdminHotelManagement from "./AdminHotelManagement";
import AdminCreateAPackage from "./AdminCreateAPackage";
import TransferCOMP from "./TransferCOMP";
import GetPackages from "./GetPackages";
import HotelVoucherForm from "./PDF Components/HotelVoucherForm";
import FlieUploadSection from "../src/Airport Voucher Panel/FileUploadSection";
import ManageFlightVouchers from "./Airport Voucher Panel/ManageFlightVouchers";
import DragNDropTesting from "./DragNDropTesting";

function App() {
  return (
    <UserProvider>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/signin" element={<SignIn />} />

            {/* Define each private route individually */}
            <Route
              path="/Pro Itinerary"
              element={
                <PrivateRoute>
                  <TravellerDetails />
                </PrivateRoute>
              }
            />
            <Route
              path="/hotelform"
              element={
                <PrivateRoute>
                  <Hotelform />
                </PrivateRoute>
              }
            />
            <Route
              path="/iteneryBuilder"
              element={
                <PrivateRoute>
                  <IteneraryBuilder />
                </PrivateRoute>
              }
            />
            <Route
              path="/resultPage"
              element={
                <PrivateRoute>
                  <ResultPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/InternationalFlights"
              element={
                <PrivateRoute>
                  <InternationalFlights />
                </PrivateRoute>
              }
            />
            <Route
              path="/Activity Management"
              element={
                <PrivateRoute>
                  <AdminActivitiyManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/Hotel Management"
              element={
                <PrivateRoute>
                  <AdminHotelManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <AgentItinerariesSection />
                </PrivateRoute>
              }
            />
            <Route
              path="/Dashboard"
              element={
                <PrivateRoute>
                  <AgentItinerariesSection />
                </PrivateRoute>
              }
            />
            <Route path="/AirportTransfers/:id/:country/:duration"
            element={
              <PrivateRoute>
                <TransferCOMP />
              </PrivateRoute>
            }
            />
            <Route path="/All Packages"
              element={
                <PrivateRoute>
                  <GetPackages />
                </PrivateRoute>
              }
            />
            <Route
              path="/Master Packages"
              element={
                <PrivateRoute>
                  <AdminCreateAPackage />
                </PrivateRoute>
              }
            />

            {/* Non-protected routes */}
            <Route path="/resultPage/:id" element={<LinkResult />} />
            <Route
              path="/resultPage/:id/Activities/:country/:day/:region"
              element={<ActivityChangePage />}
            />
            <Route
              path="/resultPage/:id/Hotels/:country/:day/:region/:numberOfPeople"
              element={<HotelChangePage />}
            />
            <Route
              path="/resultPage/:country/:id/AddInternationalFlights"
              element={<AddInternationalFlight />}
            />
            <Route
              path="/resultPage/:country/:id/AddDomesticFlights"
              element={<AddDomesticFlight />}
            />
            <Route
              path="/addCambodiaHotels/:city/:id/:day/:Category"
              element={<AddCambodiaHotels />}
            />
            <Route
              path="/FlightSearch/:id/:country/:duration"
              element={<FLightSearch />}
            />
            <Route
              path="/flightsSearchResults"
              element={<FlightsSearchResults />}
            />
            <Route
              path="/flightsSearchResults/:id/:country/:duration"
              element={<FlightsSearchResults />}
            />
            <Route
             path="/Hotel Vouchers"
             element= {<HotelVoucherForm />}
            />
            
            <Route
              path="/iteneryBuilder/:id/:country/:duration"
              element={<IteneraryBuilder />}
            />
            <Route 
            path="/Upload Flight Voucher"
            element={<FlieUploadSection />}
            />

            <Route
            path="/Flight Vouchers Management"
            element={<ManageFlightVouchers />}
            />

            <Route
            path="/DragNDropTesting"
            element= {<DragNDropTesting />}
            />
            
            
          </Routes>
        </Router>
      </AuthProvider>
    </UserProvider>
  );
}
// "Upload Flight Voucher", "Flight Vouchers Management
export default App;

// id, day, Category, city
