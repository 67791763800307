import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AddInternationalFlight = () => {
  const { country, ind, id } = useParams();
  const navigate = useNavigate();
const [selectedFlight, setSelectedFlight] = useState({
    departureCity: "",
    arrivalCity: "",
    carrier: "",
    journeyDate: "",
    departureTime: "",
    arrivalTime: "",
    price: 0,
    noOfStops: 0,
    baggage: "",
    });

    const handleAddInternationalFlight = async() => {
        console.log("selectedFlight: ", selectedFlight);
        try{
            const response = await fetch(`https://travaurabackend.azurewebsites.net/updateInternationalFlight/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ selectedInternationalFlight: selectedFlight }),
                });
                if (response.ok) {
                    
                    navigate(-1);
                } else {
                    // Handle response error
                    console.error('Error posting data');
                }
            }
            catch(err){
                console.error('Error during fetch: ', err);
            }
    };
  const citiesAndCodes = [
    "Kochi (COK)",
    "Tiruchirapalli (TRZ)",
    "Thiruvananthapuram (TRV)",
    "Chennai (MAA)",
    "Ahmedabad (AMD)",
    "Mumbai (BOM)",
    "Kolkata (CCU)",
    "Amritsar (ATQ)",
    "Coimbatore (CJB)",
    "New Delhi (DEL)",
    "Visakhapatnam (VTZ)",
    "Bengaluru (BLR)",
    "Hyderabad (HYD)",
    "Bagdogra (IXB)",
    "Kannur (CNN)",
    "Bhubaneswar (BBI)",
    "Guwahati (GAU)",
    "Pune (PNQ)",
    "Gaya (GAY)",
    "Raipur (RPR)",
    "Dibrugarh (DIB)",
    "Patna (PAT)",
    "Jaipur (JAI)",
    "Mopa (GOI)",
    "Varanasi (VNS)",
    "Indore (IDR)",
    "Chandigarh (IXC)",
  ];

  const vietnameseCities = [
    "Ho Chi Minh City (SGN)",
    "Hanoi (HAN)",
    "Da Nang (DAD)",
  ];
  const baliCities = ["Denpasar (DPS)"];

  const thailandCities= ["Bangkok (BKK)", "Phuket (HKT)", "Chiang Mai (CNX)", "Krabi (KBV)", "Don Mueang (DMK)", "Koh Samui (USM)", "Pattaya (UTP)"];

  useEffect(() => {
    console.log("selectedFlight: ", selectedFlight);
    }, [selectedFlight]);

  const baggageAllowance = ["7 Kgs", "7+20 Kgs", "7+23 Kgs", "7+30 Kgs"];
  return (
    <div class="mb-4 p-5 bg-gray-800 lg:flex lg:flex-wrap lg:justify-between">
      <div class="lg:w-1/2 lg:px-2">
        <label class="block text-gray-400 text-sm font-medium mb-2">
          International Flights
        </label>
        <select
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 my-2"
          value={selectedFlight.departureCity}
            onChange={(e) => setSelectedFlight({...selectedFlight, departureCity: e.target.value})}
        >
          <option>Departure City</option>
          {citiesAndCodes.map((item, ind) => (
            <option key={ind} value={item}>
              {item}
            </option>
          ))}
          {country === "Vietnam" &&
            vietnameseCities.map((item, ind) => (
              <option key={ind} value={item}>
                {item}
              </option>
            ))}
          {country === "Bali" &&
            baliCities.map((item, ind) => (
              <option key={ind} value={item}>
                {item}
              </option>
            ))}
          {country === "Thailand" &&
            thailandCities.map((item, ind) => (
              <option key={ind} value={item}>
                {item}
              </option>
            ))}
        </select>
        <select
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 my-2"
         value={selectedFlight.arrivalCity}
            onChange={(e) => setSelectedFlight({...selectedFlight, arrivalCity: e.target.value})}
          className="bg-gray-700 text-white rounded-md w-full p-2 my-2"
        >
          <option>Arrival City</option>
          {country === "Vietnam" &&
            vietnameseCities.map((item, ind) => (
              <option key={ind} value={item}>
                {item}
              </option>
            ))}
          {country === "Bali" &&
            baliCities.map((item, ind) => (
              <option key={ind} value={item}>
                {item}
              </option>
            ))}
          {country === "Thailand" &&
            thailandCities.map((item, ind) => (
              <option key={ind} value={item}>
                {item}
              </option>
            ))}
          {citiesAndCodes.map((item, ind) => (
            <option key={ind} value={item}>
              {item}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Enter Carrier"
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 my-2"
          value={selectedFlight.carrier}
            onChange={(e) => setSelectedFlight({...selectedFlight, carrier: e.target.value})}
        ></input>

        <select
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 my-2"
          value={selectedFlight.baggage}
            onChange={(e) => setSelectedFlight({...selectedFlight, baggage: e.target.value})}
        >
          <option>Baggage</option>
          {baggageAllowance.map((item, ind) => (
            <option key={ind} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <div class="lg:w-1/2 lg:px-2">
        <label class="block text-gray-400 text-sm font-medium">
          Journey Date
        </label>
        <input
          type="date"
          placeholder="Journey Date"
          value={selectedFlight.journeyDate}
          onChange={(e) => setSelectedFlight({...selectedFlight, journeyDate: e.target.value})}
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-fit p-2 mb-2"
        />
        <label class="block text-gray-400 text-sm font-medium ">
          Departure Time
        </label>
        <input
          type="time"
          placeholder="Departure Time"
          min="00:00"
          max="23:59"
          
            onChange={(e) => setSelectedFlight({...selectedFlight, departureTime: e.target.value})}
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-fit p-2 mb-2"
        />
        <label class="block text-gray-400 text-sm font-medium">
          Arrival Time
        </label>
        <input
          type="time"
          placeholder="Arrival Time"
          min="00:00"
          max="23:59"
            onChange={(e) => setSelectedFlight({...selectedFlight, arrivalTime: e.target.value})}
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-fit p-2 mb-2"
        />

        <label class="block text-gray-400 text-sm font-medium">Price</label>
        <input
          type="number"
          placeholder="Price"
          onWheel={(e) => e.target.blur()}
          value={selectedFlight.price}
            onChange={(e) => setSelectedFlight({...selectedFlight, price: e.target.value})}
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 mb-4"
        />
        <label class="block text-gray-400 text-sm font-medium">Stops</label>
        <input
          type="number"
          onWheel={(e) => e.target.blur()}
          placeholder="Stops"
          min={0}
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2 mb-4"
          value={selectedFlight.noOfStops}
            onChange={(e) => setSelectedFlight({...selectedFlight, noOfStops: e.target.value})}
        />
      </div>
      
        <button class="hover:scale-105 bg-blue-700 text-white rounded-md w-fit p-2 mb-4" onClick={handleAddInternationalFlight}>
          Add Flight
        </button>
     
    </div>
  );
};

export default AddInternationalFlight;
