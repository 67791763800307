import React from 'react';

const PageSkeleton = () => {
  return (
    <div className="space-y-6 animate-pulse bg-[#121212] text-white">
      <div className="container mx-auto px-4">
        {/* Header placeholder */}
        <header className="py-6">
          <div className="h-24 bg-[#212121] rounded"></div>
        </header>

        {/* Main content */}
        <main>
          {/* Title placeholder */}
          <div className="h-8 bg-[#212121] rounded w-1/2 mb-6"></div>

          {/* Trip ID & Details placeholder */}
          <div className="bg-[#212121] p-6 rounded-lg mb-6">
            <div className="h-6 bg-secondary rounded mb-4"></div>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-1">
                <div className="h-4 bg-secondary rounded mb-2"></div>
                <div className="h-4 bg-secondary rounded"></div>
              </div>
              <div className="col-span-1">
                <div className="h-4 bg-secondary rounded mb-2"></div>
                <div className="h-4 bg-secondary rounded"></div>
              </div>
            </div>
          </div>

          {/* Itinerary placeholder */}
          <div className="bg-[#212121] p-6 rounded-lg mb-6">
            <div className="h-6 bg-secondary rounded mb-4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-secondary rounded"></div>
              <div className="h-4 bg-secondary rounded w-5/6"></div>
            </div>
          </div>

          {/* Inclusions placeholder */}
          <div className="bg-[#212121] p-6 rounded-lg mb-6">
            <div className="h-6 bg-secondary rounded mb-4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-secondary rounded"></div>
              <div className="h-4 bg-secondary rounded w-5/6"></div>
            </div>
          </div>

          {/* Benefits placeholder */}
          <div className="bg-[#212121] p-6 rounded-lg mb-6">
            <div className="h-6 bg-secondary rounded mb-4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-secondary rounded"></div>
              <div className="h-4 bg-secondary rounded w-5/6"></div>
            </div>
          </div>

          {/* Pricing Details Sidebar placeholder */}
          <div className="bg-[#212121] p-6 rounded-lg mb-6">
            <div className="h-6 bg-secondary rounded mb-4"></div>
            <div className="space-y-2">
              <div className="h-4 bg-secondary rounded"></div>
              <div className="h-4 bg-secondary rounded w-5/6"></div>
            </div>
          </div>

          {/* Download button placeholder */}
          <div className="flex justify-center">
            <div className="w-32 h-10 bg-secondary rounded"></div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageSkeleton;
