const getCityNameByIataCode = async (iataCode) => {
    const query = `
      SELECT ?cityLabel WHERE {
        ?airport wdt:P238 "${iataCode}".  # IATA airport code
        ?airport wdt:P931 ?city.          # Served city
        SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
      }
    `;
  
    const url = `https://query.wikidata.org/sparql?query=${encodeURIComponent(query)}&format=json`;
  
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.results.bindings.length > 0) {
        return data.results.bindings[0].cityLabel.value;
      } else {
        console.warn(`No city found for IATA code: ${iataCode}`);
        return null;
      }
    } catch (error) {
      console.error("Error fetching city name from Wikidata:", error);
      return null;
    }
};

export default getCityNameByIataCode;
