import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { ClimbingBoxLoader } from "react-spinners";
import { AuthContext } from "../contexts/AuthProvider";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";

const FileUploadSection = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [pdfLink, setPdfLink] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser } = React.useContext(AuthContext);

  const [details, setDetails] = useState({
    name: "",
    bookingID: "",
    VoucherLink: "",
    agentUID: currentUser.uid || "",
    agentEmail: currentUser.email || "",
    agentName: currentUser.displayName || "",
    createdAt: new Date().toISOString(),
  });

  const postDetails = async () => {
    try {
      const response = await fetch(
        "https://travaurabackend.azurewebsites.net/api/flightVoucher",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(details),
        }
      );
      if (response.ok) {
        console.log("Voucher details posted successfully");
        alert("Voucher details posted successfully");
      } else {
        console.error("Error posting voucher details");
      }
    } catch (error) {
      console.error("Error posting voucher details: ", error);
    }
  };

  const onDrop = (acceptedFiles) => {
    setLoading(true);
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    if (acceptedFiles[0]) {
      uploadFiles(acceptedFiles[0]);
    }
  };

  useEffect(() => {
    console.log("details: ", details);
  }, [details]);

  const uploadFiles = async (pdf) => {
    const data = new FormData();
    data.append("file", pdf);
    data.append("upload_preset", "usvk17d3");
    data.append("cloud_name", "dxcq5zluj");
    try {
      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dxcq5zluj/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const file = await response.json();
      setPdfLink(file.secure_url);
      setDetails({ ...details, VoucherLink: file.secure_url });
      setLoading(false);
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/pdf",
    onDrop,
  });

  useEffect(() => {
    console.log("pdfLink: ", pdfLink);
  }, [pdfLink]);

  return (
    <div>
      <Navbar />
      <h1 className="text-4xl text-white text-center my-5">
        Upload FLight Voucher
      </h1>
      <form className="container ">
        <div className="flex flex-col">
          <div className="flex m-8 ">
            <div className="flex m-8 ">
              <label className="text-2xl text-white mx-5">Name</label>
              <input
                type="text"
                className="border-2 border-white rounded-md p-2 text-black"
                onChange={(e) =>
                  setDetails({ ...details, name: e.target.value })
                }
              />
            </div>

            <div className="flex m-8">
              <label className="text-white text-2xl mx-5">Booking ID</label>
              <input
                type="text"
                className="border-2 border-white rounded-md p-2 text-black"
                onChange={(e) =>
                  setDetails({ ...details, bookingID: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="flex m-8 ">
          <label className="text-2xl text-white mx-5">Voucher Upload</label>
          <div
            {...getRootProps({ className: "dropzone" })}
            className="border-2 border-dashed border-white p-4 rounded-md w-1/2 h-1/2 "
          >
            <input {...getInputProps()} />
            <p className="text-4xl text-white">
              Drag 'n' drop your PDF here, or click to select PDF
            </p>
          </div>
        </div>
        {!loading && pdfLink && (
           <button
           type="button"
           className="bg-yellow-500 p-4 m-10"
           onClick={postDetails}
         >
           Submit
         </button>
        )
          }
       

        {loading && (
          <ClimbingBoxLoader
            color="#36d7b7"
            loading={loading}
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-150"
          />
        )}

        <aside className=" w-screen px-10">
          {pdfLink && (
            <iframe
              src={details.VoucherLink}
              width="100%"
              height="500px"
            ></iframe>
          )}
        </aside>
      </form>
      <button
        className="bg-red-500 p-4 m-10"
        onClick={() => navigate("/Flight Vouchers Management")}
      >
        Manage Vouchers
      </button>
      {/* // "Upload Flight Voucher", "Flight Vouchers Management */}
      {/* <section className="container  text-white">
            <p className='text-4xl text-white'>Drag 'n' drop some files here, or click to select files</p>
                <div {...getRootProps({ className: 'dropzone' })} className='border-2 border-dashed border-white p-4 rounded-md w-1/2 h-1/2 m-4' >
                    
                    
                    <input {...getInputProps()} />
                </div>
                <aside>   
                    {pdfLink &&
                        <iframe src={pdfLink} width="100%" height="500px">
                        </iframe>
                    }
                    
                </aside>
            </section> */}
    </div>
  );
};

export default FileUploadSection;
