import React from "react";
import formatDate from "../contexts/dateConverter";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { FaExchangeAlt } from "react-icons/fa";
import { FaPlane } from "react-icons/fa";

const FlightsCard = (props) => {
  const extractCityAndCode = (inputString) => {
    const regex = /^(.*?)\s\((.*?)\)$/;
    const match = inputString.match(regex);
    if (match) {
      return { cityName: match[1], airportCode: match[2] };
    } else {
      return null; // or some error handling
    }
  };
  const handleDeleteInternationalFlight = () => {
    fetch(
      `https://travaurabackend.azurewebsites.net/deleteInternationalFlightManual/${props.id}/${props.ind}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log("data: ", data);
        // Update your component's state or parent component's state here
        // For example, using a setState function
        props.setDeleteCounter((prevCount) => prevCount + 1);
      })
      .catch((error) => {
        console.log("Error deleting international flight: ", error);
      });
  };

  return (
    <div className="bg-secondary p-4 rounded-lg my-3">
      
      <div className="bg-[#121212] p-4 rounded-lg max-w-full mx-auto my-8">
        <button className="bg-gradient-to-tr from-[#424242] to-[#769895] text-[#ece7e7] px-16 py-1 rounded relative bottom-7 left-10">
          Flight {props.ind + 1+ props.num}
        </button>
        <div className="flex items-center justify-between mb-4">
          <button className="bg-blue-400 text-white  rounded"></button>
          <div className="flex items-center mb-5 mr-3">
            <span className="text-white text-xl">{props.item.carrier}</span>
          </div>
        </div>
        <div className="flex items-center justify-between text-white  px-8">
          <div className="relative bottom-8 text-gray-300">
            <div className="text-xl font-medium">
              {props.item && props.item.departureCity &&  extractCityAndCode(props.item.departureCity).airportCode}
            </div>
            <div className="text-sm">
              {props.item &&  props.item.departureCity && extractCityAndCode(props.item.departureCity).cityName}
            </div>
            <div className="text-sm">{props.item.departureTime}</div>
          </div>
          <div className="flex-1 mx-8">
            <div className="border-t border-gray-600 flex justify-center ">
              <FaPlane className="text-gray-500 text-2xl relative bottom-3" />
            </div>
            <div className="text-center text-sm mt-2 text-gray-300">
              <div>{props.item.noOfStops} Stop(s)</div>
              <div>Baggage - {props.item.baggage}</div>
              <div>
                {props.item.journeyDate
                  ? formatDate(props.item.journeyDate)
                  : ""}
              </div>
            </div>
          </div>
          <div className="relative bottom-8 text-gray-300">
            <div className="text-xl font-medium">
              {props.item && props.item.arrivalCity && extractCityAndCode(props.item.arrivalCity).airportCode}
            </div>
            <div className="text-sm">
              {props.item && props.item.arrivalCity && extractCityAndCode(props.item.arrivalCity).cityName}
            </div>
            <div className="text-sm">{props.item.arrivalTime}</div>
          </div>
        </div>
       
      </div>
      <RiDeleteBin2Fill className="text-2xl text-red-500 cursor-pointer print-proof" onClick={handleDeleteInternationalFlight} />
    </div>
    // </div>
  );
};

export default FlightsCard;
