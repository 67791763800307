import React from "react";
import { useState, useEffect } from "react";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";

const Train = (props) => {
  // const [trainData, setTrainData] = useState([]);
  // const [filterTrainData, setFilterTrainData] = useState([]);
  const {
    selectedTrainData,
    setSelectedTrainData,
    selectedTrainPriceByDay,
    setSelectedTrainPriceByDay,
    TrainChecked,
    setTrainChecked,
  } = useContext(UserContext);

  const handleTrainSelect = () => {
    const existingTrainIndex = selectedTrainData.findIndex(
      (train) => train.day === props.day
    );

    if (existingTrainIndex !== -1) {
      // Train for this day is already selected, remove it
      const updatedTrainData = [...selectedTrainData];
      updatedTrainData.splice(existingTrainIndex, 1);
      setSelectedTrainData(updatedTrainData);
    } else { 
      // Add new train data
      const newTrainData = {
        day: props.day,
        source: props.sourceCity,
        arrival: props.arrivalCity,
        price: selectedTrainPriceByDay[props.day],
      };
      setSelectedTrainData([...selectedTrainData, newTrainData]);
    }
  };
  const isTrainSelected = selectedTrainData.some(
    (train) => train.day === props.day
  );
  useEffect(() => {
    console.log("selectedTrainData: ", selectedTrainData);
  }, [selectedTrainData]);
  return (
    <div>
      <label class="block text-gray-400 text-sm font-medium mb-2">Train</label>
      <input
        type="number"
        placeholder="Train Price"
        className="  p-2   rounded-md focus:outline-1 mb-3   bg-[#121212] placeholder:text-[#616161]  shadow-inner shadow-black  border-none focus:border-solid focus:ring-1 pl-6 text-white w-full"  
        style={{ boxShadow: '1px 1px 3px 0px black' }}
        onChange={(e) => {
          let temp = [...selectedTrainPriceByDay];
          temp[props.day] = parseInt(e.target.value);
          setSelectedTrainPriceByDay(temp);
        }}
        value={selectedTrainPriceByDay[props.day] || selectedTrainData.find((train) => train.day === props.day)?.price || ""}
      />
      <button
        className={`text-xs px-4 py-2 rounded-md flex ${
          isTrainSelected ? "bg-red-600" : "bg-gradient-to-tr from-[#625d5d] to-[#769895] text-[#ece7e7]"
        } `}
        onClick={handleTrainSelect}
      >
        {isTrainSelected ? "Remove Transfer" : "Add Transfer"}
      </button>
    </div>
  );
};

export default Train;

// onChange={(e) => {setSelectedTrainData([...selectedTrainData, {day: props.day, source: props.source, arrival: props.arrival, price: e.target.value}])}
