function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const intlDate = new Intl.DateTimeFormat('en-US', options).format(date);
  
    // Get the day of the month to determine the ordinal suffix
    const day = date.getDate();
    let suffix = 'th';
    if (day % 10 === 1 && day !== 11) {
      suffix = 'st';
    } else if (day % 10 === 2 && day !== 12) {
      suffix = 'nd';
    } else if (day % 10 === 3 && day !== 13) {
      suffix = 'rd';
    }
  
    // Extract the month and year from the formatted date
    const [month, , year] = intlDate.split(' ');
  
    // Reassemble the date with the correct ordinal suffix for the day
    return `${day}${suffix} ${month}, ${year}`;
  }
  
  export default formatDate;