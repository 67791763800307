import React from "react";
import { useState, useEffect } from "react";

const AdditionalFeatures = ({ setPackageDetails, packageDetails }) => {
  const [numberOfNights, setNumberOfNights] = useState(1);
  const [guideServiceLanguages, setGuideServiceLanguages] = useState(
    packageDetails.additionalFeatures.guideServices.languages || []
  );
  const [isGuideServiceAvailable, setIsGuideServiceAvailable] = useState(
    packageDetails.additionalFeatures.guideServices.available || false
  );

  const [localNightsInEachCity, setLocalNightsInEachCity] = useState(
    packageDetails.additionalFeatures.nightsInEachCity
  );

  useEffect(() => {
    // Synchronize local state with the parent state
    setLocalNightsInEachCity(
      packageDetails.additionalFeatures.nightsInEachCity
    );
  }, [packageDetails.additionalFeatures.nightsInEachCity]);

  const handleCityChange = (index, value) => {
    const updatedNights = [...localNightsInEachCity];
    updatedNights[index] = { ...updatedNights[index], city: value };
    setLocalNightsInEachCity(updatedNights);
    setPackageDetails((prevState) => ({
      ...prevState,
      additionalFeatures: {
        ...prevState.additionalFeatures,
        nightsInEachCity: updatedNights,
      },
    }));
  };

  const handleNightsChange = (index, value) => {
    const updatedNights = [...localNightsInEachCity];
    updatedNights[index] = { ...updatedNights[index], nights: value };
    setLocalNightsInEachCity(updatedNights);
    setPackageDetails((prevState) => ({
      ...prevState,
      additionalFeatures: {
        ...prevState.additionalFeatures,
        nightsInEachCity: updatedNights,
      },
    }));
  };

  const addCity = () => {
    const newCity = { city: "", nights: "" };
    const updatedNights = [...localNightsInEachCity, newCity];
    setLocalNightsInEachCity(updatedNights);

    setPackageDetails((prevState) => ({
      ...prevState,
      additionalFeatures: {
        ...prevState.additionalFeatures,
        nightsInEachCity: updatedNights,
      },
    }));
  };

  useEffect(() => {

    setPackageDetails((prevState) => ({
      ...prevState,
      additionalFeatures: {
        ...prevState.additionalFeatures,
        guideServices: {
          available: isGuideServiceAvailable,
          languages: guideServiceLanguages,
        },
      },
    }));
  }, [guideServiceLanguages, isGuideServiceAvailable, setPackageDetails]);

  const handleLanguageChange = (index, value) => {
    const updatedLanguages = [...guideServiceLanguages];
    updatedLanguages[index] = value;
    setGuideServiceLanguages(updatedLanguages);
  };

  const addLanguage = () => {
    setGuideServiceLanguages([...guideServiceLanguages, ""]);
  };

  const removeLanguage = (index) => {
    const filteredLanguages = guideServiceLanguages.filter(
      (_, i) => i !== index
    );
    setGuideServiceLanguages(filteredLanguages);
  };

  useEffect(() => {
    console.log("AdditionalFeatures: ", packageDetails);
  }, [packageDetails]);

  const handleInputChange = (e, index = null, field) => {
    const { name, value } = e.target;

    if (name === "nightsInEachCity") {
      const updatedNightsInEachCity = [
        ...packageDetails.additionalFeatures.nightsInEachCity,
      ];
      if (updatedNightsInEachCity[index]) {
        updatedNightsInEachCity[index] = {
          ...updatedNightsInEachCity[index],
          [field]: value,
        };
      } else {
        updatedNightsInEachCity.push({ city: "", nights: "" });
      }

      setPackageDetails((prevState) => ({
        ...prevState,
        additionalFeatures: {
          ...prevState.additionalFeatures,
          nightsInEachCity: updatedNightsInEachCity,
        },
      }));
    } else {
      setPackageDetails((prevState) => ({
        ...prevState,
        additionalFeatures: {
          ...prevState.additionalFeatures,
          [name]: value,
        },
      }));
    }
  };
  return (
    <div>
      <div className="flex justify-center">
        <label className="block text-white text-2xl font-bold my-5">
          Additional Features
        </label>
      </div>
      <div className="bg-secondary w-full max-w-4xl p-3 rounded-xl mx-auto">
        <div className="grid grid-cols-2 gap-4 m-3">
          <div className="mb-4 col-span-1">
            <label className="block text-white text-sm font-bold mb-2">
              Nights In Each City
            </label>

            <div className="flex flex-col">
              {localNightsInEachCity.map((item, index) => (
                <div key={index} className="flex mb-2">
                  <input
                    className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
                    onChange={(e) => handleCityChange(index, e.target.value)}
                    value={item.city}
                    type="text"
                    placeholder="City"
                  />
                  <input
                    className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    onChange={(e) => handleNightsChange(index, e.target.value)}
                    value={item.nights}
                    type="number"
                    placeholder="Nights"
                  />
                  <button
                    onClick={() =>
                      setLocalNightsInEachCity(
                        localNightsInEachCity.filter((_, i) => i !== index)
                      )
                    }
                    className="bg-red-500 px-2 rounded-lg text-white font-bold focus:outline-none focus:shadow-outline mt-2 self-end ml-2"
                  >
                    Remove
                  </button>
                </div>
              ))}

              <button
                onClick={addCity}
                className="bg-blue-500 px-2 rounded-lg text-white font-bold focus:outline-none focus:shadow-outline mt-2 self-start"
              >
                Add City
              </button>
            </div>
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="itineraryDetails"
            >
              Itinerary Details
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="itineraryDetails"
              onChange={handleInputChange}
              value={packageDetails.additionalFeatures.itineraryDetails}
              type="text"
              placeholder="Itinerary Details"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="transportationDetails"
            >
              Transportation Details
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="transportationDetails"
              onChange={handleInputChange}
              value={packageDetails.additionalFeatures.transportationDetails}
              type="text"
              placeholder="Transportation Details"
            />
          </div>
          <div className="mb-4 col-span-1">
            <div className="flex items-center ">
            <label className="block text-white text-sm font-bold mb-2 mx-3 mt-2">
              Guide Services Available
            </label>
            <input
              type="checkbox"
              checked={isGuideServiceAvailable}
              onChange={(prev) => {
                setIsGuideServiceAvailable(!isGuideServiceAvailable);
                if (!isGuideServiceAvailable) {
                  setGuideServiceLanguages([]);
                }
              }}
              className="mr-2 leading-tight"
            />
            </div>
            {/* <span className="text-sm text-white">Yes</span> */}
          </div>
          {isGuideServiceAvailable && (
          <div className="mb-4 col-span-1"></div>
            )}

          {/* Guide Services Languages */}
          {isGuideServiceAvailable && (
            <div className="mb-4 col-span-1">
              <label className="block text-white text-sm font-bold mb-2">
                Guide Service Languages
              </label>
              {guideServiceLanguages.map((language, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2"
                    type="text"
                    placeholder={`Language #${index + 1}`}
                    value={language}
                    onChange={(e) =>
                      handleLanguageChange(index, e.target.value)
                    }
                  />
                  <button
                    className="bg-red-500 px-2 rounded text-white"
                    onClick={() => removeLanguage(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                onClick={addLanguage}
                className="bg-blue-500 px-2 rounded-lg text-white font-bold focus:outline-none focus:shadow-outline mt-2"
              >
                Add Language
              </button>
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 gap-4 m-3">
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="travelInsurance"
            >
              Travel Insurance
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-
                    3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="travelInsurance"
              onChange={handleInputChange}
              value={packageDetails.additionalFeatures.travelInsurance}
              type="text"
              placeholder="Travel Insurance"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="paymentOptions"
            >
              Payment Options
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="paymentOptions"
              onChange={handleInputChange}
              value={packageDetails.additionalFeatures.paymentOptions}
              type="text"
              placeholder="Payment Options"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="healthSafetyMeasures"
            >
              Health Safety Measures
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="healthSafetyMeasures"
              onChange={handleInputChange}
              value={packageDetails.additionalFeatures.healthSafetyMeasures}
              type="text"
              placeholder="Health Safety Measures"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="sustainabilityPractices"
            >
              Sustainability Practices
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="sustainabilityPractices"
              onChange={handleInputChange}
              value={packageDetails.additionalFeatures.sustainabilityPractices}
              type="text"
              placeholder="Sustainability Practices"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="emergencySupport"
            >
              Emergency Support
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="emergencySupport"
              onChange={handleInputChange}
              value={packageDetails.additionalFeatures.emergencySupport}
              type="text"
              placeholder="Emergency Support"
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="localPartners"
            >
              Local Partners
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="localPartners"
              onChange={handleInputChange}
              value={packageDetails.additionalFeatures.localPartners}
              type="text"
              placeholder="Local Partners"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalFeatures;

