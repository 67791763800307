import React from "react";
import { useState, useEffect } from "react";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import { select } from "@material-tailwind/react";

const Hotel = (props) => {
  const {
    country,
    selectedCountryData,
    selectedHotel,
    setSelectedHotel,
    clickedHotelId,
    setClickedHotelId,
    adults,
    child,
    HotelCities,
    setHotelCities,
    HotelCategories,
    setHotelCategories,
    HotelRooms,
    setHotelRooms,
    HotelSupplements,
    setHotelSupplements,
  } = useContext(UserContext);
  // const [hotelCity, setHotelCity] = useState("");
  const [hotelData, setHotelData] = useState([]);
  // const [hotelCategory, setHotelCategory] = useState("");
  const [filteredHotelData, setFilteredHotelData] = useState([]);
  const hotelCategories = ["3 Star", "4 Star", "5 Star", "Budgeted"];
  const roomTypes = ["Deluxe", "Super Deluxe"];

  //   useEffect(() => {
  //     updateSelectedHotelsSupplements();
  //     updateSelectedHotelRooms();
  // }, [adults, child]);

  // const updateSelectedHotelsSupplements = () => {
  //     const newSupplementValue = ((parseInt(adults) + parseInt(child)) % 2)
  //     console.log("newSupplementValue: ", newSupplementValue);

  //     const updatedHotels = selectedHotel.map((hotel) => ({
  //         ...hotel,
  //         numberofSupplements: newSupplementValue,
  //     }));

  //     setSelectedHotel(updatedHotels);
  // };

  // const updateSelectedHotelRooms = () => {
  //     const newRoomValue = Math.max(Math.floor((parseInt(adults) + parseInt(child)) / 2), 1);

  //     const updatedHotels = selectedHotel.map((hotel) => ({
  //         ...hotel,
  //         numberOfRooms: newRoomValue,
  //     }));

  //     setSelectedHotel(updatedHotels);
  // };

  useEffect(() => {
    let tempData = props.hotelData;
    if (HotelCities[props.day - 1]) {
      tempData = tempData.filter(
        (item) =>
          item.City.replace(/\s+/g, "")
            .toLowerCase()
            .includes(
              HotelCities[props.day - 1].replace(/\s+/g, "").toLowerCase()
            ) ||
          item.Name.replace(/\s+/g, "")
            .toLowerCase()
            .includes(
              HotelCities[props.day - 1].replace(/\s+/g, "").toLowerCase()
            )
      );
    }
    if (HotelCategories[props.day - 1]) {
      tempData = tempData.filter(
        (item) => item.Category === HotelCategories[props.day - 1]
      );
    }

    tempData.sort((a, b) => {
      const aSelected = selectedHotel.some(
        (hotel) => hotel.selectedHotel._id === a._id && hotel.day === props.day
      );
      const bSelected = selectedHotel.some(
        (hotel) => hotel.selectedHotel._id === b._id && hotel.day === props.day
      );
      return bSelected - aSelected; // Selected hotels will come first
    });
    setFilteredHotelData(tempData);
  }, [
    HotelCities[props.day - 1],
    HotelCategories[props.day - 1],
    props.hotelData,
  ]);

  const handleHotelButtonClick = (item) => {
    const hotelId = item._id;
    const currentDay = props.day;

    // Check if the hotel button is already clicked for the same day
    const isAlreadySelected = selectedHotel.some(
      (hotel) => hotel.selectedHotel._id === hotelId && hotel.day === currentDay
    );

    if (isAlreadySelected) {
      // If the item is already selected for the same day, remove it from the selectedHotel
      const updatedSelectedHotels = selectedHotel.filter(
        (hotel) =>
          !(hotel.selectedHotel._id === hotelId && hotel.day === currentDay)
      );
      setSelectedHotel(updatedSelectedHotels);
      setClickedHotelId(null); // Reset the clicked hotel's unique identifier
    } else {
      // Remove any other hotel previously selected for the same day
      const otherHotels = selectedHotel.filter(
        (hotel) => hotel.day !== currentDay
      );

      // Add the new selected hotel for the current day
      setSelectedHotel([
        ...otherHotels,
        {
          day: currentDay,
          selectedHotel: item,
          numberOfRooms: parseInt(
            HotelRooms[props.day - 1] ||
              Math.floor((parseInt(adults) + parseInt(child)) / 2)
          ),
          numberofSupplements: parseInt(
            HotelSupplements[props.day - 1] ||
              Math.ceil((parseInt(adults) + parseInt(child)) / 2) -
                Math.floor((parseInt(adults) + parseInt(child)) / 2)
          ),
        },
      ]);
      setClickedHotelId(hotelId); // Set the clicked hotel's unique identifier
    }
  };

  useEffect(() => {
    console.log("HotelCities: ", HotelCities);
    console.log("HotelCategories: ", HotelCategories);
    console.log("selectedHotel: ", selectedHotel);
  }, [HotelCities, HotelCategories, selectedHotel]);


  return (
    <div>
      <div class="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
        <input
          type="text"
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
          value={HotelCities[props.day - 1] || ""}
          onChange={(e) => {
            setHotelCities((prev) => {
              let temp = [...prev];
              temp[props.day - 1] = e.target.value;
              return temp;
            });
          }}
          placeholder="Enter city or Hotel name"
        ></input>
        <select
          class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2  "
          value={
            HotelCategories[props.day - 1] ||
            (selectedHotel &&
            selectedHotel.some((hotel) => hotel.day === props.day)
              ? selectedHotel.find((hotel) => hotel.day === props.day)
                  .selectedHotel.Category
              : "")
          }
          onChange={(e) => {
            setHotelCategories((prev) => {
              let temp = [...prev];
              temp[props.day - 1] = e.target.value;
              return temp;
            });
          }}
        >
          <option>Select hotel category</option>
          {hotelCategories.map((item, ind) => (
            <option key={ind} value={item}>
              {item}
            </option>
          ))}
        </select>
        <div>
          <label class="block text-gray-400 text-sm font-medium mb-2">
            No. of rooms
          </label>
          <input
            onWheel={(e) => e.target.blur()}
            type="number"
            min={1}
            placeholder="Enter number of rooms"
            value={
              HotelRooms[props.day - 1] ||
              Math.max(
                Math.floor((parseInt(adults) + parseInt(child)) / 2),
                selectedHotel.some((hotel) => hotel.day === props.day)
                  ? selectedHotel.find((hotel) => hotel.day === props.day)
                      .numberOfRooms
                  : 0
              )
            }
            onChange={(e) => {
              let temp = [...HotelRooms];
              temp[props.day - 1] = e.target.value;
              setHotelRooms(temp);
              selectedHotel.some((hotel) => hotel.day === props.day) &&
                setSelectedHotel(
                  selectedHotel.map((hotel) =>
                    hotel.day === props.day
                      ? { ...hotel, numberOfRooms: e.target.value }
                      : hotel
                  )
                );
            }}
            class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
          />
        </div>
        <div>
          <label class="block text-gray-400 text-sm font-medium mb-2">
            No. of supplements
          </label>
          <input
            type="number"
            onWheel={(e) => e.target.blur()}
            placeholder="Enter number of supplements"
            min={0}
            value={
              HotelSupplements[props.day - 1] ||
              Math.max(
                Math.ceil((parseInt(adults) + parseInt(child)) / 2) -
                  Math.floor((parseInt(adults) + parseInt(child)) / 2),
                selectedHotel.some((hotel) => hotel.day === props.day)
                  ? selectedHotel.find((hotel) => hotel.day === props.day)
                      .numberofSupplements
                  : 0
              )
            }
            onChange={(e) => {
              let temp = [...HotelSupplements];
              temp[props.day - 1] = e.target.value;
              setHotelSupplements(temp);
              selectedHotel.some((hotel) => hotel.day === props.day) &&
                setSelectedHotel(
                  selectedHotel.map((hotel) =>
                    hotel.day === props.day
                      ? { ...hotel, numberofSupplements: e.target.value }
                      : hotel
                  )
                );
            }}
            class="hover:scale-105 bg-gray-700 text-white rounded-md w-full p-2"
          />
        </div>
      </div>
      <div className="flex overflow-y-hidden w-full">
        {filteredHotelData.map((item) => (
          <div key={item._id} className="mx-4 my-2 flex-shrink-0">
            <div
              class="  h-[185px] w-[200px] d-flex flex-column bg-secondary p-2 rounded-md "
              style={{ boxShadow: "1px 1px 3px 0px black" }}
            >
              <div className="">
                <div class="mb-2 p-2  flex-grow">
                  <div className="h-[55px]">
                    <div class="text-xl truncate-2-lines  my-2 font-medium">
                      {item.Name}
                    </div>
                  </div>
                  <div class="text-xs text-gray-400 my-1">{item.Category}</div>
                  <div class="text-xs  text-gray-400">{item.City}</div>

                  <div class="text-xs text-gray-400">{item.RoomType}</div>

                  {/* <div class="text-xs text-gray-400">{item.Rating}</div> */}
                </div>
                {/* <img
                    src={item.Images.split(" , ")[0]}
                    alt=""
                    className="w-20 h-20"
                  /> */}
              </div>

              <div className="flex justify-end ">
                <div class="text-lg text-gray-200 mr-2">
                  ₹{item.CPRoomPrice.toLocaleString("en-IN")}
                </div>
                <button
                  className={`mt-auto hover:scale-105  px-2 rounded-md flex text-xl ${
                    selectedHotel.some(
                      (hotel) =>
                        hotel.selectedHotel._id === item._id &&
                        hotel.day === props.day
                    )
                      ? "bg-green-600"
                      : "bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121]"
                  }`}
                  onClick={() => handleHotelButtonClick(item)}
                >
                  {selectedHotel.some(
                    (hotel) =>
                      hotel.selectedHotel._id === item._id &&
                      hotel.day === props.day
                  ) ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  ) : (
                    "+"
                  )}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Hotel;
