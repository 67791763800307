import React from "react";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import UserContext from "./contexts/UserContext";
import { useContext } from "react";
import PackageDetails from "./AdminPackageForms/PackageDetails";
import AccomodationDeatils from "./AdminPackageForms/AccomodationDetails";
import AdditionalFeatures from "./AdminPackageForms/AdditionalFeatures";
import { useNavigate } from "react-router-dom";

const AdminCreateAPackage = () => {
  const navigate = useNavigate();
  const { packageDetails, setPackageDetails, setCountry } =
    useContext(UserContext);
  const [section, setSection] = useState("packageDetails");
  const [customId, setCustomId] = useState("");
  const customIdGenerator = () => {
    const pre = "PACKAGE-";
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const id = pre + year + month + Math.floor(Math.random() * 1000);
    return id;
  };

  useEffect(() => {
    setCustomId(customIdGenerator());
    sessionStorage.setItem("customId", customId);
  }, []);

  const handleNavigate = () => {
    if (
      !packageDetails.packageDetails.country ||
      !packageDetails.packageDetails.days
    ) {
      alert("Please fill the package details");
      return;
    }
    navigate(
      `/iteneryBuilder/${customId}/${
        packageDetails.packageDetails.country
      }/${parseInt(packageDetails.packageDetails.days)}`
    );
  };

  return (
    <div>
      <header className="text-gray-200 body-font">
        <div className="text-3xl font-bold text-center py-4 bg-secondary">
          Create a Package
        </div>
      </header>
      <div className="grid grid-cols-5 grid-rows-1 gap-4 p-4 mx-5 ">
        <motion.div
          className={`col-span-1 row-span-1  h-full w-full p-4 rounded-xl text-white flex justify-center cursor-pointer ${
            section === "packageDetails" ||
            section === "accommodations" ||
            section === "additionalFeatures"
              ? "bg-blue-500"
              : "bg-tertiary"
          }`}
          whileHover={{ scale: 1.2 }}
          onHoverStart={(e) => {}}
          onHoverEnd={(e) => {}}
          whileTap={{ scale: 0.9 }}
          onClick={() => setSection("packageDetails")}
        >
          <div className="font-montserrat font-semibold text-xl">
            Package Details
          </div>
        </motion.div>
        <div className="flex items-center">
          <div
            className={`col-span-1 row-span-1  h-1 w-full ${
              section === "accommodations" || section === "additionalFeatures"
                ? "bg-blue-500"
                : "bg-tertiary"
            }`}
          ></div>
        </div>
        <motion.div
          className={`col-span-1 row-span-1  h-full w-full p-4 rounded-xl text-white flex justify-center cursor-pointer ${
            section === "accommodations" || section === "additionalFeatures"
              ? "bg-blue-500"
              : "bg-tertiary"
          }`}
          whileHover={{ scale: 1.2 }}
          onHoverStart={(e) => {}}
          onHoverEnd={(e) => {}}
          whileTap={{ scale: 0.9 }}
          onClick={() => setSection("accommodations")}
        >
          <div className="font-montserrat font-semibold text-xl">
            Accomodations
          </div>
        </motion.div>
        <div className="flex items-center">
          <div
            className={`col-span-1 row-span-1  h-1 w-full ${
              section === "additionalFeatures" ? "bg-blue-500" : "bg-tertiary"
            }`}
          ></div>
        </div>

        <motion.div
          className={`col-span-1 row-span-1  h-full w-full p-4 rounded-xl text-white flex justify-center cursor-pointer ${
            section === "additionalFeatures" ? "bg-blue-500" : "bg-tertiary"
          }`}
          whileHover={{ scale: 1.2 }}
          onHoverStart={(e) => {}}
          onHoverEnd={(e) => {}}
          whileTap={{ scale: 0.9 }}
          onClick={() => setSection("additionalFeatures")}
        >
          <div className="font-montserrat font-semibold text-xl">
            Additional Features
          </div>
        </motion.div>
      </div>
      {section === "packageDetails" && (
        <PackageDetails
          setPackageDetails={setPackageDetails}
          packageDetails={packageDetails}
          setCountry={setCountry}
        />
      )}
      {section === "accommodations" && (
        <AccomodationDeatils
          setPackageDetails={setPackageDetails}
          packageDetails={packageDetails}
        />
      )}
      {section === "additionalFeatures" && (
        <AdditionalFeatures
          setPackageDetails={setPackageDetails}
          packageDetails={packageDetails}
        />
      )}
      <button
        className="bg-blue-500 text-white p-2 rounded-lg  h-fit w-fit mx-3"
        onClick={handleNavigate}
      >
        Next
      </button>
    </div>
  );
};

export default AdminCreateAPackage;

// const [packageDetails, setPackageDetails] =  useState({
//     packageDetails: {
//       packageId: '',
//       country: [],
//       creationDate: '',
//       lastUpdated: '',
//       language: [],
//       name: '',
//       days: 0,
//       nights: 0,
//       placesCovered: [],
//       citiesCovered: [],
//       seasonality: '',
//       difficultyLevel: '',
//       theme: '',
//       maxGroupSize: 0,
//       customizability: false,
//       tags: [],
//       packageTypes: [],
//       inclusions: [],
//       exclusions: [],
//       optionalActivities: [],
//       cancellationPolicy: '',
//       mealPlanDetails: '',
//       healthVaccinationRequirements: '',
//       visaAssistance: false,
//       culturalEtiquetteTips: '',
//       testimonialsRatings: {
//         testimonials: [],
//         averageRating: 0
//       },
//       photoGallery: [],
//       videoOverview: '',
//       interactiveMap: '',
//     },
//     accommodations: {
//       hotelCategory: '',
//       roomType: '',
//       amenities: [],
//       locationType: '',
//     },
//     additionalFeatures: {
//       nightsInEachCity: [{
//     city: '',
//     nights: 0

//   }],
//       itineraryDetails: [],
//       transportationDetails: '',
//       guideServices: {
//         available: false,
//         languages: []
//       },
//       travelInsurance: '',
//       paymentOptions: [],
//       healthSafetyMeasures: '',
//       sustainabilityPractices: '',
//       emergencySupport: '',
//       localPartners: []
//     }
//   });
