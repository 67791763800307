import React, { useEffect, useState } from "react";
import { BlobProvider } from "@react-pdf/renderer";
import HotelVoucher from "./HotelVoucher";
import Navbar from "../Navbar";

const HotelVoucherForm = () => {
  const initialHotelDetail = {
    hotelConfirmationNumber: "",
    hotelName: "",
    hotelAddress: "",
    hotelContact: "",
    hotelEmail: "",
    checkInDateTime: "",
    checkOutDateTime: "",
    numberOfNights: "",
  };

  const initialRoomDetails = {
    roomUnits: "",
    roomPlan: "",
    roomType: "",
    additionalRequests: "",
  };

  const [Form, setForm] = useState({
    name: "",
    travauraBookingID: "",
    bookingDate: "",
    bookedBy: "",
    numberOfGuests: 1,
    numberOfHotels: 1,
    HotelDetails: [initialHotelDetail], // Initialize with one hotel details object
    RoomDetails: [initialRoomDetails], // Initialize with one room details object
  });

  useEffect(() => {
    console.log("Form: ", Form);
  }, [Form]);
  const [generatePdf, setGeneratePdf] = useState(false); // State to trigger PDF generation

  const downloadPDF = async (blobUrl) => {
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = "hotel-voucher.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setGeneratePdf(false); // Reset the PDF generation trigger
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data: ", Form);
    setGeneratePdf(true); // Set to true to trigger PDF generation
  };

  return (
    <div>
      {/* <div className="flex justify-center items-center h-20 bg-secondary ">
        <h1 className="text-3xl text-white">Hotel Voucher Form</h1>
      </div> */}
      <Navbar />
      <form
        className="flex flex-col justify-center items-center gap-6 p-4 text-black"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-4 gap-4 w-full">
          <label className="text-xl col-span-1 flex items-center text-white">
            Name:
          </label>
          <div className="col-span-1"></div>
          <input
            type="text"
            placeholder="Enter Name"
            className="p-2 rounded-md col-span-2"
            value={Form.name}
            onChange={(e) => setForm({ ...Form, name: e.target.value })}
          />

          <label className="text-xl col-span-1 flex items-center text-white">
            Number of Guests:
          </label>
          <div className="col-span-1"></div>
          <input
            type="number"
            placeholder="Enter Number of Guests"
            className="p-2 rounded-md col-span-2"
            value={Form.numberOfGuests}
            onScroll={(e) => e.target.blur()}
            onChange={(e) =>
              setForm({
                ...Form,
                numberOfGuests: e.target.value ? parseInt(e.target.value) : 1,
              })
            }
          />

          <label className="text-xl col-span-1 flex items-center text-white">
            Number of Hotels:
          </label>
          <div className="col-span-1"></div>
          <input
            type="number"
            placeholder="Enter Number of Hotels"
            className="p-2 rounded-md col-span-2"
            value={Form.numberOfHotels}
            min={1}
            onScroll={(e) => e.target.blur()}
            onChange={(e) =>
              setForm({
                ...Form,
                numberOfHotels: e.target.value ? parseInt(e.target.value) : 1,
              })
            }
          />
          <label className="text-xl col-span-1 flex items-center text-white">
            Travaura Booking ID:
          </label>
          <div className="col-span-1"></div>
          <input
            type="text"
            placeholder="Enter Travaura Booking ID"
            className="p-2 rounded-md col-span-2"
            value={Form.travauraBookingID}
            onChange={(e) => setForm({ ...Form, travauraBookingID: e.target.value })}
          />

          <label className="text-xl col-span-1 flex items-center text-white">
            Booking Date:
          </label>
          <div className="col-span-1"></div>
          <input
            type="date"
            placeholder="Enter Booking Date"
            className="p-2 rounded-md col-span-2"
            value={Form.bookingDate}
            onChange={(e) => setForm({ ...Form, bookingDate: e.target.value })}
          />

          <label className="text-xl col-span-1 flex items-center text-white">
            Booked By:
          </label>
          <div className="col-span-1"></div>
          <input
            type="text"
            placeholder="Enter Booked By"
            className="p-2 rounded-md col-span-2"
            value={Form.bookedBy}
            onChange={(e) => setForm({ ...Form, bookedBy: e.target.value })}

          />
        </div>
        <div className="w-full">
          {Array.from(Array((Form && Form.numberOfHotels) || 1
          )).map((_, index) => (
            <div key={index} className="flex gap-4 mb-4">
              {/* Hotel Details */}
              <div className="flex-1 flex flex-col gap-4 p-4 bg-gray-200 text-black rounded-md">
                <h2 className="text-2xl text-center">
                  Hotel {index + 1} Details
                </h2>
                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Hotel Confirmation Number:</label>
                <input
                  type="text"
                  placeholder="Enter Hotel Confirmation Number"
                  className="p-2 rounded-md"
                  value={
                    Form.HotelDetails[index]
                      ? Form.HotelDetails[index].hotelConfirmationNumber
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      HotelDetails: [
                        ...Form.HotelDetails.slice(0, index),
                        {
                          ...Form.HotelDetails[index],
                          hotelConfirmationNumber: e.target.value,
                        },
                        ...Form.HotelDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>
                {/* Include other hotel detail fields here */}
                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Hotel Name:</label>
                <input
                  type="text"
                  placeholder="Enter Hotel Name"
                  className="p-2 rounded-md"
                  value={
                    Form.HotelDetails[index]
                      ? Form.HotelDetails[index].hotelName
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      HotelDetails: [
                        ...Form.HotelDetails.slice(0, index),
                        {
                          ...Form.HotelDetails[index],
                          hotelName: e.target.value,
                        },
                        ...Form.HotelDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>

                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Hotel Address:</label>
                <input
                  type="text"
                  placeholder="Enter Hotel Address"
                  className="p-2 rounded-md"
                  value={
                    Form.HotelDetails[index]
                      ? Form.HotelDetails[index].hotelAddress
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      HotelDetails: [
                        ...Form.HotelDetails.slice(0, index),
                        {
                          ...Form.HotelDetails[index],
                          hotelAddress: e.target.value,
                        },
                        ...Form.HotelDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>

 <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Hotel Contact:</label>
                <input
                  type="text"
                  placeholder="Enter Hotel Contact"
                  className="p-2 rounded-md"
                  value={
                    Form.HotelDetails[index]
                      ? Form.HotelDetails[index].hotelContact
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      HotelDetails: [
                        ...Form.HotelDetails.slice(0, index),
                        {
                          ...Form.HotelDetails[index],
                          hotelContact: e.target.value,
                        },
                        ...Form.HotelDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>

                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Hotel Email:</label>
                <input
                  type="text"
                  placeholder="Enter Hotel Email"
                  className="p-2 rounded-md"
                  value={
                    Form.HotelDetails[index]
                      ? Form.HotelDetails[index].hotelEmail
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      HotelDetails: [
                        ...Form.HotelDetails.slice(0, index),
                        {
                          ...Form.HotelDetails[index],
                          hotelEmail: e.target.value,
                        },
                        ...Form.HotelDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>

                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Check In Date and Time:</label>
                <input
                  type="datetime-local"
                  placeholder="Enter Check In Date and Time"
                  className="p-2 rounded-md"
                  value={
                    Form.HotelDetails[index]
                      ? Form.HotelDetails[index].checkInDateTime
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      HotelDetails: [
                        ...Form.HotelDetails.slice(0, index),
                        {
                          ...Form.HotelDetails[index],
                          checkInDateTime: e.target.value,
                        },
                        ...Form.HotelDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>

                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Check Out Date and Time:</label>
                <input
                  type="datetime-local"
                  placeholder="Enter Check Out Date and Time"
                  className="p-2 rounded-md"
                  value={
                    Form.HotelDetails[index]
                      ? Form.HotelDetails[index].checkOutDateTime
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      HotelDetails: [
                        ...Form.HotelDetails.slice(0, index),
                        {
                          ...Form.HotelDetails[index],
                          checkOutDateTime: e.target.value,
                        },
                        ...Form.HotelDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>

                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Number of Nights:</label>
                <input
                  type="number"
                  placeholder="Enter Number of Nights"
                  className="p-2 rounded-md"
                  value={
                    Form.HotelDetails[index]
                      ? Form.HotelDetails[index].numberOfNights
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      HotelDetails: [
                        ...Form.HotelDetails.slice(0, index),
                        {
                          ...Form.HotelDetails[index],
                          numberOfNights: e.target.value,
                        },
                        ...Form.HotelDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>
              </div>

              {/* Room Details */}
              <div className="flex-1 flex flex-col gap-4 p-4 bg-gray-300 text-black rounded-md">
                <h2 className="text-2xl text-center">
                  Room {index + 1} Details
                </h2>
                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Room Units:</label>
                <input
                  type="number"
                  placeholder="Enter Room Units"
                  className="p-2 rounded-md"
                  value={
                    Form.RoomDetails[index]
                      ? Form.RoomDetails[index].roomUnits
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      RoomDetails: [
                        ...Form.RoomDetails.slice(0, index),
                        {
                          ...Form.RoomDetails[index],
                          roomUnits: e.target.value,
                        },
                        ...Form.RoomDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>
                {/* Include other room detail fields here */}
                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Room Plan:</label>
                <input
                  type="text"
                  placeholder="Enter Room Plan"
                  className="p-2 rounded-md"
                  value={
                    Form.RoomDetails[index]
                      ? Form.RoomDetails[index].roomPlan
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      RoomDetails: [
                        ...Form.RoomDetails.slice(0, index),
                        {
                          ...Form.RoomDetails[index],
                          roomPlan: e.target.value,
                        },
                        ...Form.RoomDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>

                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Room Type:</label>
                <input
                  type="text"
                  placeholder="Enter Room Type"
                  className="p-2 rounded-md"
                  value={
                    Form.RoomDetails[index]
                      ? Form.RoomDetails[index].roomType
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      RoomDetails: [
                        ...Form.RoomDetails.slice(0, index),
                        {
                          ...Form.RoomDetails[index],
                          roomType: e.target.value,
                        },
                        ...Form.RoomDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>

                <div className="grid grid-cols-2 gap-4">
                <label className="text-xl">Additional Requests:</label>
                <input
                  type="text"
                  placeholder="Enter Additional Requests"
                  className="p-2 rounded-md"
                  value={
                    Form.RoomDetails[index]
                      ? Form.RoomDetails[index].additionalRequests
                      : ""
                  }
                  onChange={(e) =>
                    setForm({
                      ...Form,
                      RoomDetails: [
                        ...Form.RoomDetails.slice(0, index),
                        {
                          ...Form.RoomDetails[index],
                          additionalRequests: e.target.value,
                        },
                        ...Form.RoomDetails.slice(index + 1),
                      ],
                    })
                  }
                />
                </div>
              </div>
            </div>
          ))}
        </div>

        <button className="bg-yellow-500 p-2 rounded-md mt-4 self-stretch">
          Generate Voucher
        </button>
      </form>

      {generatePdf && (
        <BlobProvider
          document={
            <HotelVoucher
              name={Form.name}
              travauraBookingID={Form.travauraBookingID}
              bookingDate={Form.bookingDate}
              bookedBy={Form.bookedBy}
              numberOfGuests={Form.numberOfGuests}
              HotelDetails={Form.HotelDetails}
              RoomDetails={Form.RoomDetails}
              numberOfHotels={Form.numberOfHotels}
            />
          }
        >
          {({ blob, url }) => {
            if (url) {
              downloadPDF(url);
            }
            return null;
          }}
        </BlobProvider>
      )}
    </div>
  );
};

export default HotelVoucherForm;
