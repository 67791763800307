import React from "react";
import { useState, useEffect } from "react";
import k from "../assets/images/k.png";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";

const HotelChangePage = () => {
  const { id, day, region, numberOfPeople, country } = useParams();
  const [HotelData, setHotelData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchPrompt, setSearchPrompt] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [numberOfRooms, setNumberOfRooms] = useState(
    Math.floor(numberOfPeople / 2)
  );
  const [numberofSupplements, setNumberofSupplements] = useState(
    Math.ceil(numberOfPeople / 2) - Math.floor(numberOfPeople / 2)
  );

  const navigate = useNavigate();

  const formatCurrency = (value) => {
    return value.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    });
  };

  useEffect(() => {
    if (country === "Vietnam") {
      fetch("https://travaurabackend.azurewebsites.net/api/hotel")
        .then((response) => response.json())
        .then((data) => setHotelData(data));
    } else if (country === "Bali") {
      fetch("https://travaurabackend.azurewebsites.net/api/BaliHotels")
        .then((response) => response.json())
        .then((data) => setHotelData(data));
    }
    else if (country === "Thailand") {
      fetch("https://travaurabackend.azurewebsites.net/api/ThailandHotelsSchema")
        .then((response) => response.json())
        .then((data) => setHotelData(data));
    }
  }, []);

  useEffect(() => {
    let tempData = HotelData;
    if (region !== "all") {
      tempData = tempData.filter((item) => item.Region.trim() === region);
    }
    if (searchPrompt) {
      tempData = tempData.filter(
        (item) =>
          item.Name &&
          typeof item.Name === "string" &&
          item.Name.toLowerCase().includes(searchPrompt.toLowerCase())
      );
    }
    setFilterData(tempData);
  }, [HotelData, region, searchPrompt]);

  useEffect(() => {
    console.log("selectedData: ", selectedData);
    console.log("id: ", id);
    console.log("day: ", day);
  }, [selectedData, id, day]);

  const handleUpdateActivity = (hotel) => {
    // Construct the update payload
    const updatePayload = {
      day: day,
      numberOfRooms: numberOfRooms,
      numberofSupplements: numberofSupplements,
      selectedHotel: hotel,
    };
    if (region !== "all") {
      fetch(
        `https://travaurabackend.azurewebsites.net/api/updateHotel/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatePayload),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Update response:", data);
          // Handle the response, update local state if needed
        })
        .catch((error) => {
          console.error("Error updating activity:", error);
        });
    } else {
      fetch(`https://travaurabackend.azurewebsites.net/addHotel/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatePayload),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Update response:", data);
          // Handle the response, update local state if needed
        })
        .catch((error) => {
          console.error("Error updating hotel:", error);
        });
    }
  };

  return (
    <div>
      <div className="flex">
        <aside className="w-16 bg-gray-800 h-screen flex flex-col items-center py-4">
          <div className="mb-10">
            <i className="fas fa-user-circle text-3xl"></i>
          </div>
          <div className="flex flex-col space-y-4">
            <i className="fas fa-home text-xl"></i>
            <i className="fas fa-map-marker-alt text-xl"></i>
            <i className="fas fa-cog text-xl"></i>
          </div>
        </aside>
        <div className="flex-1 p-8">
          <div className="flex justify-between items-center mb-10">
            <input
              type="text"
              placeholder="Search for destinations"
              className="bg-gray-800 text-gray-300 rounded-full py-2 px-4 w-1/3"
              value={searchPrompt}
              onChange={(e) => setSearchPrompt(e.target.value)}
            />
            
          </div>

          <h2 className="text-2xl font-semibold mb-6 text-gray-300">
            Hotels
          </h2>

          <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-6">
            {filterData.map((activity, index) => (
              <div
                key={index}
                className="bg-gray-800 rounded-lg overflow-hidden text-gray-300"
              >
               {country === "Vietnam" && activity.Images && typeof activity.Images === 'string' && (
  <img
    src={activity.Images.split(" , ")[0] || "https://www.vietnam-guide.com/pictures/overview/hoi-an-old-town.jpg"}
    alt={`Image of ${activity.Name}`}
    className="w-full h-32 sm:h-48 object-cover"
  />
)}

                {country === "Bali" && activity.Images && (
                  <img
                    src={activity.Images[0]}
                    alt={`Image of ${activity.Name}`}
                    className="w-full h-32 sm:h-48 object-cover"
                  />
                )}
                <div className="p-4">
                  <h3 className="text-lg font-semibold">{activity.Name}</h3>
                  <div className="text-sm text-gray-400">
                    <i className="fas fa-map-marker-alt"></i>{" "}
                    {activity.Category}
                  </div>
                  <div className="text-sm text-gray-400">
                    <i className="fas fa-map-marker-alt"></i> {activity.City}
                  </div>
                  <button className="text-blue-600">View</button>
                  <div className="flex items-center mt-2">
                    <i className="fas fa-star text-yellow-400"></i>
                    <span className="ml-1 text-xl">
                      Price:{" "}
                      {formatCurrency(
                        activity.CPRoomPrice + (22 / 100) * activity.CPRoomPrice
                      )}
                    </span>
                    <button
                      className={`${
                        selectedData._id === activity._id
                          ? "bg-red-500"
                          : "bg-blue-600"
                      } text-white rounded-full py-2 px-4 ml-auto`}
                      onClick={async () => {
                        if (selectedData._id === activity._id) {
                          setSelectedData({}); // Deselect the activity
                        } else {
                          setSelectedData(activity); // Select the activity
                          await new Promise((r) => setTimeout(r, 500));
                          await window.scrollTo({
                            top: document.body.scrollHeight,
                            behavior: "smooth",
                          });
                        }
                      }}
                    >
                      {selectedData._id === activity._id
                        ? "Deselect Hotel"
                        : "Add Hotel"}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button
            className="bg-blue-600 text-white rounded-full py-2 px-4 ml-auto mt-4"
            onClick={() => {
              handleUpdateActivity(selectedData);
              navigate(-1);
            }}
          >
            Go back
          </button>
        </div>
      </div>
    </div>
  );
};

export default HotelChangePage;
