import { motion } from "framer-motion";

const variants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};
const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 50,
    opacity: 0,
  },
};

const Links = () => {
  const items = [ "Dashboard","Pro Itinerary", "All Packages", "Master Packages", "My Bookings", "Hotel Vouchers", "Hotel Management", "Transfers Management", "Activity Management", "Upload Flight Voucher", "Flight Vouchers Management", "User Management", "Company Settings", "Billing"];
//   Itinerary Pro

//   My Bookings
  
//   My Itineraries
  
//   All Products
  
//   Database Management
  
//   Hotel Management
  
//   Transfers Management
  
//   Activity Management
  
//   User Management
  
//   Company Settings
  
//   Billing
  return (
    <motion.div className="links" variants={variants}>
      {items.map((item) => (
        <motion.a
        href = {`/${item}`}
          key={item}
          variants={itemVariants}
          whileHover={{ scale: 1.1, color: "#1047a1" }}
          whileTap={{ scale: 0.95 }}
        >
          {item}
        </motion.a>
      ))}
    </motion.div>
  );
};

export default Links;