import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import VoucherHead from "../assets/images/VoucherHead.png";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "col",
   
  },
  HeaderImage: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
  Header: {
    marginVertical: 10,
    padding: 10,
    marginHorizontal: 0,
    textAlign: "center",
    fontSize: 30,
    fontWeight: "bold",
    color: "#0C5393",
  },
  horizontalLine: {
    borderBottomWidth: 1, // Adjust the height of the line
    marginBottom: 20, // Adjust the vertical margin to align with your layout
    marginHorizontal: 60, // Adjust the horizontal margin to align with your layout
  },
  section2: {
    marginVertical: 10,
    // marginBottom: 25,
    padding: 10,
    marginHorizontal: 0,
    backgroundColor: "#BFF0B0",
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
    color: "#448C2F",
  },
  section3: {
    marginTop: 10,
    paddingHorizontal: 10,
    marginHorizontal: 20,

    fontSize: 30,
    fontWeight: "bold",
    color: "#0C5393",
  },
  section4: {
    marginBottom: 10,
    padding: 10,
    marginHorizontal: 20,
    fontSize: 13,
    color: "#767676",
  },
  CombinedSection: {
    margin: 0,
    paddingHorizontal: 20,
  },
  BookingDetailsHeader: {
    display: "flex",
    flexDirection: "row", // Align children in a row
    justifyContent: "space-between", // Space out the children
    alignItems: "center", // Align items vertically in the center
    marginBottom: 10,
    padding: 10,
    marginHorizontal: 20,
    marginLeft: 30,
  },
  hr: {
    borderBottomWidth: 3, // Increase the thickness of the line
    borderBottomColor: "#0C5393", // Color of the line
    width: "30%", // Width of the line
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#999999",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginHorizontal: 20,
    marginLeft: 30,
    marginBottom: 20,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "#999999",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    paddingVertical: 5,
  },
});

// Create Document Component
const HotelVoucher = ({ 
  name,
  travauraBookingID,
  bookingDate,
  bookedBy,
  numberOfGuests,
  HotelDetails,
  RoomDetails,
  numberOfHotels

 }) => (

  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.HeaderImage}>
        <Image src={VoucherHead} />
      </View>

      <View style={styles.Header}>
        <Text>Hotel Confirmation Voucher</Text>
      </View>

      {/* Horizontal Line */}
      <View style={styles.horizontalLine} />

      <View style={styles.section2}>
        <Text>Your Booking is confirmed</Text>
      </View>
      <View style={styles.CombinedSection}>
        <View style={styles.section3}>
          <Text>Hey {name && name.split(" ")[0]},</Text>
        </View>
        <View style={styles.section4}>
          <Text>
            Thank you for choosing Travaura. We’re excited to host you for your
            booking {travauraBookingID && travauraBookingID}. Below mentioned are the details of your reservation
            for hotels under your trip confirmation. Kindly go through the
            details carefully.
          </Text>
        </View>
      </View>
      <View style={styles.horizontalLine} />
      <View style={styles.CombinedSection}>
        <View style={styles.BookingDetailsHeader}>
          <Text style={{ fontSize: 25, fontWeight: "bold", color: "#0C5393" }}>
            Booking Details
          </Text>
          <View style={styles.hr}></View>
        </View>
        {/* table with 2 columns 3 rows */}
        <View style={styles.table}>
          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Travaura Booking ID</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {travauraBookingID && travauraBookingID}
              </Text>
            </View>
          </View>

          {/* Row 2 */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Booking Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {bookingDate && bookingDate}
              </Text>
            </View>
          </View>

          {/* Row 3 */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Booked by</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {bookedBy && bookedBy}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.BookingDetailsHeader}>
          <Text style={{ fontSize: 25, fontWeight: "bold", color: "#0C5393" }}>
          Guest Details
          </Text>
          <View style={styles.hr}></View>
        </View>
        {/* table with 2 columns 3 rows */}
        <View style={styles.table}>
          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Guest Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {name && name}
              </Text>
            </View>
          </View>

          {/* Row 2 */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Number of Guests</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {numberOfGuests && numberOfGuests}
              </Text>
            </View>
          </View>

          
        </View>
      </View>
    </Page>
    {Array.from(Array(numberOfHotels)).map((_, index) => (
    <Page size="A4" style={styles.page}>
      <View style={styles.HeaderImage}>
        <Image src={VoucherHead} />
      </View>

     
      <View style={styles.section2}>
        <Text>Your Booking is confirmed</Text>
      </View>
      
      
      <View style={styles.CombinedSection}>
        <View style={styles.BookingDetailsHeader}>
          <Text style={{ fontSize: 25, fontWeight: "bold", color: "#0C5393" }}>
          Hotel Details
          </Text>
          <View style={styles.hr}></View>
        </View>
        {/* table with 2 columns 3 rows */}
        <View style={styles.table}>
          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Hotel Confirmation Number</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                { HotelDetails[index] && HotelDetails[index].hotelConfirmationNumber && HotelDetails[index].hotelConfirmationNumber}
              </Text>
            </View>
          </View>

          {/* Row 2 */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Hotel Name</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {HotelDetails[index] && HotelDetails[index].hotelName && HotelDetails[index].hotelName}
              </Text>
            </View>
          </View>

          {/* Row 3 */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Hotel Address</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {HotelDetails[index] && HotelDetails[index].hotelAddress && HotelDetails[index].hotelAddress}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Hotel Contact</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {HotelDetails[index] && HotelDetails[index].hotelContact && HotelDetails[index].hotelContact}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Hotel Email</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {HotelDetails[index] && HotelDetails[index].hotelEmail && HotelDetails[index].hotelEmail}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Check in date and time</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {HotelDetails[index] && HotelDetails[index].checkInDateTime && HotelDetails[index].checkInDateTime}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Check out date and time</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {HotelDetails[index] && HotelDetails[index].checkOutDateTime && HotelDetails[index].checkOutDateTime}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Number of Nights</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {HotelDetails[index] && HotelDetails[index].numberOfNights && HotelDetails[index].numberOfNights}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.BookingDetailsHeader}>
          <Text style={{ fontSize: 25, fontWeight: "bold", color: "#0C5393" }}>
          Room Details
          </Text>
          <View style={styles.hr}></View>
        </View>
        {/* table with 2 columns 3 rows */}
        <View style={styles.table}>
          {/* Row 1 */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Room Units</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                { RoomDetails[index] && RoomDetails[index].roomUnits && RoomDetails[index].roomUnits}
              </Text>
            </View>
          </View>

          {/* Row 2 */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Room Plan</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {RoomDetails[index] && RoomDetails[index].roomPlan && RoomDetails[index].roomPlan}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Room Type</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {RoomDetails[index] && RoomDetails[index].roomType && RoomDetails[index].roomType}
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Additional Requests</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {RoomDetails[index] && RoomDetails[index].additionalRequests && RoomDetails[index].additionalRequests}
              </Text>
            </View>
          </View>

          
        </View>
      </View>
    </Page>
    ))}
  </Document>
);

export default HotelVoucher;


// const initialHotelDetail = {
//   hotelConfirmationNumber: "",
//   hotelName: "",
//   hotelAddress: "",
//   hotelContact: "",
//   hotelEmail: "",
//   checkInDateTime: "",
//   checkOutDateTime: "",
//   numberOfNights: "",
// };

// const initialRoomDetails = {
//   roomUnits: "",
//   roomPlan: "",
//   roomType: "",
//   additionalRequests: "",
// };

// const [Form, setForm] = useState({
//   name: "",
//   travauraBookingID: "",
//   bookingDate: "",
//   bookedBy: "",
//   numberOfGuests: 1,
//   numberOfHotels: 1,
//   HotelDetails: [initialHotelDetail], // Initialize with one hotel details object
//   RoomDetails: [initialRoomDetails], // Initialize with one room details object
// });

{/* <HotelVoucher
              name={Form.name}
              travauraBookingID={Form.travauraBookingID}
              bookingDate={Form.bookingDate}
              bookedBy={Form.bookedBy}
              numberOfGuests={Form.numberOfGuests}
              HotelDetails={Form.HotelDetails}
              RoomDetails={Form.RoomDetails}
            /> */}