import React from "react";
import { useState, useEffect } from "react";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";
import AirportTransferCard from "./AirportTransferCard";
import ItineraryDescription from "../Description";
import { animate, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { FaExchangeAlt } from "react-icons/fa";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
const ActivityCard = (props) => {
  const {
    setSelectedHotel,
    meals,
    setMeals,
    setItenary,
    itenary,
    selectedHotel,
    adults,
    child,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const [dayActivities, setDayActivities] = useState([]);
  const [totalDayLunch, setTotalDayLunch] = useState(0);
  const [totalDayDinner, setTotalDayDinner] = useState(0);
  const [totalDayBreakfast, setTotalDayBreakfast] = useState(0);
  const [dayHotel, setDayHotel] = useState([]);
  const [expandedStates, setExpandedStates] = useState({});

  const handleRemoveHotel = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this hotel?"
    );

    if (!isConfirmed) {
      return;
    }
    const updatePayload = {
      day: props.day,
      selectedHotel: dayHotel[0].selectedHotel,
    };
    console.log("updatePayload: ", updatePayload);
    // API request to update the selected activity
    fetch(`https://travaurabackend.azurewebsites.net/deleteHotel/${props.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatePayload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data: ", data);
        setDayHotel([]);
        setSelectedHotel(
          selectedHotel.filter((item) => item.day !== props.day)
        );
        props.setDeleteCounter((prevCount) => prevCount + 1);
      });
  };

  const handleRemoveActivity = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this activity?"
    );

    if (!isConfirmed) {
      return;
    }

    const updatePayload = {
      day: props.day,
      selectedActivity: dayActivities[0].Activities,
    };
    console.log("updatePayload: ", updatePayload);
    // API request to update the selected activity
    fetch(
      `https://travaurabackend.azurewebsites.net/deleteActivity/${props.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatePayload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("data: ", data);
        setDayActivities([]);
        setItenary(itenary.filter((item) => item.day !== props.day));
        console.log("itenary: ", props.itenary);
        props.setDeleteCounter((prevCount) => prevCount + 1);
      });
  };

  const handleExpandClick = (index) => {
    setExpandedStates((prevStates) => ({
      ...prevStates,
      [index]: !prevStates[index],
    }));
  };
  useEffect(() => {
    const activitiesForDay = props.itenary.filter(
      (item) => item.day === props.day
    );
    setDayActivities(activitiesForDay);
    console.log("activitiesForDay: ", activitiesForDay);
    console.log("dayActivities: ", dayActivities);
  }, [props.day, props.itenary]);

  useEffect(() => {
    console.log("source: ", props.ss);
  }, [props.ss]);
  useEffect(() => {
    const hotelForDay = props.selectedHotel.filter(
      (item) => item.day === props.day
    );
    setDayHotel(hotelForDay);
    console.log("hotelForDay: ", hotelForDay);
    console.log("selectedHotel: ", props.selectedHotel);
  }, [props.day, props.selectedHotel]);

  useEffect(() => {
    let tempItenary = props.itenary.filter((item) => item.day === props.day);
    let tempBreakfast = 0;
    let tempLunch = 0;
    let tempDinner = 0;
    tempItenary.forEach((item) => {
      if (item.Activities.Breakfast === "Yes") {
        tempBreakfast += 1;
      }
      if (item.Activities.Lunch === "Yes") {
        tempLunch += 1;
      }
      if (item.Activities.Dinner === "Yes") {
        tempDinner += 1;
      }
    });
    setTotalDayBreakfast(tempBreakfast);
    setTotalDayLunch(tempLunch);
    setTotalDayDinner(tempDinner);
  }, [props.itenary]);

  const handleNavigation = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to change this activity/Add Activity to this day?"
    );

    if (!isConfirmed) {
      return;
    }

    navigate(
      `activities/${props.country}/${props.day}/${
        dayActivities[0] && dayActivities[0].Activities.PartofCountry
          ? dayActivities[0].Activities.PartofCountry.trim()
          : "all"
      }`
    );
  };

  const handleHotelNavigation = () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to change this hotel/Add Hotel to this day?"
    );

    if (!isConfirmed) {
      return;
    }
    navigate(
      `hotels/${props.country}/${props.day}/${
        dayHotel[0] && dayHotel[0].selectedHotel.Region
          ? dayHotel[0].selectedHotel.Region.trim()
          : "all"
      }/${props.adults + props.child}`
    );
  };

  return (
    <div className="my-3 rounded-lg text-white ">
      <div className="font-semibold  text-START md:text-left flex justify-between md:w-4/6 mb-4">
        <p>Day {props.day}</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-11 gap-4">
        {/* Left section - Activities */}
        <div className="md:col-span-8 space-y-2 bg-[#212121] p-4 rounded-lg">
          <div className="flex justify-between">
            <p className="text-gray-400 font-bold"> Activities</p>
            <div className="flex justify-end">
              <ChangeCircleOutlinedIcon
                className="text-2xl text-gray-400 mx-3  cursor-pointer print-proof"
                onClick={handleNavigation}
              />
              <HighlightOffIcon
                className="text-2xl text-gray-400 cursor-pointer print-proof"
                onClick={handleRemoveActivity}
              />
            </div>
          </div>
          {dayActivities.length > 0 ? (
            dayActivities.map((item, ind) => (
              <div
                key={ind}
                className="md:flex flex-col items-center  md:items-start "
              >
                <div className="flex flex-row  items-center w-full">
                  <img
                    src={
                      (item.Activities.FeaturedImage &&
                        item.Activities.FeaturedImage.split(", ")[0]) ||
                      "https://res.cloudinary.com/dxcq5zluj/image/upload/v1702471626/Vietnam/Activities/ewbtilmbyshexsfxtvkl.jpg"
                    }
                    alt=""
                    className="w-32 h-32  rounded-lg mb-5 mt-2 md:my-0 "
                  />
                  <div className="flex flex-col items-start text-start md:text-start pl-4">
                    <div className="font-bold text-sm md:text-xl">
                      {item.Activities && item.Activities.Headingofevent}
                    </div>
                    <div className="text-xs text-gray-400">
                      {item.Activities.City}
                    </div>
                    <button
                      className="text-blue-500 text-xs py-2 rounded-md mt-2 print-proof w-fit"
                      onClick={() => handleExpandClick(ind)}
                    >
                      {!expandedStates[ind]
                        ? "Hide Description"
                        : "Read Description"}
                    </button>
                  </div>
                </div>
                <div>
                  {!expandedStates[ind] && (
                    <div className="bg-secondary p-4 rounded-md mt-6 ">
                      <div className="text-lg text-gray-300">
                        {props.country === "Bali" && (
                          <p className="text-gray-400">Places Covered: </p>
                        )}
                        {item.Activities.PlacesCovered
                          ? item.Activities.PlacesCovered
                          : ""}
                      </div>
                      {/* <p className="text-lg text-gray-400">Description: </p> */}
                      {item &&
                        item.Activities &&
                        item.Activities.LongDescription &&
                        item.Activities.LongDescription.split("\n").map(
                          (line, index) => (
                            <span
                              className="text-gray-300 text-xs md:text-md"
                              key={index}
                            >
                              {line}
                              <br />
                            </span>
                          )
                        )}
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="md:flex flex-col items-center  md:items-start">
              <div className="flex flex-row  items-center w-full">
                <img
                  src="https://res.cloudinary.com/dxcq5zluj/image/upload/v1705046847/ixxwg9jvwgdkzmkrgwhy.jpg"
                  alt=""
                  className="w-32 h-32 rounded-lg my-5 md:my-0"
                />
                {/* <div className="text-left mr-4 text-white ">
                No Activity Selected for the Day.
              </div> */}
                <div className="flex flex-col items-start text-start md:text-start pl-4">
                  <div className="font-bold text-sm md:text-xl">
                    Day at Leisure
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="md:col-span-3 flex flex-col justify-start space-y-4 bg-[#212121] md:px-2 rounded-lg p-4">
          <div className="flex justify-between ">
            <p className="text-gray-400 font-bold">Accommodation</p>
            <div className="font-bold text-center flex justify-between px-3">
              <ChangeCircleOutlinedIcon
                className="text-2xl text-gray-400 mx-3 md:mx-1 cursor-pointer print-proof"
                onClick={handleHotelNavigation}
              />
              <HighlightOffIcon
                className="text-2xl text-gray-400 cursor-pointer print-proof"
                onClick={handleRemoveHotel}
              />
            </div>
          </div>

          {dayHotel.length > 0 ? (
            dayHotel.map((hotelItem, hotelIndex) => (
              <div key={hotelIndex} className="text-left ">
                <div>{hotelItem.selectedHotel.Name}</div>
                <div>{hotelItem.selectedHotel.RoomType}</div>
                <div>{hotelItem.numberOfRooms} Rooms</div>
                <div>{hotelItem.numberofSupplements} Supplements</div>
                <div className="text-gray-400 text-sm">
                  {hotelItem.selectedHotel.Category}
                </div>
              </div>
            ))
          ) : dayActivities &&
            dayActivities.length > 0 &&
            dayActivities.some(
              (activityItem) =>
                activityItem &&
                activityItem.Activities &&
                activityItem.Activities.Headingofevent &&
                activityItem.Activities.Headingofevent.toLowerCase().includes(
                  "overnight"
                )
            ) ? (
            <div className="text-left">Overnight Cruise Selected</div>
          ) : (
            <div className="text-left ">
              No Hotel Selected for the Day.
            </div>
          )}
        </div>
      </div>

      {/* <AirportTransferCard
        day={props.day}
        daywiseTransfers={props.daywiseTransfers}
      /> */}
    </div>
  );
};

export default ActivityCard;
