import React from "react";
import { useState, useEffect } from "react";
import InternationalFlightComponent from "./InternationalFlightComponent";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import { useNavigate } from "react-router-dom";
import PageSkeleton from "./result/SkeletonPage";

const InternationalFlights = () => {
  const [loader, setLoader] = useState(false);
  // const {numberofFlights, setNumberofFlights, adults, setAdults, child, setChild, infants, internationalFlightsPrice, setInternationalFlightsPrice, internationalFlights, setInternationalFlights} = useContext(UserContext);
  const {
    numberofFlights,
    setNumberofFlights,
    setInternationalFlights,
    adults,
    setAdults,
    child,
    setChild,
    infants,
    setInfants,
    selectedFlightPrice,
    transferPrice,
    setTransferPrice,
    selectedHotel,
    activityPrice,
    addonPrice,
    nights,
    setNights,
    country,
    travellerDetails,
    setTravellerDetails,
    selectedFlightData,
    setSelectedFlightData,
    numberOfDays,
    internationalFlightsPrice,
    internationalFlights,

    addonDinner,
    setAddonDinner,
    addonLunch,
    setAddonLunch,
    addonBreakfast,
    setAddonBreakfast,
    itenary,
    setItenary,
    setInternationalFlightsPrice,
    daywiseTransfers,
    setDaywiseTransfers,
    EarlyCheckIn,
    setEarlyCheckIn,
    LateCheckOut,
    setLateCheckOut,
    TATAAIGInsurance,
    setTATAAIGInsurance,
    Cancellation,
    setCancellation,
    visa,
    setVisa,
    peakSeasonMarkup,
    setPeakSeasonMarkup,
    validTillDate,
    setValidTillDate,
    selectedBusData,
    setSelectedBusData,
    selectedTrainData,
    setSelectedTrainData,
    SelectedBaliIntercityTransfers,
  } = useContext(UserContext);
  const duration = parseInt(travellerDetails.duration) || 0;
  const [hotelPrice, setHotelPrice] = useState(0);
  const [toggletnc, setToggletnc] = useState(false);
  const [totalActivityBreakfast, setTotalActivityBreakfast] = useState(0);
  const [totalActivityLunch, setTotalActivityLunch] = useState(0);
  const [totalActivityDinner, setTotalActivityDinner] = useState(0);

  const formatCurrency = (value) => {
    return value.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
    });
  };

  const generateCustomId = (prefix = "TRAV") => {
    return `${prefix}-${Math.random().toString(36).substr(2, 9)}`;
  };

  // Check and set customId
  const getOrCreateCustomId = () => {
    let id = sessionStorage.getItem("customId");
    if (!id) {
      id = generateCustomId();
      sessionStorage.setItem("customId", id);
    }
    return id;
  };

  const [customId, setCustomId] = useState(getOrCreateCustomId());

  useEffect(() => {
    console.log("customId: ", customId);
  }, [customId]);

  const handlePost = async() => {
    setLoader(true);
    const data = {
      id: customId,
      travellerDetails: travellerDetails || [],
      country: country || "",
      selectedActivities: itenary || {},
      selectedHotels: selectedHotel || {},
      selectedTransfers: daywiseTransfers || {},
      selectedDomesticFlights: selectedFlightData || {},
      selectedInternationalFlights: internationalFlights || {},
      selectedAddons: {
        earlyCheckIn: EarlyCheckIn || false,
        lateCheckOut: LateCheckOut || false,
        addonBreakfast: addonBreakfast || 0,
        addonLunch: addonLunch || 0,
        addonDinner: addonDinner || 0,
        TATAAIGInsurance: TATAAIGInsurance || false,
        Cancellation: Cancellation || false,
        visa: visa || "",
        peakSeasonMarkup: peakSeasonMarkup || 0,
        validTillDate: validTillDate || "",
      },
      selectedBuses: selectedBusData || {},
      selectedTrains: selectedTrainData || {},
      selectedBaliICTransfers: SelectedBaliIntercityTransfers || {},
    };
    console.log("data to post: ", data);
    try {
      const response = await fetch("https://travaurabackend.azurewebsites.net/api/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (response.ok) {
        setLoader(false);
        navigate(`/resultPage/${customId}`);
      } else {
        // Handle response error
        console.error('Error posting data');
      }
    } catch (error) {
      console.error('Error during fetch: ', error);
    }
    
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (internationalFlights.length === 0) {
      setInternationalFlightsPrice(0);
      return;
    } else {
      let tempInternationalFlightsPrice = 0;
      internationalFlights.forEach((item) => {
        tempInternationalFlightsPrice +=
          parseInt(item.price) *
          (parseInt(adults) + parseInt(child) + parseInt(infants));
      });
      setInternationalFlightsPrice(tempInternationalFlightsPrice);
    }
  }, [internationalFlights]);
  const initialState = {
    departureCity: "",
    arrivalCity: "",
    carrier: "",
    baggage: "",
    journeyDate: "",
    departureTime: "",
    arrivalTime: "",
    price: 0,
    noOfStops: 0,
  };

  useEffect(() => {
    const newFlightsData = [...Array(parseInt(numberofFlights))].map(
      (_, ind) => {
        return internationalFlights[ind] || { ...initialState };
      }
    );

    setInternationalFlights(newFlightsData);
  }, [numberofFlights]);

  useEffect(() => {
    console.log("internationalFlightsPrice: ", internationalFlightsPrice);
  }, [internationalFlightsPrice]);

  if(loader){
    return (
      <PageSkeleton />
    )
  }
  return (
    <div className="bg-gray-800 min-h-screen p-5">
      <div>
        <h1 className="text-4xl font-semibold text-gray-300 text-center md:text-left">
          International Flights
        </h1>
        <div className="flex justify-center md:justify-start my-5">
          <label className="text-gray-300 mx-3 my-auto">
            Number of Flights:{" "}
          </label>
          <input
            className="hover:scale-105 w-20 h-10 border-2 border-gray-500 rounded-md text-black"
            type="number"
            onWheel={(e) => e.target.blur()}
            min="0"
            max="10"
            value={numberofFlights}
            onChange={(e) => {
              const value = e.target.value.trim();
              setNumberofFlights(
                value ? Math.max(0, Math.min(parseInt(value, 10), 100)) : 0
              );
            }}
          />
        </div>
      </div>

      {numberofFlights && (
        <div className="grid grid-cols-1 gap-4">
          {Array.from({
            length: Math.max(0, Math.min(parseInt(numberofFlights) || 0, 100)),
          }).map((_, ind) => (
            <InternationalFlightComponent key={ind} flightId={ind} />
          ))}
        </div>
      )}

      <div className="flex justify-center mt-10">
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-lg ml-4"
          onClick={handlePost}
        >
          Get Itinerary
        </button>
      </div>
    </div>
  );
};

export default InternationalFlights;
