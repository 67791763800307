import React from "react";
import UserContext from "./UserContext";
import { useState } from "react";

const UserProvider = ({ children }) => {
  const [selectedCombodiaID, setSelectedCombodiaID] = useState("");

  const [country, setCountry] = useState("");
  const [CambodiaAccomodationType, setCambodiaAccomodationType] = useState("");
  const [CambodiaPackagesData, setCambodiaPackagesData] = useState([]);
  const [CambodiaWhen, setCambodiaWhen] = useState("");
  const [selectedCambodiaPackage, setSelectedCambodiaPackage] = useState([]);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [nights, setNights] = useState(0);
  const [adults, setAdults] = useState(0);
  const [child, setChild] = useState(0);
  const [infants, setInfants] = useState(0);
  const [itenary, setItenary] = useState([]);
  const [transfer, setTransfer] = useState([]);
  const [addonPrice, setAddonPrice] = useState(0);
  const [activityPrice, setActivityPrice] = useState(0);
  const [selectedBusData, setSelectedBusData] = useState([]);
  const [selectedTrainData, setSelectedTrainData] = useState([]);
  const [travellerDetails, setTravellerDetails] = useState({
    name: "",
    country: "",
    tripType: "",
    dateOfTravel: "",
    duration: 0,
    accommodationType: "",
    domesticFlights: "",
    internationalFlights: "",
    adults: 0,
    child: 0,
    infants: 0,
    visa: "",
  });
  const [globalCityStructure, setGlobalCityStructure] = useState([]);
  const [ActivityData, setActivityData] = useState([]);
  const [numberOfPeople, setNumberOfPeople] = useState(0);
  const [transportDropdowns, setTransportDropdowns] = useState({
    Source: "",
    Arrival: "",
    Type: "",
    Carrier: "",
    Baggage: "",
  });
  const [numberofFlights, setNumberofFlights] = useState(0);
  const [daywiseTransfers, setDaywiseTransfers] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState([]);
  const [selectedCountryData, setSelectedCountryData] = useState([]);
  const [selectedTransportData, setSelectedTransportData] = useState([]);
  const [selectedFlightData, setSelectedFlightData] = useState([]);
  const [transferPrice, setTransferPrice] = useState(0);
  const [selectedFlightPrice, setSelectedFlightPrice] = useState(0);
  const [clickedButtons, setClickedButtons] = useState([]);
  const [internationalFlights, setInternationalFlights] = useState([]);
  const [internationalFlightsPrice, setInternationalFlightsPrice] = useState(0);

  const [addonBreakfast, setAddonBreakfast] = useState(0);
  const [addonLunch, setAddonLunch] = useState(0);
  const [addonDinner, setAddonDinner] = useState(0);

  const [regions, setRegions] = useState([]);

  const [cities, setCities] = useState([]);

  const [HotelCities, setHotelCities] = useState([]);
  const [HotelCategories, setHotelCategories] = useState([""]);
  const [HotelRooms, setHotelRooms] = useState([0]);
  const [HotelSupplements, setHotelSupplements] = useState([0]);
  const [TransferCities, setTransferCities] = useState([]);
  const [clickedHotelId, setClickedHotelId] = useState(null);

  //addons
  const [EarlyCheckIn, setEarlyCheckIn] = useState(false);
  const [LateCheckOut, setLateCheckOut] = useState(false);
  const [TATAAIGInsurance, setTATAAIGInsurance] = useState(false);
  const [Cancellation, setCancellation] = useState(false);
  const [visa, setVisa] = useState("");

  //peakSeasonMarkup
  const [peakSeasonMarkup, setPeakSeasonMarkup] = useState(0);

  const [validTillDate, setValidTillDate] = useState("");

  const [clickedTransferIds, setClickedTransferIds] = useState([]);
  const [isAirportTransfers, setIsAirportTransfers] = useState([]);
  const [noOfTransfers, setNoOfTransfers] = useState([]);

  const [DomesticFlightPricesbyDay, setDomesticFlightPricesbyDay] = useState(
    []
  );
  const [DomesticFlightArrivalTimebyDay, setDomesticFlightArrivalTimebyDay] =
    useState([]);
  const [
    DomesticFlightDepartureTimebyDay,
    setDomesticFlightDepartureTimebyDay,
  ] = useState([]);
  const [DomesticFlightNoOfStopsbyDay, setDomesticFlightNoOfStopsbyDay] =
    useState([]);
  const [DomesticFlightCarrierbyDay, setDomesticFlightCarrierbyDay] = useState(
    []
  );
  const [DomesticFlightBaggagebyDay, setDomesticFlightBaggagebyDay] = useState(
    []
  );
  const [DomesticFlightSourcebyDay, setDomesticFlightSourcebyDay] = useState(
    []
  );
  const [DomesticFlightArrivalCitybyDay, setDomesticFlightArrivalbyDay] =
    useState([]);
  const [
    DomesticFlightArrivalRegionbyDay,
    setDomesticFlightArrivalRegionbyDay,
  ] = useState([]);
  const [clickedDomesticFlights, setClickedDomesticFlights] = useState([]);
  const [TypeofIntercityTransferbyDay, setTypeofIntercityTransferbyDay] =
    useState([]);
  const [isIntercitytransferbyDay, setIsIntercitytransferbyDay] = useState([]);

  const [selectedTrainPriceByDay, setSelectedTrainPriceByDay] = useState([]);
  const [selectedBusPriceByDay, setSelectedBusPriceByDay] = useState([]);

  const [TrainChecked, setTrainChecked] = useState([]);
  const [BusChecked, setBusChecked] = useState([]);

  const [SelectedBaliIntercityTransfers, setSelectedBaliIntercityTransfers] =
    useState([]);
  const [BaliIntercitySourceByDay, setBaliIntercitySourceByDay] = useState([]);
  const [BaliIntercityArrivalByDay, setBaliIntercityArrivalByDay] = useState(
    []
  );
  const [checkedBaliIntercityTransfers, setCheckedBaliIntercityTransfers] =
    useState([]);
  const [numberOfIntercityTransfersBali, setNumberOfIntercityTransfersBali] =
    useState([]);
  const [transferData, setTransferData] = useState([]);

  const [flights, setFlights] = useState([]);
  const [
    selectedInternationalFlightOffers,
    setSelectedInternationalFlightOffers,
  ] = useState([]);

  const [isInternationalFlight, setIsInternationalFlight] = useState(false);

  const [selectedInternationalFlights, setSelectedInternationalFlights] =
    useState([]);
  const [selectedDomesticFlights, setSelectedDomesticFlights] = useState([]);

  const [hotelData, setHotelData] = useState([]);
  const [InterCityTransferData, setInterCityTransferData] = useState([]);

  const [selectedCambodiaHotels, setSelectedCambodiaHotels] = useState([]);

  const [
    selectedCitiesForAirportTransfer,
    setSelectedCitiesForAirportTransfer,
  ] = useState([]);
  const [packageDetails, setPackageDetails] = useState({
    packageDetails: {
      packageId: "",
      country: "",
      creationDate: "",
      lastUpdated: "",
      language: "",
      name: "",
      days: 0,
      nights: 0,
      placesCovered: "",
      citiesCovered: "",
      seasonality: "",
      difficultyLevel: "",
      theme: "",
      maxGroupSize: 0,
      customizability: false,
      tags: "",
      packageTypes: "",
      inclusions: "",
      exclusions: "",
      optionalActivities: "",
      cancellationPolicy: "",
      mealPlanDetails: "",
      healthVaccinationRequirements: "",
      visaAssistance: false,
      culturalEtiquetteTips: "",
      testimonialsRatings: {
        testimonials: [],
        averageRating: 0,
      },
      photoGallery: [],
      videoOverview: "",
      interactiveMap: "",
    },
    accommodations: {
      hotelCategory: "",
      roomType: "",
      amenities: "",
      locationType: "",
    },
    additionalFeatures: {
      nightsInEachCity: [
        {
          city: "",
          nights: 0,
        },
      ],
      itineraryDetails: [],
      transportationDetails: "",
      guideServices: {
        available: false,
        languages: [],
      },
      travelInsurance: "",
      paymentOptions: [],
      healthSafetyMeasures: "",
      sustainabilityPractices: "",
      emergencySupport: "",
      localPartners: "",
    },
  });
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedAddons, setSelectedAddons] = useState({});

  const [totalNumberofThingsSelected, setTotalNumberofThingsSelected] =
    useState(0);

  return (
    <UserContext.Provider
      value={{
        totalNumberofThingsSelected,
        setTotalNumberofThingsSelected,
        selectedAddons,
        setSelectedAddons,
        selectedPackage,
        setSelectedPackage,
        selectedCitiesForAirportTransfer,
        setSelectedCitiesForAirportTransfer,
        packageDetails,
        setPackageDetails,
        selectedCambodiaHotels,
        setSelectedCambodiaHotels,
        InterCityTransferData,
        setInterCityTransferData,
        hotelData,
        setHotelData,
        CambodiaAccomodationType,
        setCambodiaAccomodationType,
        selectedCombodiaID,
        setSelectedCombodiaID,
        CambodiaWhen,
        setCambodiaWhen,
        CambodiaPackagesData,
        setCambodiaPackagesData,
        selectedCambodiaPackage,
        setSelectedCambodiaPackage,
        selectedInternationalFlights,
        setSelectedInternationalFlights,
        selectedDomesticFlights,
        setSelectedDomesticFlights,
        isInternationalFlight,
        setIsInternationalFlight,
        selectedInternationalFlightOffers,
        setSelectedInternationalFlightOffers,
        flights,
        setFlights,
        transferData,
        setTransferData,
        numberOfIntercityTransfersBali,
        setNumberOfIntercityTransfersBali,
        checkedBaliIntercityTransfers,
        setCheckedBaliIntercityTransfers,
        BaliIntercitySourceByDay,
        setBaliIntercitySourceByDay,
        BaliIntercityArrivalByDay,
        setBaliIntercityArrivalByDay,
        SelectedBaliIntercityTransfers,
        setSelectedBaliIntercityTransfers,
        TransferCities,
        setTransferCities,
        TrainChecked,
        setTrainChecked,
        BusChecked,
        setBusChecked,
        selectedTrainPriceByDay,
        setSelectedTrainPriceByDay,
        selectedBusPriceByDay,
        setSelectedBusPriceByDay,
        DomesticFlightArrivalRegionbyDay,
        setDomesticFlightArrivalRegionbyDay,
        isIntercitytransferbyDay,
        setIsIntercitytransferbyDay,
        TypeofIntercityTransferbyDay,
        setTypeofIntercityTransferbyDay,
        DomesticFlightArrivalCitybyDay,
        setDomesticFlightArrivalbyDay,
        DomesticFlightPricesbyDay,
        setDomesticFlightPricesbyDay,
        DomesticFlightArrivalTimebyDay,
        setDomesticFlightArrivalTimebyDay,
        DomesticFlightDepartureTimebyDay,
        setDomesticFlightDepartureTimebyDay,
        DomesticFlightNoOfStopsbyDay,
        setDomesticFlightNoOfStopsbyDay,
        DomesticFlightCarrierbyDay,
        setDomesticFlightCarrierbyDay,
        DomesticFlightBaggagebyDay,
        setDomesticFlightBaggagebyDay,
        DomesticFlightSourcebyDay,
        setDomesticFlightSourcebyDay,
        clickedDomesticFlights,
        setClickedDomesticFlights,
        isAirportTransfers,
        setIsAirportTransfers,
        noOfTransfers,
        setNoOfTransfers,
        HotelCities,
        setHotelCities,
        HotelCategories,
        setHotelCategories,
        HotelRooms,
        setHotelRooms,
        HotelSupplements,
        setHotelSupplements,
        clickedTransferIds,
        setClickedTransferIds,
        EarlyCheckIn,
        setEarlyCheckIn,
        LateCheckOut,
        setLateCheckOut,
        TATAAIGInsurance,
        setTATAAIGInsurance,
        Cancellation,
        setCancellation,
        visa,
        setVisa,
        validTillDate,
        setValidTillDate,
        regions,
        setRegions,
        cities,
        setCities,
        numberofFlights,
        setNumberofFlights,
        clickedHotelId,
        setClickedHotelId,
        addonDinner,
        setAddonDinner,
        addonLunch,
        setAddonLunch,
        addonBreakfast,
        setAddonBreakfast,
        peakSeasonMarkup,
        setPeakSeasonMarkup,
        internationalFlights,
        setInternationalFlights,
        internationalFlightsPrice,
        setInternationalFlightsPrice,
        clickedButtons,
        setClickedButtons,
        selectedFlightPrice,
        setSelectedFlightPrice,
        transferPrice,
        setTransferPrice,
        activityPrice,
        setActivityPrice,
        addonPrice,
        setAddonPrice,
        adults,
        setAdults,
        child,
        setChild,
        infants,
        setInfants,
        nights,
        setNights,
        travellerDetails,
        setTravellerDetails,
        selectedFlightData,
        setSelectedFlightData,
        numberOfPeople,
        setNumberOfPeople,
        ActivityData,
        setActivityData,
        globalCityStructure,
        setGlobalCityStructure,
        selectedTransportData,
        setSelectedTransportData,
        selectedCountryData,
        setSelectedCountryData,
        transportDropdowns,
        daywiseTransfers,
        setDaywiseTransfers,
        setTransportDropdowns,
        country,
        setCountry,
        numberOfDays,
        setNumberOfDays,
        itenary,
        setItenary,
        transfer,
        setTransfer,
        selectedHotel,
        setSelectedHotel,
        selectedBusData,
        setSelectedBusData,
        selectedTrainData,
        setSelectedTrainData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
