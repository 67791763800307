import React, { useState, useEffect } from "react";
import { useContext } from "react";
import UserContext from "./contexts/UserContext";
import Transfer from "./Transfer";
import ItineraryDescription from "./Description";
import { motion } from "framer-motion";
import { useRef } from "react";
const Activities = (props) => {
  const [filterData, setFilterData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const {
    clickedButtons,
    setClickedButtons,
    itenary,
    setItenary,
    ActivityData,
  } = useContext(UserContext);

  useEffect(() => {
    console.log("cityrnfe: ", props.city);
    console.log("regiongvkldrfm: ", props.region);

    let tempData = ActivityData;

    if (props.region) {
      tempData = tempData.filter(
        (item) => item.PartofCountry && item.PartofCountry === props.region
      );
    }

    if (props.city) {
      const formattedSearchCity = props.city.replace(/\s+/g, "").toLowerCase();
      tempData = tempData.filter(
        (item) =>
          item.City &&
          item.City.replace(/\s+/g, "").toLowerCase() === formattedSearchCity
      );
    }

    tempData = tempData.filter((item) => item.PriceinRupees > 0);

    if (props.activityPrompt) {
      props.activityPrompt.split(" ").forEach((word) => {
        const lowerCaseWord = word.toLowerCase();
        tempData = tempData.filter(
          (item) =>
            (item.Headingofevent &&
              typeof item.Headingofevent === "string" &&
              item.Headingofevent.toLowerCase()
                .replace(/\s+/g, "")
                .includes(lowerCaseWord)) ||
            (item.PlacesCovered &&
              item.PlacesCovered.toLowerCase().includes(lowerCaseWord)) ||
            (item.City &&
              item.City.replace(/\s+/g, "")
                .toLowerCase()
                .includes(lowerCaseWord)) ||
            (item.PartofCountry &&
              item.PartofCountry.toLowerCase().includes(lowerCaseWord)) ||
            (item.Cityofaccommodation &&
              item.Cityofaccommodation.toLowerCase().includes(lowerCaseWord)) ||
            (item.Star && item.Star.toLowerCase().includes(lowerCaseWord))
        );
      });
    }

    tempData.sort((a, b) => {
      const aSelected = clickedButtons.some(
        (button) => button.itemId === a._id && button.day === props.day
      );
      const bSelected = clickedButtons.some(
        (button) => button.itemId === b._id && button.day === props.day
      );
      return bSelected - aSelected; // Selected activities will come first
    });

    setFilterData(tempData);

    if (clickedButtons.length > 0) {
      tempData = tempData.filter(
        (item) =>
          // Only hide the activity if it was clicked on a different day
          !clickedButtons.some(
            (clickedItem) =>
              clickedItem.itemId === item._id && clickedItem.day !== props.day
          )
      );
    }

    setFilterData(tempData);
  }, [
    props.region,
    props.city,
    props.activityPrompt,
    clickedButtons,
    ActivityData,
    itenary,
  ]);

  const handleButtonClick = (item) => {
    const itemId = item._id;

    const alreadySelected = clickedButtons.some(
      (button) => button.itemId === itemId && button.day === props.day
    );

    if (!alreadySelected) {
      setClickedButtons([...clickedButtons, { itemId, day: props.day }]);
      setItenary([
        ...itenary,
        {
          day: props.day,
          region: props.region,
          city: props.city,
          Activities: item,
        },
      ]);
    } else {
      const updatedClickedButtons = clickedButtons.filter(
        (button) => !(button.itemId === itemId && button.day === props.day)
      );
      setClickedButtons(updatedClickedButtons);
      const updatedItenary = itenary.filter(
        (activity) =>
          !(activity.Activities._id === itemId && activity.day === props.day)
      );
      setItenary(updatedItenary);
    }
  };

  const [showMoreActive, setShowMoreActive] = useState(null);

  const handleShowMore = (itemId) => {
    setShowMoreActive(itemId);
  };

  const handleCloseMore = () => {
    setShowMoreActive(null);
  };

  const floatingSectionRef = useRef(null);

  // Function to close the floating section if clicked outside
  const handleClickOutside = (event) => {
    if (
      floatingSectionRef.current &&
      !floatingSectionRef.current.contains(event.target)
    ) {
      setShowMoreActive(null); // Close the floating section
    }
  };

  useEffect(() => {
    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    console.log("selectedData: ", selectedData);
    console.log("Itenary : ", itenary);
    console.log("City : ", props.city);
    console.log("Region : ", props.region);
    console.log("Day : ", props.day);
    console.log("Itinerary : ", itenary);
    console.log("ActivityData : ", ActivityData);
    console.log("filterData : ", filterData);
  }, [
    selectedData,
    itenary,
    props.city,
    props.region,
    props.day,
    itenary,
    ActivityData,
    filterData,
  ]);

  return (
    <div className="flex overflow-x-auto w-full">
      {filterData.map((item) => (
        <div key={item._id} className="mx-4 my-2 flex-shrink-0">
          <div
            className="bg-secondary p-4 rounded-md "
            style={{ boxShadow: "1px 1px 3px 0px black" }}
          >
            <div className="flex">
              <div className="mb-2 p-2 flex-grow">
                <div className="h-[100px]">
                  <div
                    className="text-lg font-medium w-[200px]  truncate-2-lines"
                    title={item.Headingofevent}
                  >
                    {item.Headingofevent}
                  </div>

                  <div className="text-xs text-gray-400">{item.City}</div>
                  {item.Star && (
                    <div className="text-xs text-gray-400">{item.Star}</div>
                  )}
                </div>

                
              </div>
              <img
                src={
                  typeof item.FeaturedImage === "string"
                    ? item.FeaturedImage.split(", ")[0]
                    : ""
                }
                alt={item.Headingofevent}
                className="w-32 h-32 rounded-lg flex-shrink-0"
              />
            </div>
            <div className="flex flex-col">
              <button
                className="text-blue-500 text-xs px-4 py-2 rounded-md self-start"
                onClick={() => handleShowMore(item._id)}
              >
                {showMoreActive !== item._id ? "Show More" : "Show Less"}
              </button>
              {showMoreActive === item._id && (
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  transition={{ duration: 0.3 }}
                  className="fixed top-1/3 left-1/3 h-1/2 p-4 rounded-md z-10 bg-gray-800 overflow-x-auto"
                  ref={floatingSectionRef}
                >
                  <label className="text-xl text-gray-200">
                    {item.Headingofevent}
                  </label>
                  <div class="text-xl text-gray-300 max-w-xl">
                    Places Covered: {item.PlacesCovered}
                  </div>
                  {item.LongDescription.split("\n").map((item) => (
                    <p className="text-gray-400">{item}</p>
                  ))}
                  <div class="text-lg text-gray-400">
                    Accommodation For The Night: {item.Accommodationforthenight}
                  </div>
                  <button
                    className="text-purple-600 text-xs px-4 py-2 rounded-md self-start mt-2"
                    onClick={handleCloseMore}
                  >
                    Close
                  </button>
                </motion.div>
              )}
            </div>
            <div className="flex justify-end ">
              <div className="flex justify between">
            <div className="text-lg text-gray-200 mr-2">
                  ₹
                  {item.PriceinRupees
                    ? item.PriceinRupees.toLocaleString("en-IN")
                    : 0}
                </div>
              <button
                className={`hover:scale-105  text-xl px-2 rounded-md  ${
                  clickedButtons.some(
                    (button) =>
                      button.itemId === item._id && button.day === props.day
                  )
                    ? "bg-green-600"
                    : "bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121]"
                }`}
                onClick={() => handleButtonClick(item)}
              >
                {clickedButtons.some(
                  (button) =>
                    button.itemId === item._id && button.day === props.day
                ) ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  "+"
                )}
              </button>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* <Transfer data={props.data} city={props.city} day={props.day} region={props.region}/> */}
    </div>
  );
};

export default Activities;
