function TravellerDetailsSkeleton() {
    return (
      <div className="max-w-lg mx-auto animate-pulse">
        <div className="flex justify-start  space-x-4 mb-6">
          <div className="h-24 w-24 bg-gray-700 rounded"></div> {/* Logo placeholder */}
          <div className="h-8 w-48 bg-gray-700 rounded"></div> {/* Header placeholder */}
        </div>
  
        <form className="space-y-4">
          {Array.from({ length: 10 }).map((_, index) => (
            <div key={index} className="grid grid-cols-2 gap-4">
              <div className="h-10 bg-gray-700 rounded col-span-1"></div> {/* Input placeholder */}
              <div className="h-10 bg-gray-700 rounded col-span-1"></div> {/* Input placeholder */}
            </div>
          ))}
  
          <div className="flex justify-center">
            <div className="h-12 w-48 bg-gray-700 rounded"></div> {/* Button placeholder */}
          </div>
        </form>
      </div>
    );
  }
  
  export default TravellerDetailsSkeleton;
  