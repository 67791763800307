import React from "react";
import Transfer from "./Transfer";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "./contexts/UserContext";
import { useContext } from "react";
import { select } from "@material-tailwind/react";


const TransferCOMP = () => {
    const navigate = useNavigate();
  const { country, id, duration} = useParams();
  const [transferData, setTransferData] = useState([]);
  const [uniqueCities, setUniqueCities] = useState([]);
  const {selectedCitiesForAirportTransfer, setSelectedCitiesForAirportTransfer, selectedPackage, adults, child, daywiseTransfers,
    setDaywiseTransfers,} = useContext(UserContext);

  const [numberofDays, setNumberofDays] = useState( 
    selectedPackage?.selectedAirportTransferCities?.length > 0 ? selectedPackage?.selectedAirportTransferCities.length : 0
    );
//   const [selectedCitiesForAirportTransfer, setSelectedCitiesForAirportTransfer] = useState([]);

  

            

  useEffect(() => {
    console.log("country: ", country);
    console.log("numberofDays: ", numberofDays);
    // console.log("selectedCities: ", selectedCitiesForAirportTransfer);
  }, [country, numberofDays]);

  useEffect(() => {
    if (country === "Vietnam" || country === "Vietnam/Cambodia") {
      fetch("https://travaurabackend.azurewebsites.net/api/transfer")
        .then((response) => response.json())
        .then((data) => {
          setTransferData(data);
          console.log("transferData: ", data);
        });
    } else if (country === "Bali") {
      fetch("https://travaurabackend.azurewebsites.net/api/BaliTransfer")
        .then((response) => response.json())
        .then((data) => {
          setTransferData(data);
          console.log("transferData: ", data);
        });
    } else if (country === "Thailand") {
      fetch(
        "https://travaurabackend.azurewebsites.net/api/ThailandAirportTransfersSchema"
      )
        .then((response) => response.json())
        .then((data) => {
          setTransferData(data);
          console.log("transferData: ", data);
        });
    }
  }, [country]);

  useEffect(() => {
    if (selectedPackage?.selectedAirportTransferCities?.length > 0) {
      let tempDaywiseDefault = [];
  
      selectedPackage.selectedAirportTransferCities.forEach((city, index) => {
        let tempData = [...transferData];
        let minSeaterNeeded = parseInt(adults) + parseInt(child);
  
        // Filter transfers for the current city and sort by seater size
        let filteredTransfers = tempData
          .filter(transfer => transfer.city.toLowerCase() === city.toLowerCase())
          .sort((a, b) => a.seater - b.seater);
  
        // Find the first transfer that can accommodate the minSeaterNeeded
        let suitableTransfer = filteredTransfers.find(transfer => transfer.seater >= minSeaterNeeded);
  
        if (suitableTransfer) {
          tempDaywiseDefault.push({
            day: index + 1,
            transfer: suitableTransfer,
            noOfTransfer: 2 // Since we only need one default transfer per day
          });
        }
        
      });
  
      setDaywiseTransfers(tempDaywiseDefault); // Update your state with the default transfers
    }
  }, [transferData, selectedPackage, adults, child]); // Ensure all relevant dependencies are listed
  
       useEffect(() => {
        console.log("daywiseTransfers: ", daywiseTransfers);
        }, [daywiseTransfers]);
   

  useEffect(() => {
    let cities = transferData.map((transfer) => transfer.city);
    let uniqueCities = [...new Set(cities)];
    setUniqueCities(uniqueCities);
  }
    , [transferData]);
  return (
    <div>
      <div class="mb-4 my-14">
        <label class="flex items-center space-x-2 mb-2  my-14">
          <span class="text-gray-400 text-2xl font-medium">
            Airport Transfers
          </span>
        </label>
        <input
          type="number"
          class="border-2 border-gray-300 p-2 w-full"
          placeholder="Number of Days"
          onChange={(e) => setNumberofDays(e.target.value)}
          min={0}
          max={duration}
        />
<div className=" p-3 m-4">
{numberofDays > 0 ? (
  <div className="p-3  bg-[#212121]">
    { id.startsWith("TRAV") ?
    Array.from({ length: numberofDays }, (_, index) => (
      <Transfer key={index} day={index + 1} transferData={transferData} />
    )) :
    Array.from({ length: numberofDays }, (_, index) => (
      <select key={index} class=" mx-5 my-7 bg-gray-700 text-white rounded-md w-fit p-2" value={selectedCitiesForAirportTransfer[index] || ""} onChange={(e) => {
        let temp = [...selectedCitiesForAirportTransfer];
        temp[index] = e.target.value;
        setSelectedCitiesForAirportTransfer(temp);
        }}>
        <option value="">Select City</option>
        {uniqueCities.map((city, index) => (
          <option key={index} value={city}>
            {city}
          </option>
        )
        )}
        </select>
    ))
    }
  </div>
) : null}
</div>
      </div>
      <button
          className="py-2 px-4 bg-gradient-to-tr from-[#AADAD6] to-[#769895] text-[#212121]  rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 flex justify-between "
          onClick={() => {country==="Vietnam/Cambodia" ? navigate(`/flightsSearchResults/${id}/Vietnam/${duration}`) :
            navigate(`/flightsSearchResults/${id}/${country}/${duration}`)}}
        >
          Get Itinerary Directly
        </button>
    </div>
  );
};

export default TransferCOMP;
