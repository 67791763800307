import React from "react";
import { useState, useEffect } from "react";
import UserContext from "./contexts/UserContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const GetPackages = () => {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const {
    selectedPackage,
    setSelectedPackage,
    travellerDetails,
    setTravellerDetails,
    setCountry,
    setNumberOfDays,
    setSelectedHotel,
    setSelectedAddons,
    setDaywiseTransfers,
    
    setSelectedBaliIntercityTransfers,
    setCheckedBaliIntercityTransfers,
    setSelectedTrainData,
    setSelectedBusData,
    setCambodiaWhen,
    setSelectedCambodiaPackage,
    setCambodiaAccomodationType,
    setSelectedCambodiaHotels,
    selectedHotel,
    selectedAddons,
    daywiseTransfers,
    selectedBaliIntercityTransfers,
    checkedBaliIntercityTransfers,
    selectedTrainData,
    selectedBusData,
    selectedCambodiaPackage,
    selectedCambodiaHotels,
    setItenary,
    itenary,

  } = useContext(UserContext);

  useEffect(() => {
    fetch("https://travaurabackend.azurewebsites.net/api/packages")
    
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setPackages(data);
        console.log("packages: ", data);
      })
      .catch((error) => {
        console.error("Failed to fetch packages:", error);
      });
  }, []);

  const handleAddPackage = (selected) => () => {
    setSelectedPackage(selected);

    // Ensure that we have valid selected data before trying to update travellerDetails
    if (
      selected &&
      selected.packageDetails &&
      selected.packageDetails.packageDetails
    ) {
        setCountry(selected.country ?? "");
        setNumberOfDays(selected.packageDetails.packageDetails.days ?? 0);
        setSelectedHotel(selected.selectedHotels ?? []);
        setSelectedAddons(selected.selectedAddons[0] ?? {});
        setDaywiseTransfers(selected.selectedTransfers ?? []);
        setSelectedBaliIntercityTransfers(selected.selectedBaliICTransfers ?? []);
        setCheckedBaliIntercityTransfers(selected.selectedBaliICTransfers ?? []);
        setSelectedTrainData(selected.selectedTrains ?? []);
        setSelectedBusData(selected.selectedBuses ?? []);
        setCambodiaWhen(selected.CambodiaWhen ?? "");
        setSelectedCambodiaPackage(selected.selectedCambodiaPackage ?? []);
        setCambodiaAccomodationType(selected.CambodiaAccomodationType ?? "");
        setSelectedCambodiaHotels(selected.selectedCambodiaHotels ?? []);
        setItenary(selected.selectedActivities || []);


      setTravellerDetails((prevDetails) => ({
        ...prevDetails,
        country: selected.country ?? "",
        // Ensure a default value is provided in case of undefined 'days'
        duration: selected.packageDetails.packageDetails.days ?? 0,

      }));
    } else {
      console.error("Invalid package selection", selected);
      // Handle invalid package selection, e.g., reset to default state or show an error
    }
  };

  useEffect(() => {
    console.log("selectedPackage: ", selectedPackage);
    console.log("travellerDetails: ", travellerDetails);
    console.log("selectedHotel: ", selectedHotel);
    console.log("selectedAddons: ", selectedAddons);
    console.log("daywiseTransfers: ", daywiseTransfers);
    console.log("selectedBaliIntercityTransfers: ", selectedBaliIntercityTransfers);
    console.log("checkedBaliIntercityTransfers: ", checkedBaliIntercityTransfers);
    console.log("selectedTrainData: ", selectedTrainData);
    console.log("selectedBusData: ", selectedBusData);
    console.log("selectedCambodiaPackage: ", selectedCambodiaPackage);
    console.log("selectedCambodiaHotels: ", selectedCambodiaHotels);
    console.log("Itenary: ", itenary);

  }, [selectedPackage, travellerDetails, selectedHotel, selectedAddons, daywiseTransfers, selectedBaliIntercityTransfers, checkedBaliIntercityTransfers, selectedTrainData, selectedBusData, selectedCambodiaPackage, selectedCambodiaHotels]);

  const formatDate = (date) => {
    const d = new Date(date);
    const month = d.toLocaleString("default", { month: "long" });
    const day = d.getDate();
    const year = d.getFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <div className="text-white bg-secondary m-5 p-4 rounded-xl">
      <h1 className="text-3xl">All Packages</h1>

      <div className="grid grid-cols-8 gap-4">
        <div className="col-span-1">Package ID</div>
        <div className="col-span-1">Package Name</div>
        <div className="col-span-1">Country</div>
        <div className="col-span-1">Duration</div>
        <div className="col-span-1">Created At</div>
        <div className="col-span-1">Cities Covered</div>
        <div className="col-span-1">Tags</div>
        <div className="col-span-1">Add</div>
        <div class="col-span-8">
          <hr class="mx-5" />
        </div>
        {packages.map((packageData) => {
          return (
            <React.Fragment key={packageData._id}>
              <div className="col-span-1">
                {packageData.packageDetails?.packageDetails?.packageId ?? "-"}
              </div>
              <div className="col-span-1">
                {packageData.packageDetails?.packageDetails?.name ?? "-"}
              </div>
              <div className="col-span-1">{packageData.country ?? "-"}</div>
              <div className="col-span-1">
                {packageData.packageDetails?.packageDetails?.days ?? "-"}D{" "}
                {packageData.packageDetails?.packageDetails?.nights ?? "-"}N
              </div>
              <div className="col-span-1">
                {packageData.createdAt
                  ? formatDate(packageData.createdAt)
                  : "-"}
              </div>
              <div className="col-span-1">
                {packageData.packageDetails?.packageDetails?.citiesCovered ??
                  "-"}
              </div>
              <div className="col-span-1">
                {packageData.packageDetails?.packageDetails?.tags ?? "-"}
              </div>
              <button
                className={`rounded-xl col-span-1 ${
                  selectedPackage?._id === packageData._id
                    ? "bg-green-500"
                    : "bg-yellow-500"
                } p-2`}
                onClick={handleAddPackage(packageData)} // This should trigger the function
              >
                Add
              </button>
            </React.Fragment>
          );
        })}
      </div>
      <button
        className="bg-green-500 p-2 rounded-xl"
        onClick={() => {
        const confirm = window.confirm("Are you creating a new itinerary?");
        if (confirm) {
          // Add your logic here
          sessionStorage.removeItem("customId");
          navigate("/Pro Itinerary")
        } else {
          console.log("Cancelled");
          navigate("/Pro Itinerary")
        }
        }}
      >
        GO Create Itinerary
      </button>
    </div>
  );
};

export default GetPackages;

// setItenary(data.selectedActivities || []);

//         setNumberOfDays(data.travellerDetails.duration || 0);
//         setCountry(data.travellerDetails.country || "");
//         setSelectedHotel(data.selectedHotels || []);
//         setSelectedAddons(data.selectedAddons[0] || {});
//         setDaywiseTransfers(data.selectedTransfers || []);
//         setAdults(data.travellerDetails.adults || 0);
//         setChild(data.travellerDetails.child || 0);
//         setInfants(data.travellerDetails.infants || 0);
//         setSelectedBaliIntercityTransfers(data.selectedBaliICTransfers || []);
//         setCheckedBaliIntercityTransfers(data.selectedBaliICTransfers || []);
//         setSelectedTrainData(data.selectedTrains || []);
//         setSelectedBusData(data.selectedBuses || []);
//         setCountry(data.travellerDetails.country || "");
//         setCambodiaWhen(data.CambodiaWhen || "");
//         setSelectedCambodiaPackage(data.selectedCambodiaPackage || []);
//         setInternationalFlights(data.selectedInternationalFlights || {});
//         setSelectedFlightData(data.selectedDomesticFlights || {});
//         setSelectedInternationalFlights(data.BookingSelectedInternationalFlights || {});
//         setSelectedDomesticFlights(data.BookingSelectedDomesticFlights || {});
//         setCambodiaAccomodationType(data.CambodiaAccomodationType || "");
//        setSelectedCambodiaHotels(data.selectedCambodiaHotels || []);
// setItenary(data.selectedActivities || []);
