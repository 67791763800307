import React, { useState } from 'react';


const HotelForm = () => {
  const [formData, setFormData] = useState({
    Region: '',
    City: '',
    Category: '',
    Name: '',
    RoomType: '',
    MAPRoomPrice: '',
    CPRoomPrice: '',
    EPRoomPrice: '',
    PriceType: '',
    Rating: '',
    Image: []
  });
  const VietnamRegions = {
    Southern: ["Ho Chi Minh", "Da Lat", "Phu Quoc"],
    Northern: ["Ha Noi", "Sa Pa", "Ninh Binh"],
    SouthCentral: ["Da Lat", "Nha Trang"],
    Central: ["Da Nang", "Hoi An", "Hue", "Nha Trang", "Dong Hoi"]
  };
  const hotelCategories = [
    "3 Star",
    "4 Star",
    "5 Star"
  ];
  const roomTypes = [
    "Standard",
    "Deluxe",
    "Superior",
    "Super Deluxe",
    "Family Suite",
    "Junior Suite",
    "Senior Suite",
    "Pool Villa",
    "Garden View",
    "Sea View",
    "Pool View"
  ];



  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImage = async (e) => {
    e.preventDefault();
    try {
      const imageurl = await uploadImage(e.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  }

  const uploadImage = async (image) => {
    const data = new FormData();
    data.append('file', image);
    data.append('upload_preset', 'travaura_hotel');
    data.append('cloud_name', 'dxcq5zluj');
    const response = await fetch('https://api.cloudinary.com/v1_1/dxcq5zluj/image/upload', {
      method: 'POST',
      body: data
    });
    const file = await response.json();
    console.log(file.secure_url);
    setFormData({ ...formData, Image: [...formData.Image, file.secure_url] });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);
    try {
      const response = await fetch('https://travaurabackend.azurewebsites.net/api/hotel', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        
        if(!alert("Hotel Added Successfully")){window.location.reload();}
      } else {
        throw new Error('Something went wrong with the hotel addition');
      }
    } catch (error) {
      console.error('Submission failed:', error);
      alert("Failed to add hotel.");
    }
  };


  return (
    <form onSubmit={handleSubmit} className="max-w-md mx-auto p-4 space-y-3">
      <div>
        <label htmlFor="Region" className="block text-sm font-medium text-gray-200">Region:</label>
        <select name="Region" value={formData.Region} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
          <option value="">Select Region</option>
          {Object.keys(VietnamRegions).map((item, ind) => (
            <option key={ind} value={item} >{item}</option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="City" className="block text-sm font-medium text-gray-200">City:</label>
        <select name="City" value={formData.City} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
          <option value="">Select City</option>
          {VietnamRegions[formData.Region] && VietnamRegions[formData.Region].map((item, ind) => (
            <option key={ind} value={item} >{item}</option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="Category" className="block text-sm font-medium text-gray-200">Category:</label>
        <select name="Category" value={formData.Category} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
          <option value="">Select Category</option>
          {hotelCategories.map((item, ind) => (
            <option key={ind} value={item} >{item}</option>
          ))}
        </select>

      </div>

      <div>
        <label htmlFor="Name" className="block text-sm font-medium text-gray-200">Name:</label>
        <input type="text" name="Name" placeholder="Name" value={formData.Name} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" required />
      </div>

      <div>
        <label htmlFor="RoomType" className="block text-sm font-medium text-gray-200">Room Type:</label>
        <select name="RoomType" value={formData.RoomType} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
          <option value="">Select Room Type</option>
          {roomTypes.map((item, ind) => (
            <option key={ind} value={item} >{item}</option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="MAPRoomPrice" className="block text-sm font-medium text-gray-200">MAP Room Price:</label>
        <input type="number" name="MAPRoomPrice" placeholder="MAP Room Price" value={formData.MAPRoomPrice} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" required />
      </div>

      <div>
        <label htmlFor="CPRoomPrice" className="block text-sm font-medium text-gray-200">CP Room Price:</label>
        <input type="number" name="CPRoomPrice" placeholder="CP Room Price" value={formData.CPRoomPrice} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" required />
      </div>

      <div>
        <label htmlFor="EPRoomPrice" className="block text-sm font-medium text-gray-200">EP Room Price:</label>
        <input type="number" name="EPRoomPrice" placeholder="EP Room Price" value={formData.EPRoomPrice} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" required />
      </div>

      <div>
        <label htmlFor="PriceType" className="block text-sm font-medium text-gray-200">Price Type:</label>
        <input type="text" name="PriceType" placeholder="Price Type" value={formData.PriceType} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" required />
      </div>

      <div>
        <label htmlFor="Rating" className="block text-sm font-medium text-gray-200">Rating:</label>
        <input type="text" name="Rating" placeholder="Rating" value={formData.Rating} onChange={handleChange} className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500" />
      </div>

      <div>
        <label htmlFor="Image" className="block text-sm font-medium text-gray-200">Image:</label>
        <input
          type="file"
          name="Image"
          accept='image/*'
          placeholder="Image"
          onChange={handleImage}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>
      <div className='flex'>
        {formData.Image.length > 0 && formData.Image.map((item, ind) => (
          <img src={item} alt="" className='w-32 h-32' />
        ))}
      </div>




      <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Add Hotel</button>


    </form>
  );
};

export default HotelForm;
